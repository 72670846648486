import { createRef, useState } from "react";
import SignaturePad from "react-signature-pad-wrapper";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { Edit } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Input } from "@/shared/components/ui/input";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";

export interface SignatureData {
  name: string;
  signature: string;
}

export function SignatureDialog(props: {
  title: string;
  name: string;
  onConfirm: (data: SignatureData) => void;
}) {
  const { title, name, onConfirm } = props;
  const [open, setOpen] = useState(false);
  const [data, setData] = useState<SignatureData>({
    name,
    signature: "",
  });
  const [nameErrors, setNameErrors] = useState<string[]>([]);
  const [sigErrors, setSigErrors] = useState<string[]>([]);

  const ref = createRef<SignaturePad>();

  const clear = () => {
    setSigErrors([]);

    const pad = ref.current;
    if (!pad) {
      return;
    }
    pad.instance.clear();
  };

  const signatureToDataUrl = () => {
    const pad = ref.current?.instance;
    if (!pad || pad.isEmpty()) {
      return null;
    }

    return pad.toDataURL("image/svg+xml");
  };

  const validationPasses = () => {
    const signature = signatureToDataUrl();
    let sigValidationErrors: string[] = [];
    let nameValidationErrors: string[] = [];

    if (!signature) {
      sigValidationErrors = ["Bitte unterschreiben Sie."];
    } else {
      setSigErrors([]);
    }

    if (!data.name || data.name.length === 0) {
      nameValidationErrors = ["Bitte geben Sie Ihren Namen ein."];
    }

    if (sigValidationErrors.length > 0 || nameValidationErrors.length > 0) {
      setSigErrors(sigValidationErrors);
      setNameErrors(nameValidationErrors);
      return false;
    }

    return true;
  };

  const confirm = () => {
    if (!validationPasses()) {
      return;
    }

    const signature = signatureToDataUrl();
    onConfirm({
      name: data.name,
      signature: signature!,
    });
    setData({ name: "", signature: "" });
    setNameErrors([]);
    setSigErrors([]);
    clear();
    setOpen(false);
  };

  const close = () => {
    setData({ name: "", signature: "" });
    setNameErrors([]);
    setSigErrors([]);
    clear();
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onOpenChange={(newOpenState) => {
        if (newOpenState) {
          setOpen(newOpenState);
        } else {
          close();
        }
      }}
    >
      <DialogTrigger asChild>
        <Button variant="outline" onClick={() => setOpen(true)}>
          <Edit className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent className="min-w-[75vw]">
        <DialogHeader>
          <DialogTitle>{title}</DialogTitle>
        </DialogHeader>
        <div>
          <Input
            value={data.name}
            placeholder="Name des Unterzeichners"
            onChange={(e) => {
              setData({ ...data, name: e.target.value });
              setNameErrors([]);
            }}
          />
          <InputErrorMessages errors={nameErrors} />
          <div className="mt-4 flex items-center justify-between">
            <div className="font-bold">{t("Bitte hier Unterschreiben:")}</div>
            <Button variant="outline" size="sm" onClick={clear}>
              {t("Unterschrift zurücksetzen")}
            </Button>
          </div>
          <div
            className={
              sigErrors.length > 0
                ? "border-destructive bg-destructive-foreground mt-2 rounded border"
                : "mt-2 rounded border"
            }
          >
            <SignaturePad ref={ref} />
          </div>
          <InputErrorMessages errors={sigErrors} />
        </div>
        <DialogFooter>
          <Button variant="outline" onClick={close}>
            {t("Abbrechen")}
          </Button>
          <Button onClick={confirm}>{t("Bestätigen")}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
