import { Filter } from "@/shared/lib/filter/filter";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { Check, ListFilter } from "lucide-react";
import t from "@/lang/lang";
import {
  Command,
  CommandGroup,
  CommandItem,
  CommandList,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import { useState } from "react";
import { State, StateInfo } from "@/services/backend/vbs/work-orders/state";

export type SharedState =
  | "preparation"
  | "scheduling"
  | "execution"
  | "review"
  | "completed";

const stateList = [
  "preparation",
  "scheduling",
  "execution",
  "review",
  "completed",
];

export function WorkOrderStateFilter({
  filter,
  onFilterChange,
}: {
  filter: Filter<SharedState>;
  onFilterChange: (filter: Filter<SharedState>) => void;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
          size="sm"
        >
          <ListFilter className="mr-2 h-4 w-4" />
          <span>{t("Status")}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandList>
            <CommandGroup>
              {stateList.map((state) => (
                <CommandItem
                  key={state}
                  value={state}
                  onSelect={(currentValue) => {
                    const values = filter.values!.includes(
                      currentValue as SharedState,
                    )
                      ? filter.values!.filter((v) => v !== currentValue)
                      : [...filter.values!, currentValue];
                    if (values.length === 0) {
                      onFilterChange({ active: false, values: [] });
                    } else {
                      onFilterChange({
                        active: true,
                        values: values as SharedState[],
                      });
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      filter.values!.includes(state as SharedState)
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  <StateInfo state={state as State} />
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
