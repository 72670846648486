import { H3 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import {
  DataTable,
  DataTableContainer,
  DataTableFilters,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { useHtzAirHandlingUnitListQuery } from "@/services/backend/htz/ahu/service";
import { ColumnDef } from "@tanstack/react-table";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { NavLink, Outlet, useParams } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { NewAhuDialog } from "@/routes/customers/[customerId]/ahu-units/_components/new-ahu-dialog";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export function AhuUnitsRoute() {
  return <Outlet />;
}

export function AhuUnitsIndexRoute() {
  const { customerId } = useParams();

  return <RltUnitsTable customerId={customerId!} />;
}

function RltUnitsTable({ customerId }: { customerId: string }) {
  const {
    data: list,
    isLoading,
    error,
  } = useHtzAirHandlingUnitListQuery({
    customer: {
      active: true,
      values: [customerId],
    },
  });

  const airHandlingUnits = list?.airHandlingUnits ?? [];
  const table = useDataTableController(tableColumns, airHandlingUnits);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <DataTableContainer className="animate-pulse">
        <DataTableFilters className="flex justify-between space-x-2">
          <H3>{t("RLT-Anlagen")}</H3>
          <NewAhuDialog customerId={customerId} />
        </DataTableFilters>
        <DataTable columns={tableColumns} table={table} />
      </DataTableContainer>
    );
  }

  return (
    <DataTableContainer>
      <DataTableFilters className="flex justify-between space-x-2">
        <H3>{t("RLT-Anlagen")}</H3>
        <NewAhuDialog customerId={customerId} />
      </DataTableFilters>
      <DataTable columns={tableColumns} table={table} />
    </DataTableContainer>
  );
}

const tableColumns: ColumnDef<AirHandlingUnit>[] = [
  {
    header: t("Name"),
    accessorKey: "name",
  },
  {
    id: "actions",
    header: t("Aktionen"),
    cell: ({ row }) => {
      const ahu = row.original;

      return (
        <NavLink to={`${ahu.id}`}>
          <Button variant="ghost" size="sm" className="-mt-1.5 -mb-2.5">
            <Search className="h-4 w-4" />
          </Button>
        </NavLink>
      );
    },
  },
];
