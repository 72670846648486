import { useParams } from "react-router";
import { CalculationListTable } from "@/routes/gesec/processes/[processId]/rlt/calculations/_components/calculation-list-table";
import t from "@/lang/lang";
import { CreateCalculationDialog } from "@/routes/gesec/processes/[processId]/rlt/calculations/_components/create-calculation-dialog";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";
import { TableCard } from "@/shared/components/ui/table-card";

export function CalculationIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Kalkulationen")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateCalculationDialog processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <TableCard>
          <CalculationListTable processId={processId!} />
        </TableCard>
      </MilestonePageContent>
    </MilestonePage>
  );
}
