import { Channel, Order, OrderFile } from "@/services/backend/vbs/orders/order";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import t from "@/lang/lang";
import { ChangeEvent, HTMLAttributes, useEffect, useState } from "react";
import {
  PutRequest,
  useVbsOrderDeleteFileMutation,
  useVbsOrderPutMutation,
  useVbsOrderShowFileQuery,
  useVbsOrderUploadFileMutation,
} from "@/services/backend/vbs/orders/service";
import { Label } from "@/shared/components/ui/label";
import { Textarea } from "@/shared/components/ui/textarea";
import { cn } from "@/shared/lib/utils";
import { H4 } from "@/shared/components/ui/typography";
import { Button } from "@/shared/components/ui/button";
import { RefreshCw, Search, Trash2, UploadCloud } from "lucide-react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { DatePicker } from "@/shared/components/ui/date-picker";
import { useVbsOfferListQuery } from "@/services/backend/vbs/offers/service";
import { useToast } from "@/shared/hooks/use-toast";
import { v4 } from "uuid";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Sheet,
  SheetContent,
  SheetHeader,
  SheetTitle,
  SheetTrigger,
} from "@/shared/components/ui/sheet";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Input } from "@/shared/components/ui/input";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

interface Props {
  order: Order;
}

export function OrderCard({ order }: Props) {
  const [request, setRequest] = useState<PutRequest>({
    ...order,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsOrderPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex">
          <span>{t("Auftragseingang")}</span>
        </CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4 md:grid-cols-2">
        <OfferSelect
          offerId={request.offerId}
          onOfferIdChange={(offerId) => setRequest({ ...request, offerId })}
          processId={order.processId}
        />
        <Datepicker
          date={request.date}
          onDateChange={(date) => setRequest({ ...request, date })}
        />
        <ChannelSelect
          channel={request.channel}
          onChannelChange={(channel) => setRequest({ ...request, channel })}
        />
        <CustomerReference
          customerReference={request.customerReference}
          onCustomerReferenceChange={(customerReference) =>
            setRequest({ ...request, customerReference })
          }
        />
        <Comment
          comment={request.comment}
          onCommentChange={(comment) => setRequest({ ...request, comment })}
          className="md:col-span-2"
        />
        <Files order={order} className="md:col-span-2" />
      </CardContent>
    </Card>
  );
}

export function OrderCardSkeleton() {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex">
          <span>{t("Auftragseingang")}</span>
        </CardTitle>
      </CardHeader>
    </Card>
  );
}

interface OfferDropdownProps {
  offerId: string | null;
  onOfferIdChange: (offerId: string | null) => void;
  processId: string;
}

function OfferSelect({
  offerId,
  onOfferIdChange,
  processId,
}: OfferDropdownProps) {
  const {
    data: list,
    isLoading,
    error,
  } = useVbsOfferListQuery({
    process: {
      active: true,
      values: [processId],
    },
    immutable: {
      active: true,
      values: [true],
    },
  });
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: "Error loading offers",
        variant: "destructive",
      });
    }
  }, [error, toast]);

  if (isLoading) {
    return (
      <div className="grid animate-pulse content-start gap-1.5">
        <Label>{t("Zu Angebot")}</Label>
        <Select disabled>
          <SelectTrigger className="w-full">
            <SelectValue placeholder={t("Angebot auswählen")} />
          </SelectTrigger>
        </Select>
      </div>
    );
  }

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Zu Angebot")}</Label>
      <Select
        value={offerId ?? undefined}
        onValueChange={(v) => {
          if (v === "00000000-0000-0000-0000-000000000000") {
            onOfferIdChange(null);
            return;
          }
          onOfferIdChange(v);
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t("Angebot auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {list?.offers.map((offer) => (
            <SelectItem value={offer.id} key={offer.id}>
              <ArtefactNumberById artefactId={offer.id} />
            </SelectItem>
          ))}
          <SelectItem value="00000000-0000-0000-0000-000000000000">
            {t("Kein Angebot")}
          </SelectItem>
        </SelectContent>
      </Select>
      <div className="text-muted-foreground text-xs">
        {t(
          "Die Auswahl eines Angebots überschreibt die Leistungen des Auftragseingangs mit denen des Angebots.",
        )}
      </div>
    </div>
  );
}

interface DatepickerProps {
  date: string | null;
  onDateChange: (date: string | null) => void;
}

function Datepicker({ date, onDateChange }: DatepickerProps) {
  const formattedDate = date ? new Date(date) : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
  };

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Eingangsdatum")}</Label>
      <DatePicker date={formattedDate} onDateChange={onFormattedDateChange} />
    </div>
  );
}

interface ChannelSelectProps {
  channel: string | null;
  onChannelChange: (channel: string | null) => void;
}

function ChannelSelect({ channel, onChannelChange }: ChannelSelectProps) {
  const channels = Object.entries(Channel).map((chan) => chan[1]);

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Kanal")}</Label>
      <Select value={channel ?? undefined} onValueChange={onChannelChange}>
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t("Kanal auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {channels.map((chan) => (
            <SelectItem value={chan} key={chan}>
              {t(chan)}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}

interface CustomerReferenceProps {
  customerReference: string | null;
  onCustomerReferenceChange: (comment: string | null) => void;
  className?: string;
}

function CustomerReference({
  customerReference,
  onCustomerReferenceChange,
  className = "",
}: CustomerReferenceProps) {
  return (
    <div className={cn("grid content-start gap-1.5", className)}>
      <Label>{t("Referenz des Kunden")}</Label>
      <Input
        value={customerReference ?? ""}
        onChange={(e) => onCustomerReferenceChange(e.target.value || null)}
      />
      <div className="text-muted-foreground text-xs">
        {t("z.B. Bestellnummer, Auftragsnummer")}
      </div>
    </div>
  );
}

interface CommentProps {
  comment: string | null;
  onCommentChange: (comment: string | null) => void;
  className?: string;
}

function Comment({ comment, onCommentChange, className = "" }: CommentProps) {
  return (
    <div className={cn("grid content-start gap-1.5", className)}>
      <Label>{t("Informationen zum Auftragseingang")}</Label>
      <Textarea
        value={comment ?? ""}
        onChange={(e) => onCommentChange(e.target.value || null)}
      />
    </div>
  );
}

interface FilesProps {
  order: Order;
  className?: string;
}

function Files({ order, className = "" }: FilesProps) {
  return (
    <div className={cn("grid w-full gap-4 md:grid-cols-2 md:gap-6", className)}>
      <FileUpload order={order} />
      <FileList order={order} />
    </div>
  );
}

interface FileUploadProps {
  order: Order;
}

function FileUpload({ order }: FileUploadProps) {
  const [upload, { isLoading, error, isSuccess, reset }] =
    useVbsOrderUploadFileMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Upload erfolgreich"),
        description: t("Die Datei wurde erfolgreich hochgeladen."),
        variant: "success",
      });
      reset();
    }
  }, [isSuccess, toast, reset]);

  const doUpload = (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      upload({
        id: order.id,
        fileId: v4(),
        filename: e.target.files[0].name,
        file: e.target.files[0],
      });
      e.target.value = "";
      e.target.files = null;
    }
  };
  return (
    <div>
      <H4 className="mb-2">{t("Upload")}</H4>
      <Label
        className="border-muted-foreground/40 bg-muted hover:bg-muted/50 flex h-64 w-full cursor-pointer flex-col items-center justify-center rounded-lg border-2 border-dashed hover:border-solid"
        onDragOver={(e) => e.preventDefault()}
        onDrop={(e) => {
          e.preventDefault();
          if (e.dataTransfer.items && e.dataTransfer.items.length > 0) {
            const item = e.dataTransfer.items[0];
            // ignore none-file, none-pdf files
            if (item.kind !== "file" || item.type !== "application/pdf") {
              return;
            }
            const file = item.getAsFile();
            if (!file) {
              return;
            }
            upload({
              id: order.id,
              fileId: v4(),
              filename: file.name,
              file,
            });
          } else {
            const file = e.dataTransfer.files[0];
            if (!file) {
              return;
            }
            upload({
              id: order.id,
              fileId: v4(),
              filename: file.name,
              file,
            });
          }
        }}
      >
        <UploadCloud
          className={cn("h-12 w-12", isLoading ? "animate-bounce" : "")}
        />
        {isLoading ? (
          <span className="mt-3">{t("Lade hoch ...")}</span>
        ) : (
          <>
            <span className="mt-3">{t("PDF hochladen")}</span>
            <span className="text-muted-foreground mt-3 font-normal">
              {t("Klick oder Drag & Drop")}
            </span>
          </>
        )}
        <input
          type="file"
          hidden
          accept="application/pdf"
          onChange={doUpload}
          disabled={isLoading}
        />
      </Label>
    </div>
  );
}

interface FileListProps extends HTMLAttributes<HTMLDivElement> {
  order: Order;
}

function FileList({ order, className, ...props }: FileListProps) {
  return (
    <div className={cn("", className)} {...props}>
      <H4 className="mb-2">{t("Hochgeladene Dateien")}</H4>
      <div className="grid divide-y">
        {order.files.map((file) => (
          <div key={file.id} className="py-1.5">
            <div className="flex w-full items-center justify-between space-x-2">
              <span>{file.name}</span>
              <div className="flex gap-2">
                <FileSheet orderId={order.id} file={file} />
              </div>
            </div>
          </div>
        ))}
        {order.files.length === 0 && (
          <div className="text-muted-foreground flex">
            {t("Keine Dateien hochgeladen")}
          </div>
        )}
      </div>
    </div>
  );
}

interface FileSheetProps {
  orderId: string;
  file: OrderFile;
}

function FileSheet({ orderId, file }: FileSheetProps) {
  const { data, isLoading, error } = useVbsOrderShowFileQuery({
    id: orderId,
    fileId: file.id,
  });

  return (
    <Sheet>
      <SheetTrigger asChild>
        <Button variant="outline" size="sm">
          <Search className="h-4 w-4" />
        </Button>
      </SheetTrigger>
      <SheetContent side="bottom" className="max-h-[95vh]">
        <SheetHeader>
          <SheetTitle>
            <span className="mr-4">{file.name}</span>
            <DeleteFileDialog orderId={orderId} fileId={file.id} />
          </SheetTitle>
        </SheetHeader>
        {isLoading && (
          <div>
            <RefreshCw className="animate-spin" />
          </div>
        )}
        <RTKQueryErrorAlert error={error} />
        {data && (
          <object
            type="application/pdf"
            className="mt-6 h-[80vh] w-full rounded-lg"
            data={`${data.objectURL}#toolbar=1`}
          >
            {t("Datei konnte nicht geladen werden")}
          </object>
        )}
      </SheetContent>
    </Sheet>
  );
}

interface DeleteFileDialogProps {
  orderId: string;
  fileId: string;
}

function DeleteFileDialog({ orderId, fileId }: DeleteFileDialogProps) {
  const [open, setOpen] = useState(false);
  const [deleteFile, { isLoading, error, isSuccess, reset }] =
    useVbsOrderDeleteFileMutation();

  const doDelete = () => {
    if (isLoading) {
      return;
    }

    deleteFile({
      id: orderId,
      fileId,
    });
  };

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="destructive" size="sm">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Datei löschen")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete} disabled={isLoading}>
            {isLoading && <RefreshCw className="mr-2 h-5 w-5" />}
            <span>{t("Löschen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
