import { Contract } from "@/services/backend/vbs/contracts/contract";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { useState } from "react";
import {
  UpdateAbsoluteDiscountRequest,
  UpdateLifetimeRequest,
  UpdatePaymentTermsRequest,
  UpdateSalesDiscountRequest,
  UpdateSpecialAgreementsRequest,
  useVbsContractUpdateAbsoluteDiscountMutation,
  useVbsContractUpdateLifetimeMutation,
  useVbsContractUpdatePaymentTermsMutation,
  useVbsContractUpdateSalesDiscountMutation,
  useVbsContractUpdateSpecialAgreementsMutation,
} from "@/services/backend/vbs/contracts/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { Matcher } from "react-day-picker";
import { H4 } from "@/shared/components/ui/typography";
import { Label } from "@/shared/components/ui/label";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Checkbox } from "@/shared/components/ui/checkbox";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { cn } from "@/shared/lib/utils";
import { CalendarIcon, ChevronDown } from "lucide-react";
import { format } from "date-fns";
import { Calendar } from "@/shared/components/ui/calendar";
import { Input } from "@/shared/components/ui/input";
import { useVbsDefaultsShowQuery } from "@/services/backend/vbs/defaults/service";
import { Key } from "@/services/backend/vbs/defaults/defaults";
import { Contract as ContractDefaults } from "@/services/backend/vbs/defaults/contract";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Textarea } from "@/shared/components/ui/textarea";
import {
  FloatNumberInput,
  Value,
} from "@/routes/gesec/processes/_components/ui/number-input";
import { useGuard } from "@/shared/lib/authorization/rbac-guard";
import {
  Table,
  TableBody,
  TableCell,
  TableFooter,
  TableRow,
} from "@/shared/components/ui/table";
import { Badge } from "@/shared/components/ui/badge";

export function ContractBaseData({ contract }: { contract: Contract }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex space-x-4">
          <span>{t("Vertragsdaten")}</span>
          <Badge>{t(contract.state)}</Badge>
        </CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid gap-6">
          <ContractLifetime contract={contract} />
          <TreatmentPrices contract={contract} />
          <PaymentTerms contract={contract} />
          <SpecialAgreements contract={contract} />
        </div>
      </CardContent>
    </Card>
  );
}

function TreatmentPrices({ contract }: { contract: Contract }) {
  return (
    <div>
      <H4 className="mb-2">{t("Vertragspreise")}</H4>
      <Table className="text-base">
        <TableBody>
          <BasePriceRow contract={contract} />
          <SalesDiscountRows contract={contract} />
          <AbsoluteDiscountRow contract={contract} />
        </TableBody>
        <TableFooter>
          <TableRow className="font-bold">
            <TableCell className="py-1 pl-0" colSpan={2}>
              {t("Vertragspreis Grundbehandlung")}
            </TableCell>
            <TableCell className="py-1 pr-1 text-right">
              <Value value={contract.treatment.contractPrice} fractions={2} />
            </TableCell>
            <TableCell className="px-0 py-1 text-right">€</TableCell>
          </TableRow>
        </TableFooter>
      </Table>
    </div>
  );
}

function BasePriceRow({ contract }: { contract: Contract }) {
  return (
    <TableRow className="border-none">
      <TableCell className="w-full py-0.5 pl-0" colSpan={2}>
        <span>{t("Betrag aus ")}</span>
        <a href="#positions">{t("Vertragsleistungen")}</a>
      </TableCell>
      <TableCell className="py-0.5 pr-1 text-right">
        <Value value={contract.treatment.basePrice} fractions={2} />
      </TableCell>
      <TableCell className="mx-0 px-0 py-0.5 text-right">€</TableCell>
    </TableRow>
  );
}

function SalesDiscountRows({ contract }: { contract: Contract }) {
  const [request, setRequest] = useState<UpdateSalesDiscountRequest>({
    id: contract.id,
    active: contract.treatment.salesDiscount.active,
    percentage: contract.treatment.salesDiscount.percentage,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsContractUpdateSalesDiscountMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    { toastError: true },
  );

  const { canExecute } = useGuard("VBS.Contract.UpdateSalesDiscountRequest");

  return (
    <>
      <TableRow className="border-none">
        <TableCell className="w-full py-0.5 pl-0">
          {t("Verkaufsrabatt %")}
        </TableCell>
        <TableCell className="py-0.5">
          <Label className="flex items-center space-x-2">
            <Checkbox
              checked={contract.treatment.salesDiscount.active}
              onCheckedChange={(checked) => {
                const active = Boolean(checked);
                setRequest({ ...request, active });
              }}
              disabled={contract.immutable}
            />
            <span>{t("Aktiv")}</span>
          </Label>
        </TableCell>
        <TableCell className="py-0.5 pr-1 text-right">
          {canExecute ? (
            <FloatNumberInput
              value={request.percentage}
              onChange={(value) => {
                const percentage = Number(value);
                setRequest({ ...request, percentage });
              }}
              disabled={contract.immutable}
            />
          ) : (
            <Value
              value={contract.treatment.salesDiscount.percentage}
              fractions={2}
            />
          )}
        </TableCell>
        <TableCell className="mx-0 px-0 py-0.5 text-right">%</TableCell>
      </TableRow>
      <TableRow className="border-none">
        <TableCell className="w-full py-0.5 pl-0" colSpan={2}>
          {t("Verkaufsrabatt €")}
        </TableCell>
        <TableCell className="py-0.5 pr-1 text-right">
          <Value
            value={
              (contract.treatment.basePrice *
                contract.treatment.salesDiscount.percentage) /
              100
            }
            fractions={2}
          />
        </TableCell>
        <TableCell className="mx-0 px-0 py-0.5 text-right">€</TableCell>
      </TableRow>
    </>
  );
}

function AbsoluteDiscountRow({ contract }: { contract: Contract }) {
  const [request, setRequest] = useState<UpdateAbsoluteDiscountRequest>({
    id: contract.id,
    active: contract.treatment.absoluteDiscount.active,
    amount: contract.treatment.absoluteDiscount.amount,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsContractUpdateAbsoluteDiscountMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    { toastError: true },
  );

  const { canExecute } = useGuard("VBS.Contract.UpdateAbsoluteDiscountRequest");

  return (
    <TableRow className="border-none">
      <TableCell className="w-full py-0.5 pl-0">{t("Absolutrabatt")}</TableCell>
      <TableCell className="py-0.5">
        <Label className="flex items-center space-x-2">
          <Checkbox
            checked={contract.treatment.absoluteDiscount.active}
            onCheckedChange={(checked) => {
              const active = Boolean(checked);
              setRequest({ ...request, active });
            }}
            disabled={contract.immutable}
          />
          <span>{t("Aktiv")}</span>
        </Label>
      </TableCell>
      <TableCell className="py-0.5 pr-1 text-right">
        {canExecute ? (
          <FloatNumberInput
            value={request.amount}
            onChange={(value) => {
              const amount = Number(value);
              setRequest({ ...request, amount });
            }}
            width={28}
            disabled={contract.immutable}
          />
        ) : (
          <Value
            value={contract.treatment.absoluteDiscount.amount}
            fractions={2}
          />
        )}
      </TableCell>
      <TableCell className="mx-0 px-0 py-0.5 text-right">€</TableCell>
    </TableRow>
  );
}

function ContractLifetime({ contract }: { contract: Contract }) {
  const [request, setRequest] = useState<UpdateLifetimeRequest>({
    id: contract.id,
    lifetime: contract.lifetime,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsContractUpdateLifetimeMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    { toastError: true },
  );

  const endDateRestrictions: Matcher = request.lifetime.unlimited
    ? true
    : { before: new Date(request.lifetime.startDate) };

  return (
    <div>
      <H4 className="mb-2">{t("Laufzeit")}</H4>
      <div className="grid grid-cols-2 items-start gap-4">
        <div className="grid items-center gap-1.5">
          <Label>{t("Begin des Vertrages")}</Label>
          <Datepicker
            date={new Date(request.lifetime.startDate)}
            onDateChange={(date) =>
              setRequest({
                ...request,
                lifetime: {
                  ...request.lifetime,
                  startDate: date.toISOString(),
                },
              })
            }
            disabled={contract.immutable}
          />
          <InputValidationErrors field="lifetime.startDate" error={error} />
        </div>
        <div className="grid items-center gap-1.5">
          <Label>{t("Ende des Vertrages")}</Label>
          <Datepicker
            date={
              request.lifetime.endDate
                ? new Date(request.lifetime.endDate)
                : undefined
            }
            onDateChange={(date) =>
              setRequest({
                ...request,
                lifetime: {
                  ...request.lifetime,
                  endDate: date.toISOString(),
                },
              })
            }
            disabled={endDateRestrictions}
          />
          <InputValidationErrors field="liftime.endDate" error={error} />
          <div className="flex items-center gap-1.5">
            <Checkbox
              checked={request.lifetime.unlimited}
              onCheckedChange={(checked) => {
                const unlimited = Boolean(checked);
                if (typeof unlimited === "undefined") return;

                setRequest({
                  ...request,
                  lifetime: {
                    ...request.lifetime,
                    endDate: null,
                    unlimited,
                  },
                });
              }}
              disabled={contract.immutable}
            />
            <Label>{t("Unbegrenzte Laufzeit")}</Label>
            <InputValidationErrors field="lifetime.unlimited" error={error} />
          </div>
        </div>
      </div>
    </div>
  );
}

function Datepicker({
  date,
  onDateChange,
  disabled = undefined,
}: {
  // undefined is used since the Calendar component's
  // interface uses undefined
  date: Date | undefined;
  onDateChange: (date: Date) => void;
  disabled?: Matcher | Matcher[] | undefined;
}) {
  return (
    <Popover>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          className={cn(
            "w-full justify-start text-left font-normal",
            !date && "text-muted-foreground",
          )}
        >
          <CalendarIcon className="mr-2 h-4 w-4" />
          {date ? format(date, "PPP") : <span>Pick a date</span>}
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0">
        <Calendar
          mode="single"
          selected={date}
          onSelect={(d) => onDateChange(d ?? new Date())}
          initialFocus
          disabled={disabled}
        />
      </PopoverContent>
    </Popover>
  );
}

function PaymentTerms({ contract }: { contract: Contract }) {
  const [request, setRequest] = useState<UpdatePaymentTermsRequest>({
    id: contract.id,
    terms: contract.paymentTerms,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsContractUpdatePaymentTermsMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <div>
      <H4>{t("Zahlungsbedingungen")}</H4>
      <div className="space-y-4">
        <div className="grid items-center gap-1.5">
          <Label>{t("Formulierung für Paragraph 5 im Vertrag")}</Label>
          <div className="flex w-full items-center space-x-4">
            <Input
              value={request.terms}
              onChange={(e) =>
                setRequest({ ...request, terms: e.target.value })
              }
              disabled={contract.immutable}
            />
            <PaymentTermsPicker
              onSelect={(terms) => setRequest({ ...request, terms })}
              disabled={contract.immutable}
            />
          </div>
          <InputValidationErrors field="terms" error={error} />
        </div>
      </div>
    </div>
  );
}

function PaymentTermsPicker({
  onSelect,
  disabled = undefined,
}: {
  onSelect: (term: string) => void;
  disabled?: boolean;
}) {
  const { data, error } = useVbsDefaultsShowQuery({
    key: Key.Contract,
  });

  const defaultsData = data?.data as ContractDefaults;
  const paymentTerms = defaultsData?.paymentTerms ?? [];

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="secondary" disabled={disabled}>
          <span>{t("Aus Standard übernehmen")}</span>
          <ChevronDown className="ml-2 h-4 w-4" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <RTKQueryErrorAlert error={error} />
        <DropdownMenuGroup>
          {paymentTerms.map((term) => (
            <DropdownMenuItem key={term} onSelect={() => onSelect(term)}>
              {term}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function SpecialAgreements({ contract }: { contract: Contract }) {
  const [request, setRequest] = useState<UpdateSpecialAgreementsRequest>({
    id: contract.id,
    agreements: contract.specialAgreements,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsContractUpdateSpecialAgreementsMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    { toastError: true },
  );

  return (
    <div>
      <H4>{t("Besondere Vereinbarungen")}</H4>
      <div className="space-y-4">
        <div className="grid items-center gap-1.5">
          <Label>
            {t("OPTIONAL: Formulierung für Paragraph 8 im Vertrag")}
          </Label>
          <Textarea
            value={request.agreements}
            onChange={(e) =>
              setRequest({ ...request, agreements: e.target.value })
            }
            disabled={contract.immutable}
          />
          <InputValidationErrors field="agreements" error={error} />
        </div>
      </div>
    </div>
  );
}
