import {
  NavigationGroup,
  ProcessPage,
  ProcessPageMain,
  ProcessPageMainContent,
  ProcessPageMainHeader,
  ProcessPageSidebar,
  ProcessPageSidebarNavigation,
  ProcessSidebarTrigger,
} from "@/shared/service-manager/default-ui/process-page/process-page";
import t from "@/lang/lang";
import { Building2Icon, LayoutDashboard } from "lucide-react";
import { Separator } from "@/shared/components/ui/separator";
import { Outlet, useParams } from "react-router";
import {
  ArtefactIconCalculation,
  ArtefactIconOffer,
  ArtefactIconRequirement,
} from "@/shared/components/domain/artefact/artefact-icon";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { BreadcrumbsToArtefact } from "@/shared/service-manager/default-ui/process-page/breadcrumbs-to-artefact";
import { StateWarning } from "@/shared/components/domain/process/state/state-warning";

export default function QPMRouter() {
  const { processId } = useParams();
  const {
    data: process,
    isLoading,
    error,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <ProcessPage>
      <ProcessPageSidebar className="pt-16">
        <ProcessPageSidebarNavigation groups={sidebarNavigation} />
      </ProcessPageSidebar>
      <ProcessPageMain className="pt-16">
        <ProcessPageMainHeader className="justify-between">
          <div className="flex items-center gap-2">
            <ProcessSidebarTrigger />
            <Separator orientation="vertical" className="mr-2 h-4" />
            <BreadcrumbsToArtefact process={process!} />
          </div>
          {/* <CloseProcessDialog processId={process!.id} /> */}
          <StateWarning processId={process!.id} />
        </ProcessPageMainHeader>
        <ProcessPageMainContent>
          <Outlet />
        </ProcessPageMainContent>
      </ProcessPageMain>
    </ProcessPage>
  );
}

const sidebarNavigation: NavigationGroup[] = [
  {
    label: t("Allgemein"),
    items: [
      {
        label: t("Übersicht"),
        icon: LayoutDashboard,
        to: "overview",
      },
      {
        label: t("Gebäudeverwaltung"),
        icon: Building2Icon,
        to: "manage-building",
      },
    ],
  },
  {
    label: t("Meilensteine"),
    items: [
      {
        label: t("Bestandsaufnahmen"),
        icon: ArtefactIconRequirement,
        to: "requirements",
      },
      {
        label: t("Kalkulationen"),
        icon: ArtefactIconCalculation,
        to: "calculations",
      },
      {
        label: t("Angebote"),
        icon: ArtefactIconOffer,
        to: "offers",
      },
    ],
  },
];
