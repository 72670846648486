import {
  Destination,
  Source,
  useCpyCopyMutation,
} from "@/services/backend/cpy/service";
import { Button } from "@/shared/components/ui/button";
import { Copy } from "lucide-react";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

export function CopyButton({
  destination,
  source,
  onSuccess,
}: {
  destination: Destination;
  source: Source;
  onSuccess?: () => void;
}) {
  const [copy, { isLoading, isSuccess, error, reset }] = useCpyCopyMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
  }, [error, toast, reset]);

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: "Kopiert",
        description: "Die Daten wurden kopiert.",
        variant: "success",
      });
      onSuccess?.();
      reset();
    }
  }, [isSuccess, onSuccess, reset, toast]);

  const disabled = isLoading;

  const doCopy = () => {
    if (disabled) {
      return;
    }

    copy({ destination, source });
  };

  return (
    <Button onClick={doCopy} disabled={disabled} size="sm">
      <Copy className="h-4 w-4" />
    </Button>
  );
}
