import { Input } from "@/shared/components/ui/input";
import React, {
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";

export type IntNumberInputProps = {
  id?: string;
  value: number;
  onChange?: (value: number) => void;
  className?: string;
  disabled?: boolean;
};

export function IntNumberInput({
  id,
  value,
  onChange,
  className = "",
  disabled = false,
}: IntNumberInputProps) {
  const [intValue, setIntValue] = useState(value);
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    setIntValue(value); // Sync internal state with the value prop
  }, [value]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (e.shiftKey && !disabled) {
        if (e.key === "ArrowUp") {
          e.preventDefault();
          setIntValue((prev) => {
            const newValue = prev + 1;
            if (onChange) {
              onChange(newValue);
            }
            return newValue;
          });
        } else if (e.key === "ArrowDown") {
          e.preventDefault();
          setIntValue((prev) => {
            const newValue = prev - 1 < 0 ? 0 : prev - 1;
            if (onChange) {
              onChange(newValue);
            }
            return newValue;
          });
        } else if (e.key === "ArrowLeft") {
          e.preventDefault();
          setIntValue((prev) => {
            const newValue = prev - 10 < 0 ? 0 : prev - 10;
            if (onChange) {
              onChange(newValue);
            }
            return newValue;
          });
        } else if (e.key === "ArrowRight") {
          e.preventDefault();
          setIntValue((prev) => {
            const newValue = prev + 10;
            if (onChange) {
              onChange(newValue);
            }
            return newValue;
          });
        } else if (e.key === "0") {
          e.preventDefault();
          setIntValue(() => {
            const newValue = 0;
            if (onChange) {
              onChange(newValue);
            }
            return newValue;
          });
        }
      }
    };

    const inputElement = inputRef.current;
    inputElement?.addEventListener("keydown", handleKeyDown);

    return () => {
      inputElement?.removeEventListener("keydown", handleKeyDown);
    };
  }, [onChange, disabled]);

  const updateValue = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;
    const parsedValue = rawValue === "" ? 0 : parseInt(rawValue, 10);

    if (!Number.isNaN(parsedValue)) {
      if (intValue !== parsedValue) {
        setIntValue(parsedValue);
        if (onChange) {
          onChange(parsedValue);
        }
      }
    }
  };

  return (
    <Input
      id={id}
      ref={inputRef}
      type="text"
      autoComplete="off"
      inputMode="numeric"
      pattern="[0-9]*"
      min="0"
      step="1"
      className={`h-8 w-24 text-end ${className}`}
      value={intValue && intValue.toString()}
      disabled={disabled}
      onChange={updateValue}
    />
  );
}

export type FloatNumberInputProps = {
  id?: string;
  value: number;
  onChange?: (value: number) => void;
  handleEnterKeyDown?: (value: number) => void;
  className?: string;
  fractions?: number;
  disabled?: boolean;
  locale?: string; // Optional locale, defaults to 'de-DE' for ',' as decimal separator
};

export function FloatNumberInput({
  id,
  value,
  onChange,
  handleEnterKeyDown,
  className = "",
  fractions = 2,
  disabled = false,
  locale = "de-DE",
}: FloatNumberInputProps) {
  const inputRef = useRef<HTMLInputElement>(null);

  const formatter = useMemo(
    () =>
      new Intl.NumberFormat(locale, {
        minimumFractionDigits: fractions,
        maximumFractionDigits: fractions,
      }),
    [locale, fractions],
  );

  const formatLocaleNumber = useCallback(
    (number: number) => formatter.format(Number.isNaN(number) ? 0 : number),
    [formatter],
  );
  const [inputValue, setInputValue] = useState(formatLocaleNumber(value));

  const parseLocaleNumber = useCallback(
    (input: string) => {
      const separator = formatter.format(1.1).charAt(1); // Get decimal separator
      const normalized = input.replace(separator, ".");
      return parseFloat(normalized);
    },
    [formatter],
  );

  const updateValue = useCallback(
    (increment: number) => {
      const parsedValue = parseLocaleNumber(inputValue);
      if (!Number.isNaN(parsedValue)) {
        const newValue = Math.max(0, parsedValue + increment);
        const roundedValue = parseFloat(newValue.toFixed(fractions));
        setInputValue(formatLocaleNumber(roundedValue));
        if (onChange) {
          onChange(roundedValue);
        }
      }
    },
    [formatLocaleNumber, fractions, inputValue, onChange, parseLocaleNumber],
  );

  useEffect(() => {
    setInputValue(formatLocaleNumber(value));
  }, [value, locale, formatLocaleNumber]);

  useEffect(() => {
    const handleKeyDown = (e: KeyboardEvent) => {
      if (!disabled) {
        if (e.key === "Enter" && handleEnterKeyDown) {
          e.preventDefault();
          const parsedValue = parseLocaleNumber(inputValue);
          if (!Number.isNaN(parsedValue)) {
            handleEnterKeyDown(parsedValue);
          }
        } else if (e.shiftKey) {
          if (e.key === "ArrowUp") {
            e.preventDefault();
            updateValue(1);
          } else if (e.key === "ArrowDown") {
            e.preventDefault();
            updateValue(-1);
          }
        }
      }
    };

    const inputElement = inputRef.current;
    inputElement?.addEventListener("keydown", handleKeyDown);

    return () => {
      inputElement?.removeEventListener("keydown", handleKeyDown);
    };
  }, [
    inputValue,
    disabled,
    handleEnterKeyDown,
    parseLocaleNumber,
    updateValue,
  ]);

  const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const rawValue = e.target.value;

    if (rawValue === "") {
      setInputValue("0");
      if (onChange) {
        onChange(0);
      }
      return;
    }

    setInputValue(rawValue);

    const parsedValue = parseLocaleNumber(rawValue);
    if (!Number.isNaN(parsedValue)) {
      const roundedValue = parseFloat(parsedValue.toFixed(fractions));
      if (onChange) {
        onChange(roundedValue);
      }
    }
  };

  const handleBlur = () => {
    const parsedValue = parseLocaleNumber(inputValue);
    if (!Number.isNaN(parsedValue)) {
      const roundedValue = parseFloat(parsedValue.toFixed(fractions));
      const formattedValue = formatLocaleNumber(roundedValue);
      setInputValue(formattedValue);
      if (onChange) {
        onChange(roundedValue);
      }
    } else {
      setInputValue(formatLocaleNumber(value));
    }
  };

  return (
    <Input
      id={id}
      ref={inputRef}
      type="text"
      autoComplete="off"
      inputMode="decimal"
      pattern="[0-9]*[.,]?[0-9]*"
      className={`h-8 w-24 text-end ${className}`}
      value={inputValue}
      disabled={disabled}
      onChange={handleInputChange}
      onBlur={handleBlur}
    />
  );
}

export function round(value: number, decimals: number = 2): number {
  const factor = 10 ** decimals;
  return Math.round((value + Number.EPSILON) * factor) / factor;
}

export function Value({
  value,
  fractions = 2,
  id,
}: {
  value: number;
  fractions?: number;
  id?: string;
}) {
  return (
    <span id={id} className="border-b-2 border-white text-right">
      {value.toLocaleString("de-DE", {
        minimumFractionDigits: fractions ?? 0,
        maximumFractionDigits: fractions ?? 0,
      })}
    </span>
  );
}

export function fHours(hours: number): string {
  return hours.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function fMoney(money: number): string {
  return money.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
