import { useParams } from "react-router";
import { Card } from "@/shared/components/ui/card";
import { ConfirmationTable } from "@/routes/gesec/processes/[processId]/htz/confirmations/_components/confirmation-table";
import { CreateConfirmationButton } from "@/routes/gesec/processes/[processId]/htz/confirmations/_components/create-confirmation-button";
import t from "@/lang/lang";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";

export function ConfirmationIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Auftragsbestätigungen")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateConfirmationButton processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Card>
          <ConfirmationTable processId={processId!} />
        </Card>
      </MilestonePageContent>
    </MilestonePage>
  );
}
