import t from "@/lang/lang";
import { Outlet } from "react-router";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageDescription,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";
import {
  TabsNav,
  TabsNavContent,
  TabsNavLink,
  TabsNavList,
} from "@/shared/components/layout/tabs-nav";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";

export function RltBaseDataRoute() {
  return (
    <PersistenceContextProvider>
      <BaseDataPage>
        <BaseDataPageHeader>
          <BaseDataPageTitle>{t("RLT")}</BaseDataPageTitle>
          <BaseDataPageDescription>
            {t(
              "Hier können die Basisdaten für Vorgänge von RLT Dienstleistungen konfiguriert werden.",
            )}
          </BaseDataPageDescription>
        </BaseDataPageHeader>
        <BaseDataPageContent>
          <TabsNav>
            <div className="flex">
              <TabsNavList>
                <TabsNavLink to="calculation">{t("Kalkulation")}</TabsNavLink>
                <TabsNavLink to="offer">{t("Angebot")}</TabsNavLink>
                <TabsNavLink to="contract">{t("Vertrag")}</TabsNavLink>
              </TabsNavList>
              <PersistenceStateUsingContext className="ml-auto" />
            </div>
            <TabsNavContent className="pt-2">
              <Outlet />
            </TabsNavContent>
          </TabsNav>
        </BaseDataPageContent>
      </BaseDataPage>
    </PersistenceContextProvider>
  );
}
