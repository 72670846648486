import { useAuth } from "@/shared/lib/authorization/auth-context";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { Button } from "@/shared/components/ui/button";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "@/shared/components/ui/avatar";
import t from "@/lang/lang";
import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";
import { useUserCompanies } from "@/shared/lib/authorization/useUserCompanies";
import { CompanyName } from "@/shared/components/domain/company/company-name";

export function UserMenu() {
  const { user, logout } = useAuth();

  if (!user) {
    return null;
  }

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 w-8 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src="" alt="" />
            <AvatarFallback>{user.initials}</AvatarFallback>
          </Avatar>
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm leading-none font-medium">{user.fullname}</p>
            <p className="text-muted-foreground text-xs leading-none">
              {user.roles.join(", ")}
            </p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem>{t("Einstellungen")}</DropdownMenuItem>
        </DropdownMenuGroup>
        <CompanySwitcher />
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logout}>Log out</DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function CompanySwitcher() {
  const { companyId, setCompanyId } = useActiveCompany();
  const { companyIds } = useUserCompanies();

  if (!companyIds || companyIds.length <= 1) {
    return null;
  }

  return (
    <div>
      <DropdownMenuSeparator />
      <DropdownMenuLabel>{t("Unternehmen wechseln")}</DropdownMenuLabel>
      <DropdownMenuGroup>
        {companyIds.map((id) => (
          <DropdownMenuItem
            key={id}
            onClick={() => {
              setCompanyId(id);
            }}
          >
            <div className="flex w-full justify-between">
              <CompanyName companyId={id} short />
              {id === companyId ? (
                <div className="text-muted-foreground text-sm">
                  {t("aktiv")}
                </div>
              ) : null}
            </div>
          </DropdownMenuItem>
        ))}
      </DropdownMenuGroup>
    </div>
  );
}
