import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Check, ListFilter } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import {
  Branch,
  ListBranchRequest,
  useListBranchQuery,
} from "@/services/backend/branch/branch";
import t from "@/lang/lang";
import { Filter } from "@/shared/lib/filter/filter";
import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";

export interface BranchFilterProps {
  filter: Filter<string>;
  onChange: (filter: Filter<string>) => void;
}

export function BranchFilter({ filter, onChange }: BranchFilterProps) {
  const [open, setOpen] = useState(false);
  const { companyId } = useActiveCompany();

  // The backend authorization will automatically only return the branches
  // the user has access to.
  const {
    data: branchList,
    isLoading,
    error,
  } = useListBranchQuery({
    companyId,
  } as ListBranchRequest);

  if (isLoading) {
    return (
      <Button
        variant="outline"
        disabled
        className="w-[200px] justify-between"
        size="sm"
      >
        <ListFilter className="mr-2 h-4 w-4" />
        <span>{t("Niederlassungen")}</span>
      </Button>
    );
  }

  if (error) {
    return <div />;
  }

  // We hide this for users with only one branch
  if (branchList!.branches.length <= 1) {
    return null;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
          size="sm"
        >
          <ListFilter className="mr-2 h-4 w-4" />
          <span>{t("Niederlassungen")}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={t("Niederlassung suchen")} />
          <CommandList>
            <CommandEmpty>{t("Niederlassung nicht gefunden")}</CommandEmpty>
            <CommandGroup>
              {branchList!.branches.map((branch) => (
                <CommandItem
                  key={branch.id}
                  value={branch.name}
                  onSelect={(currentValue) => {
                    const id = findId(currentValue, branchList!.branches)!;
                    const values = filter.values!.includes(id)
                      ? filter.values!.filter((v) => v !== id)
                      : [...filter.values!, id];
                    if (values.length === 0) {
                      onChange({ active: false, values: [] });
                    } else {
                      onChange({ active: true, values });
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      filter.values!.includes(branch.id)
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  {branch.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function findId(name: string, branches: Branch[]) {
  return branches.find((branch) => branch.name.toLowerCase() === name)?.id;
}
