import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  DataTable,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Plus, RefreshCw, Trash2 } from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";
import { ComponentKind } from "@/services/backend/htz/ahu/component-kind";
import { Button } from "@/shared/components/ui/button";
import {
  useHtzAhuComponentKindDeleteMutation,
  useHtzAhuComponentKindListQuery,
  useHtzAhuComponentKindPutMutation,
} from "@/services/backend/htz/ahu/component-kind-service";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect, useState } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { v4 } from "uuid";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";

export function ComponentKindsTable() {
  const { data: list, isLoading, error } = useHtzAhuComponentKindListQuery({});

  const componentKinds = list?.kinds || [];

  const table = useDataTableController(tableColumns, componentKinds);

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader className="flex-row items-start justify-between">
        <div>
          <CardTitle>{t("Arten von Anlagenkomponenten")}</CardTitle>
          <CardDescription>
            {t(
              "Hier können verschiedene Arten von Anlagenkomponenten definiert werden. Diese Arten werden genutzt um definierten Komponenten die Vorlagen für konstruktive und visuelle Beurteilungen zuzuordnen.",
            )}
          </CardDescription>
        </div>
        <CreateComponentKindDialog />
      </CardHeader>
      <CardContent>
        <DataTable
          table={table}
          columns={tableColumns}
          className="border-none"
        />
      </CardContent>
    </Card>
  );
}

const tableColumns: ColumnDef<ComponentKind>[] = [
  {
    accessorKey: "name",
    header: "Name",
  },
  {
    id: "actions",
    cell: ({ row }) => (
      <div className="flex justify-end">
        <DeleteComponentKindButton componentKindId={row.original.id} />
      </div>
    ),
  },
];

function DeleteComponentKindButton({
  componentKindId,
}: {
  componentKindId: string;
}) {
  const [del, { isLoading, error, reset }] =
    useHtzAhuComponentKindDeleteMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const doDelete = () => {
    if (isLoading) {
      return;
    }

    del({ id: componentKindId });
  };

  return (
    <Button onClick={doDelete} variant="destructive">
      <Trash2 className="h-5 w-5" />
    </Button>
  );
}

function CreateComponentKindDialog() {
  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<ComponentKind>({
    id: "",
    name: "",
  });

  const [put, { isLoading, error, reset, isSuccess }] =
    useHtzAhuComponentKindPutMutation();

  if (isSuccess) {
    reset();
    setOpen(false);
  }

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    put({
      ...request,
      id: v4(),
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-5 w-5" />
          <span>{t("Art")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Art Erstellen")}</DialogTitle>
          <DialogDescription>
            {t("Der Name muss eindeutig sein.")}
          </DialogDescription>
        </DialogHeader>
        <div>
          <div className="grid content-start gap-1.5">
            <Label>{t("Name")}</Label>
            <Input
              value={request?.name}
              onChange={(e) => setRequest({ ...request, name: e.target.value })}
            />
            <InputValidationErrors error={error} field="name" />
          </div>
        </div>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button disabled={isLoading} onClick={doCreate}>
            {t("Erstellen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function Skeleton() {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Arten von Anlagenkomponenten")}</CardTitle>
        <CardDescription>
          {t(
            "Hier können grundsätzlich verschiedene Arten von Anlagenkomponenten definiert werden. Diese Arten werden genutzt um definierten Komponenten die Vorlagen für konstruktive und visuelle Beurteilungen zuzuordnen.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RefreshCw className="animate-spin" />
        <span>{t("Lade Daten...")}</span>
      </CardContent>
    </Card>
  );
}
