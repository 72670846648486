import { useParams } from "react-router";
import t from "@/lang/lang";
import { fMoney } from "@/routes/gesec/processes/_components/ui/number-input";
import { useQpmCalculationShowQuery } from "@/services/backend/qpm/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import { Value } from "@/routes/gemex/processes/_components/ui/number-input";

export function CalculationTotalOverviewComponent() {
  const { calculationId } = useParams();
  const {
    data: calculation,
    isLoading,
    error,
  } = useQpmCalculationShowQuery(
    { artefactMetaId: calculationId! },
    { skip: !calculationId },
  );

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    calculation && (
      <div className="overflow-clip rounded-lg border shadow-sm">
        <div className="grid grid-cols-2 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Preis Anschaffung Monitore QPM")}
          </span>
          <span className="text-end font-bold">
            {fMoney(calculation.totalCostCheckPointMaterial)}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Preis Anschaffung FIV")}
          </span>
          <span className="text-end font-bold">
            {fMoney(calculation.totalCostFlyExterminatorMaterial)}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Preis Einrichtung QPM")}
          </span>
          <span className="text-end font-bold">
            {fMoney(calculation.installationResult.qpm.totalCost)}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Preis Einrichtung ALoRa")}
          </span>
          <span className="text-end font-bold">
            {fMoney(calculation.installationResult.aLoRa.totalCost)}
          </span>
        </div>
        <div className="grid grid-cols-2 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Preis Einrichtung FIV")}
          </span>
          <span className="text-end font-bold">
            {fMoney(calculation.installationResult.fiv.totalCost)}
          </span>
        </div>
        <div className="grid grid-cols-3 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Preis Pro Maßnahme QPM")}
          </span>
          <div className="flex items-center justify-end gap-2">
            <span className="font-bold">{t("Anzahl Maßnahmen")}</span>
            <Value
              value={calculation.maintenanceResult.qpm.amountOfMeasures}
              fractions={0}
            />
          </div>
          <span className="text-end font-bold">
            {fMoney(calculation.maintenanceResult.qpm.costPerMeasure)}
          </span>
        </div>
        <div className="grid grid-cols-3 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Preis Pro Maßnahme SbD")}
          </span>
          <div className="flex items-center justify-end gap-2">
            <span className="text-end font-bold">{t("Anzahl Maßnahmen")}</span>
            <Value
              value={calculation.maintenanceResult.scb.amountOfMeasures}
              fractions={0}
            />
          </div>
          <span className="text-end font-bold">
            {fMoney(calculation.maintenanceResult.scb.costPerMeasure)}
          </span>
        </div>
        <div className="grid grid-cols-3 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Preis Pro Maßnahme FIV")}
          </span>
          <div className="flex items-center justify-end gap-2">
            <span className="font-bold">{t("Anzahl Maßnahmen")}</span>
            <Value
              value={calculation.maintenanceResult.fiv.amountOfMeasures}
              fractions={0}
            />
          </div>
          <span className="text-end font-bold">
            {fMoney(calculation.maintenanceResult.fiv.costPerMeasure)}
          </span>
        </div>
        <div className="grid grid-cols-3 gap-2 rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Miete pro Monat ALoRa")}
          </span>
          <div className="flex items-center justify-end gap-2">
            <span className="text-end font-bold">{t("Dauer")}</span>
            <Value
              value={calculation.rentingResult.rentingDuration}
              fractions={0}
            />
          </div>
          <span className="text-end font-bold">
            {fMoney(calculation.rentingResult.rentPerMonthWithMaintenance)}
          </span>
        </div>
      </div>
    )
  );
}
