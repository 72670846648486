import { ReactNode } from "react";
import { cn } from "@/shared/lib/utils";
import { LucideIcon } from "lucide-react";

export function Milestones({ children }: { children: ReactNode }) {
  return (
    <div className="pl-4">
      <ol className="border-primary relative border-l last:mb-0">{children}</ol>
    </div>
  );
}

export function Milestone({ children }: { children: ReactNode }) {
  return <li className="mb-10 ml-8">{children}</li>;
}

// eslint-disable-next-line react/no-unused-prop-types
export function MilestoneIcon(props: { icon: LucideIcon; done: boolean }) {
  const { done } = props;
  return (
    <span
      className={cn(
        "absolute -left-4 flex h-8 w-8 items-center justify-center rounded-full ring-4 ring-white",
        done ? "bg-primary" : "bg-secondary",
      )}
    >
      <props.icon className={cn("h-3.5 w-3.5", done ? "text-white" : "")} />
    </span>
  );
}

export function MilestoneTitle({ children }: { children: ReactNode }) {
  return <h3 className="leading-tight font-medium">{children}</h3>;
}

export function MilestoneStatus({ children }: { children: ReactNode }) {
  return <p className="text-sm">{children}</p>;
}
