import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Check, ListFilter } from "lucide-react";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import {
  Company,
  useListCompanyQuery,
} from "@/services/backend/company/company";
import t from "@/lang/lang";
import { Filter } from "@/shared/lib/filter/filter";

export interface CompanyFilterProps {
  filter: Filter<string>;
  onChange: (filter: Filter<string>) => void;
}

export function CompanyFilter({ filter, onChange }: CompanyFilterProps) {
  const [open, setOpen] = useState(false);

  // The backend authorization will automatically only return the companies
  // the user has access to.
  const { data: companyList, isLoading, error } = useListCompanyQuery({});

  if (isLoading) {
    return (
      <Button
        variant="outline"
        disabled
        className="w-[200px] justify-between"
        size="sm"
      >
        <ListFilter className="mr-2 h-4 w-4" />
        <span>{t("Unternehmen")}</span>
      </Button>
    );
  }

  if (error) {
    return <div />;
  }

  // We hide this for users with only one company
  if (companyList!.companies.length <= 1) {
    return null;
  }

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
          size="sm"
        >
          <ListFilter className="mr-2 h-4 w-4" />
          <span>{t("Unternehmen")}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[200px] p-0" align="start">
        <Command>
          <CommandInput placeholder={t("Niederlassung suchen")} />
          <CommandList>
            <CommandEmpty>{t("Niederlassung nicht gefunden")}</CommandEmpty>
            <CommandGroup>
              {companyList!.companies.map((company) => (
                <CommandItem
                  key={company.id}
                  value={company.name}
                  onSelect={(currentValue) => {
                    const id = findId(currentValue, companyList!.companies)!;
                    const values = filter.values!.includes(id)
                      ? filter.values!.filter((v) => v !== id)
                      : [...filter.values!, id];
                    if (values.length === 0) {
                      onChange({ active: false, values: [] });
                    } else {
                      onChange({ active: true, values });
                    }
                  }}
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      filter.values!.includes(company.id)
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  {company.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </CommandList>
        </Command>
      </PopoverContent>
    </Popover>
  );
}

function findId(name: string, companies: Company[]) {
  return companies.find((company) => company.name.toLowerCase() === name)?.id;
}
