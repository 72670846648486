import { Navigate, RouteObject } from "react-router";
import ManageBuildingRoute from "@/routes/gemex/processes/qpm/building/manage-building-route";
import RequirementsRoute from "@/routes/gemex/processes/qpm/requirements/requirements-route";
import { requirementRoutes } from "@/routes/gemex/processes/qpm/requirements/requirement-routes";
import CalculationsRoute from "@/routes/gemex/processes/qpm/calculations/calculations-route";
import { calculationsRoutes } from "@/routes/gemex/processes/qpm/calculations/calculations-routes";
import t from "@/lang/lang";
import OffersMainNavRoute from "@/routes/gemex/processes/qpm/offers/offers-main-nav-route";
import { offersRoutes } from "@/routes/gemex/processes/qpm/offers/routes";

export const qpmRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="manage-building" replace />,
  },
  {
    path: "appointments",
    element: <div>{t("Termine")}</div>,
  },
  {
    path: "manage-building",
    element: <ManageBuildingRoute />,
  },
  {
    path: "requirements",
    element: <RequirementsRoute />,
    children: requirementRoutes,
  },
  {
    path: "calculations",
    element: <CalculationsRoute />,
    children: calculationsRoutes,
  },
  {
    path: "offers",
    element: <OffersMainNavRoute />,
    children: offersRoutes,
  },
];
