import GesecRouter from "@/routes/gesec/router";
import { Outlet } from "react-router";

export default function GesecRoot() {
  const router = GesecRouter();

  return (
    <div>
      {router}
      <Outlet />
    </div>
  );
}
