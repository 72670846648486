import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { ServiceAddressCard } from "@/shared/components/domain/addresses/service-address-card";
import {
  fieldErrors,
  fieldErrorsForPrefix,
  hasFieldError,
  isValidationError,
  rtkErrIsValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { Label } from "@/shared/components/ui/label";
import { DatePicker } from "@/shared/components/ui/date-picker";
import {
  updateAddress,
  updateBeginDate,
  updateComment,
  updateContact,
  updateEndDate,
  updateExecutingBranch,
  updateRepresentative,
  updateState,
  updateTeamLeader,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Address } from "@/services/backend/addresses/address/address";
import { ContactCard } from "@/shared/components/domain/contacts/contact-card";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { RepresentativeCard } from "@/shared/components/domain/representatives/representative-card";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { usePutWorkOrder } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/work-order-hooks";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { useListBranchQuery } from "@/services/backend/branch/branch";
import { State, StateInfo } from "@/services/backend/htz/work-order/state";
import { TextInput } from "@/shared/components/form/text-input";
import { TextareaInput } from "@/shared/components/form/textarea-input";

export function WorkOrderData({ workOrder }: { workOrder: WorkOrder }) {
  return (
    <div className="space-y-2">
      <BaseDataCard workOrder={workOrder} />
      <AddressCard workOrder={workOrder} />
      <div className="grid gap-2 md:grid-cols-2">
        <ServiceContactCard workOrder={workOrder} />
        <ServiceRepresentativeCard workOrder={workOrder} />
      </div>
    </div>
  );
}

function BaseDataCard({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<WorkOrder>(workOrder);
  const [putWorkOrder, { isLoading, error, isSuccess, reset }] =
    usePutWorkOrder();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putWorkOrder,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Arbeitsschein")}</CardTitle>
      </CardHeader>
      <CardContent className="grid grid-cols-1 gap-4">
        <StateSelect
          workOrder={request}
          onWorkOrderChange={(wo) => {
            resetNotReadyErrorField("state");
            setRequest(wo);
          }}
          error={error || notReadyError}
        />
        <ExecutingBranchSelect
          workOrder={request}
          onWorkOrderChange={(wo) => {
            resetNotReadyErrorField("executingBranchId");
            setRequest(wo);
          }}
          error={error || notReadyError}
        />
        <div className="grid grid-cols-2 gap-4">
          <BeginDateInput
            workOrder={request}
            onWorkOrderChange={(wo) => {
              resetNotReadyErrorField("beginDate");
              setRequest(wo);
            }}
            error={error || notReadyError}
          />
          <EndDateInput
            workOrder={request}
            onWorkOrderChange={(wo) => {
              resetNotReadyErrorField("endDate");
              setRequest(wo);
            }}
            error={error || notReadyError}
          />
        </div>
        <TeamLeaderInput
          workOrder={request}
          onWorkOrderChange={(wo) => {
            resetNotReadyErrorField("teamLeader");
            setRequest(wo);
          }}
          error={error || notReadyError}
        />
        <CommentInput
          workOrder={request}
          onWorkOrderChange={(wo) => {
            resetNotReadyErrorField("comment");
            setRequest(wo);
          }}
          error={error || notReadyError}
        />
      </CardContent>
    </Card>
  );
}

function StateSelect({
  workOrder,
  onWorkOrderChange,
  error,
}: {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  error: unknown;
}) {
  const onStateChange = (state: State) => {
    onWorkOrderChange(updateState(workOrder, state));
  };

  return (
    <div className="grid gap-1.5">
      <Label>{t("Status")}</Label>
      <Select
        value={workOrder.state}
        onValueChange={(state) => onStateChange(state as State)}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Auswählen...")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{t("Status")}</SelectLabel>
            {Object.values(State).map((state) => (
              <SelectItem key={state} value={state}>
                <StateInfo state={state} withDescription />
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputValidationErrors error={error} field="state" />
    </div>
  );
}

function ExecutingBranchSelect({
  workOrder,
  onWorkOrderChange,
  error,
}: {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  error: unknown;
}) {
  const onBranchIdChange = (branchId: string) => {
    onWorkOrderChange(updateExecutingBranch(workOrder, branchId));
  };

  const { data: list } = useListBranchQuery({ companyId: workOrder.companyId });
  const branches = list?.branches ?? [];

  return (
    <div className="grid gap-1.5">
      <Label>{t("Ausführende Niederlassung")}</Label>
      <Select
        value={workOrder.executingBranchId}
        onValueChange={onBranchIdChange}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Auswählen...")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{t("Niederlassung")}</SelectLabel>
            {branches.map((branch) => (
              <SelectItem key={branch.id} value={branch.id}>
                {branch.name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputValidationErrors error={error} field="executingBranchId" />
    </div>
  );
}

function AddressCard({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<WorkOrder>(workOrder);
  const [putWorkOrder, { isLoading, error, isSuccess, reset }] =
    usePutWorkOrder();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putWorkOrder,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const onServiceAddressChange = (serviceAddress: Address) => {
    setRequest(updateAddress(workOrder, serviceAddress));
  };

  return (
    <ServiceAddressCard
      address={request.address}
      onAddressChange={onServiceAddressChange}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "address.")}
      resetFieldError={(field) => resetNotReadyErrorField(`address.${field}`)}
      disabled={workOrder.immutable}
    />
  );
}

interface DateInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  error: unknown;
}

function BeginDateInput({
  workOrder,
  onWorkOrderChange,
  error,
}: DateInputProps) {
  const onDateChange = (date: string | null) => {
    onWorkOrderChange(updateBeginDate(workOrder, date));
  };

  const formattedDate = workOrder.beginDate
    ? new Date(workOrder.beginDate)
    : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
  };

  const markError =
    (rtkErrIsValidationError(error) &&
      hasFieldError(error.data, "beginDate")) ||
    (isValidationError(error) && hasFieldError(error, "beginDate"));

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Arbeitsbeginn")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={workOrder.immutable}
      />
    </div>
  );
}

function EndDateInput({ workOrder, onWorkOrderChange, error }: DateInputProps) {
  const onDateChange = (date: string | null) => {
    onWorkOrderChange(updateEndDate(workOrder, date));
  };

  const formattedDate = workOrder.endDate
    ? new Date(workOrder.endDate)
    : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
  };

  const markError =
    (rtkErrIsValidationError(error) &&
      hasFieldError(error.data, "beginDate")) ||
    (isValidationError(error) && hasFieldError(error, "beginDate"));

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Arbeitsende")}</Label>
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={workOrder.immutable}
      />
    </div>
  );
}

interface TeamLeaderInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  error: unknown;
}

function TeamLeaderInput({
  workOrder,
  onWorkOrderChange,
  error,
}: TeamLeaderInputProps) {
  const onTeamLeaderChange = (teamLeader: string) => {
    onWorkOrderChange(updateTeamLeader(workOrder, teamLeader));
  };

  const errors = [
    ...(rtkErrIsValidationError(error)
      ? (fieldErrors(error.data, "teamLeader") ?? [])
      : []),
    ...(fieldErrors(error, "teamLeader") ?? []),
  ];

  return (
    <TextInput
      label={t("Teamleiter")}
      field="teamLeader"
      text={workOrder.teamLeader}
      onTextChange={onTeamLeaderChange}
      errors={errors}
    />
  );
}

interface CommentInputProps {
  workOrder: WorkOrder;
  onWorkOrderChange: (workOrder: WorkOrder) => void;
  error: unknown;
}

function CommentInput({
  workOrder,
  onWorkOrderChange,
  error,
}: CommentInputProps) {
  const onCommentChange = (comment: string) => {
    onWorkOrderChange(updateComment(workOrder, comment));
  };

  const errors = [
    ...(rtkErrIsValidationError(error)
      ? (fieldErrors(error.data, "teamLeader") ?? [])
      : []),
    ...(fieldErrors(error, "teamLeader") ?? []),
  ];

  return (
    <TextareaInput
      label={t("Kommentar (intern)")}
      field="comment"
      text={workOrder.comment}
      onTextChange={onCommentChange}
      errors={errors}
    />
  );
}

function ServiceContactCard({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<WorkOrder>(workOrder);
  const [putWorkOrder, { isLoading, error, isSuccess, reset }] =
    usePutWorkOrder();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putWorkOrder,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const onContactChange = (contact: Contact) => {
    setRequest(updateContact(workOrder, contact));
  };

  return (
    <ContactCard
      contact={request.contact}
      onContactChange={onContactChange}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "contact.")}
      resetFieldError={(field) => resetNotReadyErrorField(`contact.${field}`)}
      disabled={workOrder.immutable}
    />
  );
}

function ServiceRepresentativeCard({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<WorkOrder>(workOrder);
  const [putWorkOrder, { isLoading, error, isSuccess, reset }] =
    usePutWorkOrder();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putWorkOrder,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const onRepresentativeChange = (rep: Representative) => {
    setRequest(updateRepresentative(workOrder, rep));
  };

  return (
    <RepresentativeCard
      representative={request.representative}
      onRepresentativeChange={onRepresentativeChange}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "representative.")}
      resetFieldError={(field) =>
        resetNotReadyErrorField(`representative.${field}`)
      }
      disabled={workOrder.immutable}
    />
  );
}
