import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { useHtzOrderDeleteMutation } from "@/services/backend/htz/orders/service";
import { useEffect, useState } from "react";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useNavigate } from "react-router";

interface Props {
  orderId: string;
}

export function DeleteOrderDialog({ orderId }: Props) {
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [deleteOrder, { isLoading, error, isSuccess, reset }] =
    useHtzOrderDeleteMutation();

  const doDelete = () => {
    if (isLoading) {
      return;
    }

    deleteOrder({
      id: orderId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      reset();
      navigate("..");
    }
  }, [isSuccess, setOpen, reset, navigate]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Bestellung wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
