import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { NavLink } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { useRltOfferListQuery } from "@/services/backend/rlt/offers/service";
import { Offer } from "@/services/backend/rlt/offers/offer";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function OfferTable(props: { processId: string }) {
  const { processId } = props;

  const {
    data: list,
    isLoading,
    error,
  } = useRltOfferListQuery({
    process: {
      active: true,
      values: [processId],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-full">{t("Heruntergeladen")}</TableHead>
            <TableHead className="w-32">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Angebotsdatum")}</TableHead>
          <TableHead className="w-full">{t("Heruntergeladen")}</TableHead>
          <TableHead className="w-48">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.offers ?? [])].sort(byOfferDate).map((offer) => (
          <TableRow key={offer.id}>
            <TableCell>
              <NavLink to={`${offer.id}`}>
                <ArtefactNumberById artefactId={offer.id} />
              </NavLink>
            </TableCell>
            <TableCell>
              {new Date(offer.offerDate).toLocaleDateString()}
            </TableCell>
            <TableCell>{offer.immutable ? "ja" : "nein"}</TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavLink to={`${offer.id}`}>
                  <Button variant="ghost">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {list?.offers?.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              {t("Keine Angebote gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function byOfferDate(a: Offer, b: Offer) {
  return a.offerDate.localeCompare(b.offerDate);
}
