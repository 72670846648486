import { useParams } from "react-router";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useRltLMRAShowQuery } from "@/services/backend/rlt/work-orders/lmra-service";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { LastMinuteRiskAnalysis } from "@/services/backend/rlt/work-orders/last-minute-risk-analysis";
import { ConfirmedLMRAComponent } from "./_components/confirmed-lmra";
import { UnconfirmedLMRAComponent } from "./_components/unconfirmed-lmra";

export function WorkOrderLastMinuteRiskAnalysisRoute() {
  const { lmraId } = useParams();
  const {
    data: lmra,
    isLoading,
    error,
  } = useRltLMRAShowQuery({
    id: lmraId!,
  });

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <LMRAComponent lmra={lmra!} />;
}

export function LMRAComponent({ lmra }: { lmra: LastMinuteRiskAnalysis }) {
  return lmra.confirmed ? (
    <ConfirmedLMRAComponent lmra={lmra} />
  ) : (
    <UnconfirmedLMRAComponent lmra={lmra} />
  );
}
