import CompanyRoute from "@/routes/base-data/companies/company";
import { ContactsRoute } from "@/routes/base-data/contacts/contacts-route";
import { ContactKindRoute } from "@/routes/base-data/contacts/contact-kind/contact-kind-route";
import { BranchesRoute } from "@/routes/base-data/branches/branches";
import MatchesRoute from "@/routes/base-data/dynamics/matches/matches";
import { ProcessesBaseDataRoute } from "@/routes/base-data/processes/processes-base-data-route";
import { EntraRoute } from "@/routes/base-data/entra/entra-route";
import { entraRoutes } from "@/routes/base-data/entra/entra-routes";
import { RouteObject } from "react-router";
import { CompaniesRoute } from "@/routes/base-data/companies/companies-route";
import { CompanyListRoute } from "@/routes/base-data/companies/list/company-list-route";
import { processesBaseDataRoutes } from "@/routes/base-data/processes/processes-base-data-routes";
import { numbersBaseDataRoutes } from "@/routes/base-data/numbers/numbers-base-data-routes";
import { NumbersBaseDataRoute } from "@/routes/base-data/numbers/numbers-base-data-route";

export const baseDataRoutes: RouteObject[] = [
  {
    path: "branches",
    element: <BranchesRoute />,
  },
  {
    path: "companies",
    element: <CompaniesRoute />,
    children: [
      {
        index: true,
        element: <CompanyListRoute />,
      },
    ],
  },
  {
    path: "companies/:id",
    element: <CompanyRoute />,
  },
  {
    path: "contacts",
    element: <ContactsRoute />,
    children: [
      {
        path: "contact-kind",
        element: <ContactKindRoute />,
      },
    ],
  },
  {
    path: "dynamics/matches",
    element: <MatchesRoute />,
  },
  {
    path: "entra",
    element: <EntraRoute />,
    children: entraRoutes,
  },
  {
    path: "processes",
    element: <ProcessesBaseDataRoute />,
    children: processesBaseDataRoutes,
  },
  {
    path: "numbers",
    element: <NumbersBaseDataRoute />,
    children: numbersBaseDataRoutes,
  },
];
