import {
  Mutation,
  Query,
  useArtefactDBGet,
  useArtefactDBGetAll,
  useArtefactDBPut,
} from "@/shared/indexdb/artefact-db-hooks";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import {
  useHtzAirHandlingUnitListQuery,
  useHtzAirHandlingUnitPutMutation,
  useHtzAirHandlingUnitShowQuery,
} from "@/services/backend/htz/ahu/service";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { useParams } from "react-router";

export function usePutAirHandlingUnit(): Mutation<
  AirHandlingUnit,
  AirHandlingUnit
> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [
    putToIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBPut<AirHandlingUnit, AirHandlingUnit>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "airHandlingUnit",
  );

  const [putAirHandlingUnitMutation, { isLoading, error, isSuccess, reset }] =
    useHtzAirHandlingUnitPutMutation();

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [
        putAirHandlingUnitMutation,
        { isLoading, error, isSuccess, reset },
      ];
    case OfflineState.OFFLINE:
      return [
        putToIndexedDB,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(`Put of ahu not possible is state ${offlineState}.`);
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}

export function useGetAirHandlingUnit(id: string): Query<AirHandlingUnit> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading: serverIsLoading,
    error: serverError,
  } = useHtzAirHandlingUnitShowQuery({ id });

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGet<AirHandlingUnit>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "airHandlingUnit",
    id,
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData,
        isLoading: serverIsLoading,
        error: serverError,
      };
    case OfflineState.OFFLINE:
      return {
        data: indexedDBData,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useListAirHandlingUnit(
  workOrderId: string,
  customerId: string,
): Query<AirHandlingUnit[]> {
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading: serverIsLoading,
    error: serverError,
  } = useHtzAirHandlingUnitListQuery({
    customer: {
      active: true,
      values: [customerId],
    },
  });

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGetAll<AirHandlingUnit>(
    ArtefactKind.WorkOrder,
    workOrderId,
    "airHandlingUnit",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData?.airHandlingUnits,
        isLoading: serverIsLoading,
        error: serverError,
      };
    case OfflineState.OFFLINE:
      return {
        data: indexedDBData,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}
