import { backendApi } from "@/services/backend/api";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import {
  downloadResponseHandler,
  previewResponseHandler,
} from "@/services/backend/pdf-helpers";
import {
  Position,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";

const workOrderApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzWorkOrder", "HtzWorkOrderPosition"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzWorkOrderPut: build.mutation<WorkOrder, PutRequest>({
        query: (request) => ({
          url: `/htz/work-order`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzWorkOrder", id: request.id },
          "HtzWorkOrder",
        ],
      }),
      htzWorkOrderPutPosition: build.mutation<Position, PutPositionRequest>({
        query: (request) => ({
          url: `/htz/work-order/position`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzWorkOrderPosition", id: request.id },
          "HtzWorkOrderPosition",
        ],
      }),
      htzWorkOrderSign: build.mutation<null, SignRequest>({
        query: (request) => {
          const body = new FormData();
          body.append("id", request.id);
          body.append("signature", request.signature);
          body.append("dataFormat", request.dataFormat);
          body.append("nameOfSigner", request.nameOfSigner);
          return {
            url: `/htz/work-order/signature`,
            method: "PATCH",
            body,
            formData: true,
          };
        },
      }),
      htzWorkOrderShow: build.query<WorkOrder, ShowRequest>({
        query: (request) => ({
          url: `/htz/work-order`,
          method: "GET",
          params: request,
        }),
        providesTags: providesEntity("HtzWorkOrder"),
      }),
      htzWorkOrderList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/work-order/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.workOrders, "HtzWorkOrder"),
      }),
      htzWorkOrderListPosition: build.query<PositionList, ListPositionRequest>({
        query: (request) => ({
          url: `/htz/work-order/position/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.positions, "HtzWorkOrderPosition"),
      }),
      htzWorkOrderDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/work-order`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzWorkOrder"],
      }),
      htzWorkOrderDeletePosition: build.mutation<null, DeletePositionRequest>({
        query: (request) => ({
          url: `/htz/work-order/position`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzWorkOrderPosition"],
      }),
      htzWorkOrderPhotoDocumentationUploadImage: build.mutation<
        null,
        UploadImageRequest
      >({
        query: (request) => {
          const body = new FormData();
          body.append("positionId", request.positionId);
          body.append("photoId", request.photoId);
          body.append("fileId", request.fileId);
          body.append("file", request.file);
          return {
            url: `/htz/work-order/photo-documentation/photos`,
            method: "POST",
            body,
            formData: true,
          };
        },
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzWorkOrderPosition", id: request.positionId },
          "HtzWorkOrderPosition",
        ],
      }),
      htzWorkOrderPhotoDocumentationShowImage: build.query<
        { objectURL: string },
        ShowImageRequest
      >({
        query: (request) => ({
          url: `/htz/work-order/photo-documentation/photos`,
          method: "GET",
          params: request,
          responseHandler: async (response) => {
            if (response.status !== 200) {
              return response.json();
            }
            return {
              objectURL: window.URL.createObjectURL(await response.blob()),
            };
          },
          // IMPORTANT. Since this query will download the Image under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
      }),
      htzWorkOrderPhotoDocumentationDeleteImage: build.mutation<
        null,
        DeleteImageRequest
      >({
        query: (request) => ({
          url: `/htz/work-order/photo-documentation/photos`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzWorkOrderPosition", id: request.positionId },
          "HtzWorkOrderPosition",
          "HtzWorkOrder",
        ],
      }),
      htzWorkOrderPdfPreview: build.query<
        { objectURL: string },
        PreviewPDFRequest
      >({
        query: (request) => ({
          url: `/htz/work-order/pdf`,
          method: "PATCH",
          body: request,
          // headers: {
          //   Accept: "application/pdf",
          // },
          responseHandler: previewResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        providesTags: (_r, _e, request) => [
          { type: "HtzWorkOrder", id: request.workOrderId },
        ],
      }),
      htzWorkOrderPdfDownload: build.mutation<
        { filename: string; objectURL: string },
        PDFRequest
      >({
        query: (request) => ({
          url: `/htz/work-order/pdf`,
          method: "PATCH",
          body: {
            ...request,
            download: true,
          },
          responseHandler: downloadResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        invalidatesTags: (_r, _e, request) => [
          { type: "HtzWorkOrder", id: request.workOrderId },
          "HtzWorkOrderPosition",
        ],
      }),
    }),
  });

export const {
  useHtzWorkOrderPutMutation,
  useHtzWorkOrderPutPositionMutation,
  useHtzWorkOrderSignMutation,
  useHtzWorkOrderShowQuery,
  useHtzWorkOrderListQuery,
  useHtzWorkOrderListPositionQuery,
  useHtzWorkOrderDeleteMutation,
  useHtzWorkOrderDeletePositionMutation,
  useHtzWorkOrderPhotoDocumentationUploadImageMutation,
  useHtzWorkOrderPhotoDocumentationShowImageQuery,
  useHtzWorkOrderPhotoDocumentationDeleteImageMutation,
  useHtzWorkOrderPdfPreviewQuery,
  useHtzWorkOrderPdfDownloadMutation,
} = workOrderApi;

export interface PutRequest extends WorkOrder {}

export interface PutPositionRequest extends Position {}

export interface SignRequest {
  id: string;
  signature: string;
  dataFormat: string;
  nameOfSigner: string;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  branch?: Filter<string>;
  executingBranch?: Filter<string>;
  process?: Filter<string>;
  date?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface ListPositionRequest {
  workOrder?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  workOrders: WorkOrder[];
  count: number;
  limit?: number;
  offset?: number;
}

export interface PositionList {
  positions: Position[];
  count: number;
  limit?: number;
  offset?: number;
}

export interface DeleteRequest {
  id: string;
}

export interface DeletePositionRequest {
  id: string;
  workOrderId: string;
}

export enum WorkOrderDocument {
  ALL = "all",
  CONFIRMATION = "workOrder",
}

export interface UploadImageRequest {
  positionId: string;
  photoId: string;
  fileId: string;
  file: File;
}

export interface ShowImageRequest {
  fileId: string;
  filePrefix: string;
}

export interface DeleteImageRequest {
  positionId: string;
  photoId: string;
  fileId: string;
  filePrefix: string;
}

export interface PreviewPDFRequest {
  workOrderId: string;
  document: WorkOrderDocument;
}

export interface PDFRequest {
  workOrderId: string;
  document: WorkOrderDocument;
  download: boolean;
  downloadBy: string;
}
