import GemexRouter from "@/routes/gemex/router";
import { Outlet } from "react-router";

export default function GemexRoot() {
  const router = GemexRouter();
  return (
    <div>
      {router}
      <Outlet />
    </div>
  );
}
