import { MainPageContent } from "@/routes/_components/main-page";
import t from "@/lang/lang";
import { Role } from "@/shared/lib/authorization/roles";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { useMemo, useState } from "react";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import { ProcessesTable } from "@/routes/gesec/processes/_components/processes-table";
import { WorkOrdersTable } from "@/routes/dashboard/_components/work-orders-table";
import { FrontendUser } from "@/shared/lib/authorization/frontendUser";
import { DashboardContext } from "@/routes/dashboard/_components/dashboard-context";

/**
 * Dashboard is a page which shows content optimized for the user's role.
 * If a user has more than one role, the first role is used as default, and
 * may be switched for one of the others.
 */
export function DashboardRoute() {
  const { user } = useAuth();
  const [role, setRole] = useState<Role>(user!.roles[0] as Role);

  const contextValue = useMemo(() => ({ role, setRole }), [role, setRole]);

  const tabs = tabsForRole(role);

  return (
    <DashboardContext.Provider value={contextValue}>
      <MainPageContent className="grid grid-cols-1 gap-6">
        <Tabs defaultValue={tabs[0]!.value}>
          <div className="flex">
            <TabsList>
              {tabs.map((tab) => (
                <TabsTrigger value={tab.value} key={tab.value}>
                  {tab.label}
                </TabsTrigger>
              ))}
            </TabsList>
            <RoleSwitcher role={role} onRoleChange={setRole} user={user!} />
          </div>
          {tabs.some((tab) => tab.value === "processes") && (
            <TabsContent value="processes">
              <ProcessesTable />
            </TabsContent>
          )}
          {tabs.some((tab) => tab.value === "work-orders") && (
            <TabsContent value="work-orders">
              <WorkOrdersTable />
            </TabsContent>
          )}
        </Tabs>
      </MainPageContent>
    </DashboardContext.Provider>
  );
}

function tabsForRole(role: Role) {
  switch (role) {
    case Role.TeamLeader:
      return [{ value: "work-orders", label: t("Arbeitsscheine") }];
    default:
      // For now, only the team leader has a different view
      // this may be modified at any time.
      return [
        { value: "processes", label: t("Vorgänge") },
        { value: "work-orders", label: t("Arbeitsscheine") },
      ];
  }
}

/**
 * RoleSwitcher allows the user to adjust the design of the dashboard
 * based on their role. RoleSwitcher is only shown if the user has more
 * than one role.
 */
function RoleSwitcher({
  role,
  onRoleChange,
  user,
}: {
  role: Role;
  onRoleChange: (role: Role) => void;
  user: FrontendUser;
}) {
  const { roles } = user;

  if (roles.length <= 1) {
    return null;
  }

  return (
    <Select value={role} onValueChange={onRoleChange}>
      <SelectTrigger className="bg-secondary text-secondary-foreground hover:bg-secondary/80 ml-auto h-9 max-w-[150px] border-0">
        <SelectValue />
      </SelectTrigger>
      <SelectContent>
        {roles.map((selectable) => (
          <SelectItem value={selectable} key={selectable}>
            {t(selectable)}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}
