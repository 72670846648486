import { Calculation } from "@/services/backend/rlt/calculations/calculation";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import t from "@/lang/lang";
import { useNavigate, useParams } from "react-router";
import {
  useRltCalculationDeleteMutation,
  useRltCalculationShowQuery,
} from "@/services/backend/rlt/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useToast } from "@/shared/hooks/use-toast";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { useEffect } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Card } from "@/shared/components/ui/card";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function CalculationRoute() {
  const { calculationId } = useParams();
  const {
    data: calculation,
    isLoading,
    error,
  } = useRltCalculationShowQuery({ id: calculationId! });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return <CalculationPage calculation={calculation!} />;
}

function CalculationPage({ calculation }: { calculation: Calculation }) {
  const links = [
    { to: "treatment", label: t("Aufnahme") },
    { to: "calculation", label: t("Kalkulation") },
  ];

  return (
    <PersistenceContextProvider>
      <ArtefactPage>
        <ArtefactPageHeader>
          <ArtefactPageNavigation>
            {links.map((link) => (
              <ArtefactPageNavigationLink to={link.to} key={link.to}>
                {link.label}
              </ArtefactPageNavigationLink>
            ))}
          </ArtefactPageNavigation>
          <ArtefactPageActions>
            <PersistenceStateUsingContext className="mr-2" />
            <DeleteCalculationDialog calculationId={calculation.id} />
          </ArtefactPageActions>
        </ArtefactPageHeader>
        <ArtefactPageContent>
          <ArtefactPageNavigationOutlet />
        </ArtefactPageContent>
      </ArtefactPage>
    </PersistenceContextProvider>
  );
}

function DeleteCalculationDialog(props: { calculationId: string }) {
  const { calculationId } = props;
  const [deleteCalculation, { isLoading, error, isSuccess, reset }] =
    useRltCalculationDeleteMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteCalculation({ id: calculationId });
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Kalkulation gelöscht"),
        variant: "success",
      });
      navigate(`..`);
      reset();
    }
  }, [error, isSuccess, navigate, reset, toast]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Kalkulation wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
