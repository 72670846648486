import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Plus, Search } from "lucide-react";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import { NavLink, useNavigate } from "react-router";
import { v4 } from "uuid";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { ProcessTopic } from "@/shared/components/domain/process-topic/process-topic";
import { useState } from "react";
import { FilterMode } from "@/shared/lib/filter/filter";
import {
  useRltCalculationCreateMutation,
  useRltCalculationListQuery,
} from "@/services/backend/rlt/calculations/service";
import { CopyCalculationButton } from "@/routes/gesec/processes/[processId]/rlt/calculations/_components/copy-calculation-button";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

interface Props {
  processId: string;
}

export function CreateCalculationDialog({ processId }: Props) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const closeDialogAndNavigate = (calculationId: string) => {
    setOpen(false);
    navigate(calculationId);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="h-5 w-5" />
          <span>{t("Kalkulation")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] min-w-[80vw] overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>{t("Neue Kalkulation erstellen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Kalkulationen können entweder komplett neu oder auf Basis einer alten Kalkulation erstellt werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="manual">
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="manual">{t("Neu Anlegen")}</TabsTrigger>
            <TabsTrigger value="from-old">
              {t("Alte Kalkulationen")}
            </TabsTrigger>
          </TabsList>
          <TabsContent value="manual">
            <CreateManually
              processId={processId}
              onCreated={closeDialogAndNavigate}
            />
          </TabsContent>
          <TabsContent value="from-old">
            <CreateFromOld
              processId={processId}
              onCreated={closeDialogAndNavigate}
            />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}

function CreateManually({
  processId,
  onCreated,
}: {
  processId: string;
  onCreated: (calculationId: string) => void;
}) {
  const [id, setId] = useState(v4());
  const [create, { isLoading, error, isSuccess, reset }] =
    useRltCalculationCreateMutation();
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  if (isSuccess) {
    onCreated(id);
    reset();
    setId(v4());
  }

  const disabled = !process || isLoading;

  const onClick = () => {
    if (disabled) {
      return;
    }

    create({
      id,
      processId: process.id,
      companyId: process.companyId,
      branchId: process.branchId,
      customerId: process.customerId,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Neu Anlegen")}</CardTitle>
        <CardDescription>
          {t(
            "Erstellt eine Kalkulation mit den Basisdaten des Prozesses und des Kunden.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RTKQueryErrorAlert error={error} />
      </CardContent>
      <CardFooter className="justify-end">
        <Button onClick={onClick} disabled={disabled}>
          {t("Erstellen")}
        </Button>
      </CardFooter>
    </Card>
  );
}

function CreateFromOld(props: {
  processId: string;
  onCreated: (calculationId: string) => void;
}) {
  const { processId, onCreated } = props;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Auf Basis alter Kalkulation")}</CardTitle>
        <CardDescription>
          {t(
            "Kopiert die Daten der alten Kalkulation. Es werden nur Kalkulationen von abgeschlossenen Vorgängen angezeigt.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <OldCalculationsTable processId={processId} onCreated={onCreated} />
      </CardContent>
    </Card>
  );
}

function OldCalculationsTable(props: {
  processId: string;
  onCreated: (calculationId: string) => void;
}) {
  const { processId, onCreated } = props;
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  const {
    data: list,
    isLoading,
    error,
  } = useRltCalculationListQuery({
    customer: {
      active: true,
      values: [process?.customerId ?? ""],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
    },
    immutable: {
      active: false,
      values: [true],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead>{t("Datum")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead className="w-full">{t("Vorgang Thema")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead className="w-full">{t("Vorgang Thema")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {list?.calculations?.map((calculation) => (
          <TableRow key={calculation.id}>
            <TableCell>
              {new Date(calculation.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell>
              <ArtefactNumberById artefactId={calculation.id} />
            </TableCell>
            <TableCell>
              <ProcessTopic processId={processId} />
            </TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavigateToCalculation
                  currentProcessId={processId}
                  calculationProcessId={calculation.processId}
                  calculationId={calculation.id}
                />
                <CopyCalculationButton
                  calculationId={calculation.id}
                  processId={processId}
                  onCopy={onCreated}
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function NavigateToCalculation(props: {
  currentProcessId: string;
  calculationProcessId: string;
  calculationId: string;
}) {
  const { currentProcessId, calculationProcessId, calculationId } = props;

  // If the calculation is part of the same process,
  // simply navigate to the calculation
  if (currentProcessId === calculationProcessId) {
    return (
      <NavLink to={calculationId}>
        <Button variant="ghost">
          <Search className="h-4 w-4" />
        </Button>
      </NavLink>
    );
  }

  // If the calculation is part of a different process,
  // show a warning and then navigate to the calculation
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost">
          <Search className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Achtung")}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {t(
            "Diese Kalkulation ist Teil eines anderen Vorgangs. Sie müssen nachdem Sie die Kalkulation angesehen haben zum aktuellen Vorgang zurückkehren.",
          )}
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <NavLink
            to={`/processes/rlt/${calculationProcessId}/calculations/${calculationId}`}
          >
            <Button>{t("Vorgang wechseln")}</Button>
          </NavLink>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
