import {
  addAirHandlingUnit,
  deletePosition,
  hasAirHandlingUnit,
  Position,
  positionErrorKey,
  removeAirHandlingUnit,
  updateDescription,
  updateOptionalOffer,
  updatePosition,
  updateTitle,
  updateUnitCount,
  updateUnitPrice,
} from "@/services/backend/htz/position/position";
import {
  fieldErrors,
  hasFieldError,
  resetField,
} from "@/shared/app-lib/errors/validation-error";
import { Card, CardHeader, CardTitle } from "@/shared/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Textarea } from "@/shared/components/ui/textarea";
import { Button } from "@/shared/components/ui/button";
import {
  Check,
  ChevronDown,
  ChevronRight,
  ChevronsDown,
  Trash2,
} from "lucide-react";
import { Input } from "@/shared/components/ui/input";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import { AirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { CheckedState } from "@radix-ui/react-checkbox";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { ChangeEvent, useState } from "react";
import { usePositionTableContext } from "@/routes/gesec/processes/[processId]/htz/_shared/positions/positions-table-context";
import { AddPositionButton } from "@/routes/gesec/processes/[processId]/htz/_shared/positions/add-position-button";

export function PositionsTable() {
  const { positions, onPositionsChange, immutable } = usePositionTableContext();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <span>{t("Leistungen")}</span>
          <AddPositionButton />
        </CardTitle>
      </CardHeader>
      <Table>
        <PositionTableHeader />
        <PositionTableBody
          positions={positions}
          onPositionsChange={onPositionsChange}
          immutable={immutable}
        />
      </Table>
    </Card>
  );
}

export function PositionsTableSkeleton() {
  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <span>{t("Leistungen")}</span>
        </CardTitle>
      </CardHeader>
      <Table>
        <PositionTableHeader />
        <TableBody>
          <TableRow>
            <TableCell colSpan={9} className="animate-pulse text-center">
              {t("Lade Daten")}
            </TableCell>
          </TableRow>
        </TableBody>
      </Table>
    </Card>
  );
}

function PositionTableHeader() {
  return (
    <TableHeader>
      <TableRow>
        <TableHead>{t("Pos")}</TableHead>
        <TableHead className="w-full">{t("Titel")}</TableHead>
        <TableHead>{t("Anlagen")}</TableHead>
        <TableHead>{t("Anzahl")}</TableHead>
        <TableHead>{t("Preis / Anzahl")}</TableHead>
        <TableHead>{t("Preis Angebot")}</TableHead>
        <TableHead>{t("Optional?")}</TableHead>
        <TableHead>{t("Aktionen")}</TableHead>
      </TableRow>
    </TableHeader>
  );
}

interface PositionTableBodyProps {
  positions: Position[];
  onPositionsChange: (positions: Position[]) => void;
  immutable: boolean;
}

function PositionTableBody({
  positions,
  onPositionsChange,
  immutable,
}: PositionTableBodyProps) {
  const disabled = immutable;

  const onPositionChange = (position: Position) => {
    const updatedPositions = updatePosition(positions, position);
    onPositionsChange(updatedPositions);
  };

  const onPositionDelete = (positionId: string) => {
    onPositionsChange(deletePosition(positions, positionId));
  };

  return (
    <TableBody>
      {positions.map((position) => (
        <PositionTableRow
          key={position.id}
          position={position}
          onPositionChange={onPositionChange}
          onPositionDelete={onPositionDelete}
          disabled={disabled}
        />
      ))}
      {positions.length === 0 && (
        <TableRow>
          <TableCell colSpan={9} className="text-center">
            {t("Keine Positionen")}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

interface PositionsTableRowProps {
  position: Position;
  onPositionChange: (position: Position) => void;
  onPositionDelete: (positionId: string) => void;
  disabled?: boolean;
}

function PositionTableRow({
  position,
  onPositionChange,
  onPositionDelete,
  disabled = false,
}: PositionsTableRowProps) {
  return (
    <>
      <TableRow className="border-b-0">
        <TableCell className="py-2">{position.number}</TableCell>
        <TitleCell
          position={position}
          onPositionChange={onPositionChange}
          disabled={disabled}
        />
        <AirHandlingUnitCell
          position={position}
          onPositionChange={onPositionChange}
          disabled={disabled}
        />
        <UnitCountCell
          position={position}
          onPositionChange={onPositionChange}
          disabled={disabled}
        />
        <UnitPriceCell
          position={position}
          onPositionChange={onPositionChange}
          disabled={disabled}
        />
        <OfferPriceCell position={position} />
        <OptionalOfferCell
          position={position}
          onPositionChange={onPositionChange}
          disabled={disabled}
        />
        <ActionsCell
          position={position}
          onPositionDelete={onPositionDelete}
          disabled={disabled}
        />
      </TableRow>
      <PositionDetailsRow
        position={position}
        onPositionChange={onPositionChange}
        disabled={disabled}
      />
    </>
  );
}

function PositionDetailsRow({
  position,
  onPositionChange,
  disabled = false,
}: {
  position: Position;
  onPositionChange: (position: Position) => void;
  disabled?: boolean;
}) {
  const [open, setOpen] = useState(false);

  if (!open) {
    return (
      <PositionDetailsRowToggle open={open} toggle={() => setOpen(!open)} />
    );
  }

  return (
    <>
      <PositionDetailsRowToggle open={open} toggle={() => setOpen(!open)} />
      <TableRow>
        <TableCell className="bg-background py-2"> </TableCell>
        <TableCell className="bg-background pt-2" colSpan={8}>
          <Textarea
            value={position.description}
            onChange={(e) =>
              onPositionChange(updateDescription(position, e.target.value))
            }
            disabled={disabled}
          />
        </TableCell>
      </TableRow>
    </>
  );
}

function PositionDetailsRowToggle({
  open,
  toggle,
}: {
  open: boolean;
  toggle: () => void;
}) {
  return (
    <TableRow className={open ? "border-b-0" : ""}>
      <TableCell className="bg-background h-6 py-0 pb-1"> </TableCell>
      <TableCell colSpan={8} className="bg-background h-6 py-0 pb-1">
        <Button className="h-6 px-1.5" variant="ghost" onClick={toggle}>
          {open ? (
            <ChevronDown className="mr-2 h-4 w-4" />
          ) : (
            <ChevronRight className="mr-2 h-4 w-4" />
          )}
          <span>{t("Details")}</span>
        </Button>
      </TableCell>
    </TableRow>
  );
}

interface CellProps {
  position: Position;
  onPositionChange: (position: Position) => void;
  disabled?: boolean;
}

function TitleCell({
  position,
  onPositionChange,
  disabled = false,
}: CellProps) {
  const { title } = position;
  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    onPositionChange(updateTitle(position, e.target.value));
  };

  return (
    <TableCell className="py-2">
      <Input
        value={title}
        onChange={onChange}
        disabled={disabled}
        className="h-8"
      />
    </TableCell>
  );
}

function AirHandlingUnitCell({
  position,
  onPositionChange,
  disabled = false,
}: CellProps) {
  const [open, setOpen] = useState(false);
  const { airHandlingUnits } = usePositionTableContext();
  const { airHandlingUnits: selected } = position;

  const onSelect = (name: string) => {
    const ahuId = findId(airHandlingUnits, name)!;
    if (hasAirHandlingUnit(position, ahuId)) {
      onPositionChange(removeAirHandlingUnit(position, ahuId));
    } else {
      onPositionChange(addAirHandlingUnit(position, ahuId));
    }
  };

  return (
    <TableCell className="py-2">
      <Popover open={open} onOpenChange={setOpen}>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="h-8 justify-between"
            disabled={disabled}
          >
            <span>
              {t("Anlagen")} ({selected?.length ?? 0})
            </span>
            <ChevronsDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="p-0">
          <Command>
            <CommandInput placeholder={t("Suchen")} />
            <CommandEmpty>{t("Nicht gefunden.")}</CommandEmpty>
            <CommandGroup>
              {airHandlingUnits.map((ahu) => (
                <CommandItem key={ahu.id} value={ahu.name} onSelect={onSelect}>
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      selected?.includes(ahu.id) ? "opacity-100" : "opacity-0",
                    )}
                  />
                  {ahu.name}
                </CommandItem>
              ))}
            </CommandGroup>
          </Command>
        </PopoverContent>
      </Popover>
    </TableCell>
  );
}

function findId(airHandlingUnits: AirHandlingUnit[], name: string) {
  return airHandlingUnits.find((ahu) => ahu.name.toLowerCase() === name)?.id;
}

function UnitCountCell({
  position,
  onPositionChange,
  disabled = false,
}: CellProps) {
  const { unitCount } = position;
  const { validationError, setValidationError } = usePositionTableContext();

  const errorField = positionErrorKey(position, "unitCount");
  const hasError = hasFieldError(validationError, errorField);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newUnitCount = Number(e.target.value);
    onPositionChange(updateUnitCount(position, newUnitCount));
    setValidationError(resetField(validationError, errorField));
  };

  return (
    <TableCell className="py-2">
      <Input
        value={unitCount}
        onChange={onChange}
        disabled={disabled}
        className={cn("h-8 w-20", hasError ? "border-red-500 shadow-md" : "")}
        type="number"
      />
    </TableCell>
  );
}

function UnitPriceCell({
  position,
  onPositionChange,
  disabled = false,
}: CellProps) {
  const { unitPrice } = position;
  const { validationError, setValidationError } = usePositionTableContext();

  const errorField = positionErrorKey(position, "unitPrice");
  const hasError = hasFieldError(validationError, errorField);

  const onChange = (e: ChangeEvent<HTMLInputElement>) => {
    const newUnitPrice = Number(e.target.value);
    onPositionChange(updateUnitPrice(position, newUnitPrice));
    setValidationError(resetField(validationError, errorField));
  };

  return (
    <TableCell className="py-2">
      <Input
        value={unitPrice}
        onChange={onChange}
        disabled={disabled}
        className={cn("h-8 w-20", hasError ? "border-red-500 shadow-md" : "")}
        type="number"
      />
    </TableCell>
  );
}

function OfferPriceCell({ position }: { position: Position }) {
  const { validationError } = usePositionTableContext();

  const key = positionErrorKey(position, "offerPrice");
  const hasError = fieldErrors(validationError, key) != null;

  return (
    <TableCell className="py-2 text-right font-bold">
      <span
        className={cn(
          hasError &&
            "border-destructive rounded-lg border-2 bg-red-200 px-1.5 py-1",
        )}
      >
        {position.offerPrice.toLocaleString("de", { minimumFractionDigits: 2 })}
      </span>
    </TableCell>
  );
}

function OptionalOfferCell({
  position,
  onPositionChange,
  disabled = false,
}: CellProps) {
  const { optionalOffer } = position;
  const onCheckedChange = (checked: CheckedState) => {
    const value = checked === true;
    onPositionChange(updateOptionalOffer(position, value));
  };

  return (
    <TableCell className="py-2 text-center">
      <Checkbox
        checked={optionalOffer}
        onCheckedChange={onCheckedChange}
        disabled={disabled}
      />
    </TableCell>
  );
}

function ActionsCell({
  position,
  onPositionDelete,
  disabled = false,
}: {
  position: Position;
  onPositionDelete: (positionId: string) => void;
  disabled?: boolean;
}) {
  return (
    <TableCell className="py-2 text-right">
      <Button
        variant="destructive"
        className="h-8 w-8 p-0"
        onClick={() => onPositionDelete(position.id)}
        disabled={disabled}
      >
        <Trash2 className="h-5 w-5" />
      </Button>
    </TableCell>
  );
}
