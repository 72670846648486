import { Filter } from "@/shared/lib/filter/filter";
import { backendApi } from "../../api";
import { Calculation, Parameters } from "./calculation";
import { Selection } from "./expenses";

const rltCalculationApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltCalculationCreate: build.mutation<Calculation, CreateRequest>({
      query: (request) => ({
        url: `/rlt/calculation`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["RltCalculation"],
    }),
    rltCalculationCreateFromOld: build.mutation<
      Calculation,
      CreateFromOldRequest
    >({
      query: (request) => ({
        url: `/rlt/calculation/create-from-old`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["RltCalculation"],
    }),
    rltCalculationShow: build.query<Calculation, ShowRequest>({
      query: (request) => ({
        url: `/rlt/calculation`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltCalculation", id: result.id }] : [],
    }),
    rltCalculationList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: `/rlt/calculation/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "RltCalculation", id: "LIST" }] : [],
    }),
    rltCalculationUpdateEmployees: build.mutation<
      Calculation,
      UpdateEmployeesRequest
    >({
      query: (request) => ({
        url: `/rlt/calculation/employees`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCalculation", id: request.id },
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltCalculationUpdateParameters: build.mutation<
      Calculation,
      UpdateParametersRequest
    >({
      query: (request) => ({
        url: `/rlt/calculation/parameters`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCalculation", id: request.id },
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltCalculationUpdateTravelTime: build.mutation<
      Calculation,
      UpdateTravelTimeRequest
    >({
      query: (request) => ({
        url: `/rlt/calculation/travel-time`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCalculation", id: request.id },
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltCalculationUpdateExpenses: build.mutation<
      Calculation,
      UpdateExpensesRequest
    >({
      query: (request) => ({
        url: `/rlt/calculation/expenses`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCalculation", id: request.id },
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltCalculationDelete: build.mutation<undefined, DeleteRequest>({
      query: (request) => ({
        url: `/rlt/calculation`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltCalculation", id: request.id },
        { type: "RltCalculation", id: "LIST" },
      ],
    }),
  }),
});

export const {
  useRltCalculationCreateMutation,
  useRltCalculationCreateFromOldMutation,
  useRltCalculationShowQuery,
  useRltCalculationListQuery,
  useRltCalculationUpdateEmployeesMutation,
  useRltCalculationUpdateParametersMutation,
  useRltCalculationUpdateTravelTimeMutation,
  useRltCalculationUpdateExpensesMutation,
  useRltCalculationDeleteMutation,
} = rltCalculationApi;

export interface CreateRequest {
  id: string;
  processId: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface CreateFromOldRequest {
  oldCalculationId: string;
  id: string;
  processId: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  branch?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  createdAt?: Filter<string>;
  owner?: Filter<string>;
  ownerType?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface UpdateEmployeesRequest {
  id: string;
  count: number;
}

export interface UpdateParametersRequest {
  id: string;
  parameters: Parameters;
}

export interface UpdateTravelTimeRequest {
  id: string;
  drives: number;
  hoursPerDrive: number;
}

export interface UpdateExpensesRequest {
  id: string;
  selection: Selection;
  perDiemDays: number;
  overnightNights: number;
}

export interface DeleteRequest {
  id: string;
}

export interface List {
  calculations: Calculation[];
  count: number;
}
