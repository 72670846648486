import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  useQpmPestCategoryListQuery,
  useQpmPestCategoryPutMutation,
  useQpmPestTypeListQuery,
  useQpmPestTypePutMutation,
} from "@/services/backend/qpm/pests/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import {
  PestCategory,
  PestType,
  PutPestCategoryRequest,
  PutPestTypeRequest,
} from "@/services/backend/qpm/pests/types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Button } from "@/shared/components/ui/button";
import { Pencil, Save, X } from "lucide-react";
import { ChangeEvent, useEffect, useState } from "react";
import { v4 } from "uuid";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import {
  PersistenceStateUsingContext,
  usePersistenceStateUpdateOnTrigger,
} from "@/shared/lib/persistence-state/persistence-state-info";
import { Input } from "@/shared/components/ui/input";
import {
  PestCategoryKey,
  PestTypeKey,
} from "@/services/backend/qpm/shared/enums";
import { PestTypeUtil } from "@/services/backend/qpm/shared/pestTypeUtil";
import { PestCategoryUtil } from "@/services/backend/qpm/shared/pestCategoryUtil";

export function PestTypeAndCategoryCard() {
  return (
    <Card>
      <CardHeader>
        <div className="flex items-center justify-between gap-2">
          <CardTitle>{t("Schädlings Kategorien")}</CardTitle>
          <PersistenceStateUsingContext />
        </div>
        <CardDescription>
          {t(
            "Hier können Sie die verschiedenen Schädlings Kategorien bearbeiten.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <PestCategoryList />
      </CardContent>
      <CardHeader>
        <div className="flex items-center justify-between gap-2">
          <CardTitle>{t("Schädlings Arten")}</CardTitle>
          <PersistenceStateUsingContext />
        </div>
        <CardDescription>
          {t("Hier können Sie die verschiedenen Schädlings Arten bearbeiten.")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <PestTypeList />
      </CardContent>
    </Card>
  );
}

function PestCategoryList() {
  const {
    data: list,
    error,
    isLoading,
  } = useQpmPestCategoryListQuery({ useDefaultValuesIfNotExist: true });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-20 w-full" />
      </div>
    );
  }

  const findCategory = (type: string): PestCategory | undefined =>
    list?.categories.find((cat) => cat.type === type);

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-fit">{t("Bezeichner")}</TableHead>
          <TableHead>{t("Beschreibung")}</TableHead>
          <TableHead>{t("Typ")}</TableHead>
          <TableHead className="text-center">
            {t("Empfohlene Anzahl an Maßnahmen")}
          </TableHead>
          <TableHead className="text-right">{t("Aktion")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <PestCategoryEntry
          pestKey={PestCategoryKey.GermanRoach}
          pestCategory={findCategory(PestCategoryKey.GermanRoach)}
          position={0}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.OrientalRoach}
          pestCategory={findCategory(PestCategoryKey.OrientalRoach)}
          position={1}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.Mouse}
          pestCategory={findCategory(PestCategoryKey.Mouse)}
          position={2}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.Rat}
          pestCategory={findCategory(PestCategoryKey.Rat)}
          position={3}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.Moth}
          pestCategory={findCategory(PestCategoryKey.Moth)}
          position={4}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.RiceWeevil}
          pestCategory={findCategory(PestCategoryKey.RiceWeevil)}
          position={5}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.Fly}
          pestCategory={findCategory(PestCategoryKey.Fly)}
          position={6}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.Pheromone}
          pestCategory={findCategory(PestCategoryKey.Pheromone)}
          position={7}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.Ant}
          pestCategory={findCategory(PestCategoryKey.Ant)}
          position={8}
        />
        <PestCategoryEntry
          pestKey={PestCategoryKey.Bird}
          pestCategory={findCategory(PestCategoryKey.Bird)}
          position={9}
        />
      </TableBody>
    </Table>
  );
}

type PestCategoryEntryProps = {
  pestKey: PestCategoryKey;
  pestCategory?: PestCategory;
  position: number;
};

function PestCategoryEntry({
  pestKey,
  pestCategory,
  position,
}: PestCategoryEntryProps) {
  const [isEditMode, setEditMode] = useState<boolean>(
    !pestCategory || pestCategory.isDefaultValue!,
  );

  const [request, setRequest] = useState<PutPestCategoryRequest>({
    id: pestCategory ? pestCategory.id : v4(),
    type: pestCategory ? pestCategory.type : pestKey,
    name: pestCategory ? pestCategory.name : PestCategoryUtil.toString(pestKey),
    description: pestCategory
      ? pestCategory.description
      : PestCategoryUtil.toLongString(pestKey),
    position: pestCategory ? pestCategory.position : position,
    requiredQpmCyclePerYear: pestCategory
      ? pestCategory.requiredQpmCyclePerYear
      : 0,
  });

  const [put, { error, isLoading, isSuccess, reset }] =
    useQpmPestCategoryPutMutation();

  const { onTrigger: triggerSave } = usePersistenceStateUpdateOnTrigger(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setEditMode(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (pestCategory && !pestCategory.isDefaultValue) {
      setEditMode(false);
      setRequest({ ...pestCategory });
    }
  }, [pestCategory]);

  const handleQpmCycleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let val = parseInt(e.target.value, 10);
    if (Number.isNaN(val)) {
      val = request.requiredQpmCyclePerYear;
    }
    if (val >= 100) {
      val = 100;
    }
    setRequest({
      ...request,
      requiredQpmCyclePerYear: val,
    });
  };

  if (isEditMode) {
    return (
      <TableRow>
        <TableCell className="py-1.5 font-medium">
          <Input
            type="text"
            placeholder={t("Bezeichner")}
            defaultValue={pestKey}
            value={request.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
          />
        </TableCell>
        <TableCell className="py-1.5">
          <Input
            type="text"
            placeholder={t("Beschreibung")}
            value={request.description}
            onChange={(e) =>
              setRequest({ ...request, description: e.target.value })
            }
          />
        </TableCell>
        <TableCell className="py-1.5">{request.type}</TableCell>
        <TableCell className="flex items-center justify-center py-1.5 text-center">
          <Input
            type="number"
            className="w-fit"
            placeholder={t("Maßnahmen")}
            defaultValue={request.requiredQpmCyclePerYear}
            value={request.requiredQpmCyclePerYear}
            min={0}
            max={100}
            onChange={handleQpmCycleChange}
          />
        </TableCell>
        <TableCell className="py-1.5 text-end">
          <div className="flex items-center justify-end">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="mr-1"
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("Abbrechen")}</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button size="sm" variant="default" onClick={triggerSave}>
                    <Save className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("Speichern")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell className="py-1.5 font-medium">{request.name}</TableCell>
      <TableCell className="py-1.5">{request.description}</TableCell>
      <TableCell className="py-1.5">{request.type}</TableCell>
      <TableCell className="py-1.5 text-center">
        {request.requiredQpmCyclePerYear}
      </TableCell>
      <TableCell className="py-1.5 text-end">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                <Pencil className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("Bearbeiten")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
    </TableRow>
  );
}

function PestTypeList() {
  const {
    data: list,
    error,
    isLoading,
  } = useQpmPestTypeListQuery({ useDefaultValuesIfNotExist: true });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-20 w-full" />
      </div>
    );
  }

  const findType = (type: string): PestType | undefined =>
    list?.types.find((tp) => tp.type === type);

  return (
    list && (
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-[100px]">{t("Bezeichner")}</TableHead>
            <TableHead>{t("Beschreibung")}</TableHead>
            <TableHead>{t("Typ")}</TableHead>
            <TableHead className="text-center">
              {t("Empfohlene Anzahl an Maßnahmen")}
            </TableHead>
            <TableHead className="text-right">{t("Aktion")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          <PestTypeEntry
            pestKey={PestTypeKey.MouseNonTox}
            pestType={findType(PestTypeKey.MouseNonTox)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.MouseTox}
            pestType={findType(PestTypeKey.MouseTox)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.RatNonTox}
            pestType={findType(PestTypeKey.RatNonTox)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.RatTox}
            pestType={findType(PestTypeKey.RatTox)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.MouseTrapALoRa}
            pestType={findType(PestTypeKey.MouseTrapALoRa)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.RatTrapALoRa}
            pestType={findType(PestTypeKey.RatTrapALoRa)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.CrawlingInsects}
            pestType={findType(PestTypeKey.CrawlingInsects)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.BeetleAttractant}
            pestType={findType(PestTypeKey.BeetleAttractant)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.MothTrapPheromone}
            pestType={findType(PestTypeKey.MothTrapPheromone)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.FlyExterminator}
            pestType={findType(PestTypeKey.FlyExterminator)}
          />
          <PestTypeEntry
            pestKey={PestTypeKey.BirdVisualCheckPoint}
            pestType={findType(PestTypeKey.BirdVisualCheckPoint)}
          />
        </TableBody>
      </Table>
    )
  );
}

type PestTypeEntryProps = {
  pestKey: PestTypeKey;
  pestType?: PestType;
};

function PestTypeEntry({ pestKey, pestType }: PestTypeEntryProps) {
  const [isEditMode, setEditMode] = useState(
    !pestType || pestType.isDefaultValue,
  );

  const [request, setRequest] = useState<PutPestTypeRequest>({
    id: pestType ? pestType.id : v4(),
    type: pestType ? pestType.type : pestKey,
    name: pestType ? pestType.name : PestTypeUtil.toString(pestKey),
    description: pestType
      ? pestType.description
      : PestTypeUtil.toLongString(pestKey),
    requiredQpmCyclePerYear: pestType ? pestType.requiredQpmCyclePerYear : 0,
  });

  const [put, { error, isLoading, isSuccess, reset }] =
    useQpmPestTypePutMutation();

  const { onTrigger: triggerSave } = usePersistenceStateUpdateOnTrigger(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setEditMode(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (pestType && !pestType.isDefaultValue) {
      setEditMode(false);
      setRequest({ ...pestType });
    }
  }, [pestType]);

  const handleQpmCycleChange = (e: ChangeEvent<HTMLInputElement>) => {
    let val = parseInt(e.target.value, 10);
    if (Number.isNaN(val)) {
      val = request.requiredQpmCyclePerYear;
    }
    if (val >= 100) {
      val = 100;
    }
    setRequest({
      ...request,
      requiredQpmCyclePerYear: val,
    });
  };

  if (isEditMode) {
    return (
      <TableRow>
        <TableCell className="py-1.5 font-medium">
          <Input
            type="text"
            placeholder={t("Bezeichner")}
            defaultValue={pestKey}
            value={request.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
          />
        </TableCell>
        <TableCell className="py-1.5">
          <Input
            type="text"
            placeholder={t("Beschreibung")}
            value={request.description}
            onChange={(e) =>
              setRequest({ ...request, description: e.target.value })
            }
          />
        </TableCell>
        <TableCell className="py-1.5">{request.type}</TableCell>
        <TableCell className="flex items-center justify-center py-1.5 text-center">
          <Input
            type="number"
            className="w-fit"
            placeholder={t("Maßnahmen")}
            defaultValue={request.requiredQpmCyclePerYear}
            value={request.requiredQpmCyclePerYear}
            min={0}
            max={100}
            onChange={handleQpmCycleChange}
          />
        </TableCell>
        <TableCell className="py-1.5 text-end">
          <div className="flex items-center justify-end">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="mr-1"
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("Abbrechen")}</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button size="sm" variant="default" onClick={triggerSave}>
                    <Save className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("Speichern")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell className="py-1.5 font-medium">{request.name}</TableCell>
      <TableCell className="py-1.5">{request.description}</TableCell>
      <TableCell className="py-1.5">{request.type}</TableCell>
      <TableCell className="py-1.5 text-center">
        {request.requiredQpmCyclePerYear}
      </TableCell>
      <TableCell className="py-1.5 text-end">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                variant="outline"
                size="sm"
                onClick={() => {
                  setEditMode(true);
                }}
              >
                <Pencil className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("Bearbeiten")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
    </TableRow>
  );
}
