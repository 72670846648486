import { useParams } from "react-router";
import { Card } from "@/shared/components/ui/card";
import { CreateReportButton } from "@/routes/gesec/processes/[processId]/htz/reports/_components/create-report-button";
import { ReportsTable } from "@/routes/gesec/processes/[processId]/htz/reports/_components/reports-table";
import t from "@/lang/lang";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";

export function ReportsIndexRoute() {
  const { processId } = useParams();
  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Berichte")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateReportButton processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Card>
          <ReportsTable processId={processId!} />
        </Card>
      </MilestonePageContent>
    </MilestonePage>
  );
}
