import {
  Dialog,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import t from "@/lang/lang";
import { Kind } from "@/services/backend/contacts/contact-kind/kind";
import { useContactsContactKindPutMutation } from "@/services/backend/contacts/contact-kind/service";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { useEffect, useState } from "react";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { useToast } from "@/shared/hooks/use-toast";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { v4 } from "uuid";

export function NewContactKindDialog() {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<Kind>({
    id: "",
    name: "",
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    useContactsContactKindPutMutation();
  const { toast } = useToast();

  const doPut = () => {
    if (isLoading) {
      return;
    }
    put({
      ...request,
      id: v4(),
    });
  };

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      reset();
      setRequest({ id: "", name: "" });
      toast({
        title: t("Kontaktart erstellt"),
        variant: "success",
      });
    }
  }, [isSuccess, setOpen, reset, toast]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="h-5 w-5" />
          <span>{t("Kontaktart")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Kontaktart erstellen")}</DialogTitle>
        </DialogHeader>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Name")}</Label>
          <Input
            type="text"
            placeholder={t("Name")}
            value={request.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
          />
          <InputValidationErrors error={error} field="name" />
        </div>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            {t("Abbrechen")}
          </Button>
          <Button onClick={doPut}>{t("Erstellen")}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
