import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { ChangeEvent, useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Edit, Plus } from "lucide-react";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  fieldErrors,
  rtkErrIsValidationError,
} from "@/shared/app-lib/errors/validation-error";
import { v4 } from "uuid";
import {
  addItem,
  Item,
  SizeFactor,
  updateItem,
} from "@/services/backend/htz/ahu/ahu-component-catalog";
import {
  useHtzAhuComponentCatalogPutMutation,
  useHtzAhuComponentCatalogShowQuery,
} from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { useHtzAhuComponentKindListQuery } from "@/services/backend/htz/ahu/component-kind-service";

export function PutAhuComponentCatalogItemDialog({
  catalogId,
  item = undefined,
}: {
  catalogId: string;
  item?: Item;
}) {
  const { data: catalog } = useHtzAhuComponentCatalogShowQuery({
    id: catalogId,
  });
  const [open, setOpen] = useState(false);
  const [updatedItem, setUpdatedItem] = useState<Item>(
    item ?? {
      id: v4(),
      catalogId,
      componentKindId: "",
      name: "",
      details: "",
      group: "",
      type: "",
      img: "",
      sizeFactor: 2,
    },
  );

  const [put, { isLoading, error, isSuccess, reset }] =
    useHtzAhuComponentCatalogPutMutation();

  const validationError = rtkErrIsValidationError(error) ? error.data : null;
  const errorsFor = (field: string): string[] => {
    const prefixedField = `items.${updatedItem.id}.${field}`;
    const errors = fieldErrors(validationError, prefixedField);
    return errors ?? [];
  };

  if (isSuccess) {
    setOpen(false);
    if (!item) {
      setUpdatedItem({
        id: v4(),
        catalogId,
        componentKindId: "",
        name: "",
        details: "",
        group: "",
        type: "",
        img: "",
        sizeFactor: 2,
      });
    }
    reset();
  }

  if (!catalog) {
    return item ? (
      <Button variant="outline" size="sm" disabled>
        <Edit className="h-5 w-5" />
      </Button>
    ) : (
      <Button size="sm" disabled>
        <Plus className="mr-2 h-5 w-5" />
        <span>{t("Vorlage")}</span>
      </Button>
    );
  }

  const onClick = () => {
    if (isLoading || !catalog) {
      return;
    }

    const updatedCatalog = item
      ? updateItem(catalog, updatedItem)
      : addItem(catalog, updatedItem);

    put(updatedCatalog);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {item ? (
          <Button variant="outline" size="sm">
            <Edit className="h-5 w-5" />
          </Button>
        ) : (
          <Button size="sm">
            <Plus className="mr-2 h-5 w-5" />
            <span>{t("Vorlage")}</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {item ? t("Vorlage bearbeiten") : t("Vorlage hinzufügen")}
          </DialogTitle>
        </DialogHeader>
        <NameInput
          name={updatedItem.name}
          onNameChange={(name) => setUpdatedItem({ ...updatedItem, name })}
          errors={errorsFor("name")}
        />
        <ComponentKindSelect
          componentKindId={updatedItem.componentKindId}
          onComponentKindIdChange={(componentKindId) =>
            setUpdatedItem({ ...updatedItem, componentKindId })
          }
          errors={errorsFor("componentKindId")}
        />
        <DetailsInput
          details={updatedItem.details}
          onDetailsChange={(details) =>
            setUpdatedItem({ ...updatedItem, details })
          }
          errors={errorsFor("details")}
        />
        <GroupInput
          group={updatedItem.group}
          onGroupChange={(group) => setUpdatedItem({ ...updatedItem, group })}
          errors={errorsFor("group")}
        />
        <TypeInput
          type={updatedItem.type}
          onTypeChange={(type) => setUpdatedItem({ ...updatedItem, type })}
          errors={errorsFor("type")}
        />
        <ImageInput
          img={updatedItem.img}
          onImgChange={(img) => setUpdatedItem({ ...updatedItem, img })}
          errors={errorsFor("img")}
        />
        <SizeFactorSelect
          sizeFactor={updatedItem.sizeFactor}
          onSizeFactorChange={(sizeFactor) =>
            setUpdatedItem({ ...updatedItem, sizeFactor })
          }
          errors={errorsFor("sizeFactor")}
        />
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t("Speichern")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function NameInput({
  name,
  onNameChange,
  errors,
}: {
  name: string;
  onNameChange: (name: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Name")}</Label>
      <Input
        type="text"
        placeholder={t("Name")}
        value={name}
        onChange={(e) => onNameChange(e.target.value)}
      />
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function ComponentKindSelect({
  componentKindId,
  onComponentKindIdChange,
  errors,
}: {
  componentKindId: string;
  onComponentKindIdChange: (id: string) => void;
  errors: string[];
}) {
  const { data: list, isLoading, error } = useHtzAhuComponentKindListQuery({});

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="grid gap-1.5">
      <Label>{t("Komponentenart")}</Label>
      <Select value={componentKindId} onValueChange={onComponentKindIdChange}>
        <SelectTrigger>
          <SelectValue placeholder={t("Art der Komponente")} />
        </SelectTrigger>
        <SelectContent>
          {list?.kinds.map((kind) => (
            <SelectItem value={kind.id}>{kind.name}</SelectItem>
          ))}
        </SelectContent>
      </Select>
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function DetailsInput({
  details,
  onDetailsChange,
  errors,
}: {
  details: string;
  onDetailsChange: (details: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Details")}</Label>
      <Input
        type="text"
        placeholder={t("Details")}
        value={details}
        onChange={(e) => onDetailsChange(e.target.value)}
      />
      <span className="text-muted-foreground text-xs">
        {t(
          "Optional. Beispielsweise Filtertyp: ISO ePM2,5 70%; wird in Gruppen angezeigt",
        )}
      </span>
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function GroupInput({
  group,
  onGroupChange,
  errors,
}: {
  group: string;
  onGroupChange: (details: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Gruppe")}</Label>
      <Input
        type="text"
        placeholder={t("Details")}
        value={group}
        onChange={(e) => onGroupChange(e.target.value)}
      />
      <span className="text-muted-foreground text-xs">
        {t(
          "Optional. Ordnet die Vorlage in einer Gruppe, bei der Erstellung der RLT-Anlage.",
        )}
      </span>
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function TypeInput({
  type,
  onTypeChange,
  errors,
}: {
  type: string;
  onTypeChange: (type: string) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("React Flow Type")}</Label>
      <Input
        type="text"
        placeholder={t("Type")}
        value={type}
        onChange={(e) => onTypeChange(e.target.value)}
      />
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function ImageInput({
  img,
  onImgChange,
  errors,
}: {
  img: string;
  onImgChange: (img: string) => void;
  errors: string[];
}) {
  const onChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      const file: File = e.target.files[0];
      const data: string = await file.text();
      const encoded = btoa(data);
      onImgChange(encoded);
      e.target.value = "";
      e.target.files = null;
    }
  };

  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Piktogramm")}</Label>
      <img src={`data:image/svg+xml;base64,${img}`} alt="" className="h-20" />
      <Input
        type="file"
        placeholder={t("Piktogramm")}
        accept=".svg"
        onChange={onChange}
      />
      <InputErrorMessages errors={errors} />
    </div>
  );
}

function SizeFactorSelect({
  sizeFactor,
  onSizeFactorChange,
  errors,
}: {
  sizeFactor: SizeFactor;
  onSizeFactorChange: (sizeFactor: SizeFactor) => void;
  errors: string[];
}) {
  return (
    <div className="w-full items-center space-y-1.5">
      <Label>{t("Größenfaktor (1 | 2 | 4)")}</Label>
      <Select
        value={sizeFactor.toString()}
        onValueChange={(value) => {
          const cast = Number(value);
          switch (cast) {
            case 1:
            case 2:
            case 4:
              onSizeFactorChange(cast);
              break;
            default:
              break;
          }
        }}
      >
        <SelectTrigger>
          <SelectValue placeholder={t("Größenfaktor")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectItem value="1">1</SelectItem>
            <SelectItem value="2">2</SelectItem>
            <SelectItem value="4">4</SelectItem>
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputErrorMessages errors={errors} />
    </div>
  );
}
