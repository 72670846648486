import { RouteObject } from "react-router";
import { CertificatesIndexRoute } from "@/routes/gesec/processes/[processId]/vbs/certificates/_components/certificates-index-route";
import { CertificateRoute } from "@/routes/gesec/processes/[processId]/vbs/certificates/[certificateId]/certificate-route";

export const certificatesRoutes: RouteObject[] = [
  {
    index: true,
    element: <CertificatesIndexRoute />,
  },
  {
    path: ":certificateId",
    element: <CertificateRoute />,
  },
];
