import { ConsumableTypeKey } from "@/services/backend/qpm/shared/enums";

export class ConsumableTypeUtil {
  private static descriptions: { [key in ConsumableTypeKey]: string } = {
    [ConsumableTypeKey.Bait]: "Köder",
    [ConsumableTypeKey.BaitTox]: "Köder mit Wirkstoff",
    [ConsumableTypeKey.BaitNonTox]: "Köder ohne Wirkstoff",
    [ConsumableTypeKey.Tube]: "Röhre",
    [ConsumableTypeKey.Adhesive]: "Klebefläche",
  };

  private static abbreviations: { [key in ConsumableTypeKey]: string } = {
    [ConsumableTypeKey.Bait]: "Köder",
    [ConsumableTypeKey.BaitTox]: "Köder Tox",
    [ConsumableTypeKey.BaitNonTox]: "Köder Non-Tox",
    [ConsumableTypeKey.Tube]: "Röhre",
    [ConsumableTypeKey.Adhesive]: "Klebefläche",
  };

  static toString(type: ConsumableTypeKey): string {
    return this.abbreviations[type];
  }

  static toLongString(type: ConsumableTypeKey): string {
    return this.descriptions[type] || "Unknown";
  }
}
