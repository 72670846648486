import { useParams } from "react-router";
import { useQpmMonitorTypeGetQuery } from "@/services/backend/qpm/monitors/service";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { MonitorComponent } from "@/routes/base-data/processes/qpm/catalog/[catalogId]/[monitorTypeKey]/[monitorId]/_components/monitor-component";
import t from "@/lang/lang";

export function CreateOrEditMonitorRoute() {
  const { catalogId, monitorTypeKey, monitorId } = useParams();

  const {
    data: type,
    error,
    isLoading,
  } = useQpmMonitorTypeGetQuery(
    {
      catalogId: catalogId!,
      monitorTypeKey: monitorTypeKey! as MonitorTypeKey,
    },
    { skip: !catalogId || !monitorTypeKey },
  );

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <CardContent>
        <Skeleton className="h-48 w-full" />
      </CardContent>
    );
  }

  return (
    type &&
    catalogId &&
    monitorId &&
    !isLoading && (
      <>
        <CardHeader>
          <CardTitle>{t("Monitor anlegen oder bearbeiten")}</CardTitle>
        </CardHeader>
        <MonitorComponent
          catalogId={catalogId}
          type={type}
          monitorId={monitorId}
        />
      </>
    )
  );
}
