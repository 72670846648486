import { useParams } from "react-router";
import { OfferTable } from "@/routes/gesec/processes/[processId]/rlt/offers/_components/offer-table";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";
import t from "@/lang/lang";
import { CreateOfferDialog } from "@/routes/gesec/processes/[processId]/rlt/offers/_components/create-offer-dialog";
import { TableCard } from "@/shared/components/ui/table-card";

export function OfferIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Angebote")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateOfferDialog processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <TableCard>
          <OfferTable processId={processId!} />
        </TableCard>
      </MilestonePageContent>
    </MilestonePage>
  );
}
