import { useRoutes } from "react-router";
import ProcessesRoute from "@/routes/gemex/processes/processes-route";
import QPMRouter from "@/routes/gemex/processes/qpm/router";
import TasksDashboard from "@/routes/gemex/tasks/tasks";
import { qpmRoutes } from "@/routes/gemex/processes/qpm/qpm-routes";

export default function GemexRouter() {
  return useRoutes([
    {
      path: "tasks",
      element: <TasksDashboard />,
    },
    {
      path: "processes",
      element: <ProcessesRoute />,
      children: [],
    },
    {
      path: "processes/qpm/:processId",
      element: <QPMRouter />,
      children: qpmRoutes,
    },
  ]);
}
