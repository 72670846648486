import { Navigate, Outlet, RouteObject } from "react-router";
import { CalculationParametersRoutePage } from "@/routes/base-data/processes/qpm/calculation-parameters/calculation-parameters-route-page";
import { CatalogListTablePage } from "@/routes/base-data/processes/qpm/catalog/catalog-list-table-page";
import { CatalogRoutePage } from "@/routes/base-data/processes/qpm/catalog/[catalogId]/catalog-route-page";
import { BaseDataPage } from "@/routes/base-data/processes/qpm/base-data/base-data-page";
import { CatalogRoute } from "@/routes/base-data/processes/qpm/catalog/catalog-route";
import { MonitorTypeRoute } from "@/routes/base-data/processes/qpm/catalog/[catalogId]/[monitorTypeKey]/monitor-type-route";
import { CreateOrEditMonitorRoute } from "@/routes/base-data/processes/qpm/catalog/[catalogId]/[monitorTypeKey]/[monitorId]/create-or-edit-monitor-route";

export const qpmBaseDataRoutes: RouteObject[] = [
  {
    index: true,
    element: <Navigate to="catalog" replace />,
  },
  {
    path: "base-data",
    element: <BaseDataPage />,
  },
  {
    path: "catalog",
    element: <CatalogRoute />,
    children: [
      {
        index: true,
        element: <CatalogListTablePage />,
      },
      {
        path: ":catalogId",
        element: <Outlet />,
        children: [
          {
            index: true,
            element: <CatalogRoutePage />,
          },
          {
            path: ":monitorTypeKey",
            element: <MonitorTypeRoute />,
          },
          {
            path: ":monitorTypeKey/:monitorId",
            element: <CreateOrEditMonitorRoute />,
          },
        ],
      },
    ],
  },
  {
    path: "catalog/:catalogId",
    element: <CatalogRoutePage />,
  },
  {
    path: "calculation-parameters",
    element: <CalculationParametersRoutePage />,
  },
];
