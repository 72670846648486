import { Filter } from "@/shared/lib/filter/filter";
import { providesList } from "@/services/backend/tag-helpers";
import { Address } from "@/services/backend/addresses/address/address";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { backendApi } from "../../api";
import { IncidentalCost, Offer } from "./offer";
import t from "../../../../lang/lang";

const offerService = backendApi.injectEndpoints({
  endpoints: (build) => ({
    vbsOfferCreate: build.mutation<Offer, CreateRequest>({
      query: (request) => ({
        url: `/vbs/offer`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferCreateFromOld: build.mutation<Offer, CreateFromOldRequest>({
      query: (request) => ({
        url: `/vbs/offer/create-from-old`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [{ type: "VbsOffer", id: "LIST" }, "VbsOffer"],
    }),
    vbsOfferCreateFromCalculation: build.mutation<
      Offer,
      CreateFromCalculationRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/create-from-calculation`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: () => [{ type: "VbsOffer", id: "LIST" }, "VbsOffer"],
    }),
    vbsOfferShow: build.query<Offer, ShowRequest>({
      query: (request) => ({
        url: `/vbs/offer`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsOffer", id: result.id }] : [],
    }),
    vbsOfferList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: `/vbs/offer/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) => providesList(result?.offers, "VbsOffer"),
    }),
    vbsOfferUpdateDate: build.mutation<Offer, UpdateDateRequest>({
      query: (request) => ({
        url: `/vbs/offer/offer-date`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateTreatmentBasePrice: build.mutation<
      Offer,
      UpdateTreatmentBasePriceRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/treatment/base-price`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateTreatmentSalesDiscount: build.mutation<
      Offer,
      UpdateTreatmentSalesDiscountRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/treatment/sales-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateTreatmentAbsoluteDiscount: build.mutation<
      Offer,
      UpdateTreatmentAbsoluteDiscountRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/treatment/absolute-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateTreatmentUseCalculation: build.mutation<
      Offer,
      UpdateTreatmentUseCalculationRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/treatment-use-calculation`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateIncidentalCost: build.mutation<
      Offer,
      UpdateIncidentalCostRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/incidental-cost`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateIncidentalCostUseCalculation: build.mutation<
      Offer,
      UpdateIncidentalCostUseCalculationRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/incidental-cost-use-calculation`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateRatePerAccessPanel: build.mutation<
      Offer,
      UpdateRatePerAccessPanelRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/prices-for-extras/rate-per-access-panel`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateHourlyRate: build.mutation<Offer, UpdateHourlyRateRequest>({
      query: (request) => ({
        url: `/vbs/offer/prices-for-extras/hourly-rate`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdatePostalAddress: build.mutation<
      Offer,
      UpdatePostalAddressRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/postal-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateServiceAddress: build.mutation<
      Offer,
      UpdateServiceAddressRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/service-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferUpdateRepresentative: build.mutation<
      Offer,
      UpdateRepresentativeRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/representative`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    vbsOfferPreviewPDF: build.query<{ objectURL: string }, PreviewPDFRequest>({
      query: (request) => ({
        url: `/vbs/offer/preview-pdf`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      providesTags: (_r, _e, request) => [{ type: "VbsOffer", id: request.id }],
    }),
    vbsOfferDownloadPDF: build.mutation<
      { filename: string; objectURL: string },
      DownloadPDFRequest
    >({
      query: (request) => ({
        url: `/vbs/offer/download-pdf`,
        method: "PATCH",
        body: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          // attempt to retrieve the filename from content-disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          // fallback filename
          let filename = t("Angebot.pdf");
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return {
            filename,
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this mutation will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
    deleteOffer: build.mutation<Offer, DeleteRequest>({
      query: (request) => ({
        url: `/vbs/offer`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsOffer", id: request.id },
        "VbsOffer",
      ],
    }),
  }),
});

export const {
  useVbsOfferCreateMutation,
  useVbsOfferCreateFromOldMutation,
  useVbsOfferCreateFromCalculationMutation,
  useVbsOfferShowQuery,
  useVbsOfferListQuery,
  useVbsOfferUpdateDateMutation,
  useVbsOfferUpdateTreatmentBasePriceMutation,
  useVbsOfferUpdateTreatmentSalesDiscountMutation,
  useVbsOfferUpdateTreatmentAbsoluteDiscountMutation,
  useVbsOfferUpdateTreatmentUseCalculationMutation,
  useVbsOfferUpdateIncidentalCostMutation,
  useVbsOfferUpdateIncidentalCostUseCalculationMutation,
  useVbsOfferUpdateRatePerAccessPanelMutation,
  useVbsOfferUpdateHourlyRateMutation,
  useVbsOfferUpdatePostalAddressMutation,
  useVbsOfferUpdateServiceAddressMutation,
  useVbsOfferUpdateRepresentativeMutation,
  useVbsOfferDownloadPDFMutation,
  useVbsOfferPreviewPDFQuery,
  useDeleteOfferMutation,
} = offerService;

export interface CreateRequest {
  id: string;
  processId: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface CreateFromOldRequest {
  oldOfferId: string;
  id: string;
  processId: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface CreateFromCalculationRequest {
  calculationId: string;
  id: string;
  processId: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  branch?: Filter<string>;
  process?: Filter<string>;
  offerDate?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  offers: Offer[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export interface UpdateDateRequest {
  id: string;
  date: string;
}

export interface UpdateTreatmentBasePriceRequest {
  id: string;
  price: number;
}

export interface UpdateTreatmentSalesDiscountRequest {
  id: string;
  active: boolean;
  percentage: number;
}

export interface UpdateTreatmentAbsoluteDiscountRequest {
  id: string;
  active: boolean;
  amount: number;
}

export interface UpdateTreatmentUseCalculationRequest {
  id: string;
  value: boolean;
}

export interface UpdateIncidentalCostRequest {
  id: string;
  incidentalCost: IncidentalCost;
}

export interface UpdateIncidentalCostUseCalculationRequest {
  id: string;
  value: boolean;
}

export interface UpdateRatePerAccessPanelRequest {
  id: string;
  rate: number;
}

export interface UpdateHourlyRateRequest {
  id: string;
  rate: number;
}

export interface UpdatePostalAddressRequest {
  id: string;
  postalAddress: Address;
}

export interface UpdateServiceAddressRequest {
  id: string;
  serviceAddress: Address;
}

export interface UpdateRepresentativeRequest {
  id: string;
  representative: Representative;
}

export interface DownloadPDFRequest {
  id: string;
  document: OfferDocument;
  userId: string;
}

export enum OfferDocument {
  ALL = "all",
  OFFER = "offer",
  ORDER = "order",
  TREATMENT = "treatment",
}

export interface PreviewPDFRequest {
  id: string;
  document: OfferDocument;
}
