import { v4 } from "uuid";
import t from "@/lang/lang";
import { useRltCertificateCreateMutation } from "@/services/backend/rlt/certificates/service";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useEffect } from "react";
import { Button } from "@/shared/components/ui/button";
import { Plus, RefreshCw } from "lucide-react";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

export function NewCertificateButton(props: { processId: string }) {
  const { processId } = props;
  const [createCertificate, { isLoading, error, reset }] =
    useRltCertificateCreateMutation();
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  const disabled = !process || isLoading;

  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  const onClick = () => {
    if (disabled) {
      return;
    }

    createCertificate({
      id: v4(),
      processId: process.id,
      companyId: process.companyId,
      branchId: process.branchId,
      customerId: process.customerId,
    });
  };

  return (
    <Button onClick={onClick} disabled={disabled}>
      {isLoading ? (
        <RefreshCw className="h-5 w-5 animate-spin" />
      ) : (
        <Plus className="h-5 w-5" />
      )}
      <span>{t("Neues Zertifikat")}</span>
    </Button>
  );
}
