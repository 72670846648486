import { createContext, useContext } from "react";
import { FrontendUser } from "@/shared/lib/authorization/frontendUser";

/**
 * useAuth allows clients to access the AuthContext
 */
export const useAuth = () => useContext(AuthContext);

export const AuthContext = createContext<AuthContextInterface>({
  user: null,
  logout: () => {},
  login: () => {},
});

export interface AuthContextInterface {
  user: FrontendUser | null;
  logout: () => void;
  login: (user: FrontendUser) => void;
}
