import { useParams } from "react-router";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { useQpmOfferShowQuery } from "@/services/backend/qpm/offer/service";
import { OfferNavLinkType } from "@/services/backend/qpm/offer/enum";
import { DeleteOfferDialog } from "@/routes/gemex/processes/qpm/offers/[offerId]/_components/delete-offer-dialog";
import { OfferPdf } from "@/routes/gemex/processes/qpm/offers/[offerId]/_components/offer-pdf";
import { OfferContextProvider } from "@/routes/gemex/processes/qpm/offers/_components/offer-context";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function OfferRoute() {
  const params = useParams();
  const { offerId } = params;
  const links = [
    { to: OfferNavLinkType.Overview, label: t("Übersicht") },
    { to: OfferNavLinkType.Addresses, label: t("Adressen") },
    { to: OfferNavLinkType.Representative, label: t("Betreuer") },
    { to: OfferNavLinkType.CoverLetter, label: t("Anschreiben") },
  ];

  const {
    data: offer,
    isLoading,
    error,
  } = useQpmOfferShowQuery({ artefactMetaId: offerId! }, { skip: !offerId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    offer && (
      <PersistenceContextProvider>
        <OfferContextProvider offer={offer}>
          <ArtefactPage>
            <ArtefactPageHeader>
              <ArtefactPageNavigation>
                {links.map((link) => (
                  <ArtefactPageNavigationLink to={link.to} key={link.to}>
                    {link.label}
                  </ArtefactPageNavigationLink>
                ))}
              </ArtefactPageNavigation>
              <ArtefactPageActions>
                <PersistenceStateUsingContext className="mr-2" />
                <DeleteOfferDialog offerId={offer.artefactData.id} />
                <OfferPdf offer={offer} />
              </ArtefactPageActions>
            </ArtefactPageHeader>
            <ArtefactPageContent>
              <ArtefactPageNavigationOutlet />
            </ArtefactPageContent>
          </ArtefactPage>
        </OfferContextProvider>
      </PersistenceContextProvider>
    )
  );
}
