import t from "@/lang/lang";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@/shared/components/ui/table";
import { Button } from "@/shared/components/ui/button";
import {
  ScrollBodyTable,
  ScrollBodyTableHeader,
} from "@/shared/components/ui/table-scroll-body";
import { ScrollArea } from "@/shared/components/ui/scroll-area";
import { NavLink } from "react-router";

export interface CustomerResult {
  id: string;
  name: string;
  topResult: boolean;
}

export function CustomerResults({
  customers,
}: {
  customers: CustomerResult[];
}) {
  const enableScroll = customers.length > 4;

  return (
    <div>
      <div className="flex justify-between">
        <h5 className="text-lg font-bold">{t("Kunden")}</h5>
        <span className="text-muted-foreground text-sm">
          {customers.length} {t("gefunden")}
        </span>
      </div>
      <ScrollArea className={enableScroll ? "h-[20vh] border-b" : ""}>
        <ScrollBodyTable>
          <ScrollBodyTableHeader>
            <TableRow>
              <TableHead>{t("Name")}</TableHead>
              <TableHead>{t("Aktionen")}</TableHead>
            </TableRow>
          </ScrollBodyTableHeader>
          <TableBody>
            {customers.map((customer) => (
              <TableRow key={customer.id}>
                <TableCell className="w-full py-0 whitespace-nowrap">
                  {customer.name}
                </TableCell>
                <TableCell className="py-0 whitespace-nowrap">
                  <NavigateToCustomer customer={customer} />
                </TableCell>
              </TableRow>
            ))}
            {customers.length === 0 && (
              <TableRow>
                <TableCell colSpan={2} className="py-2 text-center">
                  {t("Keine Kunden gefunden")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </ScrollBodyTable>
      </ScrollArea>
    </div>
  );
}

function NavigateToCustomer({ customer }: { customer: CustomerResult }) {
  const { id } = customer;
  const path = `/customers/${id}`;
  return (
    <NavLink to={path}>
      <Button variant="ghost" size="sm">
        <span>{t("Navigieren")}</span>
      </Button>
    </NavLink>
  );
}
