import { createContext, ReactNode, useContext, useMemo, useState } from "react";
import { Offer } from "@/services/backend/qpm/offer/types";
import { ValidationError } from "@/shared/app-lib/errors/validation-error";

interface OfferContextInterface {
  offer: Offer;
  onOfferChange: (offer: Offer) => void;
  validationError: ValidationError | null;
  setValidationError: (err: ValidationError | null) => void;
}

export const OfferContext = createContext<OfferContextInterface>({
  offer: {} as Offer,
  onOfferChange: () => null,
  validationError: null,
  setValidationError: () => null,
});

export function useOfferContext() {
  return useContext(OfferContext);
}

export function OfferContextProvider({
  children,
  offer: propOffer,
}: {
  children: ReactNode;
  offer: Offer;
}) {
  // idea would be to use index db here for offline availability
  const [offer, setOffer] = useState<Offer>();
  const [validationError, setValidationError] =
    useState<ValidationError | null>(null);

  // after vector clocks are added, they can be used to
  // update the offer here.
  if (!offer || offer.artefactData.id !== propOffer.artefactData.id) {
    setOffer(propOffer);
  }

  const onOfferChange = (changedOffer: Offer) => {
    setOffer(changedOffer);
  };

  const value = useMemo(
    () => ({
      offer: offer!,
      onOfferChange,
      validationError,
      setValidationError,
    }),
    [offer, validationError],
  );

  return (
    <OfferContext.Provider value={value}>{children}</OfferContext.Provider>
  );
}
