import { Navigate, RouteObject } from "react-router";
import { OfferIndexRoute } from "@/routes/gemex/processes/qpm/offers/_components/offer-index-route";
import { OfferRoute } from "@/routes/gemex/processes/qpm/offers/[offerId]/offer-route";
import { OfferNavLinkType } from "@/services/backend/qpm/offer/enum";
import { OfferOverviewComponent } from "@/routes/gemex/processes/qpm/offers/[offerId]/overview/offer-overview-component";
import { AddressesRoute } from "@/routes/gemex/processes/qpm/offers/[offerId]/addresses/addresses-route";
import { RepresentativeRoute } from "@/routes/gemex/processes/qpm/offers/[offerId]/representative/representative-route";
import { CoverLetterRoute } from "@/routes/gemex/processes/qpm/offers/[offerId]/coverletter/cover-letter-route";

export const offersRoutes: RouteObject[] = [
  {
    index: true,
    element: <OfferIndexRoute />,
  },
  {
    path: ":offerId",
    element: <OfferRoute />,
    children: [
      {
        index: true,
        element: <Navigate to={OfferNavLinkType.Overview} replace />,
      },
      {
        path: OfferNavLinkType.Overview,
        element: <OfferOverviewComponent />,
      },
      {
        path: OfferNavLinkType.Addresses,
        element: <AddressesRoute />,
      },
      {
        path: OfferNavLinkType.Representative,
        element: <RepresentativeRoute />,
      },
      {
        path: OfferNavLinkType.CoverLetter,
        element: <CoverLetterRoute />,
      },
    ],
  },
];
