import { useNavigate } from "react-router";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { useEffect } from "react";
import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import t from "@/lang/lang";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useToast } from "@/shared/hooks/use-toast";
import { useHtzWorkOrderPutMutation } from "@/services/backend/htz/work-order/service";
import { newWorkOrder } from "@/services/backend/htz/work-order/work-order";

interface Props {
  processId: string;
}

export function CreateWorkOrderButton({ processId }: Props) {
  const { data: process } = useProcessesProcessShowQuery({ id: processId });
  const [put, { isLoading, error, data, isSuccess, reset }] =
    useHtzWorkOrderPutMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
    if (isSuccess) {
      navigate(data.id);
      reset();
    }
  }, [data?.id, error, isSuccess, reset, toast, navigate]);

  const doCreate = () => {
    if (isLoading || !process) {
      return;
    }

    put(newWorkOrder(process));
  };

  return (
    <Button onClick={doCreate} disabled={isLoading || !process}>
      <Plus className="mr-2 h-5 w-5" />
      <span>{t("Arbeitsschein")}</span>
    </Button>
  );
}
