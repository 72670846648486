import { HTMLAttributes } from "react";
import { cn } from "@/shared/lib/utils";

export function MainPage({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={cn("", className)} {...props}>
      {children}
    </div>
  );
}

export function MainPageHeader({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div
      className={cn("bg-background fixed z-50 w-full border-b", className)}
      {...props}
    >
      <div className="flex h-16 items-center px-4">{children}</div>
    </div>
  );
}

export function MainPageContent({
  children,
  className,
  ...props
}: HTMLAttributes<HTMLDivElement>) {
  return (
    <div className="pt-16">
      <div className={cn("px-4 py-4", className)} {...props}>
        {children}
      </div>
    </div>
  );
}
