import { createContext, useContext } from "react";
import { Role } from "@/shared/lib/authorization/roles";

interface DashboardContextInterface {
  role: Role;
  setRole: (role: Role) => void;
}

export const DashboardContext = createContext<DashboardContextInterface>({
  role: Role.TeamLeader,
  setRole: () => null,
});

export function useDashboardContext() {
  return useContext(DashboardContext);
}
