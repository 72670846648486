import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { NavLink } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { useHtzOrderListQuery } from "@/services/backend/htz/orders/service";
import { Order } from "@/services/backend/htz/orders/order";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { formatDate } from "@/shared/lib/utilities/date";
import { isZeroUUID } from "@/shared/lib/utilities/uuid";

export function OrderTable({ processId }: { processId: string }) {
  const {
    data: list,
    isLoading,
    error,
  } = useHtzOrderListQuery({
    process: {
      active: true,
      values: [processId],
    },
    customer: {
      active: false,
      values: [],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="whitespace-nowrap">{t("Eingang")}</TableHead>
          <TableHead className="whitespace-nowrap">{t("Zu Angebot")}</TableHead>
          <TableHead className="whitespace-nowrap">{t("Kanal")}</TableHead>
          <TableHead className="w-full whitespace-nowrap">
            {t("Kundenreferenz")}
          </TableHead>
          <TableHead className="w-48">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.orders ?? [])].sort(byDate).map((order) => (
          <TableRow key={order.id}>
            <TableCell className="whitespace-nowrap">
              {formatDate(order.date ?? "")}
            </TableCell>
            <TableCell>
              {!order.offerId || isZeroUUID(order.offerId) ? (
                <span>{t("Ohne Angebot")}</span>
              ) : (
                <ArtefactNumberById artefactId={order.offerId} />
              )}
            </TableCell>
            <TableCell className="whitespace-nowrap">
              {t(order.channel ?? "")}
            </TableCell>
            <TableCell>{t(order.customerReference)}</TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavLink to={`${order.id}`}>
                  <Button variant="ghost">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {list?.orders?.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              {t("Keine Auftragseingänge gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function Skeleton() {
  return (
    <Table className="animate-pulse">
      <TableHeader>
        <TableRow>
          <TableHead className="whitespace-nowrap">{t("Eingang")}</TableHead>
          <TableHead className="whitespace-nowrap">{t("Zu Angebot")}</TableHead>
          <TableHead className="whitespace-nowrap">{t("Kanal")}</TableHead>
          <TableHead className="w-full whitespace-nowrap">
            {t("Kundenreferenz")}
          </TableHead>
          <TableHead className="w-32">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
    </Table>
  );
}

function byDate(a: Order, b: Order) {
  switch (true) {
    case a.date === null && b.date === null:
      return 0;
    case a.date !== null && b.date === null:
      return 1;
    case a.date === null && b.date !== null:
      return -1;
    default:
      return a.date!.localeCompare(b.date!);
  }
}
