import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Checkbox } from "@/shared/components/ui/checkbox";
import {
  FloatNumberInput,
  IntNumberInput,
  Value,
} from "@/routes/gemex/processes/_components/ui/number-input";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { MonitorTypeUtil } from "@/services/backend/qpm/shared/monitorTypeUtil";
import {
  useQpmOfferShowQuery,
  useQpmOfferUpdateHirePurchaseMutation,
} from "@/services/backend/qpm/offer/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  HirePurchase,
  UpdateHirePurchaseRequest,
} from "@/services/backend/qpm/offer/types";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";

export type HirePurchaseTableComponentProps = {
  offerId: string;
};

export function HirePurchaseTableComponent({
  offerId,
}: HirePurchaseTableComponentProps) {
  const {
    data: offer,
    isLoading,
    error,
  } = useQpmOfferShowQuery({ artefactMetaId: offerId });

  if (isLoading) {
    return <HirePurchaseTableRowSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="h-fit px-1 py-1 text-lg font-bold">
            {t("Mietkauf")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-center">
            {t("Aktiv")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-center">
            {t("Optional")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Anzahl")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Anschaffungspreis")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Aufschlag")} %
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Gesamtpreis")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Laufzeit")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Monatsrate")}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading && <HirePurchaseTableRowSkeleton />}
        {!isLoading && offer && (
          <HirePurchaseTableRow
            offerId={offer.artefactData.id}
            data={offer.hirePurchaseFiv}
            monitorType={MonitorTypeKey.FlyExterminator}
          />
        )}
      </TableBody>
    </Table>
  );
}

export type HirePurchaseTableRowProps = {
  offerId: string;
  data: HirePurchase;
  monitorType: MonitorTypeKey;
};

export function HirePurchaseTableRow({
  offerId,
  data,
  monitorType,
}: HirePurchaseTableRowProps) {
  const [request, setRequest] = useState<UpdateHirePurchaseRequest>({
    offerId,
    isActive: data.isActive,
    isOptional: data.isOptional,
    monitorType: data.monitorType,
    surcharge: data.surcharge,
    durationMonths: data.durationMonths,
  });
  const [setHirePurchase, { error, isLoading, isSuccess, reset }] =
    useQpmOfferUpdateHirePurchaseMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setHirePurchase,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
    { toastError: true },
  );

  const handleSurcharge = (value: number) => {
    let surcharge = 0;
    if (value > 0) {
      surcharge = value / 100;
    }
    setRequest({ ...request, surcharge });
  };

  return (
    <TableRow key={monitorType} className="border-none">
      <TableCell className="px-1 py-2 text-base font-medium">
        {MonitorTypeUtil.toString(data.monitorType)}
      </TableCell>
      <TableCell role="checkbox" className="px-1 py-2 text-center font-medium">
        <Checkbox
          checked={data.isActive}
          onCheckedChange={(newVal) => {
            setRequest({ ...request, isActive: newVal as boolean });
          }}
          disabled={isLoading}
        />
      </TableCell>
      <TableCell role="checkbox" className="px-1 py-2 text-center font-medium">
        <Checkbox
          checked={data.isActive && data.isOptional}
          onCheckedChange={(newVal) => {
            setRequest({ ...request, isOptional: newVal as boolean });
          }}
          disabled={isLoading || !data.isActive}
        />
      </TableCell>
      <TableCell className="px-1 py-2 text-end font-medium">
        <Value value={data.monitorAmount} fractions={0} />
      </TableCell>
      <TableCell className="px-1 py-2 text-end">
        <Value value={data.totalCost} fractions={2} />
      </TableCell>
      <TableCell className="px-1 py-2 text-end">
        <FloatNumberInput
          className="justify-self-end"
          value={data.surcharge * 100}
          onChange={(newVal) => {
            handleSurcharge(newVal);
          }}
          disabled={isLoading || !data.isActive}
        />
      </TableCell>

      <TableCell className="px-1 py-2 text-end">
        <Value value={data.totalCostSurcharged} fractions={2} />
      </TableCell>
      <TableCell className="px-1 py-2 text-end">
        <IntNumberInput
          className="justify-self-end"
          value={data.durationMonths}
          onChange={(newVal) => {
            setRequest({ ...request, durationMonths: newVal });
          }}
          disabled={isLoading || !data.isActive}
        />
      </TableCell>
      <TableCell className="px-1 py-2 text-end">
        <Value value={data.ratePerMonth} fractions={2} />
      </TableCell>
    </TableRow>
  );
}

export function HirePurchaseTableRowSkeleton() {
  return (
    <TableRow>
      <TableCell className="px-1 py-2 text-base font-medium">
        <Skeleton className="h-8 w-full" />
      </TableCell>
      <TableCell role="checkbox" className="px-1 py-2 text-center font-medium">
        <Skeleton className="h-8 w-full" />
      </TableCell>
      <TableCell role="checkbox" className="px-1 py-2 text-center font-medium">
        <Skeleton className="h-8 w-full" />
      </TableCell>
      <TableCell className="px-1 py-2 text-end font-medium">
        <Skeleton className="h-8 w-full" />
      </TableCell>
      <TableCell className="px-1 py-2 text-end">
        <Skeleton className="h-8 w-full" />
      </TableCell>
      <TableCell className="px-1 py-2 text-end">
        <Skeleton className="h-8 w-full" />
      </TableCell>
      <TableCell className="px-1 py-2 text-end">
        <Skeleton className="h-8 w-full" />
      </TableCell>
      <TableCell className="px-1 py-2 text-end">
        <Skeleton className="h-8 w-full" />
      </TableCell>
    </TableRow>
  );
}
