import { useParams } from "react-router";
import {
  useRltConfirmationShowQuery,
  useRltConfirmationUpdateRepresentativeMutation,
} from "@/services/backend/rlt/confirmations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RepresentativeCardWithMutation } from "@/shared/components/domain/representatives/representative-card";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";

export function ConfirmationRepresentativeRoute() {
  const { confirmationId } = useParams();

  const {
    data: confirmation,
    isLoading,
    error,
  } = useRltConfirmationShowQuery({
    id: confirmationId!,
  });

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!confirmation) {
    return <div />;
  }

  return (
    <RepresentativeCardWithMutation
      id={confirmation!.id}
      representative={confirmation!.representative}
      mutation={useRltConfirmationUpdateRepresentativeMutation}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "representative.")}
      resetFieldError={(field) =>
        resetNotReadyErrorField(`representative.${field}`)
      }
    />
  );
}
