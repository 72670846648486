import { useEffect, useState } from "react";
import { useParams } from "react-router";
import {
  FloatNumberInput,
  Value,
} from "@/routes/gesec/processes/_components/ui/number-input";
import { useGuard } from "@/shared/lib/authorization/rbac-guard";
import { Confirmation } from "@/services/backend/rlt/confirmations/confirmation";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { rfc3339toDate } from "@/shared/lib/utilities/date";
import {
  UpdateDateRequest,
  UpdateFinalDiscountRequest,
  UpdateHourlyRateRequest,
  UpdateIncidentalCostRequest,
  UpdateOfferReferenceRequest,
  UpdateOrderDateRequest,
  UpdateOrderReferenceRequest,
  UpdateRatePerAccessPanelRequest,
  UpdateTreatmentBasePriceRequest,
  useRltConfirmationShowQuery,
  useRltConfirmationUpdateDateMutation,
  useRltConfirmationUpdateFinalDiscountMutation,
  useRltConfirmationUpdateHourlyRateMutation,
  useRltConfirmationUpdateIncidentalCostMutation,
  useRltConfirmationUpdateIncidentalCostUseCalculationMutation,
  useRltConfirmationUpdateOfferReferenceMutation,
  useRltConfirmationUpdateOrderDateMutation,
  useRltConfirmationUpdateOrderReferenceMutation,
  useRltConfirmationUpdateRatePerAccessPanelMutation,
  useRltConfirmationUpdateTreatmentBasePriceMutation,
  useRltConfirmationUpdateTreatmentIgnoreOfferDiscountsMutation,
  useRltConfirmationUpdateTreatmentUseCalculationMutation,
} from "@/services/backend/rlt/confirmations/service";
import { useRltOfferListQuery } from "@/services/backend/rlt/offers/service";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectSeparator,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Label } from "@/shared/components/ui/label";
import { Switch } from "@/shared/components/ui/switch";
import t from "@/lang/lang";
import {
  ErrArtefactNotReady,
  errorsFor,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { AlertCircle } from "lucide-react";
import { Separator } from "@/shared/components/ui/separator";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { DatePicker } from "@/shared/components/ui/date-picker";
import { isZeroUUID, NullUUID } from "@/shared/lib/utilities/uuid";
import { useRltOrderListQuery } from "@/services/backend/rlt/orders/service";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { OrderIdentifier } from "@/shared/components/domain/rlt/order/order-identifier";

export function ConfirmationDataRoute() {
  const { confirmationId } = useParams();

  const {
    data: confirmation,
    isLoading,
    error,
  } = useRltConfirmationShowQuery({
    id: confirmationId!,
  });

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!confirmation) {
    return <div />;
  }

  return <ConfirmationDataView confirmation={confirmation} />;
}

export function ConfirmationDataView(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  return (
    <div className="space-y-4">
      <Card>
        <CardHeader>
          <CardTitle>{t("Auftragsbestätigung")}</CardTitle>
        </CardHeader>
        <CardContent>
          <OrderReference confirmation={confirmation} />
          <Separator className="my-4" />
          <div className="grid gap-4 md:grid-cols-3">
            <OrderDate confirmation={confirmation} />
            <OfferReference confirmation={confirmation} />
            <ConfirmationDate confirmation={confirmation} />
          </div>
        </CardContent>
      </Card>
      <Summary confirmation={confirmation} />
    </div>
  );
}

function OrderReference({ confirmation }: { confirmation: Confirmation }) {
  const [request, setRequest] = useState<UpdateOrderReferenceRequest>({
    id: confirmation.id,
    orderId: confirmation.orderId,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateOrderReferenceMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  return (
    <OrderSelect
      orderId={request.orderId}
      onOrderIdChange={(orderId) =>
        setRequest({ ...request, orderId: orderId ?? NullUUID })
      }
      processId={confirmation.processId}
    />
  );
}

interface OrderSelectProps {
  orderId: string | null;
  onOrderIdChange: (orderId: string | null) => void;
  processId: string;
}

function OrderSelect({
  orderId,
  onOrderIdChange,
  processId,
}: OrderSelectProps) {
  const {
    data: list,
    isLoading,
    error,
  } = useRltOrderListQuery({
    process: {
      active: true,
      values: [processId],
    },
    immutable: {
      active: false,
      values: [true],
    },
  });
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: "Error loading orders",
        variant: "destructive",
      });
    }
  }, [error, toast]);

  if (isLoading) {
    return (
      <div className="grid animate-pulse content-start gap-1.5">
        <Label>{t("Bestätigt Auftragseingang")}</Label>
        <Select disabled>
          <SelectTrigger className="w-full">
            <SelectValue placeholder={t("Auftragseingang auswählen")} />
          </SelectTrigger>
        </Select>
      </div>
    );
  }

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Bestätigt Auftragseingang")}</Label>
      <Select
        value={orderId ?? undefined}
        onValueChange={(v) => {
          if (isZeroUUID(v)) {
            onOrderIdChange(null);
            return;
          }
          onOrderIdChange(v);
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t("Auftragseingang auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {list?.orders.map((order) => (
            <SelectItem value={order.id} key={order.id}>
              <OrderIdentifier orderId={order.id} />
            </SelectItem>
          ))}
          <SelectSeparator />
          <SelectItem value="00000000-0000-0000-0000-000000000000">
            {t("Kein Auftragseingang")}
          </SelectItem>
        </SelectContent>
      </Select>
      <div className="text-muted-foreground text-xs">
        {t(
          "Die Auswahl des Auftragseingangs überschreibt alle verbundenen Daten: Datum des Auftragseingangs, Referenz zum Angebot, Leistungen, Adressen, Betreuer, Adressen, Betreuer. Wenn ein Auftragseingang ausgewählt ist, können das Datum des Auftragseingangs und die Referenz zum Angebot nicht mehr bearbeitet werden.",
        )}
      </div>
    </div>
  );
}

function OrderDate({ confirmation }: { confirmation: Confirmation }) {
  const [request, setRequest] = useState<UpdateOrderDateRequest>({
    id: confirmation.id,
    date: confirmation.orderDate,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateOrderDateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    50,
  );

  const disabled = !isZeroUUID(confirmation.orderId);
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Datum des Auftragseingangs")}</Label>
      <DatePicker
        date={rfc3339toDate(confirmation.orderDate)}
        onDateChange={(date) => {
          resetNotReadyErrorField("confirmationDate");
          setRequest({
            ...request,
            date: date ? date.toISOString() : "",
          });
        }}
        disabled={disabled}
      />
      <ConfirmationNotReadyWarning field="orderDate" error={notReadyError} />
    </div>
  );
}

function OfferReference({ confirmation }: { confirmation: Confirmation }) {
  const [request, setRequest] = useState<UpdateOfferReferenceRequest>({
    id: confirmation.id,
    offerId: confirmation.confirmedOfferId,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateOfferReferenceMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    1000,
  );

  const disabled = !isZeroUUID(confirmation.orderId);
  const { notReadyError } = useErrArtefactNotReady();
  return (
    <OfferSelect
      offerId={confirmation.confirmedOfferId}
      onOfferIdChange={(offerId) =>
        setRequest({ ...request, offerId: offerId ?? "" })
      }
      processId={confirmation.processId}
      notReadyError={notReadyError}
      disabled={disabled}
    />
  );
}

interface OfferSelectProps {
  offerId: string | null;
  onOfferIdChange: (offerId: string | null) => void;
  processId: string;
  notReadyError: ErrArtefactNotReady | null;
  disabled?: boolean;
}

function OfferSelect({
  offerId,
  onOfferIdChange,
  processId,
  notReadyError,
  disabled = false,
}: OfferSelectProps) {
  const {
    data: list,
    isLoading,
    error,
  } = useRltOfferListQuery({
    process: {
      active: true,
      values: [processId],
    },
    immutable: {
      active: true,
      values: [true],
    },
  });
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        title: "Error",
        description: "Error loading offers",
        variant: "destructive",
      });
    }
  }, [error, toast]);

  if (isLoading) {
    return (
      <div className="grid animate-pulse content-start gap-1.5">
        <Label>{t("Bezieht sich auf Angebot")}</Label>
        <Select disabled>
          <SelectTrigger className="w-full">
            <SelectValue placeholder={t("Angebot auswählen")} />
          </SelectTrigger>
        </Select>
      </div>
    );
  }

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Bezieht sich auf Angebot")}</Label>
      <Select
        value={offerId ?? undefined}
        onValueChange={(v) => {
          if (isZeroUUID(v)) {
            onOfferIdChange(null);
            return;
          }
          onOfferIdChange(v);
        }}
        disabled={disabled}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t("Angebot auswählen")} />
        </SelectTrigger>
        <SelectContent>
          {list?.offers.map((offer) => (
            <SelectItem value={offer.id} key={offer.id}>
              <ArtefactNumberById artefactId={offer.id} />
            </SelectItem>
          ))}
          <SelectItem value="00000000-0000-0000-0000-000000000000">
            {t("Kein Angebot")}
          </SelectItem>
        </SelectContent>
      </Select>
      <ConfirmationNotReadyWarning
        field="confirmedOfferId"
        error={notReadyError}
      />
      <div className="text-muted-foreground text-xs">
        {t(
          "Die Auswahl eines Angebots überschreibt die Leistungen der Auftragsbestätigung mit denen des Angebots.",
        )}
      </div>
    </div>
  );
}

function ConfirmationDate(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const [request, setRequest] = useState<UpdateDateRequest>({
    id: confirmation.id,
    date: confirmation.confirmationDate,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateDateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    1000,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="grid content-start gap-1.5">
      <Label>{t("Datum der Auftragsbestätigung")}</Label>
      <DatePicker
        date={rfc3339toDate(request.date)}
        onDateChange={(date) => {
          resetNotReadyErrorField("confirmationDate");
          setRequest({
            ...request,
            date: date ? date.toISOString() : "",
          });
        }}
      />
      <ConfirmationNotReadyWarning
        field="confirmationDate"
        error={notReadyError}
      />
    </div>
  );
}

function ConfirmationNotReadyWarning({
  field,
  error,
}: {
  field: string;
  error: ErrArtefactNotReady | null;
}) {
  if (!error) {
    return null;
  }

  const fieldErrors = errorsFor(error, field);

  if (fieldErrors) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <AlertCircle className="inline-flex h-4 w-4 text-red-500" />
          </TooltipTrigger>
          <TooltipContent align="end">
            <div className="px-2 py-1">
              <div>{t("Angebot nicht fertig:")}</div>
              <Separator className="mb-2" />
              {fieldErrors.map((msg) => (
                <div key={msg} className="text-sm text-red-700">
                  {msg}
                </div>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return null;
}

function Summary({ confirmation }: { confirmation: Confirmation }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Zusammenfassung")}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-4">
        <BaseTreatment confirmation={confirmation} />
        <Separator />
        <IncidentalCost confirmation={confirmation} />
        <Separator />
        <Extras confirmation={confirmation} />
      </CardContent>
    </Card>
  );
}

function Extras({ confirmation }: { confirmation: Confirmation }) {
  return (
    <div>
      <div className="font-bold">{t("Extras")}</div>
      <RatePerAccessPanel confirmation={confirmation} />
      <HourlyRate confirmation={confirmation} />
    </div>
  );
}

function BaseTreatment(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  return (
    <div className="space-y-2">
      <div className="flex items-start justify-between">
        <span className="font-bold">{t("Grundbehandlung")}</span>
        <TreatmentUseCalculationToggle confirmation={confirmation} />
      </div>
      <TreatmentBasePrice confirmation={confirmation} />
      <DataFromOffer confirmation={confirmation} />
      <FinalDiscount confirmation={confirmation} />
      <BaseTreatmentFinalPrice confirmation={confirmation} />
    </div>
  );
}

function DataFromOffer({ confirmation }: { confirmation: Confirmation }) {
  return (
    <div className="border-l-primary ml-4 border-l-2 pl-2">
      <div className="font-medium">{t("Daten aus Angebot")}</div>
      <ReferralSurcharge confirmation={confirmation} />
      <BaseTreatmentPrice confirmation={confirmation} />
      <OfferDiscounts confirmation={confirmation} />
    </div>
  );
}

function IgnoreOfferData({ confirmation }: { confirmation: Confirmation }) {
  const [update, { isLoading, error, reset }] =
    useRltConfirmationUpdateTreatmentIgnoreOfferDiscountsMutation();
  const { toast } = useToast();
  const { canExecute } = useGuard(
    "VBS.Confirmation.UpdateTreatmentIgnoreOfferDiscountsRequest",
  );

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  const onCheckedChange = (checked: boolean) => {
    if (isLoading) {
      return;
    }
    update({ id: confirmation.id, value: checked });
  };

  if (!canExecute) {
    return <div />;
  }
  return (
    <div className="mt-1 flex items-center space-x-2">
      <Checkbox
        id="terms"
        checked={confirmation.treatment.ignoreOfferDiscounts}
        onCheckedChange={onCheckedChange}
      />
      <label
        htmlFor="terms"
        className="text-sm leading-none peer-disabled:cursor-not-allowed peer-disabled:opacity-70"
      >
        {t("Rabatte aus Angebot ignorieren")}
      </label>
    </div>
  );
}

function TreatmentUseCalculationToggle(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const [update, { isLoading, error, reset }] =
    useRltConfirmationUpdateTreatmentUseCalculationMutation();
  const { toast } = useToast();
  const { canExecute } = useGuard(
    "VBS.Confirmation.UpdateTreatmentUseCalculationRequest",
  );

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  const onCheckedChange = (checked: boolean) => {
    if (isLoading) {
      return;
    }
    update({ id: confirmation.id, value: checked });
  };

  if (!canExecute) {
    return <div />;
  }

  return (
    <div className="flex items-center space-x-2">
      <Label htmlFor="treatment-use-calculation">
        {t("Kalkulation nutzen")}
      </Label>
      <Switch
        id="treatment-use-calculation"
        checked={confirmation.treatmentUseCalculation}
        onCheckedChange={onCheckedChange}
      />
    </div>
  );
}

function TreatmentBasePrice(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const [request, setRequest] = useState<UpdateTreatmentBasePriceRequest>({
    id: confirmation.id,
    price: confirmation.treatment.basePrice,
  });

  useEffect(() => {
    if (!confirmation.treatmentUseCalculation) {
      setRequest((prev) => {
        if (prev.price !== confirmation.treatment.basePrice) {
          return {
            ...prev,
            price: confirmation.treatment.basePrice,
          };
        }
        return prev;
      });
    }
    // This useEffect is supposed to update the request with updated
    // price after treatmentUseCalculations was toggled.
    // Otherwise, the input fields would be out of date in the UI.
    // However, we do not want to update anything just because the
    // confirmation.treatment.basePrice has changed (which may happen due
    // to invalidation), therefore it is left out of the dependencies
    // on purpose.
    // React is aware that useEffect has a shortcoming here and
    // in a future version there will be a useEffectEvent hook
    // which can be used to achieve the current behaviour without
    // breaking the reactivity rules:
    // https://react.dev/learn/removing-effect-dependencies#do-you-want-to-read-a-value-without-reacting-to-its-changes
    // https://react.dev/learn/separating-events-from-effects#is-it-okay-to-suppress-the-dependency-linter-instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation.treatmentUseCalculation]);

  const [update, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateTreatmentBasePriceMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="">{t("Betrag aus Kalkulation")}</span>
        <div className="flex justify-end space-x-2">
          {confirmation.treatmentUseCalculation ? (
            <Value value={confirmation.treatment.basePrice} fractions={2} />
          ) : (
            <FloatNumberInput
              value={request.price}
              onChange={(price) => setRequest({ ...request, price })}
              width={24}
            />
          )}
          <span className="w-2">€</span>
        </div>
      </div>
    </div>
  );
}

function ReferralSurcharge(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="">{t("Tippprovision €")}</span>
        <div className="flex justify-end space-x-2">
          <Value value={confirmation.treatment.tipCommission} fractions={2} />
          <span className="w-2">€</span>
        </div>
      </div>
    </div>
  );
}

function BaseTreatmentPrice(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  return (
    <div className="pb-2">
      <div className="flex items-center justify-between font-medium">
        <span>{t("Grundbehandlung inkl. Tippprovision")}</span>
        <div className="flex justify-end space-x-2">
          <Value
            value={confirmation.treatment.priceWithCharges}
            fractions={2}
          />
          <span className="w-2">€</span>
        </div>
      </div>
    </div>
  );
}

function OfferDiscounts(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const { treatment } = confirmation;
  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="">{t("Verkaufsrabatt")}</span>
        <div className="flex justify-end space-x-2">
          <Value
            value={
              treatment.salesDiscount.active
                ? treatment.salesDiscount.percentage
                : 0
            }
            fractions={2}
          />
          <span className="w-2">%</span>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <span className="">{t("Absolutrabatt")}</span>
        <div className="flex justify-end space-x-2">
          <Value
            value={
              treatment.absoluteDiscount.active
                ? treatment.absoluteDiscount.amount
                : 0
            }
            fractions={2}
          />
          <span className="w-2">€</span>
        </div>
      </div>
      <IgnoreOfferData confirmation={confirmation} />
      <div className="flex items-center justify-between font-medium">
        <span className="">
          {t("Grundbehandlung inkl. Tippprovision abzgl. Rabatte")}
        </span>
        <div className="flex justify-end space-x-2">
          <Value value={treatment.offerPrice} fractions={2} />
          <span className="w-2">€</span>
        </div>
      </div>
    </div>
  );
}

function FinalDiscount(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const [request, setRequest] = useState<UpdateFinalDiscountRequest>({
    id: confirmation.id,
    finalDiscount: confirmation.treatment.finalDiscount,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateFinalDiscountMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { canExecute } = useGuard(
    "VBS.Confirmation.UpdateFinalDiscountRequest",
  );

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="">{t("Finaler Rabatt")}</span>
        <div className="flex justify-end space-x-2">
          {canExecute ? (
            <FloatNumberInput
              value={request.finalDiscount}
              onChange={(finalDiscount) =>
                setRequest({ ...request, finalDiscount })
              }
              width={24}
            />
          ) : (
            <Value value={confirmation.treatment.finalDiscount} fractions={2} />
          )}
          <span className="w-2">€</span>
        </div>
      </div>
    </div>
  );
}

function BaseTreatmentFinalPrice(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const { notReadyError } = useErrArtefactNotReady();

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="font-bold">{t("Grundbehandlung Final")}</span>
        <div className="flex justify-end space-x-2 font-bold">
          <Value
            value={confirmation.treatment.confirmationPrice}
            fractions={2}
          />
          <span className="w-2">€</span>
          <ConfirmationNotReadyWarning
            field="treatment.confirmationPrice"
            error={notReadyError}
          />
        </div>
      </div>
    </div>
  );
}

function IncidentalCost(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const [request, setRequest] = useState<UpdateIncidentalCostRequest>({
    id: confirmation.id,
    incidentalCost: confirmation.incidentalCost,
  });

  useEffect(() => {
    if (!confirmation.incidentalCostUseCalculation) {
      setRequest((prev) => {
        if (prev.incidentalCost !== confirmation.incidentalCost) {
          return {
            ...prev,
            incidentalCost: confirmation.incidentalCost,
          };
        }
        return prev;
      });
    }
    // This useEffect is supposed to update the request with updated
    // incidentalCost after incidentalCostUseCalculation was toggled.
    // Otherwise, the input fields would be out of date in the UI.
    // However, we do not want to update anything just because the
    // confirmation.incidentalCost has changed (which may happen due
    // to invalidation), therefore it is left out of the dependencies
    // on purpose.
    // React is aware that useEffect has a shortcoming here and
    // in a future version there will be a useEffectEvent hook
    // which can be used to achieve the current behaviour without
    // breaking the reactivity rules:
    // https://react.dev/learn/removing-effect-dependencies#do-you-want-to-read-a-value-without-reacting-to-its-changes
    // https://react.dev/learn/separating-events-from-effects#is-it-okay-to-suppress-the-dependency-linter-instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [confirmation.incidentalCostUseCalculation]);

  const [update, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateIncidentalCostMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  const onChangeMaterialFlatRate = (materialFlatRate: number) => {
    const totalAmount =
      materialFlatRate +
      request.incidentalCost.travelTime +
      request.incidentalCost.expenses;
    setRequest({
      ...request,
      incidentalCost: {
        ...request.incidentalCost,
        materialFlatRate,
        totalAmount,
      },
    });
  };

  const onChangeTravelTime = (travelTime: number) => {
    const totalAmount =
      request.incidentalCost.materialFlatRate +
      travelTime +
      request.incidentalCost.expenses;
    setRequest({
      ...request,
      incidentalCost: {
        ...request.incidentalCost,
        travelTime,
        totalAmount,
      },
    });
  };

  const onChangeExpenses = (expenses: number) => {
    const totalAmount =
      request.incidentalCost.materialFlatRate +
      request.incidentalCost.travelTime +
      expenses;
    setRequest({
      ...request,
      incidentalCost: {
        ...request.incidentalCost,
        expenses,
        totalAmount,
      },
    });
  };

  return (
    <div className="space-y-2">
      <div className="flex items-start justify-between">
        <span className="font-bold">
          {t("Nebenkosten (Material, Spesen, Fahrtkosten)")}
        </span>
        <IncidentalCostUseCalculationToggle confirmation={confirmation} />
      </div>
      <div>
        <div className="flex items-center justify-between">
          <span>
            <span>{t("Materialkosten")}</span>
          </span>
          <div className="space-x-2">
            {confirmation.incidentalCostUseCalculation ? (
              <Value
                value={confirmation.incidentalCost.materialFlatRate}
                fractions={2}
              />
            ) : (
              <FloatNumberInput
                value={request.incidentalCost.materialFlatRate}
                onChange={onChangeMaterialFlatRate}
                width={24}
              />
            )}
            <span className="font-mono">€</span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span>
            <span>{t("Fahrtkosten")}</span>
          </span>
          <div className="space-x-2">
            {confirmation.incidentalCostUseCalculation ? (
              <Value
                value={confirmation.incidentalCost.travelTime}
                fractions={2}
              />
            ) : (
              <FloatNumberInput
                value={request.incidentalCost.travelTime}
                onChange={onChangeTravelTime}
                width={24}
              />
            )}
            <span className="font-mono">€</span>
          </div>
        </div>
        <div className="flex items-center justify-between">
          <span>
            <span>{t("Spesen")}</span>
          </span>
          <div className="space-x-2">
            {confirmation.incidentalCostUseCalculation ? (
              <Value
                value={confirmation.incidentalCost.expenses}
                fractions={2}
              />
            ) : (
              <FloatNumberInput
                value={request.incidentalCost.expenses}
                onChange={onChangeExpenses}
                width={24}
              />
            )}
            <span className="font-mono">€</span>
          </div>
        </div>
        <div className="flex items-center justify-between font-bold">
          <span>
            <span>{t("Gesamt")}</span>
          </span>
          <div className="space-x-2">
            <Value value={request.incidentalCost.totalAmount} fractions={2} />
            <span className="font-mono">€</span>
          </div>
        </div>
      </div>
    </div>
  );
}

function IncidentalCostUseCalculationToggle(props: {
  confirmation: Confirmation;
}) {
  const { confirmation } = props;
  const [update, { isLoading, error, reset }] =
    useRltConfirmationUpdateIncidentalCostUseCalculationMutation();
  const { toast } = useToast();
  const { canExecute } = useGuard(
    "VBS.Confirmation.UpdateIncidentalCostUseCalculationRequest",
  );

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  const onCheckedChange = (checked: boolean) => {
    if (isLoading) {
      return;
    }
    update({ id: confirmation.id, value: checked });
  };

  if (!canExecute) {
    return <div />;
  }

  return (
    <div className="flex items-center space-x-2">
      <Label htmlFor="incidental-cost-use-calculation">
        {t("Kalkulation nutzen")}
      </Label>
      <Switch
        id="incidental-cost-use-calculation"
        checked={confirmation.incidentalCostUseCalculation}
        onCheckedChange={onCheckedChange}
      />
    </div>
  );
}

function RatePerAccessPanel(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const [request, setRequest] = useState<UpdateRatePerAccessPanelRequest>({
    id: confirmation.id,
    ratePerAccessPanel: confirmation.pricesForExtras.ratePerAccessPanel,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateRatePerAccessPanelMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { canExecute } = useGuard(
    "VBS.Confirmation.UpdateRatePerAccessPanelRequest",
  );
  const { notReadyError } = useErrArtefactNotReady();

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="">{t("Revisionsöffnungen")}</span>
        <div className="flex justify-end space-x-2">
          {canExecute ? (
            <FloatNumberInput
              value={request.ratePerAccessPanel}
              onChange={(ratePerAccessPanel) =>
                setRequest({ ...request, ratePerAccessPanel })
              }
            />
          ) : (
            <Value
              value={confirmation.pricesForExtras.ratePerAccessPanel}
              fractions={2}
            />
          )}
          <span className="w-2">€</span>
          <ConfirmationNotReadyWarning
            field="pricesForExtras.ratePerAccessPanel"
            error={notReadyError}
          />
        </div>
      </div>
    </div>
  );
}

function HourlyRate(props: { confirmation: Confirmation }) {
  const { confirmation } = props;
  const [request, setRequest] = useState<UpdateHourlyRateRequest>({
    id: confirmation.id,
    hourlyRate: confirmation.pricesForExtras.hourlyRate,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltConfirmationUpdateHourlyRateMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );
  const { canExecute } = useGuard("VBS.Confirmation.UpdateHourlyRateRequest");
  const { notReadyError } = useErrArtefactNotReady();

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="">{t("Stundensatz Zusatzarbeiten")}</span>
        <div className="flex justify-end space-x-2">
          {canExecute ? (
            <FloatNumberInput
              value={request.hourlyRate}
              onChange={(hourlyRate) => setRequest({ ...request, hourlyRate })}
            />
          ) : (
            <Value
              value={confirmation.pricesForExtras.hourlyRate}
              fractions={2}
            />
          )}
          <span className="w-2">€</span>
          <ConfirmationNotReadyWarning
            field="pricesForExtras.hourlyRate"
            error={notReadyError}
          />
        </div>
      </div>
    </div>
  );
}
