import { Position } from "@/services/backend/vbs/treatments/position";
import { HTMLAttributes } from "react";
import { cn } from "@/shared/lib/utils";
import { CheckCircle } from "lucide-react";
import { useTreatmentComponentState } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state-context";
import { OfferPriceCell } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/_components/offer-price-cell";
import {
  ALL_COLUMNS,
  Column,
} from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import { BasePriceCell } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/_components/base-price-cell";
import { CycleCell } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/_components/cycle-cell";
import { CycleDiscountCell } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/_components/cycle-discount-cell";
import { ContractPriceCell } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/_components/contract-price-cell";
import { TipCommissionCell } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/_components/tip-commission-cell";
import { KindCell } from "./kind-cell";
import { ComponentCell } from "./component-cell";
import { FormulaCell } from "./formula-cell";
import { CleanTimeCell } from "./clean-time-cell";
import { CommentCell } from "./comment-cell";
import { DeletePositionDialog } from "./delete-position-dialog";

export function PositionRow({
  treatmentId,
  position,
}: {
  treatmentId: string;
  position: Position;
}) {
  const { state } = useTreatmentComponentState();
  const { columns } = state;

  return (
    <tr className="border-t align-top">
      {ALL_COLUMNS.map((column) => {
        if (!columns.includes(column)) {
          return null;
        }
        return (
          <Cell
            key={column}
            column={column}
            treatmentId={treatmentId}
            position={position}
          />
        );
      })}
    </tr>
  );
}

function Cell({
  treatmentId,
  column,
  position,
}: {
  treatmentId: string;
  column: Column;
  position: Position;
}) {
  switch (column) {
    case Column.Position:
      return (
        <TD className="font-bold">
          <span className="flex h-6 items-center">{position.number}</span>
        </TD>
      );
    case Column.Completed:
      return (
        <TD className="flex justify-center">
          {position.completed ? <CheckCircle className="h-5 w-5" /> : null}
        </TD>
      );
    case Column.ComponentKind:
      return (
        <TD>
          <KindCell kind={position.component.kind} />
        </TD>
      );
    case Column.ComponentData:
      return (
        <TD>
          <ComponentCell
            treatmentId={treatmentId}
            positionId={position.id}
            component={position.component}
          />
        </TD>
      );
    case Column.Cycle:
      return (
        <TD className="text-right">
          <CycleCell treatmentId={treatmentId} position={position} />
        </TD>
      );
    case Column.Formula:
      return (
        <TD>
          <FormulaCell
            treatmentId={treatmentId}
            positionId={position.id}
            formula={position.formula}
          />
        </TD>
      );
    case Column.CleanTime:
      return (
        <TD className="text-right">
          <CleanTimeCell postion={position} />
        </TD>
      );
    case Column.BasePrice:
      return (
        <TD className="text-right">
          <BasePriceCell position={position} />
        </TD>
      );
    case Column.TipCommission:
      return (
        <TD className="text-right">
          <TipCommissionCell treatmentId={treatmentId} position={position} />
        </TD>
      );
    case Column.OfferPrice:
      return (
        <TD className="text-right">
          <OfferPriceCell position={position} />
        </TD>
      );
    case Column.CycleDiscount:
      return (
        <TD className="text-right">
          <CycleDiscountCell treatmentId={treatmentId} position={position} />
        </TD>
      );
    case Column.ContractPrice:
      return (
        <TD className="text-right">
          <ContractPriceCell position={position} />
        </TD>
      );
    case Column.ActuallyTreated:
      return (
        <TD>
          <ComponentCell
            treatmentId={treatmentId}
            positionId={position.id}
            component={position.actuallyTreated}
            actuallyTreated
          />
        </TD>
      );
    case Column.Comment:
      return (
        <TD>
          <CommentCell
            treatmentId={treatmentId}
            positionId={position.id}
            comment={position.comment}
          />
        </TD>
      );
    case Column.Actions:
      return (
        <TD className="flex justify-end space-x-2 px-3 py-2">
          <DeletePositionDialog treatmentId={treatmentId} position={position} />
        </TD>
      );
    default:
      return null;
  }
}

interface TDProps extends HTMLAttributes<HTMLTableCellElement> {}

function TD({ children, className }: TDProps) {
  return <th className={cn("px-6 py-3 font-normal", className)}>{children}</th>;
}
