import { useParams } from "react-router";
import { useVbsConfirmationShowQuery } from "@/services/backend/vbs/confirmations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Confirmation } from "@/services/backend/vbs/confirmations/confirmation";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import t from "@/lang/lang";
import {
  ErrArtefactNotReady,
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { DeleteConfirmationDialog } from "@/routes/gesec/processes/[processId]/vbs/confirmations/[confirmationId]/_components/delete-confirmation-dialog";
import { Card } from "@/shared/components/ui/card";
import { ConfirmationPdf } from "@/routes/gesec/processes/[processId]/vbs/confirmations/[confirmationId]/_components/confirmation-pdf";
import { ConfirmationImmutablePage } from "@/routes/gesec/processes/[processId]/vbs/confirmations/[confirmationId]/_components/confirmation-immutable-page";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function ConfirmationRoute() {
  const { confirmationId } = useParams();

  const {
    data: confirmation,
    isLoading,
    error,
  } = useVbsConfirmationShowQuery({
    id: confirmationId!,
  });

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (confirmation!.immutable) {
    return <ConfirmationImmutablePage confirmation={confirmation!} />;
  }

  return <ConfirmationPage confirmation={confirmation!} />;
}

function ConfirmationPage(props: { confirmation: Confirmation }) {
  const { confirmation } = props;

  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <Page confirmation={confirmation} />
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

function Page({ confirmation }: { confirmation: Confirmation }) {
  return (
    <ArtefactPage>
      <ArtefactPageHeader>
        <TabNavigation />
        <ArtefactPageActions>
          <PersistenceStateUsingContext className="mr-2" />
          <DeleteConfirmationDialog confirmationId={confirmation.id} />
          <ConfirmationPdf confirmationId={confirmation.id} />
        </ArtefactPageActions>
      </ArtefactPageHeader>
      <ArtefactPageContent>
        <ArtefactPageNavigationOutlet />
      </ArtefactPageContent>
    </ArtefactPage>
  );
}

function TabNavigation() {
  const { notReadyError } = useErrArtefactNotReady();

  const links = [
    {
      to: "data",
      hasValidationError: hasConfirmationError(notReadyError),
      label: t("Bestätigung"),
    },
    {
      to: "treatment",
      hasValidationError: false,
      label: t("Leistungen"),
    },
    {
      to: "calculation",
      hasValidationError: false,
      label: t("Kalkulation"),
    },
    {
      to: "customer-address",
      hasValidationError: hasAddressError(notReadyError),
      label: t("Anschrift"),
    },
    {
      to: "representative",
      hasValidationError: hasRepresentativeError(notReadyError),
      label: t("Betreuer"),
    },
  ];

  return (
    <ArtefactPageNavigation>
      {links.map((link) => (
        <ArtefactPageNavigationLink
          to={link.to}
          hasValidationError={link.hasValidationError}
        >
          {link.label}
        </ArtefactPageNavigationLink>
      ))}
    </ArtefactPageNavigation>
  );
}

function hasConfirmationError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "confirmationDate",
    "treatment.confirmationPrice",
    "pricesForExtras.ratePerAccessPanel",
    "pricesForExtras.hourlyRate",
  ];

  return fields.some((field) => field in error.errors);
}

function hasAddressError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }
  const fields = [
    "customerAddress.city",
    "customerAddress.company",
    "customerAddress.street",
    "customerAddress.postalCode",
    "serviceAddress.company",
    "serviceAddress.street",
    "serviceAddress.postalCode",
    "serviceAddress.city",
  ];

  return fields.some((field) => field in error.errors);
}

function hasRepresentativeError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "representative.email",
    "representative.mobilephone",
    "representative.name",
  ];

  return fields.some((field) => field in error.errors);
}
