import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";
import { useShowCompanyQuery } from "@/services/backend/company/company";
import { MainNav, NavLinkElement } from "@/routes/_components/main-nav";
import { Toaster } from "@/shared/components/ui/toaster";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Search } from "@/routes/_components/search/search";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { MainPage, MainPageHeader } from "@/routes/_components/main-page";
import { Logo } from "@/routes/_components/logo";
import { UserMenu } from "@/routes/_components/user-menu";
import { Outlet } from "react-router";

export default function Root() {
  const { companyId } = useActiveCompany();
  const { user } = useAuth();

  const {
    data: company,
    isLoading,
    error,
  } = useShowCompanyQuery({ id: companyId });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  // This is intermediary until the frontend dynamically creates this
  // based on the user's authorization rights.
  // The customers list page is not ready / needed for now.
  // The base-data pages are partially not ready and only needed for Admins.
  // (do note, that this only hides the links, the pages are still accessible).
  const navList: NavLinkElement[] = user?.roles.includes("Admin")
    ? [
        { to: "/dashboard", label: "Dashboard" },
        {
          to: `/tenant/${company?.portalRoute}/processes`,
          label: "Vorgänge",
        },
        { to: "/customers", label: "Kunden" },
        { to: "/base-data", label: "Stammdaten" },
      ]
    : [{ to: "/dashboard", label: "Dashboard" }];

  return (
    <MainPage>
      <MainPageHeader>
        <Logo />
        <MainNav navList={navList} />
        <div className="ml-auto flex items-center space-x-4">
          <Search />
          <UserMenu />
        </div>
      </MainPageHeader>
      <Outlet />
      <Toaster />
    </MainPage>
  );
}
