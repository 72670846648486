import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageDescription,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";
import t from "@/lang/lang";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { Outlet } from "react-router";
import {
  TabsNav,
  TabsNavContent,
  TabsNavLink,
  TabsNavList,
} from "@/shared/components/layout/tabs-nav";

export function HtzBaseDataRoute() {
  return (
    <PersistenceContextProvider>
      <BaseDataPage>
        <BaseDataPageHeader>
          <BaseDataPageTitle>{t("HTZ")}</BaseDataPageTitle>
          <BaseDataPageDescription>
            {t(
              "Hier können die Basisdaten für Vorgänge von HTZ Dienstleistungen konfiguriert werden.",
            )}
          </BaseDataPageDescription>
        </BaseDataPageHeader>
        <BaseDataPageContent>
          <TabsNav>
            <TabsNavList>
              <TabsNavLink to="position-catalogs">
                {t("Leistungskataloge")}
              </TabsNavLink>
              <TabsNavLink to="ahu-component-catalogs">
                {t("RLT-Komponenten")}
              </TabsNavLink>
              <TabsNavLink to="structural-assessment-catalogs">
                {t("Konstruktive Beurteilungen")}
              </TabsNavLink>
              <TabsNavLink to="visual-assessment-catalogs">
                {t("Visuelle Beurteilungen")}
              </TabsNavLink>
            </TabsNavList>
            <TabsNavContent className="pt-2">
              <Outlet />
            </TabsNavContent>
          </TabsNav>
        </BaseDataPageContent>
      </BaseDataPage>
    </PersistenceContextProvider>
  );
}
