import { Offer } from "@/services/backend/qpm/offer/types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { FloatNumberInput } from "@/routes/gemex/processes/_components/ui/number-input";

export type InstallationTableComponentProps = {
  offer: Offer;
};

export function InstallationTableComponent({
  offer,
}: InstallationTableComponentProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="h-fit px-1 py-1 text-lg font-medium">
            {t("Einrichtung und Installation")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Gesamtpreis")}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Anschaffung QPM Boxen- und Fallensystem")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.materialCost.qpm.totalCost}
            />
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Anschaffung Fluginsektenvernichter")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.materialCost.fiv.totalCost}
            />
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Installation QPM")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.installationResult.qpm.totalCost}
            />
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Installation FIV")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.installationResult.fiv.totalCost}
            />
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Installation ALoRa")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.installationResult.aLoRa.totalCost}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
