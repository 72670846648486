import { Navigate, RouteObject } from "react-router";
import { OfferIndexRoute } from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-index-route";
import { OfferRoute } from "@/routes/gesec/processes/[processId]/htz/offers/[offerId]/offer-route";
import { DataRoute } from "@/routes/gesec/processes/[processId]/htz/offers/[offerId]/data/data-route";
import { PositionsRoute } from "@/routes/gesec/processes/[processId]/htz/offers/[offerId]/positions/positions-route";
import { AddressesRoute } from "@/routes/gesec/processes/[processId]/htz/offers/[offerId]/addresses/addresses-route";
import { RepresentativeRoute } from "@/routes/gesec/processes/[processId]/htz/offers/[offerId]/representative/representative-route";

export const htzOffersRoutes: RouteObject[] = [
  {
    index: true,
    element: <OfferIndexRoute />,
  },
  {
    path: ":offerId",
    element: <OfferRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" replace />,
      },
      {
        path: "data",
        element: <DataRoute />,
      },
      {
        path: "positions",
        element: <PositionsRoute />,
      },
      {
        path: "addresses",
        element: <AddressesRoute />,
      },
      {
        path: "representative",
        element: <RepresentativeRoute />,
      },
    ],
  },
];
