import { Report } from "@/services/backend/htz/report/report";
import {
  createContext,
  ReactNode,
  useContext,
  useEffect,
  useMemo,
} from "react";
import {
  Controls,
  getNodesBounds,
  getViewportForBounds,
  NodeProps,
  Panel,
  ReactFlow,
  useReactFlow,
} from "@xyflow/react";
import {
  AirHandlingUnit,
  Component,
} from "@/services/backend/htz/ahu/air-handling-unit";
import { cn } from "@/shared/lib/utils";
import { AhuComponentCatalog } from "@/services/backend/htz/ahu/ahu-component-catalog";
import { useHtzAirHandlingUnitShowQuery } from "@/services/backend/htz/ahu/service";
import {
  useHtzAhuComponentCatalogShowActiveCatalogQuery,
  useHtzAhuComponentCatalogShowQuery,
} from "@/services/backend/htz/ahu/ahu-component-catalog-service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Result } from "@/services/backend/htz/inspection/result";
import { toPng } from "html-to-image";
import { useHtzReportUploadAhuImageMutation } from "@/services/backend/htz/report/service";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function ReportAhuSchema({ report }: { report: Report }) {
  const {
    data: ahu,
    isLoading: ahuIsLoading,
    error: ahuError,
  } = useHtzAirHandlingUnitShowQuery({
    id: report.airHandlingUnitId ?? "",
  });

  const {
    data: activeCatalog,
    isLoading: activeCatalogIsLoading,
    error: activeCatalogError,
  } = useHtzAhuComponentCatalogShowActiveCatalogQuery({});

  const {
    data: ahuComponentCatalog,
    isLoading: ahuComponentCatalogIsLoading,
    error: ahuComponentCatalogError,
  } = useHtzAhuComponentCatalogShowQuery({
    id: activeCatalog?.catalogId ?? "",
  });

  if (ahuIsLoading || ahuComponentCatalogIsLoading || activeCatalogIsLoading) {
    return null;
  }

  const error = ahuError || ahuComponentCatalogError || activeCatalogError;
  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <ReportAhuContextProvider
      ahu={ahu!}
      ahuComponentCatalog={ahuComponentCatalog!}
      report={report}
    >
      <div className="h-[300px]">
        <ReportModeSchema />
      </div>
    </ReportAhuContextProvider>
  );
}

function ReportAhuContextProvider({
  ahu,
  ahuComponentCatalog,
  report,
  children,
}: {
  ahu: AirHandlingUnit;
  ahuComponentCatalog: AhuComponentCatalog;
  report: Report;
  children: ReactNode;
}) {
  const value = useMemo(
    () => ({ ahu, ahuComponentCatalog, report }),
    [ahu, ahuComponentCatalog, report],
  );

  return (
    <ReportAhuContext.Provider value={value}>
      {children}
    </ReportAhuContext.Provider>
  );
}

interface ContextInterface {
  ahu: AirHandlingUnit;
  ahuComponentCatalog: AhuComponentCatalog;
  report: Report;
}

const ReportAhuContext = createContext<ContextInterface>({
  ahu: {} as AirHandlingUnit,
  ahuComponentCatalog: {} as AhuComponentCatalog,
  report: {} as Report,
});

const gridGap = 12;

function ReportModeSchema() {
  const { ahu, ahuComponentCatalog } = useContext(ReportAhuContext);
  const { components: nodes } = ahu;

  const nodeTypes = useMemo(
    () =>
      ahuComponentCatalog.items.reduce(
        (acc, template) => ({
          ...acc,
          // btw, TS is crazy...
          [template.type]: ReportModeSchemaComponent,
        }),
        {},
      ),
    [ahuComponentCatalog],
  );

  return (
    <div className="h-full rounded-lg border">
      <ReactFlow
        nodes={nodes}
        nodeTypes={nodeTypes}
        nodesDraggable={false}
        snapGrid={[gridGap, gridGap]}
        fitView
        fitViewOptions={{
          padding: 0.25,
        }}
      >
        <Controls />
        <DownloadButton />
      </ReactFlow>
    </div>
  );
}

function ReportModeSchemaComponent({ id, data }: NodeProps<Component>) {
  const { ahu, report } = useContext(ReportAhuContext);

  const component = ahu.components.find((c) => c.id === id);
  const item = report.items.find((i) => i.componentId === component?.id);

  // Components (the imgs) are expected to be defined in a
  // fixed aspect ratio and same size.
  // The sizeFactor is used to scale them as configured.
  const width = 12 * data.sizeFactor;

  return (
    <div className="relative">
      <img
        src={`data:image/svg+xml;base64,${data.img}`}
        alt={data.name}
        className={cn(
          `w-${width} bg-white`,
          item?.result === Result.WARNING ? "bg-yellow-300" : "",
          item?.result === Result.CRITICAL ? "bg-red-500" : "",
        )}
      />
      <div className="absolute top-12 left-2 text-xs font-bold tracking-tight">
        {data.details}
      </div>
    </div>
  );
}

const imageWidth = 2048;
const imageHeight = 1024;

function DownloadButton() {
  const { getNodes } = useReactFlow();
  const { report } = useContext(ReportAhuContext);

  const [patch, { isLoading, error, reset }] =
    useHtzReportUploadAhuImageMutation();

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const { resetNotReadyErrorField } = useErrArtefactNotReady();

  const onClick = () => {
    // we calculate a transform for the nodes so that all nodes are visible
    // we then overwrite the transform of the `.react-flow__viewport` element
    // with the style option of the html-to-image library
    const nodesBounds = getNodesBounds(getNodes());
    const viewport = getViewportForBounds(
      nodesBounds,
      imageWidth,
      imageHeight,
      1.0,
      1.0,
      0,
    );

    // @ts-expect-error it is a little unclear what to cast to here, but this
    // will not be null, undefined or anything else causing a runtime error
    toPng(document.querySelector(".react-flow__viewport"), {
      backgroundColor: "#ffffff",
      width: imageWidth,
      height: imageHeight,
      style: {
        width: imageWidth,
        height: imageHeight,
        transform: `translate(${viewport.x}px, ${viewport.y}px) scale(${viewport.zoom})`,
      },
    }).then((dataUrl) => {
      resetNotReadyErrorField("ahuImageFileId");
      resetNotReadyErrorField("ahuImageFilePrefix");
      patch({
        id: report.id,
        data: dataUrl,
      });
    });
  };

  return (
    <Panel position="top-right">
      <Button onClick={onClick} disabled={isLoading || report.immutable}>
        {t("Zum Bericht hinzufügen")}
      </Button>
    </Panel>
  );
}
