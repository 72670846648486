import { useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import { ChevronDown, ChevronRight } from "lucide-react";
import t from "@/lang/lang";
import { MonitorTypeUtil } from "@/services/backend/qpm/shared/monitorTypeUtil";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { MaintenancePriceListComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/maintenance-price-list";
import { useParams } from "react-router";
import { ServiceCostsComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/service-cost-component";
import { CalculationServiceType } from "@/services/backend/qpm/calculations/enum";
import { fMoney } from "@/routes/gesec/processes/_components/ui/number-input";

export function CalculationMaintenanceOverviewComponent() {
  const { calculationId } = useParams();

  return (
    calculationId && (
      <div className="overflow-clip rounded-lg border shadow-sm">
        <ServiceCostsComponent
          calculationId={calculationId}
          calculationServiceType={CalculationServiceType.Maintenance}
        />
        <MaintenanceCostsComponent calculationId={calculationId} />
        <div className="flex justify-between rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">{t("Gesamt")}</span>
          <span className="font-bold">{fMoney(0)}</span>
        </div>
      </div>
    )
  );
}

export type MaintenanceCostsComponentProps = {
  calculationId: string;
};

export function MaintenanceCostsComponent({
  calculationId,
}: MaintenanceCostsComponentProps) {
  const [open, setOpen] = useState(true);

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="border-t bg-gray-50 px-6 py-3">
        <div className="flex justify-between font-bold">
          <CollapsibleTrigger>
            <div className="flex items-center space-x-2">
              {open ? (
                <ChevronDown className="h-5 w-5" />
              ) : (
                <ChevronRight className="h-5 w-5" />
              )}
              <span>
                {t("Wartungskosten")}{" "}
                {t(MonitorTypeUtil.toString(MonitorTypeKey.FlyExterminator))}
              </span>
            </div>
          </CollapsibleTrigger>
          {/* <span className="">{fMoney(calculation.totalCost || 0)}</span> */}
        </div>
      </div>
      <CollapsibleContent>
        <div className="">
          <div className="">
            <MaintenancePriceListComponent
              calculationId={calculationId}
              monitorTypeKey={MonitorTypeKey.FlyExterminator}
            />
          </div>
        </div>
      </CollapsibleContent>
    </Collapsible>
  );
}
