export enum Role {
  Admin = "Admin",
  Director = "Director",
  BranchManager = "BranchManager",
  Salesperson = "Salesperson",
  TeamOffice = "TeamOffice",
  TeamLeader = "TeamLeader",
}

export const RoleList = [
  Role.Admin,
  Role.Director,
  Role.BranchManager,
  Role.Salesperson,
  Role.TeamOffice,
  Role.TeamLeader,
];
