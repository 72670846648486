import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { NavLink } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { useHtzWorkOrderListQuery } from "@/services/backend/htz/work-order/service";

export function WorkOrdersTable(props: { processId: string }) {
  const { processId } = props;

  const {
    data: list,
    isLoading,
    error,
  } = useHtzWorkOrderListQuery({
    process: {
      active: true,
      values: [processId],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-full">{t("Festgeschrieben")}</TableHead>
            <TableHead className="w-32">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Erstellt am")}</TableHead>
          <TableHead className="w-full">{t("Festgeschrieben")}</TableHead>
          <TableHead className="w-32">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.workOrders ?? [])]
          .sort((a, b) => a.createdAt.localeCompare(b.createdAt))
          .map((workOrder) => (
            <TableRow key={workOrder.id}>
              <TableCell>
                <NavLink to={`${workOrder.id}`}>
                  <ArtefactNumberById artefactId={workOrder.id} />
                </NavLink>
              </TableCell>
              <TableCell className="whitespace-nowrap">
                {formatDate(workOrder.createdAt)},{" "}
                {formatTime(workOrder.createdAt)}
              </TableCell>
              <TableCell>{workOrder.immutable ? "ja" : "nein"}</TableCell>
              <TableCell>
                <div className="flex space-x-2">
                  <NavLink to={`${workOrder.id}`}>
                    <Button variant="ghost">
                      <Search className="h-4 w-4" />
                    </Button>
                  </NavLink>
                </div>
              </TableCell>
            </TableRow>
          ))}
        {list?.workOrders?.length === 0 && (
          <TableRow>
            <TableCell colSpan={4} className="text-center">
              {t("Keine Arbeitsscheine gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
