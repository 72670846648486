import { ServiceAddressCard } from "@/shared/components/domain/addresses/service-address-card";
import { useState } from "react";
import {
  UpdateCustomerAddressRequest,
  UpdateServiceAddressRequest,
  useVbsContractUpdateCustomerAddressMutation,
  useVbsContractUpdateServiceAddressMutation,
} from "@/services/backend/vbs/contracts/service";
import { Contract } from "@/services/backend/vbs/contracts/contract";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { CustomerAddressCard } from "@/shared/components/domain/addresses/customer-address-card";
import t from "@/lang/lang";

export function ContractAddresses({ contract }: { contract: Contract }) {
  return (
    <div className="space-y-2">
      <ServiceAddress contract={contract} />
      <CustomerAddress contract={contract} />
    </div>
  );
}

function ServiceAddress({ contract }: { contract: Contract }) {
  const [request, setRequest] = useState<UpdateServiceAddressRequest>({
    id: contract.id,
    serviceAddress: contract.serviceAddress,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsContractUpdateServiceAddressMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <ServiceAddressCard
      address={request.serviceAddress}
      onAddressChange={(serviceAddress) =>
        setRequest({ ...request, serviceAddress })
      }
    />
  );
}

function CustomerAddress({ contract }: { contract: Contract }) {
  const [request, setRequest] = useState<UpdateCustomerAddressRequest>({
    id: contract.id,
    customerAddress: contract.customerAddress,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsContractUpdateCustomerAddressMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <CustomerAddressCard
      customerAddress={request.customerAddress}
      onCustomerAddressChange={(customerAddress) =>
        setRequest({ ...request, customerAddress })
      }
      description={t(
        "Die Adresse des Unternehmens mit welchem der Vertrag geschlossen wird.",
      )}
    />
  );
}
