import { HTMLAttributes, ReactNode } from "react";
import { NavLink, Outlet, OutletProps } from "react-router";
import { cn } from "@/shared/lib/utils";

interface TabsNavProps extends HTMLAttributes<HTMLDivElement> {}

/**
 * TabsNav and the dedicated child components
 * provide a combination of the shadcn Tabs design
 * and the react-router NavLink component.
 * This way the shadcn Tabs design can be used
 * but instead of merely switching out components
 * a new route is navigated to, making the tabs
 * sharable.
 */
export function TabsNav({ children, className, ...props }: TabsNavProps) {
  return (
    <div className={cn("", className)} {...props}>
      {children}
    </div>
  );
}

export function TabsNavList(props: { children: ReactNode }) {
  const { children } = props;
  return (
    <div className="bg-muted text-muted-foreground inline-flex h-10 items-center justify-center rounded-md p-1">
      {children}
    </div>
  );
}

interface TabsNavLinkProps {
  to: string;
  children: ReactNode;
  disabled?: boolean;
  end?: boolean;
  // allows to mark tab, if the content it navigates to has an error
  hasValidationError?: boolean;
}

export function TabsNavLink({
  to,
  children,
  disabled = false,
  end = false,
  hasValidationError = false,
}: TabsNavLinkProps) {
  const baseClassName =
    "inline-flex items-center justify-center whitespace-nowrap rounded-sm px-3 py-1.5 text-sm font-medium ring-offset-background transition-all focus-visible:outline-hidden focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 ";
  const activeClassName = "bg-background text-foreground shadow-xs";
  const inactiveClassName = "";
  const disabledClassName = "disabled:pointer-events-none disabled:opacity-50";
  const errorClassName = "border border-red-700";

  return (
    <NavLink
      to={to}
      className={({ isActive }) =>
        isActive
          ? cn(
              baseClassName,
              activeClassName,
              disabled ? disabledClassName : "",
              hasValidationError ? errorClassName : "",
            )
          : cn(
              baseClassName,
              inactiveClassName,
              disabled ? disabledClassName : "",
              hasValidationError ? errorClassName : "",
            )
      }
      end={end ?? false}
    >
      {children}
    </NavLink>
  );
}

interface TabsNavContentProps extends HTMLAttributes<HTMLDivElement> {
  outletProps?: OutletProps;
}

export function TabsNavContent({
  outletProps = undefined,
  className,
  ...props
}: TabsNavContentProps) {
  return (
    <div className={cn("", className)} {...props}>
      <Outlet {...outletProps} />
    </div>
  );
}
