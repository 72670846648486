import { Position } from "@/services/backend/rlt/treatments/position";
import { cn } from "@/shared/lib/utils";
import { fPrice } from "./utils";

export function BasePriceCell({
  position,
  className = "",
}: {
  position: Position;
  className?: string;
}) {
  return (
    <div
      className={cn(
        "flex h-6 items-center justify-end font-bold whitespace-nowrap",
        className,
      )}
    >
      {fPrice(position.basePrice)} €
    </div>
  );
}
