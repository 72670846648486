import {
  Report,
  VDI6022,
  vdi6022FromString,
} from "@/services/backend/htz/report/report";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { useEffect, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { DateInput } from "@/shared/components/form/date-input";
import {
  UpdateBaseDataRequest,
  useHtzReportUpdateBaseDataMutation,
  useHtzReportUpdateVDI6022Mutation,
} from "@/services/backend/htz/report/service";
import { TextInput } from "@/shared/components/form/text-input";
import { inputValidationErrorMessages } from "@/shared/components/ui/input-error-messages";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { useHtzAirHandlingUnitShowQuery } from "@/services/backend/htz/ahu/service";
import { useNumbersArtefactNumberShowQuery } from "@/services/backend/numbers/artefact-number/service";
import { useHtzWorkOrderListPositionQuery } from "@/services/backend/htz/work-order/service";
import { Separator } from "@/shared/components/ui/separator";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { Check, X } from "lucide-react";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

export function BaseDataCard({ report }: { report: Report }) {
  const [request, setRequest] = useState<UpdateBaseDataRequest>({ ...report });
  const [patch, { isLoading, error, isSuccess, reset }] =
    useHtzReportUpdateBaseDataMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    patch,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const { data: ahu } = useHtzAirHandlingUnitShowQuery({
    id: report.airHandlingUnitId ?? "",
  });

  const { data: number } = useNumbersArtefactNumberShowQuery({
    artefactId: report.workOrderId ?? "",
  });

  const { data: workOrderPositionList } = useHtzWorkOrderListPositionQuery({
    workOrder: { active: true, values: [report.workOrderId ?? ""] },
  });
  const workOrderPosition = workOrderPositionList?.positions.find(
    (position) => position.id === report.workOrderPositionId,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Anlagenbericht")}</CardTitle>
        <CardDescription>{t("Daten für das Deckblatt")}</CardDescription>
      </CardHeader>
      <CardContent>
        <div className="grid grid-cols-4 gap-4">
          <TextInput
            label={t("Daten übernommen aus Arbeitsschein")}
            field="workOrderId"
            text={number?.string ?? ""}
            onTextChange={() => {}}
            errors={null}
            disabled
          />
          <TextInput
            label={t("Daten übernommen aus Position")}
            field="workOrderPositionId"
            text={workOrderPosition?.number.toString() ?? ""}
            onTextChange={() => {}}
            errors={null}
            disabled
          />
          <TextInput
            label={t("RLT-Anlage")}
            field="airHandlingUnitId"
            text={ahu?.name ?? ""}
            onTextChange={() => {}}
            errors={null}
            disabled
          />
          <TextInput
            label={t("Berichtnummer")}
            field="number"
            text={report.number}
            onTextChange={() => {}}
            errors={null}
            disabled
          />
          <TextInput
            label={t("Bericht Author")}
            field="createdBy"
            text={request.createdBy}
            onTextChange={(createdBy) => {
              resetNotReadyErrorField("createdBy");
              setRequest({ ...request, createdBy });
            }}
            errors={inputValidationErrorMessages(
              error || notReadyError,
              "createdBy",
            )}
            disabled={report.immutable}
          />
          <DateInput
            label={t("Berichtsdatum")}
            date={request.date}
            onDateChange={(date) => {
              resetNotReadyErrorField("date");
              setRequest({ ...request, date });
            }}
            errors={inputValidationErrorMessages(
              error || notReadyError,
              "date",
            )}
            disabled={report.immutable}
          />
          <TextInput
            label={t("Inspektion durchgeführt von")}
            field="inspectionConductedBy"
            text={request.inspectionConductedBy}
            onTextChange={(inspectionConductedBy) => {
              resetNotReadyErrorField("inspectionConductedBy");
              setRequest({ ...request, inspectionConductedBy });
            }}
            errors={inputValidationErrorMessages(
              error || notReadyError,
              "inspectionConductedBy",
            )}
            disabled={report.immutable}
          />
          <DateInput
            label={t("Inspektionsdatum")}
            date={request.inspectionDate}
            onDateChange={(inspectionDate) => {
              resetNotReadyErrorField("inspectionDate");
              setRequest({ ...request, inspectionDate });
            }}
            errors={inputValidationErrorMessages(
              error || notReadyError,
              "inspectionDate",
            )}
            disabled={report.immutable}
          />
        </div>
        <Separator className="my-4" />
        <VDI6022Result report={report} />
      </CardContent>
    </Card>
  );
}

function VDI6022Result({ report }: { report: Report }) {
  const [patch, { isLoading, error, reset }] =
    useHtzReportUpdateVDI6022Mutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
  }, [error, reset, toast]);

  const onValueChange = (vdi6022: string) => {
    if (isLoading) return;

    patch({
      id: report.id,
      vdi6022: vdi6022FromString(vdi6022),
    });
  };

  return (
    <Select
      value={report.vdi6022}
      onValueChange={onValueChange}
      disabled={isLoading}
    >
      <SelectTrigger className="border-2">
        <SelectValue placeholder={t("Ergebnis auswählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value={VDI6022.Passed}>
            <span className="text-primary flex items-center font-bold">
              <Check className="mr-2" />
              <span>{t(VDI6022.Passed)}</span>
            </span>
          </SelectItem>
          <SelectItem value={VDI6022.Failed}>
            <span className="text-destructive flex items-center font-bold">
              <X className="mr-2" />
              <span>{t(VDI6022.Failed)}</span>
            </span>
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
