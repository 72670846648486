import { useParams } from "react-router";
import { useHtzReportShowQuery } from "@/services/backend/htz/report/service";
import { Report } from "@/services/backend/htz/report/report";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { ArtefactImmutableAlert } from "@/shared/service-manager/artefact/artefact";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { DeleteReportDialog } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/_components/delete-report-dialog";
import { ReportPDF } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/_components/report-pdf";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { CopyDataDropdown } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/_components/copy-data-dropdown";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function ReportRoute() {
  const { reportId } = useParams();

  const {
    data: report,
    isLoading,
    error,
  } = useHtzReportShowQuery({
    id: reportId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <ReportPage report={report!} />
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

function ReportPage({ report }: { report: Report }) {
  const { notReadyError } = useErrArtefactNotReady();

  const dataErr = hasFieldError(
    notReadyError,
    "date",
    "createdBy",
    "objectAddress",
    "createdFor",
    "inspectionDate",
    "inspectionConductedBy",
    "supportingStaff",
    "nextInspectionDate",
    "VDI6022",
    "representative",
  );
  const itemErr = hasFieldError(
    notReadyError,
    "item",
    "ahuImagePrefix",
    "ahuImageFileID",
  );
  const microbialErr = hasFieldError(notReadyError, "microbial");
  const structuralErr = hasFieldError(notReadyError, "structural");
  const visualErr = hasFieldError(notReadyError, "visual");
  const photosErr = hasFieldError(notReadyError, "photo");
  const attachmentErr = hasFieldError(notReadyError, "attachment");

  const links = [
    { to: "data", label: t("Bericht"), hasValidationError: dataErr },
    { to: "items", label: t("Komponenten"), hasValidationError: itemErr },
    {
      to: "microbial",
      label: t("Mikrobiologisch"),
      hasValidationError: microbialErr,
    },
    {
      to: "structural",
      label: t("Konstruktiv"),
      hasValidationError: structuralErr,
    },
    { to: "visual", label: t("Visuell"), hasValidationError: visualErr },
    { to: "photos", label: t("Fotos"), hasValidationError: photosErr },
    {
      to: "attachments",
      label: t("Anhänge"),
      hasValidationError: attachmentErr,
    },
  ];

  return (
    <ArtefactPage>
      <ArtefactImmutableAlert artefact={report} />
      <ArtefactPageHeader>
        <ArtefactPageNavigation>
          {links.map((link) => (
            <ArtefactPageNavigationLink
              key={link.to}
              to={link.to}
              hasValidationError={link.hasValidationError}
            >
              {link.label}
            </ArtefactPageNavigationLink>
          ))}
        </ArtefactPageNavigation>
        <CopyDataDropdown report={report!} />
        <ArtefactPageActions>
          {!report?.immutable && (
            <>
              <PersistenceStateUsingContext className="mr-2" />
              <DeleteReportDialog reportId={report!.id} />
            </>
          )}
          <ReportPDF report={report!} />
        </ArtefactPageActions>
      </ArtefactPageHeader>
      <ArtefactPageContent>
        <ArtefactPageNavigationOutlet />
      </ArtefactPageContent>
    </ArtefactPage>
  );
}
