import { HTMLAttributes } from "react";
import { cn } from "@/shared/lib/utils";
import { useNumbersArtefactNumberShowQuery } from "@/services/backend/numbers/artefact-number/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import {
  is404,
  parseRTKQueryError,
} from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { ArtefactNumber as ArtefactNumberInterface } from "@/services/backend/numbers/artefact-number/artefact-number";
import t from "@/lang/lang";

export interface ArtefactNumberByIdProps
  extends HTMLAttributes<HTMLSpanElement> {
  artefactId: string;
}

export function ArtefactNumberById({
  artefactId,
  className,
  ...props
}: ArtefactNumberByIdProps) {
  const {
    data: number,
    isLoading,
    error,
  } = useNumbersArtefactNumberShowQuery({ artefactId });

  if (isLoading) {
    return <Skeleton className="h-2 w-52" />;
  }

  if (error) {
    if (is404(error)) {
      return (
        <span className={cn("font-mono whitespace-nowrap", className)}>
          {t("- keine Nummer -")}
        </span>
      );
    }

    const err = parseRTKQueryError(error);
    return <span className="whitespace-nowrap">{err?.title}</span>;
  }

  return <ArtefactNumber number={number!} className={className} {...props} />;
}

export interface ArtefactNumberProps extends HTMLAttributes<HTMLSpanElement> {
  number: ArtefactNumberInterface;
}

export function ArtefactNumber({
  number,
  className,
  ...props
}: ArtefactNumberProps) {
  return (
    <span className={cn("font-mono whitespace-nowrap", className)} {...props}>
      {number.string}
    </span>
  );
}
