import { useParams } from "react-router";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { OfferTableComponent } from "@/routes/gemex/processes/qpm/offers/_components/offer-table-component";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";
import { CreateOfferDialog } from "@/routes/gemex/processes/qpm/offers/_components/create-offer-dialog";

export function OfferIndexRoute() {
  const params = useParams();
  const { processId } = params;

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Angebote")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateOfferDialog processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Card>
          <OfferTableComponent processId={processId!} />
        </Card>
      </MilestonePageContent>
    </MilestonePage>
  );
}
