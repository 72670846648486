import { Textarea } from "@/shared/components/ui/textarea";
import {
  Requirement,
  UpdateRequirementRequest,
} from "@/services/backend/qpm/requirements/types";
import { Input } from "@/shared/components/ui/input";
import { Label } from "@/shared/components/ui/label";
import t from "@/lang/lang";
import { formatDateTimeLocal } from "@/shared/lib/utilities/date";
import { AddressInfoComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/address-info-component";
import { useParams } from "react-router";
import { URLParams } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/create-requirement";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  useQpmRequirementGetQuery,
  useQpmRequirementUpdateMutation,
} from "@/services/backend/qpm/requirements/service";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";

export function RequirementDetailsRoute() {
  const { requirementId } = useParams<URLParams>();

  const {
    data: requirement,
    error: errorRequirement,
    isLoading: isLoadingRequirement,
  } = useQpmRequirementGetQuery({
    artefactMetaId: requirementId ?? "",
  });

  if (errorRequirement) {
    return <RTKQueryErrorAlert error={errorRequirement} />;
  }

  if (isLoadingRequirement) {
    return (
      <div className="mt-2">
        <Skeleton className="mb-2 h-24 w-full" />
        <Skeleton className="mb-2 h-24 w-full" />
        <Skeleton className="h-24 w-full" />
      </div>
    );
  }

  return (
    requirement && <RequirementDetailsComponent requirement={requirement} />
  );
}

export type RequirementDetailsComponentProps = {
  requirement: Requirement;
};

export function RequirementDetailsComponent({
  requirement,
}: RequirementDetailsComponentProps) {
  const [request, setRequest] = useState<UpdateRequirementRequest>({
    artefactMeta: requirement.artefactData,
    description: requirement.artefactData.description,
    subject: requirement.artefactData.subject,
  });
  const [setRequirement, { error, isSuccess, isLoading, reset }] =
    useQpmRequirementUpdateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setRequirement,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <div className="grid gap-3">
      <div className="grid grid-cols-2 gap-3">
        <div className="grid gap-1.5">
          <Label htmlFor="subject">{t("Thema")}</Label>
          <Input
            id="subject"
            type="text"
            className="w-full"
            defaultValue={requirement.artefactData.subject}
            onChange={(e) => {
              setRequest({ ...request, subject: e.target.value });
            }}
          />
        </div>
        <div className="grid gap-1.5">
          <Label htmlFor="number">{t("Nummer")}</Label>
          <div className="border-input text-muted-foreground flex h-10 items-center rounded-md border px-3 py-2 text-sm">
            <ArtefactNumberById artefactId={requirement.artefactData.id} />
          </div>
        </div>
        <div className="grid gap-1.5">
          <Label htmlFor="description">{t("Beschreibung")}</Label>
          <Textarea
            id="description"
            defaultValue={requirement.artefactData.description}
            className="min-h-10"
            onChange={(e) => {
              setRequest({ ...request, description: e.target.value });
            }}
          />
        </div>
        <div className="grid gap-1.5">
          <Label htmlFor="date">{t("Erstellt am")}</Label>
          <Input
            id="date"
            type="datetime-local"
            className="w-full"
            value={formatDateTimeLocal(requirement.artefactData.createdAt)}
            disabled
          />
        </div>
      </div>
      <div className="">
        <AddressInfoComponent />
      </div>
    </div>
  );
}
