import {
  CreateAreaRequest,
  Level,
} from "@/services/backend/qpm/buildings/types";
import { useToast } from "@/shared/hooks/use-toast";
import { useCallback, useEffect, useState } from "react";
import { useQpmAreaAddMutation } from "@/services/backend/qpm/buildings/service";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { v4 } from "uuid";
import { Loader2, Plus } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";

export type NewAreaPopOverButtonProps = {
  level: Level;
};

export function NewAreaPopOverButton({ level }: NewAreaPopOverButtonProps) {
  const { toast } = useToast();
  const [areaName, setAreaName] = useState<string>("");
  const [
    createNewArea,
    {
      error: errorCreateArea,
      reset: resetCreateArea,
      isSuccess: isSuccessCreateArea,
      isLoading: isLoadingCreateArea,
    },
  ] = useQpmAreaAddMutation();

  useEffect(() => {
    if (errorCreateArea) {
      toast({ ...parseRTKQueryError(errorCreateArea) });
      resetCreateArea();
    }
  }, [errorCreateArea, resetCreateArea, toast]);

  useEffect(() => {
    if (isSuccessCreateArea) {
      setAreaName("");
    }
  }, [isSuccessCreateArea]);

  const handleAreaNameChange = (event: { target: { value: string } }) => {
    setAreaName(event.target.value);
  };

  const handleSave = useCallback(() => {
    if (isLoadingCreateArea || !areaName) {
      return;
    }
    const request: CreateAreaRequest = {
      id: v4(),
      levelId: level.base.id,
      name: areaName,
    };
    createNewArea(request);
  }, [areaName, createNewArea, isLoadingCreateArea, level.base.id]);

  if (isLoadingCreateArea) {
    return <Loader2 className="animate-spin" />;
  }

  return (
    <TooltipProvider>
      <Tooltip>
        <Popover>
          <TooltipTrigger asChild>
            <PopoverTrigger asChild>
              <Button size="icon" variant="outline">
                <Plus className="h-5 w-5" />
              </Button>
            </PopoverTrigger>
          </TooltipTrigger>
          <PopoverContent side="bottom" align="end">
            <div className="grid gap-4">
              <div className="flex flex-col gap-1.5">
                <div className="space-y-2">
                  <h4 className="leading-none font-medium">
                    {t("Bereich hinzufügen")}
                  </h4>
                  <p className="text-muted-foreground text-sm">
                    {t("Geben Sie hier den Namen des neuen Bereichs ein.")}
                    {` ${t("Ebene")} ${level.base.name}`}
                  </p>
                </div>
                <Label htmlFor={`new-level-input-${level.base.id}`}>
                  {t("Neuer Bereichsname:")}
                </Label>
                <Input
                  id={`new-level-input-${level.base.id}`}
                  onChange={handleAreaNameChange}
                  disabled={isLoadingCreateArea}
                  onKeyDown={(event) => {
                    if (
                      event.key === "Enter" &&
                      areaName &&
                      !isLoadingCreateArea
                    ) {
                      handleSave();
                    }
                  }}
                />
              </div>
              <Button
                disabled={isLoadingCreateArea || !areaName}
                onClick={handleSave}
              >
                {t("Speichern")}
              </Button>
            </div>
          </PopoverContent>
        </Popover>
        <TooltipContent>
          <p>{t("Bereich hinzufügen")}</p>
        </TooltipContent>
      </Tooltip>
    </TooltipProvider>
  );
}
