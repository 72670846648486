import {
  Component,
  FreeText,
  Kind,
} from "@/services/backend/vbs/treatments/component";
import { SegmentKind } from "@/services/backend/vbs/treatments/segmentKind";
import { Formula } from "@/services/backend/vbs/treatments/formula";
import t from "@/lang/lang";

export interface Position {
  id: string;
  number: string;
  component: Component;
  cycle: number;
  segmentKind: SegmentKind;
  area: string;
  comment: string;
  formula: Formula;
  cleanTime: number;
  basePrice: number;
  tipCommission: number;
  offerPrice: number;
  cycleDiscount: number;
  contractPrice: number;
  actuallyTreated: Component;
  completed: boolean;
  completedAt: string;
  immutable: boolean;
  immutabilityReason: string;
}

/**
 * positionTitle returns the fitting title for a position.
 * For some component kinds simply stating the kind is not
 * enough.
 * @param position
 */
export function positionTitle(position: Position): string {
  switch (position.component.kind) {
    case Kind.FreeText:
      return (position.component.data as FreeText).text;
    default:
      return t(position.component.kind);
  }
}
