import { NavLink, useParams } from "react-router";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { useVbsCertificateShowQuery } from "@/services/backend/vbs/certificates/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import t from "@/lang/lang";
import { ErrArtefactNotReadyContextProvider } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { ArrowLeft } from "lucide-react";
import { Button } from "@/shared/components/ui/button";
import { CertificatePdf } from "@/routes/gesec/processes/[processId]/vbs/certificates/[certificateId]/_components/certificate-pdf";
import { CertificateImmutablePage } from "@/routes/gesec/processes/[processId]/vbs/certificates/[certificateId]/_components/certificate-immutable-page";
import { Card } from "@/shared/components/ui/card";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";
import { DeleteCertificateDialog } from "@/routes/gesec/processes/[processId]/vbs/certificates/[certificateId]/_components/delete-certificate-dialog";
import { CopyDataDialog } from "@/routes/gesec/processes/_components/copy-data-dialog/copy-data-dialog";
import { useState } from "react";
import { v4 } from "uuid";
import { CertificateComponent } from "./_components/certificate-component";

export function CertificateRoute() {
  const { certificateId } = useParams();
  const {
    data: certificate,
    isLoading,
    error,
  } = useVbsCertificateShowQuery({
    id: certificateId!,
  });

  const [key, setKey] = useState(v4());

  const certificateWasIssued = certificate?.immutable;

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (certificateWasIssued) {
    return <CertificateImmutablePage certificate={certificate!} />;
  }

  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <ArtefactPage>
          <ArtefactPageHeader>
            <div className="flex items-center space-x-4">
              <Button variant="outline">
                <NavLink to=".." className="flex items-center space-x-2">
                  <ArrowLeft className="h-4 w-4" />
                  <span>{t("Zurück zur Übersicht")}</span>
                </NavLink>
              </Button>
              <CopyDataDialog
                destination={{
                  location: "gsm.vbs",
                  document: "certificate",
                  id: certificate!.id,
                }}
                processId={certificate!.processId}
                onCopySuccess={() => setKey(v4())}
              />
            </div>
            <ArtefactPageActions>
              <PersistenceStateUsingContext className="mr-2" />
              <DeleteCertificateDialog certificate={certificate!} />
              <CertificatePdf certificateId={certificateId!} />
            </ArtefactPageActions>
          </ArtefactPageHeader>
          <ArtefactPageContent>
            <CertificateComponent certificateId={certificateId!} key={key} />
          </ArtefactPageContent>
        </ArtefactPage>
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}
