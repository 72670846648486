import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { AlertCircle } from "lucide-react";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { useEffect } from "react";
import { useRltOfferShowQuery } from "@/services/backend/rlt/offers/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  useRltContractCreateForOfferMutation,
  useRltContractListQuery,
  useRltContractShowQuery,
} from "@/services/backend/rlt/contracts/service";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { v4 } from "uuid";
import { ContractPositions } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-positions";
import { ContractBaseData } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-base-data";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { ContractComments } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-comments";
import { Offer } from "@/services/backend/rlt/offers/offer";

export function ContractForOffer({
  processId,
  offerId,
}: {
  processId: string;
  offerId: string;
}) {
  return <NewContractGuard offerId={offerId} processId={processId} />;
}

function AlertIfContractsExist({
  processId,
  offerId,
}: {
  processId: string;
  offerId: string;
}) {
  const { data: process } = useProcessesProcessShowQuery({
    id: processId,
  });
  const { data: offer } = useRltOfferShowQuery({
    id: offerId,
  });

  const { data, isLoading, error } = useRltContractListQuery({
    company: {
      active: true,
      values: [process?.companyId ?? ""],
    },
    customer: {
      active: true,
      values: [process?.customerId ?? ""],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading || !process || !offer) {
    return null;
  }

  if (data!.contracts.filter((c) => c.id !== offer.id).length === 0) {
    return null;
  }

  return (
    <Alert className="my-4">
      <AlertCircle className="h-5 w-5" />
      <AlertTitle>{t("Es existieren bereits Verträge")}</AlertTitle>
      <AlertDescription>
        {t(
          "Es existieren bereits Verträge für diesen Kunden. Bitte prüfen Sie zuerst ob einer dieser zum aktuellen Angebot passt. Dort können Sie auch geltende Konditionen übernehmen.",
        )}
      </AlertDescription>
    </Alert>
  );
}

function NewContractGuard({
  offerId,
  processId,
}: {
  offerId: string;
  processId: string;
}) {
  const {
    data: offer,
    error,
    isLoading,
  } = useRltOfferShowQuery({ id: offerId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return null;
  }

  const contractId = offer?.contractId;

  if (contractId && contractId !== "00000000-0000-0000-0000-000000000000") {
    return <ContractData contractId={contractId} offerId={offerId} />;
  }

  return (
    <div>
      <AlertIfContractsExist processId={processId} offerId={offerId} />
      <CreateContractButton offer={offer!} />
    </div>
  );
}

function CreateContractButton({ offer }: { offer: Offer }) {
  const [create, { isLoading, error, reset }] =
    useRltContractCreateForOfferMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const onClick = () => {
    if (isLoading) {
      return;
    }

    create({
      id: v4(),
      offerId: offer.id,
      companyId: offer.companyId,
      branchId: offer.branchId,
      customerId: offer.customerId,
    });
  };

  return (
    <div>
      <Button onClick={onClick} disabled={isLoading}>
        {t("Neuen Vertrag erstellen")}
      </Button>
    </div>
  );
}

function ContractData({
  contractId,
  offerId,
}: {
  contractId: string;
  offerId: string;
}) {
  const {
    data: contract,
    isLoading,
    error,
  } = useRltContractShowQuery({
    contractId,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <div className="space-y-2">
      <ContractBaseData contract={contract!} />
      <ContractPositions contract={contract!} offerId={offerId} />
      <ContractComments contract={contract!} />
    </div>
  );
}
