import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useParams } from "react-router";
import { useQpmOfferShowQuery } from "@/services/backend/qpm/offer/service";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Separator } from "@/shared/components/ui/separator";
import { SelectCalculationButtonComponent } from "@/routes/gemex/processes/qpm/offers/[offerId]/_components/select-calculation-component";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { HirePurchaseTableComponent } from "@/routes/gemex/processes/qpm/offers/[offerId]/_components/hire-purchase-table-component";
import { InstallationTableComponent } from "@/routes/gemex/processes/qpm/offers/[offerId]/_components/installation-table-component";
import { MaintenanceTableComponent } from "@/routes/gemex/processes/qpm/offers/[offerId]/_components/maintenance-table-component";
import { RentingTableComponent } from "@/routes/gemex/processes/qpm/offers/[offerId]/_components/renting-table-component";
import { OfferDateSelector } from "@/routes/gemex/processes/qpm/offers/[offerId]/overview/_components/offer-date-selector";

export function OfferOverviewComponent() {
  const params = useParams();
  const { offerId } = params;
  const {
    data: offer,
    isLoading,
    error,
  } = useQpmOfferShowQuery({ artefactMetaId: offerId! }, { skip: !offerId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    offer && (
      <Card className="">
        <CardHeader className="py-4 font-bold">
          <div className="flex items-center justify-between">
            <CardTitle>
              {t("Angebot")}
              {" - "}
              <ArtefactNumberById
                className="text-xl"
                artefactId={offer.artefactData.id}
              />
            </CardTitle>
            <div className="flex items-center justify-between gap-2">
              <span className="text-sm">{t("Kalkulationsgrundlage")}:</span>
              <SelectCalculationButtonComponent
                offerId={offerId}
                processId={offer.artefactData.processId}
                selectedCalculationId={
                  offer.artefactData?.child?.artefact?.linkedArtefacts
                    ?.calculation?.linkedArtefact?.id
                }
              />
            </div>
          </div>
        </CardHeader>
        <div className="mx-6 mb-2">
          <Separator />
        </div>
        <CardContent>
          <OfferDateSelector offer={offer} />
          <div className="flex flex-col gap-6">
            <div className="flex flex-col">
              <InstallationTableComponent offer={offer} />
            </div>
            {/* Mietkauf */}
            <div className="flex flex-col">
              <HirePurchaseTableComponent offerId={offer.artefactData.id} />
            </div>
            {/* <Separator className="my-2" /> */}
            {/* Betriebs- und Serviceleistungen */}
            <div className="flex flex-col">
              <MaintenanceTableComponent offer={offer} />
            </div>
            {/* Miete ALoRa */}
            <div className="flex flex-col">
              <RentingTableComponent offer={offer} />
            </div>
          </div>
        </CardContent>
      </Card>
    )
  );
}
