import { TriangleAlert } from "lucide-react";
import {
  Alert,
  AlertDescription,
  AlertTitle,
} from "@/shared/components/ui/alert";
import { FC, ReactNode } from "react";
import { clsx } from "clsx";

interface AlertWarningProps {
  title: string;
  children: ReactNode;
  className?: string;
}

export const AlertWarning: FC<AlertWarningProps> = ({
  title,
  children,
  className,
}) => {
  return (
    <Alert
      variant="default"
      className={clsx(
        "border-warning-accent bg-warning flex flex-row gap-3",
        className,
      )}
    >
      <div>
        <TriangleAlert className="text-warning-accent h-4 w-4" />
      </div>
      <div>
        <AlertTitle>{title}</AlertTitle>
        <AlertDescription>
          <div className="flex flex-col flex-wrap">{children}</div>
        </AlertDescription>
      </div>
    </Alert>
  );
};
