import {
  ArtefactActions,
  ArtefactActionsDownload,
  ArtefactActionsHeader,
  ArtefactImmutablePage,
  ArtefactPreview,
} from "@/shared/service-manager/artefact/artefact-immutable-page";
import t from "@/lang/lang";
import { ArtefactImmutableAlert } from "@/shared/service-manager/artefact/artefact";
import { Separator } from "@/shared/components/ui/separator";
import { Button } from "@/shared/components/ui/button";
import { Copy, Download } from "lucide-react";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { useDownloadPdf } from "@/shared/lib/utilities/download-pdf";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { v4 } from "uuid";
import { useNavigate } from "react-router";
import { Offer } from "@/services/backend/vbs/offers/offer";
import {
  OfferDocument,
  useVbsOfferCreateFromOldMutation,
  useVbsOfferDownloadPDFMutation,
  useVbsOfferPreviewPDFQuery,
} from "@/services/backend/vbs/offers/service";
import {
  ContractDocument,
  useVbsContractDownloadPDFMutation,
} from "@/services/backend/vbs/contracts/service";
import { isZeroUUID } from "@/shared/lib/utilities/uuid";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

export function OfferImmutablePage({ offer }: { offer: Offer }) {
  const { data, isLoading, error } = useVbsOfferPreviewPDFQuery({
    id: offer.id,
    document: OfferDocument.ALL,
  });

  return (
    <ArtefactImmutablePage>
      <ArtefactPreview
        objectURL={data?.objectURL}
        isLoading={isLoading}
        error={error}
      />
      <ArtefactActions>
        <ArtefactActionsHeader
          title={t("Angebot")}
          artefactNumber={<ArtefactNumberById artefactId={offer.id} />}
        />
        <ArtefactImmutableAlert artefact={offer} />
        <Separator />
        <DuplicateOfferButton processId={offer.processId} offerId={offer.id} />
        <div className="text-muted-foreground text-xs">
          {t(
            "Ein Klick auf Aktualisieren erstellt eine neues Angebot und übernimmt die Daten des aktuellen.",
          )}
        </div>
        <Separator />
        <ArtefactActionsDownload>
          <DownloadButton
            offerId={offer.id}
            document={OfferDocument.ALL}
            text={t("Alle Dokumente")}
          />
          <DownloadButton
            offerId={offer.id}
            document={OfferDocument.OFFER}
            text={t("Angebot")}
          />
          <DownloadButton
            offerId={offer.id}
            document={OfferDocument.TREATMENT}
            text={t("Leistungsverzeichnis")}
          />
          <DownloadButton
            offerId={offer.id}
            document={OfferDocument.ORDER}
            text={t("Auftrag")}
          />
          <DownloadContractButton
            contractId={offer.contractId}
            document={ContractDocument.CONTRACT}
            text={t("Vertrag")}
          />
          <DownloadContractButton
            contractId={offer.contractId}
            document={ContractDocument.TREATMENT}
            text={t("Vertrag LV")}
          />
        </ArtefactActionsDownload>
      </ArtefactActions>
    </ArtefactImmutablePage>
  );
}

function DuplicateOfferButton({
  processId,
  offerId,
}: {
  processId: string;
  offerId: string;
}) {
  const [create, { isLoading, error, reset }] =
    useVbsOfferCreateFromOldMutation();
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  const disabled = isLoading || !process;

  const { toast } = useToast();
  const navigate = useNavigate();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
  }, [error, toast, reset]);

  const onClick = () => {
    if (disabled) {
      return;
    }

    const id = v4();
    create({
      oldOfferId: offerId,
      id,
      processId: process.id,
      companyId: process.companyId,
      branchId: process.branchId,
      customerId: process.customerId,
    })
      .unwrap()
      .then(() => {
        navigate(`../${id}`);
        reset();
      });
  };

  return (
    <Button
      onClick={onClick}
      disabled={isLoading}
      className="w-full justify-start"
    >
      <Copy className="mr-4 h-5 w-5" />
      <span>{t("Aktualisieren")}</span>
    </Button>
  );
}

export function DownloadButton({
  offerId,
  document: documentType,
  text,
}: {
  offerId: string;
  document: OfferDocument;
  text: string;
}) {
  const [download, { isLoading, error, data, reset }] =
    useVbsOfferDownloadPDFMutation();
  const { user } = useAuth();
  const { toast } = useToast();

  useDownloadPdf(data, reset);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [toast, error]);

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: offerId,
      document: documentType,
      userId: user?.id ?? "",
    });
  };

  return (
    <Button variant="outline" className="justify-start" onClick={doDownload}>
      {isLoading ? (
        <Download className="mr-4 h-5 w-5 animate-bounce" />
      ) : (
        <Download className="mr-4 h-5 w-5" />
      )}
      <span>{text}</span>
    </Button>
  );
}

export function DownloadContractButton({
  contractId,
  document: documentType,
  text,
}: {
  contractId: string;
  document: ContractDocument;
  text: string;
}) {
  const [download, { isLoading, error, data, reset }] =
    useVbsContractDownloadPDFMutation();
  const { user } = useAuth();
  const { toast } = useToast();

  useDownloadPdf(data, reset);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [toast, error]);

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: contractId,
      document: documentType,
      downloadBy: user?.id ?? "",
    });
  };

  return (
    <Button
      variant="outline"
      className="justify-start"
      onClick={doDownload}
      disabled={isLoading || isZeroUUID(contractId)}
    >
      {isLoading ? (
        <Download className="mr-4 h-5 w-5 animate-bounce" />
      ) : (
        <Download className="mr-4 h-5 w-5" />
      )}
      <span>{text}</span>
    </Button>
  );
}
