import { Kind } from "@/services/backend/vbs/treatments/component";
import t from "@/lang/lang";
import { useTreatmentComponentState } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state-context";
import { Column } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";

export function KindCell({ kind }: { kind: Kind }) {
  const { state } = useTreatmentComponentState();

  switch (kind) {
    case Kind.ExhaustHoodSystem:
      return (
        <div className="whitespace-nowrap">
          {/* Further, the order of the components is important.
            It must match the order in the FormulaView component. */}
          <div className="flex h-6 items-center">{t(Kind.ExhaustHood)}</div>
          <div className="flex h-6 items-center">
            {t(Kind.AerosolSeparator)}
          </div>
          <div className="flex h-6 items-center">
            {t(Kind.AluminiumMeshGreaseFilter)}
          </div>
          <div className="flex h-6 items-center">{t(Kind.Lighting)}</div>
          <div className="flex h-6 items-center">{t(Kind.DummyPlate)}</div>
          {state.columns.includes(Column.CleanTime) && (
            <div className="mt-2 flex h-6 items-center font-bold">
              {t("Gesamt")}
            </div>
          )}
        </div>
      );
    case Kind.VentilatedCeilingSystem:
      return (
        <div className="whitespace-nowrap">
          <div className="flex h-6 items-center">
            {t(Kind.VentilatedCeiling)}
          </div>
          <div className="flex h-6 items-center">
            {t(Kind.AerosolSeparator)}
          </div>
          <div className="flex h-6 items-center">{t(Kind.Lighting)}</div>
          <div className="flex h-6 items-center">{t(Kind.DummyPlate)}</div>
          {state.columns.includes(Column.CleanTime) && (
            <div className="mt-2 flex h-6 items-center font-bold">
              {t("Gesamt")}
            </div>
          )}
        </div>
      );
    default:
      return (
        <div className="flex h-6 items-center font-bold whitespace-nowrap">
          {t(kind)}
        </div>
      );
  }
}
