import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { TimeNumberInput } from "@/routes/gemex/processes/_components/ui/time-number-input";
import {
  FloatNumberInput,
  IntNumberInput,
} from "@/routes/gemex/processes/_components/ui/number-input";
import {
  useQpmDefaultsGetQuery,
  useQpmDefaultsPutMutation,
} from "@/services/backend/qpm/base-data/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  PutDefaultsRequest,
  QpmDefaultsData,
} from "@/services/backend/qpm/base-data/types";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";

export function CalculationParametersRoutePage() {
  const {
    data: defaults,
    isLoading,
    error,
  } = useQpmDefaultsGetQuery({ key: "Calculation" });

  if (isLoading) {
    return <Skeleton className="h-36 w-full" />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader>
        <div className="flex items-end justify-between">
          <CardTitle>{t("Kalkulation Parameter")}</CardTitle>
          <PersistenceStateUsingContext />
        </div>
        <CardDescription>
          <p>
            {t("Sie können hier die Parameter der Kalkulation bearbeiten.")}
          </p>
        </CardDescription>
      </CardHeader>
      <CardContent>
        {isLoading && <Skeleton className="h-36 w-full" />}
        {!isLoading && defaults && (
          <ParameterCardContent data={defaults.data} />
        )}
      </CardContent>
    </Card>
  );
}

type ParameterCardContentProps = {
  data: QpmDefaultsData;
};

function ParameterCardContent({ data }: ParameterCardContentProps) {
  const [request, setRequest] = useState<PutDefaultsRequest>({
    key: "Calculation",
    data,
  });

  const [put, { error, isLoading, isSuccess, reset }] =
    useQpmDefaultsPutMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <div className="grid grid-cols-1 gap-4 lg:grid-cols-2">
      <div className="flex flex-col gap-2">
        <CardTitle>{t("Installationszeiten für Monitore")}</CardTitle>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Installationszeit QPM Monitore")}</Label>
          <TimeNumberInput
            value={data.serviceTimeInstallationPerMonitorQPM}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  serviceTimeInstallationPerMonitorQPM: val,
                },
              }))
            }
          />
        </div>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Installationszeit SbD Monitore")}</Label>
          <TimeNumberInput
            value={data.serviceTimeInstallationPerMonitorSCB}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  serviceTimeInstallationPerMonitorSCB: val,
                },
              }))
            }
          />
        </div>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Installationszeit FIV")}</Label>
          <TimeNumberInput
            value={data.serviceTimeInstallationPerMonitorFIV}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  serviceTimeInstallationPerMonitorFIV: val,
                },
              }))
            }
          />
        </div>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Installationszeit ALoRa Monitore")}</Label>
          <TimeNumberInput
            value={data.serviceTimeInstallationPerMonitorALoRa}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  serviceTimeInstallationPerMonitorALoRa: val,
                },
              }))
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <CardTitle>{t("Servicezeiten für Monitore")}</CardTitle>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Servicezeit QPM Monitore")}</Label>
          <TimeNumberInput
            value={data.serviceTimeMaintenancePerMonitorQPM}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  serviceTimeMaintenancePerMonitorQPM: val,
                },
              }))
            }
          />
        </div>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Servicezeit SbD Monitore")}</Label>
          <TimeNumberInput
            value={data.serviceTimeMaintenancePerMonitorSCB}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  serviceTimeMaintenancePerMonitorSCB: val,
                },
              }))
            }
          />
        </div>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Servicezeit FIV")}</Label>
          <TimeNumberInput
            value={data.serviceTimeMaintenancePerMonitorFIV}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  serviceTimeMaintenancePerMonitorFIV: val,
                },
              }))
            }
          />
        </div>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Servicezeit ALoRa Monitore")}</Label>
          <TimeNumberInput
            value={data.serviceTimeMaintenancePerMonitorALoRa}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  serviceTimeMaintenancePerMonitorALoRa: val,
                },
              }))
            }
          />
        </div>
      </div>
      <div className="flex flex-col gap-2">
        <CardTitle>{t("Preisberechnung")}</CardTitle>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Zielumsatz pro Stunde")}</Label>
          <FloatNumberInput
            value={data.targetRevenue}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  targetRevenue: val,
                },
              }))
            }
          />
        </div>
        <div className="grid grid-cols-2 items-center gap-2">
          <Label>{t("Standardmietdauer in Monaten")}</Label>
          <IntNumberInput
            value={data.rentingDurationMonths}
            onChange={(val) =>
              setRequest((prev) => ({
                key: prev.key,
                data: {
                  ...prev.data,
                  rentingDurationMonths: val,
                },
              }))
            }
          />
        </div>
      </div>
    </div>
  );
}
