import {
  ConfirmationDocument,
  useVbsConfirmationPreviewPDFQuery,
  useVbsConfirmationShowQuery,
  useVbsConfirmationUpdatePostalAddressMutation,
  useVbsConfirmationUpdateRepresentativeMutation,
  useVbsConfirmationUpdateServiceAddressMutation,
} from "@/services/backend/vbs/confirmations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsHeader,
  ArtefactSheetContent,
  ArtefactSheetContextProvider,
  ArtefactSheetHeader,
  ArtefactSheetMain,
  ArtefactSheetPreview,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
  useArtefactSheetContext,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import t from "@/lang/lang";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { TreatmentComponent } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component";
import { Column } from "@/routes/gesec/processes/[processId]/vbs/_shared/treatment/treatment-component-state";
import { CalculationComponent } from "@/routes/gesec/processes/[processId]/vbs/calculations/[calculationId]/calculation/calculation-route";
import { ServiceAddressCardWithMutation } from "@/shared/components/domain/addresses/service-address-card";
import { RepresentativeCardWithMutation } from "@/shared/components/domain/representatives/representative-card";
import { Button } from "@/shared/components/ui/button";
import { Confirmation } from "@/services/backend/vbs/confirmations/confirmation";
import {
  ArtefactActionsDownload,
  ArtefactActionsHeader,
} from "@/shared/service-manager/artefact/artefact-immutable-page";
import { DownloadButton } from "@/routes/gesec/processes/[processId]/vbs/confirmations/[confirmationId]/_components/confirmation-immutable-page";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { NavigateToArtefactButton } from "@/shared/components/domain/artefact/preview/_components/navigate-to-artefact-button";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { RefreshCw } from "lucide-react";
import { ConfirmationDataView } from "@/routes/gesec/processes/[processId]/vbs/confirmations/[confirmationId]/data/confirmation-data-route";
import {
  ArtefactPage,
  ArtefactPageContent,
  ArtefactPageHeader,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";
import { PostalAddressCardWithMutation } from "@/shared/components/domain/addresses/postal-address-card";

export function VbsConfirmationPreview({
  confirmationId,
}: {
  confirmationId: string;
}) {
  const {
    data: confirmation,
    isLoading,
    error,
  } = useVbsConfirmationShowQuery({ id: confirmationId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <RefreshCw className="h-4 w-4" />;
  }

  return (
    <ArtefactSheetContextProvider>
      <ConfirmationArtefactSheet confirmation={confirmation!} />
    </ArtefactSheetContextProvider>
  );
}

function ConfirmationArtefactSheet({
  confirmation,
}: {
  confirmation: Confirmation;
}) {
  const { open, setOpen } = useArtefactSheetContext();

  return (
    <ArtefactSheet open={open} onOpenChange={setOpen}>
      <ArtefactSheetTrigger asChild>
        <Button variant="ghost" size="sm">
          {t("Vorschau")}
        </Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>
            {t(confirmation!.artefactKind)} {t("Vorschau")}
          </ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          {confirmation!.immutable ? (
            <ImmutableConfirmationPreview confirmation={confirmation!} />
          ) : (
            <MutableConfirmationPreview confirmation={confirmation!} />
          )}
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function ImmutableConfirmationPreview({
  confirmation,
}: {
  confirmation: Confirmation;
}) {
  const { open } = useArtefactSheetContext();

  const { data, isLoading, error } = useVbsConfirmationPreviewPDFQuery(
    {
      id: confirmation.id,
      document: ConfirmationDocument.All,
    },
    {
      // This prevents request to the backend if the artefact sheet has
      // not been opened. Otherwise, whenever the preview component
      // is used, the PDF would be preloaded (and often unnecessarily so).
      skip: !open,
    },
  );

  return (
    <>
      <ArtefactSheetPreview
        isLoading={isLoading}
        objectURL={data?.objectURL}
        error={error}
      />
      <ImmutableConfirmationActions confirmation={confirmation} />
    </>
  );
}

function ImmutableConfirmationActions({
  confirmation,
}: {
  confirmation: Confirmation;
}) {
  return (
    <ArtefactSheetActions>
      <ArtefactActionsHeader
        title={t(confirmation.artefactKind)}
        artefactNumber={<ArtefactNumberById artefactId={confirmation.id} />}
      />
      <ArtefactSheetActionsContent>
        <ArtefactActionsDownload>
          <DownloadButton
            confirmationId={confirmation.id}
            document={ConfirmationDocument.All}
            text={t("Alle Dokumente")}
          />
          <DownloadButton
            confirmationId={confirmation.id}
            document={ConfirmationDocument.Letter}
            text={t("Angebot")}
          />
          <DownloadButton
            confirmationId={confirmation.id}
            document={ConfirmationDocument.Treatment}
            text={t("Leistungsverzeichnis")}
          />
        </ArtefactActionsDownload>
      </ArtefactSheetActionsContent>
    </ArtefactSheetActions>
  );
}

function MutableConfirmationPreview({
  confirmation,
}: {
  confirmation: Confirmation;
}) {
  return (
    <>
      <MutableConfirmationView confirmation={confirmation} />
      <MutableConfirmationActions confirmation={confirmation} />
    </>
  );
}

function MutableConfirmationView({
  confirmation,
}: {
  confirmation: Confirmation;
}) {
  return (
    <div className="md:col-span-4">
      <PersistenceContextProvider>
        <ArtefactPage>
          <Tabs defaultValue="data">
            <ArtefactPageHeader>
              <TabsList>
                <TabsTrigger value="data">{t("Bestätigung")}</TabsTrigger>
                <TabsTrigger value="treatment">{t("Leistungen")}</TabsTrigger>
                <TabsTrigger value="calculation">
                  {t("Kalkulation")}
                </TabsTrigger>
                <TabsTrigger value="customer-address">
                  {t("Anschrift")}
                </TabsTrigger>
                <TabsTrigger value="representative">
                  {t("Betreuer")}
                </TabsTrigger>
              </TabsList>
              <PersistenceStateUsingContext className="mr-2 ml-auto" />
            </ArtefactPageHeader>
            <ArtefactPageContent className="max-h-[75vh] overflow-auto">
              <TabsContent value="data">
                <ConfirmationDataView confirmation={confirmation} />
              </TabsContent>
              <TabsContent value="treatment">
                <TreatmentComponent
                  treatmentId={confirmation.treatmentId}
                  availableColumns={[
                    Column.Position,
                    Column.ComponentKind,
                    Column.ComponentData,
                    Column.Cycle,
                    Column.Formula,
                    Column.CleanTime,
                    Column.BasePrice,
                    Column.TipCommission,
                    Column.OfferPrice,
                    Column.Comment,
                    Column.Actions,
                  ]}
                  defaultColumns={[
                    Column.Position,
                    Column.ComponentKind,
                    Column.BasePrice,
                    Column.TipCommission,
                    Column.OfferPrice,
                    Column.Comment,
                    Column.Actions,
                  ]}
                />
              </TabsContent>
              <TabsContent value="calculation">
                <CalculationComponent
                  calculationId={confirmation.calculationId}
                />
              </TabsContent>
              <TabsContent value="customer-address">
                <div className="space-y-4">
                  <PostalAddressCardWithMutation
                    id={confirmation!.id}
                    address={confirmation!.postalAddress}
                    mutation={useVbsConfirmationUpdatePostalAddressMutation}
                  />
                  <ServiceAddressCardWithMutation
                    id={confirmation!.id}
                    address={confirmation!.serviceAddress}
                    mutation={useVbsConfirmationUpdateServiceAddressMutation}
                  />
                </div>
              </TabsContent>
              <TabsContent value="representative">
                <RepresentativeCardWithMutation
                  id={confirmation!.id}
                  representative={confirmation!.representative}
                  mutation={useVbsConfirmationUpdateRepresentativeMutation}
                />
              </TabsContent>
            </ArtefactPageContent>
          </Tabs>
        </ArtefactPage>
      </PersistenceContextProvider>
    </div>
  );
}

function MutableConfirmationActions({
  confirmation,
}: {
  confirmation: Confirmation;
}) {
  return (
    <ArtefactSheetActions className="md:col-span-1">
      <ArtefactSheetActionsHeader
        title={t(confirmation.artefactKind)}
        artefactNumber={<ArtefactNumberById artefactId={confirmation.id} />}
      />
      <ArtefactSheetActionsContent>
        <NavigateToArtefactButton
          artefactId={confirmation.id}
          artefactKind={confirmation.artefactKind}
          processId={confirmation.processId}
          processKind={ProcessKind.VBS}
          companyId={confirmation.companyId}
        />
      </ArtefactSheetActionsContent>
    </ArtefactSheetActions>
  );
}
