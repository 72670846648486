import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import {
  useQpmMonitorTypeListQuery,
  useQpmMonitorTypePutMutation,
} from "@/services/backend/qpm/monitors/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { useCallback, useEffect, useState } from "react";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  MonitorType,
  PutMonitorTypeRequest,
} from "@/services/backend/qpm/monitors/types";
import { v4 } from "uuid";
import { usePersistenceStateUpdateOnTrigger } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { Button } from "@/shared/components/ui/button";
import { Pencil, Save, X } from "lucide-react";
import { MonitorTypeUtil } from "@/services/backend/qpm/shared/monitorTypeUtil";
import { Input } from "@/shared/components/ui/input";
import { TimeNumberInput } from "@/routes/gemex/processes/_components/ui/time-number-input";

export function MonitorTypeCard({ catalogId }: { catalogId: string }) {
  const {
    data: list,
    error,
    isLoading,
  } = useQpmMonitorTypeListQuery({
    catalog: { active: true, values: [catalogId] },
  });

  const findType = useCallback(
    (type: MonitorTypeKey): MonitorType | undefined =>
      list?.types.find((tp) => tp.key === type),
    [list],
  );

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="py-4">
      <CardHeader>
        <CardTitle>{t("Monitor Arten")}</CardTitle>
        <CardDescription>
          {t("Hier können Sie die verschiedenen Monitorarten bearbeiten.")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        {isLoading && <Skeleton className="h-20 w-full" />}
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-[100px]">{t("Kennung")}</TableHead>
              <TableHead>{t("Name")}</TableHead>
              <TableHead>{t("Beschreibung")}</TableHead>
              <TableHead className="text-center">
                {t("Installationszeit")}
              </TableHead>
              <TableHead className="text-center">{t("Servicezeit")}</TableHead>
              <TableHead className="text-right">{t("Aktion")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TypeComponent
              catalogId={catalogId}
              typeKey={MonitorTypeKey.CheckPoint}
              type={findType(MonitorTypeKey.CheckPoint)}
            />
            <TypeComponent
              catalogId={catalogId}
              typeKey={MonitorTypeKey.FlyExterminator}
              type={findType(MonitorTypeKey.FlyExterminator)}
            />
            <TypeComponent
              catalogId={catalogId}
              typeKey={MonitorTypeKey.ALoRa}
              type={findType(MonitorTypeKey.ALoRa)}
            />
          </TableBody>
        </Table>
      </CardContent>
    </div>
  );
}

type TypeComponentProps = {
  catalogId: string;
  typeKey: MonitorTypeKey;
  type?: MonitorType;
};

function TypeComponent({ catalogId, typeKey, type }: TypeComponentProps) {
  const [isEditMode, setEditMode] = useState(!type);
  const [request, setRequest] = useState<PutMonitorTypeRequest>({
    id: type ? type.id : v4(),
    catalogId,
    key: type ? type.key : typeKey,
    name: type ? type.name : "",
    description: type ? type.description : "",
    installationTime: type ? type.installationTime : 0,
    serviceTime: type ? type.serviceTime : 0,
  });

  const [put, { error, isLoading, isSuccess, reset }] =
    useQpmMonitorTypePutMutation();

  const { onTrigger: triggerSave } = usePersistenceStateUpdateOnTrigger(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setEditMode(false);
    }
  }, [isSuccess]);

  useEffect(() => {
    if (type) {
      setEditMode(false);
      setRequest({ ...type });
    }
  }, [type]);

  if (isEditMode) {
    return (
      <TableRow>
        <TableCell className="py-1.5">
          {MonitorTypeUtil.toString(typeKey)}
        </TableCell>
        <TableCell className="py-1.5">
          {MonitorTypeUtil.toLongString(typeKey)}
        </TableCell>
        <TableCell className="py-1.5">
          <Input
            type="text"
            placeholder={t("Beschreibung")}
            value={request.description}
            onChange={(e) =>
              setRequest({ ...request, description: e.target.value })
            }
          />
        </TableCell>
        <TableCell className="py-1.5">
          <TimeNumberInput
            className="justify-self-center"
            value={request.installationTime}
            onChange={(val) =>
              setRequest({ ...request, installationTime: val })
            }
          />
        </TableCell>
        <TableCell className="py-1.5">
          <TimeNumberInput
            className="justify-self-center"
            value={request.serviceTime}
            onChange={(val) => setRequest({ ...request, serviceTime: val })}
          />
        </TableCell>
        <TableCell className="py-1.5 text-end">
          <div className="flex items-center justify-end">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="mr-1"
                    size="sm"
                    variant="outline"
                    onClick={() => {
                      setEditMode(false);
                    }}
                  >
                    <X className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("Abbrechen")}</p>
                </TooltipContent>
              </Tooltip>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button size="sm" variant="default" onClick={triggerSave}>
                    <Save className="h-4 w-4" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  <p>{t("Speichern")}</p>
                </TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </div>
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell className="py-1.5">
        {MonitorTypeUtil.toString(typeKey)}
      </TableCell>
      <TableCell className="py-1.5">
        {MonitorTypeUtil.toLongString(typeKey)}
      </TableCell>
      <TableCell className="py-1.5">{request.description}</TableCell>
      <TableCell className="py-1.5">
        <TimeNumberInput
          className="justify-self-center"
          value={request.installationTime}
          onChange={(val) => setRequest({ ...request, installationTime: val })}
          disabled
        />
      </TableCell>
      <TableCell className="py-1.5">
        <TimeNumberInput
          className="justify-self-center"
          value={request.serviceTime}
          onChange={(val) => setRequest({ ...request, serviceTime: val })}
          disabled
        />
      </TableCell>
      <TableCell className="py-1.5 text-end">
        <div className="flex items-center justify-end">
          <TooltipProvider>
            <Tooltip>
              <TooltipTrigger asChild>
                <Button
                  size="sm"
                  variant="outline"
                  onClick={() => {
                    setEditMode(true);
                  }}
                >
                  <Pencil className="h-4 w-4" />
                </Button>
              </TooltipTrigger>
              <TooltipContent>
                <p>{t("Bearbeiten")}</p>
              </TooltipContent>
            </Tooltip>
          </TooltipProvider>
        </div>
      </TableCell>
    </TableRow>
  );
}
