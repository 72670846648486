import { ProcessesTable } from "@/routes/gesec/processes/_components/processes-table";
import { MainPageContent } from "@/routes/_components/main-page";

export default function ProcessesRoute() {
  return (
    <MainPageContent>
      <ProcessesTable />
    </MainPageContent>
  );
}
