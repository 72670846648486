import { PestCategoryKey } from "@/services/backend/qpm/shared/enums";

export class PestCategoryUtil {
  private static descriptions: { [key in PestCategoryKey]: string } = {
    [PestCategoryKey.GermanRoach]: "deutsche Schabe",
    [PestCategoryKey.OrientalRoach]: "orientalische Schabe",
    [PestCategoryKey.Mouse]: "Befall von Mäusen",
    [PestCategoryKey.Rat]: "Befall von Ratten",
    [PestCategoryKey.Moth]: "Befall von Motten",
    [PestCategoryKey.RiceWeevil]: "Befall von Reismehlkäfer",
    [PestCategoryKey.Fly]: "Befall von Fliegen",
    [PestCategoryKey.Pheromone]: "Pheromone im Einsatz",
    [PestCategoryKey.Ant]: "Befall von Ameisen",
    [PestCategoryKey.Bird]: "Vogel-/ Taubenabwehr",
  };

  private static abbreviations: { [key in PestCategoryKey]: string } = {
    [PestCategoryKey.GermanRoach]: "dt. Schabe",
    [PestCategoryKey.OrientalRoach]: "orient. Schabe",
    [PestCategoryKey.Mouse]: "Mäuse",
    [PestCategoryKey.Rat]: "Ratten",
    [PestCategoryKey.Moth]: "Motten",
    [PestCategoryKey.RiceWeevil]: "Reismehlkäfer",
    [PestCategoryKey.Fly]: "Fliegen",
    [PestCategoryKey.Pheromone]: "Pheromone",
    [PestCategoryKey.Ant]: "Ameisen",
    [PestCategoryKey.Bird]: "Vogel-/ Taubenabwehr",
  };

  static toString(type: PestCategoryKey): string {
    return this.abbreviations[type];
  }

  static toLongString(type: PestCategoryKey): string {
    return this.descriptions[type] || "Unknown";
  }
}
