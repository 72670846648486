import { useEffect, useState } from "react";
import { NavLink, useNavigate } from "react-router";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Plus, Search } from "lucide-react";
import t from "@/lang/lang";
import { Tabs, TabsList, TabsTrigger } from "@/shared/components/ui/tabs";
import { v4 } from "uuid";
import {
  Card,
  CardContent,
  CardDescription,
  CardFooter,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { FilterMode } from "@/shared/lib/filter/filter";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { ProcessTopic } from "@/shared/components/domain/process-topic/process-topic";
import {
  useQpmOfferCreateMutation,
  useQpmOfferListQuery,
} from "@/services/backend/qpm/offer/service";
import { TabsContent } from "@radix-ui/react-tabs";
import { CreateFromCalculation } from "@/routes/gemex/processes/qpm/offers/_components/create-from-calculation";

export type CreateOfferDialogProps = {
  processId: string;
};

export function CreateOfferDialog({ processId }: CreateOfferDialogProps) {
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();

  const closeDialogAndNavigate = (offerId: string) => {
    setOpen(false);
    navigate(offerId);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button>
          <Plus className="mr-2 h-5 w-5" />
          <span>{t("Angebot")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] min-w-[80vw] overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>{t("Neues Angebot erstellen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Angebote können entweder komplett neu, auf Basis einer Kalkulation oder auf Basis eines alten Angebots erstellt werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <Tabs defaultValue="manual">
          <TabsList className="grid w-full grid-cols-3">
            <TabsTrigger value="manual">{t("Neu Anlegen")}</TabsTrigger>
            <TabsTrigger value="from-calculation">
              {t("Kalkulationen")}
            </TabsTrigger>
            <TabsTrigger value="from-old">{t("Alte Angebote")}</TabsTrigger>
          </TabsList>
          <TabsContent value="manual">
            <CreateManually
              processId={processId}
              onCreated={closeDialogAndNavigate}
            />
          </TabsContent>
          <TabsContent value="from-calculation">
            <CreateFromCalculation
              processId={processId}
              onCreated={closeDialogAndNavigate}
            />
          </TabsContent>
          <TabsContent value="from-old">
            <CreateFromOld
              processId={processId}
              onCreated={closeDialogAndNavigate}
            />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}

function CreateManually({
  processId,
  onCreated,
}: {
  processId: string;
  onCreated: (offerId: string) => void;
}) {
  const [create, { isLoading, error, data }] = useQpmOfferCreateMutation();

  useEffect(() => {
    if (data) {
      onCreated(data.artefactData.id);
    }
  }, [data, onCreated]);

  const onClick = () => {
    if (isLoading) {
      return;
    }
    const id = v4();
    create({
      artefactMetaId: id,
      processId,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Neu Anlegen")}</CardTitle>
        <CardDescription>
          {t(
            "Erstellt eine Kalkulation mit den Basisdaten des Prozesses und des Kunden.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <RTKQueryErrorAlert error={error} />
      </CardContent>
      <CardFooter className="justify-start">
        <Button onClick={onClick} disabled={isLoading}>
          {t("Erstellen")}
        </Button>
      </CardFooter>
    </Card>
  );
}

function CreateFromOld(props: {
  processId: string;
  onCreated: (offerId: string) => void;
}) {
  const { processId, onCreated } = props;

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Auf Basis alter Kalkulation")}</CardTitle>
        <CardDescription>
          {t(
            "Kopiert die Daten der alten Kalkulation. Es werden nur Kalkulationen von abgeschlossenen Vorgängen angezeigt.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <OldOffersTable processId={processId} onCreated={onCreated} />
      </CardContent>
    </Card>
  );
}

function OldOffersTable(props: {
  processId: string;
  onCreated: (offerId: string) => void;
}) {
  const { processId, onCreated } = props;
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  const {
    data: list,
    isLoading,
    error,
  } = useQpmOfferListQuery({
    ownerType: {
      active: false,
      values: [],
    },
    process: {
      active: false,
      values: [],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: true,
      values: [process?.customerId || ""],
    },
    immutable: {
      active: false,
      values: [true],
    },
    parent: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead>{t("Datum")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead className="w-full">{t("Vorgang Thema")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead className="w-full">{t("Vorgang Thema")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {list?.artefacts?.map((artefact) => (
          <TableRow key={artefact.id}>
            <TableCell>
              {new Date(artefact.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell>
              <ArtefactNumberById artefactId={artefact.id} />
            </TableCell>
            <TableCell>
              <ProcessTopic processId={processId} />
            </TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavigateToOffer
                  currentProcessId={processId}
                  offerProcessId={artefact.processId}
                  offerId={artefact.id}
                />
                <CopyOfferButton offerId={artefact.id} onCopy={onCreated} />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function NavigateToOffer(props: {
  currentProcessId: string;
  offerProcessId: string;
  offerId: string;
}) {
  const { currentProcessId, offerProcessId, offerId } = props;

  // If the offer is part of the same process,
  // simply navigate to the offer
  if (currentProcessId === offerProcessId) {
    return (
      <NavLink to={offerId}>
        <Button variant="ghost">
          <Search className="h-4 w-4" />
        </Button>
      </NavLink>
    );
  }

  // If the offer is part of a different process,
  // show a warning and then navigate to the offer
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="ghost">
          <Search className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Achtung")}</DialogTitle>
        </DialogHeader>
        <DialogDescription>
          {t(
            "Dieses Angebot ist Teil eines anderen Vorgangs. Sie müssen nachdem Sie das Angebot angesehen haben zum aktuellen Vorgang zurückkehren.",
          )}
        </DialogDescription>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <NavLink to={`/processes/qpm/${offerProcessId}/offers/${offerId}`}>
            <Button>{t("Vorgang wechseln")}</Button>
          </NavLink>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export type CopyOfferButtonProps = {
  offerId: string;
  onCopy: (offerId: string) => void;
};

export function CopyOfferButton({ offerId, onCopy }: CopyOfferButtonProps) {
  return <Button onClick={() => onCopy(offerId)}>NOT IMPLEMENTED</Button>;
}
