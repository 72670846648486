import { backendApi } from "@/services/backend/api";
import { Component, Kind } from "@/services/backend/rlt/treatments/component";
import { SegmentKind } from "@/services/backend/rlt/treatments/segmentKind";
import { Treatment } from "@/services/backend/rlt/treatments/treatment";
import { Filter } from "@/shared/lib/filter/filter";

const treatmentApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltTreatmentCreate: build.mutation<Treatment, CreateRequest>({
      query: (request) => ({
        url: "/rlt/treatment",
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "RltTreatment", id: "LIST" }],
    }),
    rltTreatmentCreateFromOld: build.mutation<Treatment, CreateFromOldRequest>({
      query: (request) => ({
        url: "/rlt/treatment/create-from-old",
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "RltTreatment", id: "LIST" }],
    }),
    rltTreatmentShow: build.query<Treatment, ShowRequest>({
      query: (request) => ({
        url: `/rlt/treatment`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltTreatment", id: result.id }] : [],
    }),
    rltTreatmentList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: "/rlt/treatment/list",
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "RltTreatment", id: "LIST" }] : [],
    }),
    rltTreatmentListAvailableComponentKinds: build.query<
      AvailableComponentKinds,
      ListAvailableComponentKindsRequest
    >({
      query: (request) => ({
        url: "/rlt/treatment/list-available-component-kinds",
        method: "GET",
        params: request,
      }),
    }),
    rltTreatmentCopyBaseData: build.mutation<Treatment, CopyBaseDataRequest>({
      query: (request) => ({
        url: "/rlt/treatment/copy-base-data",
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.destinationId },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentAddArea: build.mutation<Treatment, AddAreaRequest>({
      query: (request) => ({
        url: `/rlt/treatment/area`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentRenameArea: build.mutation<Treatment, RenameAreaRequest>({
      query: (request) => ({
        url: `/rlt/treatment/area`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentDeleteArea: build.mutation<Treatment, DeleteAreaRequest>({
      query: (request) => ({
        url: `/rlt/treatment/area`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentAddPosition: build.mutation<Treatment, AddPositionRequest>({
      query: (request) => ({
        url: `/rlt/treatment/position`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentCopyPosition: build.mutation<Treatment, CopyPositionRequest>({
      query: (request) => ({
        url: `/rlt/treatment/position/copy`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentDeletePosition: build.mutation<
      Treatment,
      DeletePositionRequest
    >({
      query: (request) => ({
        url: `/rlt/treatment/position`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentUpdateComponent: build.mutation<
      Treatment,
      UpdateComponentRequest
    >({
      query: (request) => ({
        url: `/rlt/treatment/position/component`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentUpdateCycle: build.mutation<Treatment, UpdateCycleRequest>({
      query: (request) => ({
        url: `/rlt/treatment/position/cycle`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentUpdateFormula: build.mutation<Treatment, UpdateFormulaRequest>({
      query: (request) => ({
        url: `/rlt/treatment/position/formula`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentUpdateComment: build.mutation<Treatment, UpdateCommentRequest>({
      query: (request) => ({
        url: `/rlt/treatment/position/comment`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentUpdateTipCommission: build.mutation<
      Treatment,
      UpdateTipCommissionRequest
    >({
      query: (request) => ({
        url: `/rlt/treatment/position/tip-commission`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentUpdateTipCommissionAll: build.mutation<
      Treatment,
      UpdateTipCommissionAllRequest
    >({
      query: (request) => ({
        url: `/rlt/treatment/tip-commission-all`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: () => ["RltTreatment", "RltCalculation", "RltOffer"],
    }),
    rltTreatmentUpdateCycleDiscount: build.mutation<
      Treatment,
      UpdateCycleDiscountRequest
    >({
      query: (request) => ({
        url: `/rlt/treatment/position/cycle-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentUpdatePositionCompletion: build.mutation<
      Treatment,
      UpdatePositionCompletion
    >({
      query: (request) => ({
        url: `/rlt/treatment/position/completion`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
      ],
    }),
    rltTreatmentUpdateActuallyTreated: build.mutation<
      Treatment,
      UpdateActuallyTreatedRequest
    >({
      query: (request) => ({
        url: `/rlt/treatment/position/actually-treated`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentSetImmutable: build.mutation<Treatment, SetImmutableRequest>({
      query: (request) => ({
        url: `/rlt/treatment/immutable`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
    rltTreatmentDelete: build.mutation<Treatment, DeleteRequest>({
      query: (request) => ({
        url: `/rlt/treatment}`,
        method: "DELETE",
        body: request,
      }),
      invalidatesTags: (_result, _error, request) => [
        { type: "RltTreatment", id: "LIST" },
        { type: "RltTreatment", id: request.id },
        "RltCalculation",
        "RltOffer",
        "RltConfirmation",
        "RltContract",
      ],
    }),
  }),
});

export const {
  useRltTreatmentCreateMutation,
  useRltTreatmentCreateFromOldMutation,
  useRltTreatmentShowQuery,
  useRltTreatmentListQuery,
  useRltTreatmentListAvailableComponentKindsQuery,
  useRltTreatmentCopyBaseDataMutation,
  useRltTreatmentAddAreaMutation,
  useRltTreatmentRenameAreaMutation,
  useRltTreatmentDeleteAreaMutation,
  useRltTreatmentAddPositionMutation,
  useRltTreatmentCopyPositionMutation,
  useRltTreatmentDeletePositionMutation,
  useRltTreatmentUpdateComponentMutation,
  useRltTreatmentUpdateCycleMutation,
  useRltTreatmentUpdateFormulaMutation,
  useRltTreatmentUpdateCommentMutation,
  useRltTreatmentUpdateTipCommissionMutation,
  useRltTreatmentUpdateTipCommissionAllMutation,
  useRltTreatmentUpdateCycleDiscountMutation,
  useRltTreatmentUpdatePositionCompletionMutation,
  useRltTreatmentUpdateActuallyTreatedMutation,
  useRltTreatmentSetImmutableMutation,
  useRltTreatmentDeleteMutation,
} = treatmentApi;

export interface CreateRequest {
  id: string;
  processId: string;
  companyId: string;
  branchId: string;
  customerId: string;
  ownerType: string;
  ownerId: string;
}

export interface CreateFromOldRequest {
  oldId: string;
  id: string;
  processId: string;
  companyId: string;
  branchId: string;
  customerId: string;
  ownerType: string;
  ownerId: string;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  branch?: Filter<string>;
  process?: Filter<string>;
  ownerType?: Filter<string>;
  owner?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface List {
  treatments: Treatment[];
  count: number;
}

export interface ListAvailableComponentKindsRequest {}

export interface AvailableComponentKinds {
  [segmentKind: string]: Kind[];
}

export interface CopyBaseDataRequest {
  destinationId: string;
  sourceId: string;
}

export interface AddAreaRequest {
  id: string;
  segment: SegmentKind;
  area: string;
}

export interface RenameAreaRequest {
  id: string;
  segment: SegmentKind;
  old: string;
  new: string;
}

export interface DeleteAreaRequest {
  id: string;
  segment: SegmentKind;
  area: string;
}

export interface AddPositionRequest {
  id: string;
  positionId: string;
  parentId?: string;
  segment: SegmentKind;
  area: string;
  componentKind: Kind;
}

export interface CopyPositionRequest {
  id: string;
  oldPositionId: string;
  newPositionId: string;
  newArea: string;
}

export interface DeletePositionRequest {
  id: string;
  positionId: string;
}

export interface UpdateComponentRequest {
  id: string;
  positionId: string;
  component: Component;
}

export interface UpdateCycleRequest {
  id: string;
  positionId: string;
  cycle: number;
}

export interface UpdateFormulaRequest {
  id: string;
  positionId: string;
  component: Component;
}

export interface UpdateCommentRequest {
  id: string;
  positionId: string;
  comment: string;
}

export interface UpdateTipCommissionRequest {
  id: string;
  positionId: string;
  rate: number;
}

export interface UpdateTipCommissionAllRequest {
  id: string;
  rate: number;
}

export interface UpdateCycleDiscountRequest {
  id: string;
  positionId: string;
  discount: number;
}

export interface UpdateActuallyTreatedRequest {
  id: string;
  positionId: string;
  component: Component;
}

export interface UpdatePositionCompletion {
  id: string;
  positionId: string;
  completed: boolean;
  forceCompletion: boolean;
}

export interface SetImmutableRequest {
  id: string;
  reason: string;
}

export interface DeleteRequest {
  id: string;
}
