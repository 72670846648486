import t from "@/lang/lang";
import { ProcessListTable } from "@/routes/gemex/processes/components/process-list-table";
import { H1 } from "@/shared/components/ui/typography";
import { MainPageContent } from "@/routes/_components/main-page";

export default function ProcessesRoute() {
  return (
    <MainPageContent>
      <H1 className="mb-4">{t("Vorgänge")}</H1>
      <ProcessListTable />
    </MainPageContent>
  );
}
