import { backendApi } from "@/services/backend/api";
import {
  PutDefaultsRequest,
  QpmDefaults,
  ShowDefaultsRequest,
} from "@/services/backend/qpm/base-data/types";

const qpmBaseDataApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["QpmDefaults"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmDefaultsPut: build.mutation<QpmDefaults, PutDefaultsRequest>({
        query: (request) => ({
          url: `/qpm/defaults`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmDefaults"],
      }),
      qpmDefaultsGet: build.query<QpmDefaults, ShowDefaultsRequest>({
        query: (request) => ({
          url: `/qpm/defaults`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) => (result ? ["QpmDefaults"] : []),
      }),
    }),
  });

export const { useQpmDefaultsPutMutation, useQpmDefaultsGetQuery } =
  qpmBaseDataApi;
