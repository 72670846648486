import { Confirmation } from "@/services/backend/vbs/confirmations/confirmation";
import {
  ArtefactActions,
  ArtefactActionsDownload,
  ArtefactActionsHeader,
  ArtefactImmutablePage,
  ArtefactPreview,
} from "@/shared/service-manager/artefact/artefact-immutable-page";
import t from "@/lang/lang";
import {
  ConfirmationDocument,
  useVbsConfirmationCreateFromOldMutation,
  useVbsConfirmationDownloadPDFMutation,
  useVbsConfirmationPreviewPDFQuery,
} from "@/services/backend/vbs/confirmations/service";
import { ArtefactImmutableAlert } from "@/shared/service-manager/artefact/artefact";
import { Separator } from "@/shared/components/ui/separator";
import { Button } from "@/shared/components/ui/button";
import { Copy, Download } from "lucide-react";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { useDownloadPdf } from "@/shared/lib/utilities/download-pdf";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect, useState } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { v4 } from "uuid";
import { useNavigate } from "react-router";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

export function ConfirmationImmutablePage({
  confirmation,
}: {
  confirmation: Confirmation;
}) {
  const { data, isLoading, error } = useVbsConfirmationPreviewPDFQuery({
    id: confirmation.id,
    document: ConfirmationDocument.All,
  });

  return (
    <ArtefactImmutablePage>
      <ArtefactPreview
        objectURL={data?.objectURL}
        isLoading={isLoading}
        error={error}
      />
      <ArtefactActions>
        <ArtefactActionsHeader
          title={t("Auftragsbestätigung")}
          artefactNumber={<ArtefactNumberById artefactId={confirmation.id} />}
        />
        <ArtefactImmutableAlert artefact={confirmation} />
        <Separator />
        <DuplicateConfirmationButton
          processId={confirmation.processId}
          confirmationId={confirmation.id}
        />
        <div className="text-muted-foreground text-xs">
          {t(
            "Ein Klick auf Aktualisieren erstellt eine neue Auftragsbestätigung und übernimmt die Daten der aktuellen.",
          )}
        </div>
        <Separator />
        <ArtefactActionsDownload>
          <DownloadButton
            confirmationId={confirmation.id}
            document={ConfirmationDocument.All}
            text={t("Alle Dokumente")}
          />
          <DownloadButton
            confirmationId={confirmation.id}
            document={ConfirmationDocument.Letter}
            text={t("Anschreiben")}
          />
          <DownloadButton
            confirmationId={confirmation.id}
            document={ConfirmationDocument.Treatment}
            text={t("Leistungsverzeichnis")}
          />
        </ArtefactActionsDownload>
      </ArtefactActions>
    </ArtefactImmutablePage>
  );
}

function DuplicateConfirmationButton({
  processId,
  confirmationId,
}: {
  processId: string;
  confirmationId: string;
}) {
  const [create, { isLoading, error, isSuccess, reset }] =
    useVbsConfirmationCreateFromOldMutation();
  const { data: process } = useProcessesProcessShowQuery({ id: processId });
  // needed to navigate to the new confirmation
  const [newId, setNewId] = useState(v4());

  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      setNewId(v4());
      reset();
    }
  }, [error, toast, reset]);

  useEffect(() => {
    if (isSuccess) {
      navigate(`../${newId}`);
      setNewId(v4());
      reset();
    }
  }, [isSuccess, navigate, newId, reset]);

  const disabled = !process || isLoading;

  const onClick = () => {
    if (disabled) {
      return;
    }

    create({
      oldConfirmationId: confirmationId,
      id: newId,
      processId: process.id,
      companyId: process.companyId,
      branchId: process.branchId,
      customerId: process.customerId,
    });
  };

  return (
    <Button
      onClick={onClick}
      disabled={disabled}
      className="w-full justify-start"
    >
      <Copy className="mr-4 h-5 w-5" />
      <span>{t("Aktualisieren")}</span>
    </Button>
  );
}

export function DownloadButton({
  confirmationId,
  document: documentType,
  text,
}: {
  confirmationId: string;
  document: ConfirmationDocument;
  text: string;
}) {
  const [download, { isLoading, error, data, reset }] =
    useVbsConfirmationDownloadPDFMutation();
  const { user } = useAuth();
  const { toast } = useToast();

  useDownloadPdf(data, reset);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [toast, error]);

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: confirmationId,
      document: documentType,
      userId: user?.id ?? "",
    });
  };

  return (
    <Button variant="outline" className="justify-start" onClick={doDownload}>
      {isLoading ? (
        <Download className="mr-4 h-5 w-5 animate-bounce" />
      ) : (
        <Download className="mr-4 h-5 w-5" />
      )}
      <span>{text}</span>
    </Button>
  );
}
