import { Button } from "@/shared/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui//dialog";
import { Textarea } from "@/shared/components/ui/textarea";
import { RadioGroup, RadioGroupItem } from "@/shared/components/ui/radio-group";
import { Label } from "@/shared/components/ui/label";
import {
  useQpmRiskLevelRecordListQuery,
  useQpmRiskTypeRecordListQuery,
} from "@/services/backend/qpm/risks/service";
import { useEffect, useMemo, useState } from "react";
import {
  RiskLevel,
  RiskType,
  RiskTypeRecord,
} from "@/services/backend/qpm/risks/types";
import { RiskLevelKey, RiskTypeKey } from "@/services/backend/qpm/shared/enums";
import {
  useQpmRequirementAreaRiskAddMutation,
  useQpmRequirementAreaRiskListQuery,
  useQpmRequirementAreaSetAmountOfMeasuresMutation,
} from "@/services/backend/qpm/requirements/service";
import {
  AddRequirementAreaRiskRequest,
  RequirementArea,
  RequirementAreaRisk,
  RequirementAreaSetAmountOfMeasuresRequest,
} from "@/services/backend/qpm/requirements/types";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RiskTypeUtil } from "@/services/backend/qpm/shared/riskTypeUtil";
import { RiskLevelUtil } from "@/services/backend/qpm/shared/riskLevelUtil";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useParams } from "react-router";
import t from "@/lang/lang";
import { IntNumberInput } from "@/routes/gemex/processes/_components/ui/number-input";

const RISK_TYPE_ORDER = [
  RiskTypeKey.FlyingInsect,
  RiskTypeKey.CrawlingInsect,
  RiskTypeKey.Rodent,
] as const;

const RISK_LEVEL_ORDER = [
  RiskLevelKey.None,
  RiskLevelKey.Low,
  RiskLevelKey.Medium,
  RiskLevelKey.High,
] as const;

const RISK_LEVEL_COLORS: Record<RiskLevelKey, string> = {
  [RiskLevelKey.None]: "",
  [RiskLevelKey.Low]: "text-yellow-300",
  [RiskLevelKey.Medium]: "text-orange-400",
  [RiskLevelKey.High]: "text-red-600",
};

interface RiskAnalysisProps {
  reqArea: RequirementArea;
  requirementId: string;
}

export function RiskAnalysisComponent({
  reqArea,
  requirementId,
}: RiskAnalysisProps) {
  const {
    data: riskTypeData,
    error: riskTypeError,
    isLoading: isLoadingRiskType,
  } = useQpmRiskTypeRecordListQuery({ artefactMetaId: requirementId });

  const {
    data: reqAreaRiskData,
    error: reqAreaRiskError,
    isLoading: isLoadingReqAreaRisk,
  } = useQpmRequirementAreaRiskListQuery({
    artefactMetaId: {
      active: true,
      values: [requirementId],
    },
    areaId: {
      active: true,
      values: [reqArea.area.base.id],
    },
  });

  const riskTypeComponents = useMemo(() => {
    if (!riskTypeData) return null;

    return RISK_TYPE_ORDER.map((riskKey) => {
      const riskType = riskTypeData.types.find((type) => type.key === riskKey);
      if (!riskType) return null;

      const reqAreaRisk = reqAreaRiskData?.risks.find(
        (risk) => risk.riskTypeId === riskType.id,
      );

      const initialRiskData: RequirementAreaRisk = reqAreaRisk || {
        artefactMetaId: requirementId,
        areaId: reqArea.area.base.id,
        riskTypeId: riskType.id,
        riskLevelId: "",
        detection: "",
        recommendation: "",
        measure: "",
      };

      return (
        <RiskTypeComponent
          key={riskKey}
          riskType={riskType}
          initialRiskData={initialRiskData}
        />
      );
    });
  }, [riskTypeData, reqAreaRiskData, requirementId, reqArea.area.base.id]);

  if (riskTypeError) {
    return <RTKQueryErrorAlert error={riskTypeError} />;
  }

  if (reqAreaRiskError) {
    return <RTKQueryErrorAlert error={reqAreaRiskError} />;
  }

  if (!riskTypeData) {
    return <div>{t("Fehler beim Laden der Risikodaten!")}</div>;
  }

  if (isLoadingRiskType || isLoadingReqAreaRisk) {
    return <RiskAnalysisComponentSkeleton area={reqArea} />;
  }

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="h-8" variant="outline">
          {t("Risikobewertung")}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-svh max-w-(--breakpoint-lg) overflow-scroll">
        <PersistenceContextProvider>
          <DialogHeader>
            <div className="flex items-end justify-between">
              <div>
                <DialogTitle>
                  {t("Risikobewertung")} {reqArea.area.base.name}
                </DialogTitle>
                <DialogDescription>
                  {t("Bitte bewerten Sie das Risiko für den Bereich")}{" "}
                  <span className="font-bold">{reqArea.area.base.name}</span>.
                </DialogDescription>
              </div>
              <PersistenceStateUsingContext />
            </div>
          </DialogHeader>
          <RecommendedMeasuresComponent
            reqArea={reqArea}
            requirementId={requirementId}
          />
          {riskTypeComponents!.map((riskTypeComponent) => (
            <div>{riskTypeComponent}</div>
          ))}
        </PersistenceContextProvider>
      </DialogContent>
    </Dialog>
  );
}

function useRiskLevel(requirementId: string) {
  const [riskLevelMap, setRiskLevelMap] = useState<
    Map<RiskLevelKey, RiskLevel>
  >(new Map());

  const {
    data: riskLevelData,
    error: riskLevelError,
    isLoading: isLoadingRiskLevel,
  } = useQpmRiskLevelRecordListQuery({ artefactMetaId: requirementId });

  useEffect(() => {
    if (riskLevelData) {
      const lvlMap = new Map(
        riskLevelData.levels.map((level) => [level.key, level]),
      );
      setRiskLevelMap(lvlMap);
    }
  }, [riskLevelData]);

  return {
    riskLevelMap,
    riskLevelError,
    isLoadingRiskLevel,
  };
}

function RiskTypeComponent({
  riskType,
  initialRiskData,
}: {
  riskType: RiskTypeRecord;
  initialRiskData: RequirementAreaRisk;
}) {
  const { requirementId } = useParams();
  const [riskData, setRiskData] = useState(initialRiskData);
  const [request, setRequest] =
    useState<AddRequirementAreaRiskRequest>(initialRiskData);

  const { riskLevelMap, riskLevelError, isLoadingRiskLevel } = useRiskLevel(
    requirementId!,
  );

  const [
    setReqAreaRisk,
    {
      error: errorSetReqAreaRisk,
      reset: resetSetReqAreaRisk,
      isLoading: isLoadingSetReqAreaRisk,
      isSuccess: isSuccessSetReqAreaRisk,
    },
  ] = useQpmRequirementAreaRiskAddMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setReqAreaRisk,
    isLoadingSetReqAreaRisk,
    errorSetReqAreaRisk,
    isSuccessSetReqAreaRisk,
    resetSetReqAreaRisk,
    500,
  );

  const handleRiskLevelChange = (riskLevelId: string) => {
    const updatedRequest = { ...initialRiskData, riskLevelId };
    setRiskData(updatedRequest);
    setRequest(updatedRequest);
  };

  const handleTextFieldUpdate = (
    field: keyof Pick<
      RequirementAreaRisk,
      "detection" | "recommendation" | "measure"
    >,
    value: string,
  ) => {
    if (request.riskLevelId === "") return;

    const updatedRequest = { ...riskData, [field]: value };
    setRiskData(updatedRequest);
    setRequest(updatedRequest);
  };

  if (riskLevelError) {
    return <RTKQueryErrorAlert error={riskLevelError} />;
  }

  if (isLoadingRiskLevel) {
    return <RiskTypeComponentSkeleton riskType={riskType} />;
  }

  return (
    <>
      <span className="text-xl font-bold">
        {RiskTypeUtil.toLongString(riskType.key)}
      </span>
      <div className="flex flex-col gap-2">
        <RadioGroup
          className="mb-2 flex gap-x-4 gap-y-2"
          defaultValue={riskData.riskLevelId}
        >
          {RISK_LEVEL_ORDER.map((riskLevelKey) => {
            const riskLevelItem = riskLevelMap.get(riskLevelKey);
            if (!riskLevelItem) return null;

            return (
              <div key={riskLevelKey} className="flex items-center space-x-2">
                <RadioGroupItem
                  className={RISK_LEVEL_COLORS[riskLevelKey]}
                  value={riskLevelItem.id}
                  id={`${riskType.key}-${riskLevelItem.id}`}
                  onClick={() => handleRiskLevelChange(riskLevelItem.id)}
                />
                <Label htmlFor={`${riskType.key}-${riskLevelItem.id}`}>
                  {RiskLevelUtil.toLongString(riskLevelKey)}
                </Label>
              </div>
            );
          })}
        </RadioGroup>
        <TextFieldsGrid
          riskData={riskData}
          onTextFieldUpdate={handleTextFieldUpdate}
        />
      </div>
    </>
  );
}

function TextFieldsGrid({
  riskData,
  onTextFieldUpdate,
}: {
  riskData: RequirementAreaRisk;
  onTextFieldUpdate: (
    field: keyof Pick<
      RequirementAreaRisk,
      "detection" | "recommendation" | "measure"
    >,
    value: string,
  ) => void;
}) {
  return (
    <div className="grid grid-cols-3 flex-col items-start gap-2">
      <TextFieldWithLabel
        id="textDetection"
        label="Feststellung"
        placeholder="Feststellung..."
        value={riskData.detection}
        onChange={(value) => onTextFieldUpdate("detection", value)}
      />
      <TextFieldWithLabel
        id="textRecommendation"
        label="Empfehlungen"
        placeholder="Empfehlungen..."
        value={riskData.recommendation}
        onChange={(value) => onTextFieldUpdate("recommendation", value)}
      />
      <TextFieldWithLabel
        id="textMeasure"
        label="Maßnahmen"
        placeholder="Maßnahmen..."
        value={riskData.measure}
        onChange={(value) => onTextFieldUpdate("measure", value)}
      />
    </div>
  );
}

function TextFieldWithLabel({
  id,
  label,
  placeholder,
  value,
  onChange,
}: {
  id: string;
  label: string;
  placeholder: string;
  value: string;
  onChange: (value: string) => void;
}) {
  return (
    <div className="flex flex-col gap-1">
      <Label htmlFor={id}>{label}</Label>
      <Textarea
        id={id}
        placeholder={placeholder}
        value={value}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
}

function RiskAnalysisComponentSkeleton({ area }: { area: RequirementArea }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button className="h-8" variant="outline">
          {t("Risikobewertung")}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-svh max-w-(--breakpoint-lg) overflow-scroll">
        <DialogHeader>
          <DialogTitle>
            {t("Risikobewertung")} {area.area.base.name}
          </DialogTitle>
          <DialogDescription>
            {t("Bitte bewerten Sie das Risiko für den Bereich")}{" "}
            {area.area.base.name}.
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-4 p-4">
          <Skeleton className="h-36 w-full" />
          <Skeleton className="h-36 w-full" />
          <Skeleton className="h-36 w-full" />
        </div>
      </DialogContent>
    </Dialog>
  );
}

function RiskTypeComponentSkeleton({ riskType }: { riskType: RiskType }) {
  return (
    <>
      <span className="text-xl font-bold">
        {RiskTypeUtil.toLongString(riskType.key)}
      </span>
      <div className="flex flex-col gap-4">
        <Skeleton className="h-10 w-full" />
        <div className="grid grid-cols-3 flex-col items-start gap-2">
          <Skeleton className="h-28 w-full" />
          <Skeleton className="h-28 w-full" />
          <Skeleton className="h-28 w-full" />
        </div>
      </div>
    </>
  );
}

export type RecommendedAmountOfMeasuresComponentProps = {
  requirementId: string;
  reqArea: RequirementArea;
};

function RecommendedMeasuresComponent({
  requirementId,
  reqArea,
}: RecommendedAmountOfMeasuresComponentProps) {
  const [request, setRequest] =
    useState<RequirementAreaSetAmountOfMeasuresRequest>({
      artefactMetaId: requirementId,
      areaId: reqArea.area.base.id,
      amountOfMeasures: reqArea.amountOfMeasures,
    });

  const [
    setAmountOfMeasures,
    {
      error: errorSetReqAreaRisk,
      reset: resetSetReqAreaRisk,
      isLoading: isLoadingSetReqAreaRisk,
      isSuccess: isSuccessSetReqAreaRisk,
    },
  ] = useQpmRequirementAreaSetAmountOfMeasuresMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setAmountOfMeasures,
    isLoadingSetReqAreaRisk,
    errorSetReqAreaRisk,
    isSuccessSetReqAreaRisk,
    resetSetReqAreaRisk,
    500,
  );

  const handleAmountOfMeasureChange = (amount: number) => {
    const updatedRequest: RequirementAreaSetAmountOfMeasuresRequest = {
      ...reqArea,
      amountOfMeasures: amount,
    };
    setRequest(updatedRequest);
  };

  return (
    <div className="flex items-center gap-2">
      <span className="font-medium">
        {t("Empfohlene Anzahl an Maßnahmen im Jahr")}
      </span>
      <IntNumberInput
        value={reqArea.amountOfMeasures}
        onChange={handleAmountOfMeasureChange}
      />
    </div>
  );
}
