import OverviewRoute from "@/routes/gesec/processes/[processId]/vbs/overview/overview";
import ContactRoute from "@/routes/gesec/processes/[processId]/vbs/contact/contact";
import { CalculationsRoute } from "@/routes/gesec/processes/[processId]/vbs/calculations/calculations-route";
import { Navigate, RouteObject } from "react-router";
import OffersRoute from "@/routes/gesec/processes/[processId]/vbs/offers/offers-route";
import ConfirmationsRoute from "@/routes/gesec/processes/[processId]/vbs/confirmations/confirmations-route";
import { WorkOrdersRoute } from "@/routes/gesec/processes/[processId]/vbs/work-orders/work-orders-route";
import { calculationsRoutes } from "@/routes/gesec/processes/[processId]/vbs/calculations/calculations-routes";
import { CertificatesRoute } from "@/routes/gesec/processes/[processId]/vbs/certificates/certificates-route";
import { vbsOffersRoutes } from "@/routes/gesec/processes/[processId]/vbs/offers/offers-routes";
import { confirmationsRoutes } from "@/routes/gesec/processes/[processId]/vbs/confirmations/confirmations-routes";
import { workOrdersRoutes } from "@/routes/gesec/processes/[processId]/vbs/work-orders/work-orders-routes";
import { certificatesRoutes } from "@/routes/gesec/processes/[processId]/vbs/certificates/certificates-routes";
import OrdersRoute from "@/routes/gesec/processes/[processId]/vbs/orders/orders-route";
import { vbsOrdersRoutes } from "@/routes/gesec/processes/[processId]/vbs/orders/orders-routes";

export const vbsRoutes: RouteObject[] = [
  {
    element: <Navigate to="overview" />,
    index: true,
  },
  {
    path: "overview",
    element: <OverviewRoute />,
  },
  {
    path: "contact",
    element: <ContactRoute />,
  },
  {
    path: "calculations",
    element: <CalculationsRoute />,
    children: calculationsRoutes,
  },
  {
    path: "offers",
    element: <OffersRoute />,
    children: vbsOffersRoutes,
  },
  {
    path: "orders",
    element: <OrdersRoute />,
    children: vbsOrdersRoutes,
  },
  {
    path: "confirmations",
    element: <ConfirmationsRoute />,
    children: confirmationsRoutes,
  },
  {
    path: "work-orders",
    element: <WorkOrdersRoute />,
    children: workOrdersRoutes,
  },
  {
    path: "certificates",
    element: <CertificatesRoute />,
    children: certificatesRoutes,
  },
];
