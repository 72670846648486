import { useNavigate } from "react-router";
import { useQpmMonitorListQuery } from "@/services/backend/qpm/monitors/service";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { MonitorTypeUtil } from "@/services/backend/qpm/shared/monitorTypeUtil";
import { Checkbox } from "@/shared/components/ui/checkbox";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { Button } from "@/shared/components/ui/button";
import { Pencil, Plus } from "lucide-react";
import { Skeleton } from "@/shared/components/ui/skeleton";
import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { v4 } from "uuid";

export function MonitorCatalog({
  catalogId,
  monitorTypeKey,
}: {
  catalogId?: string;
  monitorTypeKey?: MonitorTypeKey;
}) {
  const navigate = useNavigate();
  const {
    data: monitorList,
    isLoading,
    error,
  } = useQpmMonitorListQuery({
    catalog: {
      active: true,
      values: [catalogId!],
    },
    type: {
      active: true,
      values: [monitorTypeKey!],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <MonitorCatalogLoadingState />;
  }

  return (
    <>
      <CardHeader>
        <div className="flex justify-between">
          <CardTitle>
            {MonitorTypeUtil.toLongString(monitorTypeKey as MonitorTypeKey)}
          </CardTitle>
          <Button variant="default" onClick={() => navigate(`${v4()}`)}>
            <Plus />
            {MonitorTypeUtil.toLongString(
              monitorTypeKey as MonitorTypeKey,
            )}{" "}
            {t("hinzufügen")}
          </Button>
        </div>
        <CardDescription>
          {t(
            "Sie können hier neue Monitore anlegen oder bestehende bearbeiten.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Name")}</TableHead>
              <TableHead>{t("Typ")}</TableHead>
              {monitorTypeKey === MonitorTypeKey.CheckPoint && (
                <TableHead className="text-center">
                  {t("Mit Wirkstoff")}
                </TableHead>
              )}
              <TableHead className="text-center">
                {t("Empfohlene Maßnahmen")}
              </TableHead>
              <TableHead className="text-center">{t("Preis")}</TableHead>
              <TableHead className="text-end">{t("Aktion")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {monitorList &&
              monitorList.monitors.map((monitor) => (
                <TableRow>
                  <TableCell>{monitor.name}</TableCell>
                  <TableCell>
                    {MonitorTypeUtil.toString(monitor.type.key)}
                  </TableCell>
                  {monitorTypeKey === MonitorTypeKey.CheckPoint && (
                    <TableCell className="text-center">
                      <Checkbox
                        className="disabled:opacity-100"
                        checked={monitor.hasLethalAgent}
                        disabled
                      />
                    </TableCell>
                  )}
                  <TableCell className="text-center">
                    {monitor.recommendedAmountOfMeasuresPerYear}
                  </TableCell>
                  <TableCell className="text-center">{monitor.price}</TableCell>
                  <TableCell className="text-end">
                    <TooltipProvider>
                      <Tooltip>
                        <TooltipTrigger asChild>
                          <Button
                            size="sm"
                            variant="outline"
                            onClick={() => navigate(`${monitor.id}`)}
                          >
                            <Pencil className="h-4 w-4" />
                          </Button>
                        </TooltipTrigger>
                        <TooltipContent>
                          <p>{t("Bearbeiten")}</p>
                        </TooltipContent>
                      </Tooltip>
                    </TooltipProvider>
                  </TableCell>
                </TableRow>
              ))}
          </TableBody>
        </Table>
      </CardContent>
    </>
  );
}

function MonitorCatalogLoadingState() {
  return (
    <div className="flex w-full flex-col gap-2">
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
    </div>
  );
}
