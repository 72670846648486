import { UUID } from "@/shared/nidavellir/types/entity-id";

export interface Photo {
  id: UUID;
  airHandlingUnitId: UUID;
  componentId: UUID;
  comment: string;
  fileId: string | null;
  filePrefix: string;
  createdAt: string;
}

export function newPhoto(
  id: UUID,
  airHandlingUnitId: UUID,
  componentId: UUID,
): Photo {
  return {
    id,
    airHandlingUnitId,
    componentId,
    comment: "",
    fileId: null,
    filePrefix: "",
    createdAt: new Date().toISOString(),
  };
}
