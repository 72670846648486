import { Plus } from "lucide-react";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { PutCatalogRequest } from "@/services/backend/qpm/catalog/types";
import { useEffect, useState } from "react";
import { v4 } from "uuid";
import { Textarea } from "@/shared/components/ui/textarea";
import { formatDate } from "@/shared/lib/utilities/date";
import { useQpmCatalogPutMutation } from "@/services/backend/qpm/catalog/service";
import { usePersistenceStateUpdateOnTrigger } from "@/shared/lib/persistence-state/persistence-state-info";

export function CreateNewCatalogDialog() {
  const [open, setOpen] = useState<boolean>(false);
  const [request, setRequest] = useState<PutCatalogRequest>({
    id: v4(),
    name: "",
    description: "",
    createdAt: new Date().toISOString(),
    updatedAt: new Date().toISOString(),
  });

  const [put, { error, isLoading, isSuccess, reset }] =
    useQpmCatalogPutMutation();

  const { onTrigger: triggerSave } = usePersistenceStateUpdateOnTrigger(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setTimeout(() => {
        setOpen(false);
      }, 350);
    }
  }, [isSuccess]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant="default">
          <Plus />
          {t("Katalog hinzufügen")}
        </Button>
      </DialogTrigger>
      <DialogContent className="max-w-screen-md">
        <DialogHeader>
          <DialogTitle>{t("Neuen Katalog erstellen")}</DialogTitle>
          <DialogDescription>
            {t("Sie können hier einen neuen Katalog anlegen.")}
            <br />
            {t(
              "Vergeben Sie hierzu einen Namen und optional eine Beschreibung.",
            )}
          </DialogDescription>
        </DialogHeader>
        <div className="flex flex-col gap-2">
          <div className="">
            <Label htmlFor="name">{t("Name")}</Label>
            <Input
              id="name"
              type="text"
              value={request.name}
              onChange={(val) => {
                setRequest((prev) => ({ ...prev, name: val.target.value }));
              }}
            />
          </div>
          <div className="">
            <Label htmlFor="description">{t("Beschreibung")}</Label>
            <Textarea
              id="description"
              value={request.description}
              onChange={(val) => {
                setRequest((prev) => ({
                  ...prev,
                  description: val.target.value,
                }));
              }}
            />
          </div>
          <div className="flex items-center justify-between gap-2">
            <Label htmlFor="createdAt">{t("Erstellungsdatum")}</Label>
            <Input
              className="w-fit text-end"
              id="createdAt"
              value={formatDate(request.createdAt)}
              disabled
            />
          </div>
        </div>
        <DialogFooter>
          <Button variant="default" onClick={triggerSave}>
            {t("Katalog erstellen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
