export function IntNumberInput(props: {
  value: number;
  onChange: (value: number) => void;
}) {
  const { value, onChange } = props;
  return (
    <input
      type="number"
      min="0"
      step="1"
      className="h-6 w-12 [appearance:textfield] rounded border-b-2 border-white bg-gray-100 px-1 text-right focus:border-blue-600 focus:ring-0 focus:outline-hidden [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
      value={value === 0 ? "" : value}
      onChange={(e) => {
        let intValue = parseInt(e.target.value, 10);
        if (Number.isNaN(intValue)) {
          intValue = 0;
        }
        onChange(intValue);
      }}
      onBeforeInput={(e) => {
        const event = e as unknown as InputEvent;
        const int = parseInt(event.data ?? "0", 10);
        if (Number.isNaN(int)) {
          event.preventDefault();
        }
      }}
    />
  );
}

export function FloatNumberInput(props: {
  value: number;
  onChange: (value: number) => void;
  width?: number;
  fractions?: number;
  disabled?: boolean;
}) {
  const {
    value,
    onChange,
    width = 12,
    fractions = 2,
    disabled = false,
  } = props;
  return (
    <input
      disabled={disabled}
      type="number"
      min="0"
      step="0.01"
      className={`w-${width} h-6 [appearance:textfield] rounded border-b-2 border-white bg-gray-100 px-1 text-right focus:border-blue-600 focus:ring-0 focus:outline-hidden [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none`}
      value={value === 0 ? "" : value}
      onChange={(e) => {
        let floatValue = parseFloat(e.target.value);
        if (Number.isNaN(floatValue)) {
          floatValue = 0;
        }
        floatValue = round(floatValue, fractions);
        onChange(floatValue);
      }}
      onBeforeInput={(e) => {
        const event = e as unknown as InputEvent;
        const float = parseFloat(event.data ?? "0");
        if (Number.isNaN(float) && event.data !== ",") {
          event.preventDefault();
        }
      }}
    />
  );
}

export function round(value: number, decimals: number = 2): number {
  const factor = 10 ** decimals;
  return Math.round((value + Number.EPSILON) * factor) / factor;
}

export function Value({
  value,
  fractions = 2,
}: {
  value: number;
  fractions?: number;
}) {
  return (
    <span className="border-b-2 border-white text-right">
      {value.toLocaleString("de-DE", {
        minimumFractionDigits: fractions ?? 0,
        maximumFractionDigits: fractions ?? 0,
      })}
    </span>
  );
}

export function fHours(hours: number): string {
  return hours.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}

export function fMoney(money: number): string {
  return money.toLocaleString("de-DE", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });
}
