import { ComponentProps } from "react";
import { cn } from "@/shared/lib/utils";

export function TableCard({
  children,
  className,
  ...props
}: ComponentProps<"div">) {
  return (
    <div className={cn("rounded-xl border shadow-sm", className)} {...props}>
      {children}
    </div>
  );
}
