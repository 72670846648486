import * as React from "react";
import { cn } from "@/shared/lib/utils";

/**
 * ScrollBodyTable in combination with ScrollBodyTableHeader
 * enables to define a shadcn table, in which the body can be
 * scrolled and the header is sticky. The two components should
 * be used as a replacement for their respective counterparts.
 * The table should then be wrapped in a ScrollArea component.
 */
const ScrollBodyTable = ({
  ref,
  className,
  ...props
}: React.ComponentProps<"table">) => (
  <table
    ref={ref}
    className={cn("w-full caption-bottom text-sm", className)}
    {...props}
  />
);
ScrollBodyTable.displayName = "ScrollBodyTable";

const ScrollBodyTableHeader = ({
  ref,
  className,
  ...props
}: React.ComponentProps<"thead">) => (
  <thead
    ref={ref}
    className={cn("bg-background sticky top-0 [&_tr]:border-b", className)}
    {...props}
  />
);
ScrollBodyTableHeader.displayName = "TableHeader";

export { ScrollBodyTable, ScrollBodyTableHeader };
