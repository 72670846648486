import { useRltWorkOrderCreateMutation } from "@/services/backend/rlt/work-orders/service";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { useNavigate } from "react-router";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { v4 } from "uuid";
import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import t from "@/lang/lang";

export function CreateWorkOrderButton({ processId }: { processId: string }) {
  const [create, { isLoading, error, reset }] = useRltWorkOrderCreateMutation();
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  const disabled = !process || isLoading;

  const navigate = useNavigate();
  const { toast } = useToast();

  if (error) {
    toast({
      ...parseRTKQueryError(error),
    });
    reset();
  }

  const onClick = () => {
    if (disabled) {
      return;
    }

    const id = v4();
    create({
      id,
      processId: process.id,
      companyId: process.companyId,
      branchId: process.branchId,
      customerId: process.customerId,
    })
      .unwrap()
      .then(() => {
        navigate(`${id}`);
      })
      .catch(() => null);
  };

  return (
    <Button onClick={onClick} disabled={disabled}>
      <Plus className="h-5 w-5" />
      <span>{t("Arbeitsschein hinzufügen")}</span>
    </Button>
  );
}
