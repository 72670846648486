import { Defaults, Key } from "@/services/backend/rlt/defaults/defaults";
import {
  PutRequest,
  useRltDefaultsPutMutation,
  useRltDefaultsShowQuery,
} from "@/services/backend/rlt/defaults/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useEffect, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Input } from "@/shared/components/ui/input";
import { Offer } from "@/services/backend/rlt/defaults/offer";

export function OfferRoute() {
  const { data, isLoading, error } = useRltDefaultsShowQuery({
    key: Key.Offer,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading || !data) {
    return (
      <Card className="animate-pulse">
        <CardHeader>
          <CardTitle>{t("Angebotsdaten")}</CardTitle>
          <CardDescription>
            {t(
              "Festlegung von allgemeinen Daten die für RLT Angebote genutzt werden sollen.",
            )}
          </CardDescription>
        </CardHeader>
      </Card>
    );
  }

  return <GeneralDataCard defaults={data} />;
}

function GeneralDataCard({ defaults }: { defaults: Defaults }) {
  const [request, setRequest] = useState<PutRequest>(defaults);
  const [put, { isLoading, error, isSuccess, reset }] =
    useRltDefaultsPutMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    150,
  );
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
      });
      reset();
    }
  }, [error, reset, toast]);

  const data = request.data as Offer;
  const setData = (offer: Offer) => {
    setRequest({
      ...request,
      data: offer,
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Angebotsdaten")}</CardTitle>
        <CardDescription>
          {t(
            "Festlegung von allgemeinen Daten die für RLT Angebote genutzt werden sollen.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent className="p-0">
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead className="w-full pl-6">{t("Parameter")}</TableHead>
              <TableHead>{t("Einheit")}</TableHead>
              <TableHead className="pr-6">{t("Standardwert")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            <TableRow>
              <TableCell className="pl-6">{t("Verkaufsrabatt")}</TableCell>
              <TableCell className="text-right whitespace-nowrap">
                {t("%")}
              </TableCell>
              <TableCell className="pr-6">
                <Input
                  className="-my-2 h-8 [appearance:textfield] text-right [&::-webkit-inner-spin-button]:appearance-none [&::-webkit-outer-spin-button]:appearance-none"
                  type="number"
                  value={data.baseTreatmentSalesDiscount}
                  onChange={(e) =>
                    setData({
                      ...data,
                      baseTreatmentSalesDiscount: e.target.valueAsNumber,
                    })
                  }
                />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}
