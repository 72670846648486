import {
  DataTable,
  DataTableBrowserPagination,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { ColumnDef } from "@tanstack/react-table";
import { Customer } from "@/services/backend/customers/customer";
import t from "@/lang/lang";
import { NavLink } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { useCustomersCustomerListQuery } from "@/services/backend/customers/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";

export function CustomersTable() {
  const { data: list, isLoading, error } = useCustomersCustomerListQuery({});

  const customers = list?.customers ?? [];
  const table = useDataTableController(tableColumns, customers);

  if (isLoading) {
    return (
      <DataTableContainer>
        <DataTable columns={tableColumns} table={table} />
        <DataTableBrowserPagination table={table} />
      </DataTableContainer>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <DataTableContainer>
      <DataTable columns={tableColumns} table={table} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  );
}

const tableColumns: ColumnDef<Customer>[] = [
  {
    header: t("Name"),
    accessorKey: "name",
  },
  {
    id: "actions",
    header: t("Aktionen"),
    cell: ({ row }) => {
      const customer = row.original;

      return (
        <NavLink to={`${customer.id}`}>
          <Button variant="ghost" size="sm" className="-mt-1.5 -mb-2.5">
            <Search className="h-4 w-4" />
          </Button>
        </NavLink>
      );
    },
  },
];
