import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  useQpmCalculationListQuery,
  useQpmCalculationShowCurrentQuery,
} from "@/services/backend/qpm/calculations/service";
import { FilterMode } from "@/shared/lib/filter/filter";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { NavLink } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { CheckCircle, Copy, Loader2, Search } from "lucide-react";
import { useToast } from "@/shared/hooks/use-toast";
import { ArtefactMeta } from "@/services/backend/qpm/artefact-meta/types";
import { useQpmOfferApplyCalculationMutation } from "@/services/backend/qpm/offer/service";
import { ApplyCalculationDataRequest } from "@/services/backend/qpm/offer/types";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { v4 } from "uuid";

export function CreateFromCalculation({
  offerId,
  processId,
  onCreated,
}: {
  offerId?: string;
  processId: string;
  onCreated?: (offerId: string) => void;
}) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Auf Basis alter Kalkulation")}</CardTitle>
        <CardDescription>
          {t(
            "Kopiert die Daten der alten Kalkulation. Es werden nur Kalkulationen von abgeschlossenen Vorgängen angezeigt.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <CalculationsTable
          offerId={offerId}
          processId={processId}
          onCreated={onCreated}
        />
      </CardContent>
    </Card>
  );
}

function CalculationsTable({
  offerId,
  processId,
  onCreated,
}: {
  offerId?: string;
  processId: string;
  onCreated?: (offerId: string) => void;
}) {
  const {
    data: list,
    isLoading,
    error,
  } = useQpmCalculationListQuery({
    process: {
      active: true,
      values: [processId],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
    },
    parent: {
      active: true,
      mode: FilterMode.IsNull,
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead className="w-24">{t("Aktuelle")}</TableHead>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-56">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-24">{t("Aktuelle")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Erstellt am")}</TableHead>
          <TableHead className="w-56">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.artefacts ?? [])].sort(byCreatedAt).map((calculation) => (
          <TableRow key={calculation.id}>
            <TableCell className="pl-8">
              <CurrentCalculationMarker
                calculationId={calculation.id}
                processId={processId}
              />
            </TableCell>
            <TableCell>
              <ArtefactNumberById artefactId={calculation.id} />
            </TableCell>
            <TableCell>
              {new Date(calculation.createdAt).toLocaleDateString()}
            </TableCell>
            <TableCell>
              <div className="flex space-x-2">
                <NavLink to={`../calculations/${calculation.id}`}>
                  <Button variant="outline" size="sm">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
                <CreateOfferFromCalculationButton
                  calculationId={calculation.id}
                  processId={processId}
                  onCreated={onCreated}
                  offerId={offerId}
                />
              </div>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}

function byCreatedAt(a: ArtefactMeta, b: ArtefactMeta) {
  return a.createdAt.localeCompare(b.createdAt);
}

function CurrentCalculationMarker({
  calculationId,
  processId,
}: {
  calculationId: string;
  processId: string;
}) {
  const { data: current } = useQpmCalculationShowCurrentQuery({
    processId,
  });

  if (current?.calculationId === calculationId) {
    return <CheckCircle className="h-5 w-5 text-green-700" color="green" />;
  }

  return <span />;
}

export type CreateFromCalculationButtonProps = {
  calculationId: string;
  processId: string;
  offerId?: string;
  onCreated?: (offerId: string) => void;
};

function CreateOfferFromCalculationButton({
  calculationId,
  processId,
  onCreated,
  offerId,
}: CreateFromCalculationButtonProps) {
  const [applyCalculationData, { error, isLoading, reset }] =
    useQpmOfferApplyCalculationMutation();

  const onClick = () => {
    const offerRequestId: string = offerId || v4();
    const request: ApplyCalculationDataRequest = {
      processId,
      offerArtefactId: offerRequestId,
      calculationArtefactId: calculationId,
    };

    applyCalculationData(request)
      .unwrap()
      .then(() => {
        if (onCreated) {
          onCreated(offerRequestId);
        }
      });
  };
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
  }, [error, toast, reset]);

  return (
    <Button onClick={onClick} disabled={isLoading} size="sm">
      {isLoading && <Loader2 className="h-4 w-4 animate-spin" />}
      {!isLoading && (
        <>
          <Copy className="mr-3 h-4 w-4" />
          <span>{t("Daten Übernehmen")}</span>
        </>
      )}
    </Button>
  );
}
