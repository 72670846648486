import { ConsumableUnitKey } from "@/services/backend/qpm/shared/enums";

export class ConsumableUnitTypeUtil {
  private static descriptions: { [key in ConsumableUnitKey]: string } = {
    [ConsumableUnitKey.Item]: "Stück",
    [ConsumableUnitKey.Gram]: "Gramm",
    [ConsumableUnitKey.Milliliters]: "Milliliter",
  };

  private static abbreviations: { [key in ConsumableUnitKey]: string } = {
    [ConsumableUnitKey.Item]: "St.",
    [ConsumableUnitKey.Gram]: "g",
    [ConsumableUnitKey.Milliliters]: "ml",
  };

  static toString(type: ConsumableUnitKey): string {
    return this.abbreviations[type];
  }

  static toLongString(type: ConsumableUnitKey): string {
    return this.descriptions[type] || "Unknown";
  }
}
