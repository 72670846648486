import { useEffect, useState } from "react";
import { SegmentKind } from "@/services/backend/rlt/treatments/segmentKind";
import { Position } from "@/services/backend/rlt/treatments/position";
import { Segment } from "@/services/backend/rlt/treatments/segment";
import t from "@/lang/lang";
import { useRltTreatmentAddAreaMutation } from "@/services/backend/rlt/treatments/service";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { SelectColumnsDropdown } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/_components/select-columns-dropdown";
import { useTreatmentComponentState } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state-context";
import { Button } from "@/shared/components/ui/button";
import { ChevronDown, Plus } from "lucide-react";
import { Input } from "@/shared/components/ui/input";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Column } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Separator } from "@/shared/components/ui/separator";
import { Label } from "@/shared/components/ui/label";
import { fCleanTime } from "./utils";
import { SegmentAreaComponent } from "./segment-area-component";

export function SegmentComponent({
  treatmentId,
  segment,
  positions,
}: {
  treatmentId: string;
  segment: Segment;
  positions: Position[];
}) {
  const { segmentKind, areas, cleanTime } = segment;
  const { state } = useTreatmentComponentState();
  const { columns } = state;

  return (
    <div className="space-y-4">
      <div className="flex items-center justify-end space-x-2 px-4 pt-2">
        <AddAreaPopover treatmentId={treatmentId} segmentKind={segmentKind} />
        <SelectColumnsDropdown />
      </div>
      <div>
        {areas.map((segmentArea) => (
          <SegmentAreaComponent
            treatmentId={treatmentId}
            segmentKind={segmentKind}
            segmentArea={segmentArea}
            positions={positions}
            key={segmentArea.area}
          />
        ))}
        {columns.includes(Column.CleanTime) && (
          <div className="bg-muted flex items-center justify-end space-x-4 border-t px-6 py-3 font-bold uppercase">
            <span>{t("Gesamt")}</span>
            <span>{fCleanTime(cleanTime)}</span>
          </div>
        )}
      </div>
    </div>
  );
}

function AddAreaPopover({
  treatmentId,
  segmentKind,
}: {
  treatmentId: string;
  segmentKind: SegmentKind;
}) {
  const [open, setOpen] = useState(false);
  const [individualName, setIndividualName] = useState("");
  const [add, { isLoading, error }] = useRltTreatmentAddAreaMutation();
  const { toast } = useToast();

  const { setPersistenceState } = usePersistenceContext();
  useEffect(() => {
    if (isLoading) {
      setPersistenceState(PersistenceState.SAVING);
    } else if (error) {
      setPersistenceState(PersistenceState.ERROR);
    } else {
      setPersistenceState(PersistenceState.SAVED);
    }
  }, [isLoading, error, setPersistenceState]);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
    }
  }, [error, toast]);

  const onAdd = (name: string) => {
    if (isLoading) {
      return;
    }
    add({
      id: treatmentId,
      area: name,
      segment: segmentKind,
    });
    setOpen(false);
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button variant="outline">
          <span>{t("Bereich hinzufügen")}</span>
          <ChevronDown className="ml-2 h-5 w-5" />
        </Button>
      </PopoverTrigger>
      <PopoverContent align="end" className="flex flex-col gap-2">
        <Input
          value={individualName}
          onChange={(e) => setIndividualName(e.target.value)}
          placeholder={t("Name eingeben")}
        />
        <Button
          variant="secondary"
          className="w-full py-2"
          onClick={() => onAdd(individualName)}
        >
          {t("Erstellen")}
        </Button>
        <Separator />
        <Label>{t("Vorschläge")}</Label>
        {["Lüftungszentrale", "Technikzentrale"].map((name) => (
          <Button
            key={name}
            onClick={() => onAdd(t(name))}
            className="w-full cursor-pointer"
            variant="outline"
          >
            <Plus className="h-4 w-4" />
            <span>{t(name)}</span>
          </Button>
        ))}
      </PopoverContent>
    </Popover>
  );
}
