import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import {
  PutRequest,
  useAuthEntraGroupPutMutation,
} from "@/services/backend/auth/entragroup/service";
import { useState } from "react";
import { Group } from "@/services/backend/auth/entragroup/group";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { useListCompanyQuery } from "@/services/backend/company/company";
import { Edit, Plus } from "lucide-react";
import {
  ListBranchRequest,
  useListBranchQuery,
} from "@/services/backend/branch/branch";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { CompanyName } from "@/shared/components/domain/company/company-name";

export function PutEntraGroupDialog({ group = undefined }: { group?: Group }) {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<PutRequest>({
    ...(group ?? { id: "", name: "", companies: [], branches: [] }),
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    useAuthEntraGroupPutMutation();

  if (isSuccess) {
    setOpen(false);
    if (!group) {
      setRequest({ id: "", name: "", companies: [], branches: [] });
    }
    reset();
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }
    put(request);
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        {group ? (
          <Button variant="outline" size="sm">
            <Edit className="h-5 w-5" />
          </Button>
        ) : (
          <Button size="sm">
            <Plus className="h-5 w-5" />
            <span>{t("Hinzufügen")}</span>
          </Button>
        )}
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Entra Gruppe")}</DialogTitle>
        </DialogHeader>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("ID")}</Label>
          <p className="text-muted-foreground text-sm">
            {t("Die tatsächliche ID der Gruppe in Microsoft Entra.")}
          </p>
          <Input
            type="text"
            placeholder={t("ID")}
            value={request.id}
            onChange={(e) => setRequest({ ...request, id: e.target.value })}
          />
          <InputValidationErrors error={error} field="id" />
        </div>
        <div className="w-full items-center space-y-1.5">
          <Label>{t("Name")}</Label>
          <Input
            type="text"
            placeholder={t("Name")}
            value={request.name}
            onChange={(e) => setRequest({ ...request, name: e.target.value })}
          />
          <InputValidationErrors error={error} field="name" />
        </div>
        <CompanySelector
          companies={request.companies}
          onChange={(companies) => setRequest({ ...request, companies })}
        />
        <BranchSelector
          branches={request.branches}
          onChange={(branches) => setRequest({ ...request, branches })}
        />
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="ghost">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={onClick} disabled={isLoading}>
            {t("Speichern")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function CompanySelector({
  companies,
  onChange,
}: {
  companies: string[];
  onChange: (companies: string[]) => void;
}) {
  const { data: list } = useListCompanyQuery({});
  const isSelected = (id: string) => companies.includes(id);
  const onCheckedChange = (id: string, value: boolean) => {
    if (value) {
      onChange(companies.filter((c) => c !== id));
    } else {
      onChange(Array.from(new Set([...companies, id])));
    }
  };

  return (
    <div>
      <Label className="block pb-3">{t("Unternehmen")}</Label>
      <div className="space-y-2">
        {list?.companies.map((company) => (
          <div className="flex items-center space-x-2" key={company.id}>
            <Checkbox
              id="terms"
              checked={isSelected(company.id)}
              onCheckedChange={() =>
                onCheckedChange(company.id, isSelected(company.id))
              }
            />
            <Label>{company.name}</Label>
          </div>
        ))}
      </div>
    </div>
  );
}

function BranchSelector({
  branches,
  onChange,
}: {
  branches: string[];
  onChange: (branches: string[]) => void;
}) {
  const { data: list } = useListBranchQuery({} as ListBranchRequest);
  const isSelected = (id: string) => branches.includes(id);
  const onCheckedChange = (id: string, value: boolean) => {
    if (value) {
      onChange(branches.filter((c) => c !== id));
    } else {
      onChange(Array.from(new Set([...branches, id])));
    }
  };

  return (
    <div>
      <Label className="block pb-3">{t("Niederlassungen")}</Label>
      <div className="space-y-2">
        {list?.branches.map((branch) => (
          <div className="flex items-center space-x-2" key={branch.id}>
            <Checkbox
              id="terms"
              checked={isSelected(branch.id)}
              onCheckedChange={() =>
                onCheckedChange(branch.id, isSelected(branch.id))
              }
            />
            <Label>
              {branch.name} (<CompanyName companyId={branch.companyId} />)
            </Label>
          </div>
        ))}
      </div>
    </div>
  );
}
