import OverviewRoute from "@/routes/gesec/processes/[processId]/rlt/overview/overview";
import ContactRoute from "@/routes/gesec/processes/[processId]/rlt/contact/contact";
import { CalculationsRoute } from "@/routes/gesec/processes/[processId]/rlt/calculations/calculations-route";
import { Navigate, RouteObject } from "react-router";
import OffersRoute from "@/routes/gesec/processes/[processId]/rlt/offers/offers-route";
import ConfirmationsRoute from "@/routes/gesec/processes/[processId]/rlt/confirmations/confirmations-route";
import { WorkOrdersRoute } from "@/routes/gesec/processes/[processId]/rlt/work-orders/work-orders-route";
import { calculationsRoutes } from "@/routes/gesec/processes/[processId]/rlt/calculations/calculations-routes";
import { CertificatesRoute } from "@/routes/gesec/processes/[processId]/rlt/certificates/certificates-route";
import { rltOffersRoutes } from "@/routes/gesec/processes/[processId]/rlt/offers/offers-routes";
import { certificatesRoutes } from "@/routes/gesec/processes/[processId]/rlt/certificates/certificates-routes";
import { confirmationsRoutes } from "@/routes/gesec/processes/[processId]/rlt/confirmations/confirmations-routes";
import { workOrdersRoutes } from "@/routes/gesec/processes/[processId]/rlt/work-orders/work-orders-routes";
import OrdersRoute from "@/routes/gesec/processes/[processId]/rlt/orders/orders-route";
import { rltOrdersRoutes } from "@/routes/gesec/processes/[processId]/rlt/orders/orders-routes";

export const rltRoutes: RouteObject[] = [
  {
    element: <Navigate to="overview" />,
    index: true,
  },
  {
    path: "overview",
    element: <OverviewRoute />,
  },
  {
    path: "contact",
    element: <ContactRoute />,
  },
  {
    path: "calculations",
    element: <CalculationsRoute />,
    children: calculationsRoutes,
  },
  {
    path: "offers",
    element: <OffersRoute />,
    children: rltOffersRoutes,
  },
  {
    path: "orders",
    element: <OrdersRoute />,
    children: rltOrdersRoutes,
  },
  {
    path: "confirmations",
    element: <ConfirmationsRoute />,
    children: confirmationsRoutes,
  },
  {
    path: "work-orders",
    element: <WorkOrdersRoute />,
    children: workOrdersRoutes,
  },
  {
    path: "certificates",
    element: <CertificatesRoute />,
    children: certificatesRoutes,
  },
];
