import { Offer } from "@/services/backend/qpm/offer/types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import {
  FloatNumberInput,
  IntNumberInput,
} from "@/routes/gemex/processes/_components/ui/number-input";

export type RentingTableComponentProps = {
  offer: Offer;
};

export function RentingTableComponent({ offer }: RentingTableComponentProps) {
  return (
    <Table className="">
      <TableHeader>
        <TableRow>
          <TableHead className="h-fit px-1 py-1 text-lg font-bold">
            {t("Miete ALoRa")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Dauer")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Monatsrate ohne Funktionsprüfung")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Monatsrate inklusive Funktionsprüfung")}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Mietpreis ALoRa")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <IntNumberInput
              className="justify-self-end"
              value={offer.rentingResult.rentingDuration}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.rentingResult.rentPerMonth}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.rentingResult.rentPerMonthWithMaintenance}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
