import { backendApi } from "@/services/backend/api";
import {
  AddRiskLevelRequest,
  AddRiskTypeRequest,
  ListRiskLevelRecordsRequest,
  ListRiskLevelsRequest,
  ListRiskTypeRecordsRequest,
  ListRiskTypesRequest,
  RiskLevel,
  RiskLevelList,
  RiskLevelRecordsList,
  RiskType,
  RiskTypeList,
  RiskTypeRecordsList,
} from "@/services/backend/qpm/risks/types";
import { DeleteRequirementAreaRisksRequest } from "@/services/backend/qpm/requirements/types";

const qpmRiskServiceApi = backendApi
  .enhanceEndpoints({
    addTagTypes: [
      "QpmRiskType",
      "QpmRiskLevel",
      "QpmRiskTypeRecord",
      "QpmRiskLevelRecord",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmRiskTypeCreate: build.mutation<RiskType, AddRiskTypeRequest>({
        query: (request) => ({
          url: `/qpm/risk/type`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmRiskType"],
      }),
      qpmRiskTypeList: build.query<RiskTypeList, ListRiskTypesRequest>({
        query: (request) => ({
          url: `/qpm/risk/type/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRiskType", id: "LIST" }] : [],
      }),
      qpmRiskTypeDelete: build.mutation<
        null,
        DeleteRequirementAreaRisksRequest
      >({
        query: (request) => ({
          url: `/qpm/risk/type`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmRiskType"],
      }),
      qpmRiskLevelCreate: build.mutation<RiskLevel, AddRiskLevelRequest>({
        query: (request) => ({
          url: `/qpm/risk/level`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmRiskLevel"],
      }),
      qpmRiskLevelList: build.query<RiskLevelList, ListRiskLevelsRequest>({
        query: (request) => ({
          url: `/qpm/risk/level/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRiskLevel", id: "LIST" }] : [],
      }),
      qpmRiskLevelDelete: build.mutation<
        null,
        DeleteRequirementAreaRisksRequest
      >({
        query: (request) => ({
          url: `/qpm/risk/level`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmRiskLevel"],
      }),
      qpmRiskTypeRecordList: build.query<
        RiskTypeRecordsList,
        ListRiskTypeRecordsRequest
      >({
        query: (request) => ({
          url: `/qpm/risk-record/type/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRiskTypeRecord", id: "LIST" }] : [],
      }),
      qpmRiskLevelRecordList: build.query<
        RiskLevelRecordsList,
        ListRiskLevelRecordsRequest
      >({
        query: (request) => ({
          url: `/qpm/risk-record/level/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmRiskLevel", id: "LIST" }] : [],
      }),
    }),
  });

export const {
  useQpmRiskTypeCreateMutation,
  useQpmRiskTypeListQuery,
  useQpmRiskTypeDeleteMutation,
  useQpmRiskLevelCreateMutation,
  useQpmRiskLevelListQuery,
  useQpmRiskLevelDeleteMutation,
  useQpmRiskTypeRecordListQuery,
  useQpmRiskLevelRecordListQuery,
} = qpmRiskServiceApi;
