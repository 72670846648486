import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import t from "@/lang/lang";
import { Copy } from "lucide-react";
import { Button } from "@/shared/components/ui/button";
import { SourceTable } from "@/routes/gesec/processes/_components/copy-data-dialog/source-table";
import { Destination } from "@/services/backend/cpy/service";
import { useState } from "react";

/**
 * CopyDataDialog is a dialog that allows the user to copy data from a source
 * to a destination. The destination information passed will be used to resolve
 * the sources that can be copied.
 *
 * @param destination
 * @param processId
 * @param onCopySuccess is a callback that is called when the copy was successful.
 * It is called with a delay of onCopySuccessTimeout. This is useful in cases
 * where the component tree and the local state in it must be reset. The timeout
 * allows rtk query to update its state before the callback is called. Clients
 * of CopyDataDialog may use a key to force a re-render of the component tree
 * and update this key in the onCopySuccess callback.
 * @param onCopySuccessTimeout
 * @constructor
 */
export function CopyDataDialog({
  destination,
  processId,
  onCopySuccess,
  onCopySuccessTimeout = 1000,
}: {
  destination: Destination;
  processId: string;
  onCopySuccess?: () => void;
  onCopySuccessTimeout?: number;
}) {
  const [open, setOpen] = useState(false);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Copy className="h-4 w-4" />
          <span>{t("Daten übernehmen")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent className="sm:max-w-[1016px]">
        <DialogHeader>
          <DialogTitle>{t("Daten kopieren")}</DialogTitle>
          <DialogDescription>
            {t("Es können Daten aus verschiedenen Quellen übernommen werden.")}
          </DialogDescription>
        </DialogHeader>
        <SourceTable
          destination={destination}
          processId={processId}
          onCopySuccess={() => {
            setOpen(false);
            if (onCopySuccess) {
              setTimeout(() => onCopySuccess(), onCopySuccessTimeout);
            }
          }}
        />
      </DialogContent>
    </Dialog>
  );
}
