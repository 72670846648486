import { Certificate } from "@/services/backend/vbs/certificates/certificate";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import t from "@/lang/lang";
import { useVbsCertificateDeleteMutation } from "@/services/backend/vbs/certificates/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useToast } from "@/shared/hooks/use-toast";

export function DeleteCertificateDialog({
  certificate,
}: {
  certificate: Certificate;
}) {
  const [open, setOpen] = useState(false);
  const [doDelete, { isLoading, error, isSuccess, reset }] =
    useVbsCertificateDeleteMutation();

  const navigate = useNavigate();
  const { toast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      reset();
      setOpen(false);
      toast({
        title: t("Zertifikat gelöscht"),
        variant: "success",
      });
      navigate("..");
    }
  }, [isSuccess, reset, navigate, toast]);

  const onClick = () => {
    if (isLoading) {
      return;
    }

    doDelete({ id: certificate.id });
  };

  if (certificate.immutable) {
    return null;
  }

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Zertifikat löschen?")}</DialogTitle>
          <DialogDescription>
            {t("Ein Klick auf Löschen, löscht das Zertifikat unwiderruflich.")}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick} disabled={isLoading}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
