import { Offer } from "@/services/backend/qpm/offer/types";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import {
  FloatNumberInput,
  IntNumberInput,
} from "@/routes/gemex/processes/_components/ui/number-input";

export type MaintenanceTableComponentProps = {
  offer: Offer;
};

export function MaintenanceTableComponent({
  offer,
}: MaintenanceTableComponentProps) {
  return (
    <Table className="">
      <TableHeader>
        <TableRow>
          <TableHead className="h-fit px-1 py-1 text-lg font-bold">
            {t("Betriebs- und Serviceleistungen")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Maßnahmen")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Preis pro Maßnahme")}
          </TableHead>
          <TableHead className="h-fit px-1 py-1 text-end">
            {t("Gesamtpreis")}
          </TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Service QPM Monitore")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <IntNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.qpm.amountOfMeasures}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.qpm.costPerMeasure}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.qpm.totalCost}
            />
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Service SbD Monitore")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <IntNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.scb.amountOfMeasures}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.scb.costPerMeasure}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.scb.totalCost}
            />
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Wartung Fluginsektenvernichter")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <IntNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.fiv.amountOfMeasures}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.fiv.costPerMeasure}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.fiv.totalCost}
            />
          </TableCell>
        </TableRow>
        <TableRow className="border-none">
          <TableCell className="px-1 py-2 text-base font-medium">
            {t("Funktionsprüfung ALoRa")}
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <IntNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.aLoRa.amountOfMeasures}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.aLoRa.costPerMeasure}
            />
          </TableCell>
          <TableCell className="px-1 py-2 text-end">
            <FloatNumberInput
              className="justify-self-end"
              value={offer.maintenanceResult.aLoRa.totalCost}
            />
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
}
