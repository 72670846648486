import { ReactNode, useState } from "react";
import {
  ListRequest,
  OrderValues,
  useProcessesProcessListQuery,
} from "@/services/backend/processes/process/service";
import { Label } from "@/services/backend/processes/state/state";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { ArrowUpDown } from "lucide-react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { ViewModel } from "@/services/backend/processes/process/process";
import { NavLink } from "react-router";
import { ProcessNumber } from "@/shared/components/domain/numbers/process-number";
import { StateIcon } from "@/shared/components/domain/process/state/state-icon";
import { CustomerName } from "@/shared/components/domain/customer/customer-name";
import { BranchName } from "@/shared/components/domain/branch/branch-name";
import { formatDate } from "@/shared/lib/utilities/date";
import { ProcessKindFilter } from "@/routes/gesec/processes/_components/filters/process-kind-filter";
import { BranchFilter } from "@/routes/gesec/processes/_components/filters/branch-filter";
import { StateFilter } from "@/routes/gesec/processes/_components/filters/state-filter";
import { ResetFilters } from "@/routes/gesec/processes/_components/filters/reset-filters";
import {
  NewProcessDialog,
  useProcessLink,
} from "@/routes/gesec/processes/_components/new-process-dialog";
import { PaginationLimitOffset } from "@/shared/components/ui/pagination-limit-offset";
import { CompanyFilter } from "@/routes/gesec/processes/_components/filters/company-filter";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { CustomerFilter } from "@/routes/gesec/processes/_components/filters/customer-filter";
import { TableCard } from "@/shared/components/ui/table-card";

export function ProcessesTable() {
  const [request, setRequest] = useState<ListRequest>(defaultRequest());

  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useProcessesProcessListQuery(request, {
    refetchOnMountOrArgChange: true,
    refetchOnFocus: true,
  });

  const toggle = (column: OrderValues) => {
    const current = request.orderBy?.find((f) => f.field === column);

    if (!current) {
      setRequest({
        ...request,
        orderBy: [
          // push it to the front, get past values
          { field: column, dsc: false },
          // add past values
          ...(request.orderBy ?? []),
        ],
      });
      return;
    }

    setRequest({
      ...request,
      orderBy: [
        // push it to the front, get past values
        { field: column, dsc: !current.dsc },
        // remove duplicates
        ...(request.orderBy?.filter((f) => f.field !== column) ?? []),
      ],
    });
  };

  const disabled = isLoading || isFetching;

  return (
    <div className="space-y-2">
      <div className="flex justify-between space-x-2">
        <div className="flex space-x-2">
          <ProcessKindFilter
            filter={request.kind!}
            onChange={(value) => setRequest({ ...request, kind: value })}
          />
          <CustomerFilter
            filter={request.customer!}
            onFilterChange={(value) =>
              setRequest({ ...request, customer: value })
            }
          />
          <CompanyFilter
            filter={request.company!}
            onChange={(value) => setRequest({ ...request, company: value })}
          />
          <BranchFilter
            filter={request.branch!}
            onChange={(value) => setRequest({ ...request, branch: value })}
          />
          <StateFilter
            filter={request.state!}
            onChange={(value) => setRequest({ ...request, state: value })}
          />
          <ResetFilters onClick={() => setRequest(defaultRequest())} />
        </div>
        <NewProcessDialog />
      </div>
      <TableCard>
        <Table>
          <ProcessTableHeader disabled={disabled} toggle={toggle} />
          <RTKQueryErrorAlert error={error} />
          <ProcessTableBody processes={list?.processes ?? []} />
        </Table>
      </TableCard>
      <div className="flex justify-end">
        {list && (
          <PaginationLimitOffset
            offset={request.offset!}
            limit={request.limit!}
            totalCount={list.totalProcessCount}
            setLimitOffset={(limit, offset) =>
              setRequest({ ...request, limit, offset })
            }
          />
        )}
      </div>
    </div>
  );
}

function ProcessTableHeader({
  disabled,
  toggle,
}: {
  disabled: boolean;
  toggle: (column: OrderValues) => void;
}) {
  return (
    <TableHeader>
      <TableRow>
        <TableHead className="pr-0">
          <Button
            variant="ghost"
            size="sm"
            disabled={disabled}
            onClick={() => toggle("kind")}
          >
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        </TableHead>
        <TableHead className="pr-0">
          <Button
            variant="ghost"
            size="sm"
            disabled={disabled}
            onClick={() => toggle("state")}
          >
            <ArrowUpDown className="h-4 w-4" />
          </Button>
        </TableHead>
        <TableHead className="pr-0">{t("Nummer")}</TableHead>
        <TableHead className="pr-0">
          <Button
            variant="ghost"
            size="sm"
            disabled={disabled}
            onClick={() => toggle("topic")}
          >
            <span>{t("Thema")}</span>
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        </TableHead>
        <TableHead>{t("Kunde")}</TableHead>
        <TableHead>{t("NL")}</TableHead>
        <TableHead className="px-0">
          <Button
            variant="ghost"
            size="sm"
            disabled={disabled}
            onClick={() => toggle("createdAt")}
          >
            <span>{t("Erstellt am")}</span>
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        </TableHead>
        <TableHead className="px-0">
          <Button
            variant="ghost"
            size="sm"
            disabled={disabled}
            onClick={() => toggle("opportunityDataCreatedOn")}
          >
            <span>{t("VC Erstellt")}</span>
            <ArrowUpDown className="ml-2 h-4 w-4" />
          </Button>
        </TableHead>
      </TableRow>
    </TableHeader>
  );
}

function ProcessTableBody({ processes }: { processes: ViewModel[] }) {
  return (
    <TableBody>
      {processes.map((process) => (
        <TableRow key={process.id}>
          <TableCell className="pr-0">{process.kind}</TableCell>
          <TableCell className="pr-0">
            <StateIcon label={process.state} className="ml-2" />
          </TableCell>
          <TableCell className="pr-0">
            <ProcessNumber processId={process.id} className="font-mono" />
          </TableCell>
          <TableCell className="py-0 pr-0">
            <ProcessLink
              processKind={process.kind}
              processId={process.id}
              companyId={process.companyId}
            >
              {process.topic.length > 50
                ? `${process.topic.substring(0, 50)} ...`
                : process.topic}
            </ProcessLink>
          </TableCell>
          <TableCell>
            <CustomerName customerId={process.customerId} />
          </TableCell>
          <TableCell>
            <BranchName branchId={process.branchId} />
          </TableCell>
          <TableCell>{formatDate(process.createdAt)}</TableCell>
          <TableCell>
            {!process.opportunityData.createdOn ||
            isZeroDateTime(process.opportunityData.createdOn)
              ? t("ohne VC")
              : formatDate(process.opportunityData.createdOn)}
          </TableCell>
        </TableRow>
      ))}
    </TableBody>
  );
}

function ProcessLink({
  children,
  processKind,
  processId,
  companyId,
}: {
  children: ReactNode;
  processKind: ProcessKind;
  processId: string;
  companyId: string;
}) {
  const processLink = useProcessLink(companyId, processKind, processId);
  if (processLink === "") {
    return null;
  }

  return (
    <Button asChild variant="link" size="sm" className="whitespace-normal">
      <NavLink to={processLink}>{children}</NavLink>
    </Button>
  );
}

function isZeroDateTime(date: string) {
  return date === "0001-01-01T00:00:00Z";
}

const defaultRequest = (): ListRequest => ({
  kind: {
    active: false,
    values: [],
  },
  topic: {
    active: false,
    values: [],
  },
  customer: {
    active: false,
    values: [],
  },
  company: {
    active: false,
    values: [],
  },
  branch: {
    active: false,
    values: [],
  },
  state: {
    active: true,
    values: [Label.Running],
  },
  orderBy: [{ field: "opportunityDataModifiedOn", dsc: true }],
  limit: 10,
  offset: 0,
});
