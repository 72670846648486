import { useSamplesLaboratoryListQuery } from "@/services/backend/samples/lab/service";
import { Query, useArtefactDBGetAll } from "@/shared/indexdb/artefact-db-hooks";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { useParams } from "react-router";
import { Laboratory } from "@/services/backend/samples/lab/laboratory";

export function useGetLaboratories(): Query<Laboratory[]> {
  const { offlineState } = useOfflineContext();
  const { workOrderId } = useParams();

  const {
    data: laboratoryList,
    isLoading: laboratoryIsLoading,
    error: laboratoryError,
  } = useSamplesLaboratoryListQuery({});
  const laboratories = laboratoryList?.labs ?? [];

  const {
    data: indexedDBData,
    isLoading: indexedDBIsLoading,
    error: indexedDBError,
  } = useArtefactDBGetAll<Laboratory>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "laboratories",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: laboratories,
        isLoading: laboratoryIsLoading,
        error: laboratoryError,
      };
    case OfflineState.OFFLINE:
      return {
        data: indexedDBData,
        isLoading: indexedDBIsLoading,
        error: indexedDBError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}
