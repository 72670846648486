import { backendApi } from "@/services/backend/api";
import {
  ActiveCatalog,
  Catalog,
  CatalogList,
  DeleteCatalogRequest,
  GetActiveCatalogRequest,
  GetCatalogRequest,
  ListRequest,
  PutCatalogRequest,
  SetActiveCatalogRequest,
} from "@/services/backend/qpm/catalog/types";

const qpmCatalogServiceApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["QpmCatalog", "QpmActiveCatalog"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmCatalogPut: build.mutation<Catalog, PutCatalogRequest>({
        query: (request) => ({
          url: `/qpm/catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result) =>
          result
            ? ["QpmCatalog", { type: "QpmActiveCatalog", id: result.id }]
            : [],
      }),
      qpmCatalogGet: build.query<Catalog, GetCatalogRequest>({
        query: (request) => ({
          url: `/qpm/catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: ["QpmCatalog"],
      }),
      qpmCatalogSetActive: build.mutation<
        ActiveCatalog,
        SetActiveCatalogRequest
      >({
        query: (request) => ({
          url: `/qpm/catalog/set-active`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmActiveCatalog"],
      }),
      qpmCatalogGetActive: build.query<ActiveCatalog, GetActiveCatalogRequest>({
        query: (request) => ({
          url: `/qpm/catalog/active`,
          method: "GET",
          params: request,
        }),
        providesTags: ["QpmActiveCatalog"],
      }),
      qpmCatalogList: build.query<CatalogList, ListRequest>({
        query: (request) => ({
          url: `/qpm/catalog/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmCatalog", id: "LIST" }] : [],
      }),
      qpmCatalogDelete: build.mutation<null, DeleteCatalogRequest>({
        query: (request) => ({
          url: `/qpm/catalog`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmCatalog", "QpmActiveCatalog"],
      }),
    }),
  });

export const {
  useQpmCatalogPutMutation,
  useQpmCatalogGetQuery,
  useQpmCatalogSetActiveMutation,
  useQpmCatalogGetActiveQuery,
  useQpmCatalogListQuery,
  useQpmCatalogDeleteMutation,
} = qpmCatalogServiceApi;
