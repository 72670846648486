import { Button } from "@/shared/components/ui/button";
import { Copy } from "lucide-react";
import { v4 } from "uuid";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useRltOfferCreateFromOldMutation } from "@/services/backend/rlt/offers/service";
import { useEffect, useState } from "react";
import t from "@/lang/lang";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

interface Props {
  offerId: string;
  processId: string;
  onCopy: (offerId: string) => void;
}

export function CopyOfferButton({ offerId, processId, onCopy }: Props) {
  const [id, setId] = useState(v4());
  const [copy, { isLoading, error, isSuccess, reset }] =
    useRltOfferCreateFromOldMutation();
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  if (isSuccess) {
    onCopy(id);
    reset();
    setId(v4());
  }

  const disabled = isLoading || !process;

  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  const onClick = () => {
    if (disabled) {
      return;
    }

    copy({
      oldOfferId: offerId,
      id,
      processId: process.id,
      companyId: process.companyId,
      branchId: process.branchId,
      customerId: process.customerId,
    });
  };

  return (
    <Button onClick={onClick} size="sm" disabled={disabled}>
      <Copy className="mr-2 h-4 w-4" />
      <span>{t("Daten übernehmen")}</span>
    </Button>
  );
}
