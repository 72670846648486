import { cn } from "@/shared/lib/utils";
import { NavLink } from "react-router";
import { HTMLAttributes } from "react";

export type NavLinkElement = {
  to: string;
  label: string;
};

type MainNavProps = {
  navList: NavLinkElement[];
} & HTMLAttributes<HTMLElement>;

export function MainNav({ className, navList, ...props }: MainNavProps) {
  return (
    <nav
      className={cn("flex items-center space-x-4 px-6 lg:space-x-6", className)}
      {...props}
    >
      {navList.map(({ to, label }) => (
        <NavLink
          key={to}
          to={to}
          className={({ isActive }) =>
            isActive
              ? "hover:text-primary text-sm font-medium transition-colors"
              : "text-muted-foreground hover:text-primary text-sm font-medium transition-colors"
          }
        >
          {label}
        </NavLink>
      ))}
    </nav>
  );
}
