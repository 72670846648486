import { ArtefactMeta } from "@/services/backend/qpm/artefact-meta/types";
import { Filter } from "@/shared/lib/filter/filter";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { Address } from "@/services/backend/addresses/address/address";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";

export type NewOfferRequest = {
  processId: string;
  artefactMetaId: string;
};

export type Offer = OfferCalculationResult & {
  artefactData: ArtefactMeta;
  date: string;
  representative: Representative;
  postalAddress: Address;
  serviceAddress: Address;
  discount: OfferDiscount;
  materialCost: OfferMaterialCost;
  hirePurchaseFiv: HirePurchase;
  coverLetterText: OfferCoverLetterTextBlocks;
};

export type PutCoverLetterTextBlocksRequest = OfferCoverLetterTextBlocks & {
  offerId: string;
};

export type OfferCalculationResult = {
  installationResult: OfferInstallationResult;
  maintenanceResult: OfferMaintenanceResult;
  rentingResult: OfferRentingResult;
};

export type OfferInstallationResult = {
  qpm: OfferResultEntry;
  fiv: OfferResultEntry;
  aLoRa: OfferResultEntry;
};

export type OfferMaintenanceResult = {
  qpm: OfferResultEntry;
  scb: OfferResultEntry;
  fiv: OfferResultEntry;
  aLoRa: OfferResultEntry;
};

export type OfferResultEntry = {
  totalCost: number;
  costPerMeasure: number;
  amountOfMeasures: number;
};

export type OfferRentingResult = {
  rentingDuration: number;
  rentPerMonth: number;
  rentPerMonthWithMaintenance: number;
};

export type OfferMaterialCost = {
  qpm: OfferMaterialPriceList;
  fiv: OfferMaterialPriceList;
  aLoRa: OfferMaterialPriceList;
};

export type OfferMaterialPriceList = {
  totalAmount: number;
  totalCost: number;
  positions: OfferMaterialPricePosition[];
};

export type OfferMaterialPricePosition = {
  monitorName: string;
  amount: number;
  price: number;
  totalCost: number;
};

export type HirePurchase = {
  isActive: boolean;
  isOptional: boolean;
  monitorType: MonitorTypeKey;
  monitorAmount: number;
  totalCost: number;
  surcharge: number;
  totalCostSurcharged: number;
  durationMonths: number;
  ratePerMonth: number;
};

export type OfferDiscount = {
  isApplied: boolean;
  totalDiscountPercentage: number;
  discountTiers: DiscountTierList;
};

export type DiscountTierList = {
  tiers: DiscountTier[];
};

export type DiscountTier = {
  minimumMonitorAmount: number;
  percentage: number;
  isApplied: boolean;
};

export type PutRequest = Offer;

export type OfferList = {
  artefacts: ArtefactMeta[];
};

export type ListOfferRequest = {
  process: Filter<string>;
  customer: Filter<string>;
  immutable: Filter<boolean>;
  owner: Filter<string>;
  ownerType: Filter<string>;
  parent: Filter<string>;
  calculation: Filter<string>;
};

export type ShowOfferRequest = {
  artefactMetaId: string;
};

export type ApplyCalculationDataRequest = {
  processId?: string;
  offerArtefactId?: string;
  calculationArtefactId: string;
};

export type SetOfferDateRequest = {
  offerId: string;
  date: string;
};

export type UpdatePostalAddressRequest = {
  id: string;
  postalAddress: Address;
};

export type UpdateServiceAddressRequest = {
  id: string;
  serviceAddress: Address;
};

export type UpdateRepresentativeRequest = {
  id: string;
  representative: Representative;
};

export type UpdateHirePurchaseRequest = {
  offerId: string;
  isActive: boolean;
  isOptional: boolean;
  monitorType: MonitorTypeKey;
  surcharge: number;
  durationMonths: number;
};

export type DeleteRequest = {
  id: string;
};

export enum OfferDocument {
  ALL = "all",
  OFFER = "offer",
  MonitorCatalog = "monitorCatalog",
  QualityAssuranceRef = "qualityAssuranceRef",
  ServiceProgram = "serviceProgram",
  Contract = "contract",
  ORDER = "order",
}

export interface PreviewPDFRequest {
  offerId: string;
  document: OfferDocument;
}

export interface PDFRequest {
  offerId: string;
  document: OfferDocument;
  download: boolean;
  downloadBy: string;
}

export type OfferCoverLetterTextBlocks = {
  coverLetterSalutation: string;
  coverLetterCustomText: string;
  coverLetterConceptText: string;
  coverLetterCustomerRequirements: string;
};
