import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { NavLink, useNavigate, useParams } from "react-router";
import { CatalogInfoCard } from "@/routes/base-data/processes/qpm/catalog/[catalogId]/_components/catalog-info-card";
import { MonitorTypeCard } from "@/routes/base-data/processes/qpm/catalog/[catalogId]/_components/monitor-type-card";
import { Button } from "@/shared/components/ui/button";
import { ArrowLeft, Loader2 } from "lucide-react";
import { useQpmCatalogGetQuery } from "@/services/backend/qpm/catalog/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useEffect, useState } from "react";
import { ConsumableCatalog } from "@/routes/base-data/processes/qpm/catalog/[catalogId]/_components/consumable-catalog";

export function CatalogRoutePage() {
  const params = useParams();

  const {
    data: catalog,
    error,
    isLoading,
  } = useQpmCatalogGetQuery(
    { id: params.catalogId! },
    { skip: !params.catalogId },
  );

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Loader2 className="animate-spin" />;
  }

  if (!params.catalogId) {
    return <div>{t("Katalog ID nicht gefunden.")}</div>;
  }

  if (!error && !isLoading && !catalog) {
    return <CatalogNotFoundCard catalogId={params.catalogId} />;
  }

  return (
    catalog && (
      <div className="flex flex-col gap-2">
        <CatalogInfoCard catalog={catalog} />
        <MonitorTypeCard catalogId={params.catalogId} />
        <div>
          <CardHeader>
            <div className="flex items-end justify-between">
              <CardTitle>{t("Verbrauchsmaterialien")}</CardTitle>
            </div>
          </CardHeader>
          <CardContent>
            <ConsumableCatalog catalogId={params.catalogId} />
          </CardContent>
        </div>
      </div>
    )
  );
}

function CatalogNotFoundCard({ catalogId }: { catalogId: string }) {
  const REDIRECT_TIMER_SECONDS = 10;
  const navigate = useNavigate();
  const [seconds, setSeconds] = useState(REDIRECT_TIMER_SECONDS);

  useEffect(() => {
    const interval = setInterval(() => {
      setSeconds((prev) => prev - 1);
    }, 1000);

    const timeout = setTimeout(() => {
      navigate("..");
    }, REDIRECT_TIMER_SECONDS * 1000);

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [navigate]);

  return (
    <Card>
      <CardHeader>
        <div className="flex flex-col gap-4">
          <NavLink to="./..">
            <Button variant="outline" size="sm" className="m-0">
              <ArrowLeft className="mr-2 h-4 w-4" />
              <span>{t("Übersicht")}</span>
            </Button>
          </NavLink>
          <CardTitle>{t("Katalog nicht gefunden!")}</CardTitle>
        </div>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-2">
          <span>
            {t("Der Katalog mit der ID")} {catalogId}{" "}
            {t("konnte nicht gefunden werden.")}
          </span>
          <span>
            {t("Sie werden in ")} {seconds}{" "}
            {t("Sekunden zurück auf die Übersichtsseite weitergeleitet")}
          </span>
        </div>
      </CardContent>
    </Card>
  );
}
