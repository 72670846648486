import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { useState } from "react";
import { Filter, FilterMode } from "@/shared/lib/filter/filter";
import { Input } from "@/shared/components/ui/input";
import {
  Destination,
  SourcesRequest,
  useCpySourcesQuery,
} from "@/services/backend/cpy/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RefreshCw } from "lucide-react";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { CopyButton } from "@/routes/gesec/processes/_components/copy-data-dialog/copy-button";
import { ArtefactPreview } from "@/shared/components/domain/artefact/preview/artefact-preview";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { TableCard } from "@/shared/components/ui/table-card";

/**
 * Source
 * @param destination the destination to copy to.
 * @param processId optional process id to be used to filter the initial data.
 * @param onCopySuccess is a callback that is called when the copy was successful.
 */
export function SourceTable({
  destination,
  processId,
  onCopySuccess,
}: {
  destination: Destination;
  processId?: string;
  onCopySuccess?: () => void;
}) {
  const [request, setRequest] = useState<SourcesRequest>({
    destination,
    artefactNumber: "",
    process: {
      active: true,
      mode: FilterMode.Or,
      values: processId ? [processId] : [],
    },
    createdAt: {
      active: true,
      mode: FilterMode.Or,
      values: [],
    } as Filter<string>,
    updatedAt: {
      active: true,
      mode: FilterMode.Or,
      values: [],
    } as Filter<string>,
    company: {
      active: true,
      mode: FilterMode.Or,
      values: [],
    } as Filter<string>,
    branch: {
      active: true,
      mode: FilterMode.Or,
      values: [],
    } as Filter<string>,
    customer: {
      active: true,
      mode: FilterMode.Or,
      values: [],
    } as Filter<string>,
    serviceObject: {
      active: true,
      mode: FilterMode.Or,
      values: [],
    } as Filter<string>,
  });

  const {
    data: list,
    isLoading,
    isFetching,
    error,
  } = useCpySourcesQuery(request);

  return (
    <div>
      <div className="flex items-center justify-between">
        <div className="mb-2 flex items-center space-x-2">
          <Input
            placeholder={t("Artefact Nummer")}
            className="h-9 max-w-[140px]"
            value={request.artefactNumber}
            onChange={(e) =>
              setRequest({ ...request, artefactNumber: e.target.value })
            }
          />
          {/* add filters here */}
        </div>
        {isFetching && <RefreshCw className="h-4 w-4 animate-spin" />}
      </div>
      <TableCard>
        <Table className="w-[966px]">
          <TableHeader className="block">
            <TableRow className="block">
              <TableHead className="w-[130px]">{t("Ort")}</TableHead>
              <TableHead className="w-[180px]">{t("Dokument")}</TableHead>
              <TableHead className="w-[150px]">{t("Nummer")}</TableHead>
              <TableHead className="w-[160px]">{t("Erstellt am")}</TableHead>
              <TableHead className="w-[160px]">{t("Geändert am")}</TableHead>
              <TableHead className="w-[186px]">{t("Aktionen")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody className="block max-h-[400px] overflow-x-hidden overflow-y-scroll">
            {isLoading && (
              <TableRow>
                <TableCell colSpan={6}>{t("Lade Daten ...")}</TableCell>
              </TableRow>
            )}
            {error && (
              <TableRow>
                <TableCell colSpan={6}>
                  <RTKQueryErrorAlert
                    error={error}
                    ignoreValidationError={false}
                  />
                </TableCell>
              </TableRow>
            )}
            {list?.sources.map((source) => (
              <TableRow key={source.id} className="block *:py-1">
                <TableCell className="w-[130px]">{source.location}</TableCell>
                <TableCell className="w-[180px]">{source.document}</TableCell>
                <TableCell className="w-[150px]">{source.number}</TableCell>
                <TableCell className="w-[160px]">
                  {formatDate(source.createdAt)}, {formatTime(source.createdAt)}
                </TableCell>
                <TableCell className="w-[160px]">
                  {formatDate(source.updatedAt)}, {formatTime(source.updatedAt)}
                </TableCell>
                <TableCell className="w-[186px]">
                  <div className="flex items-center space-x-2">
                    <ArtefactPreview
                      artefactId={source.id}
                      artefactKind={source.document as ArtefactKind}
                      processKind={processKind(source.location)}
                    />
                    <CopyButton
                      destination={destination}
                      source={source}
                      onSuccess={onCopySuccess}
                    />
                  </div>
                </TableCell>
              </TableRow>
            ))}
            {list?.sources.length === 0 && (
              <TableRow>
                <TableCell colSpan={6}>{t("Keine Daten verfügbar.")}</TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </TableCard>
    </div>
  );
}

function processKind(location: string): ProcessKind {
  switch (location) {
    case "gsm.htz":
      return ProcessKind.HTZ;
    case "gsm.qpm":
      return ProcessKind.QPM;
    case "gsm.rlt":
      return ProcessKind.RLT;
    case "gsm.vbs":
      return ProcessKind.VBS;
    default:
      return ProcessKind.UNDEFINED;
  }
}
