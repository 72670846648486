import {
  useErrArtefactNotReady,
  useSetErrArtefactNotReadyFromRtk,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Separator } from "@/shared/components/ui/separator";
import {
  ArtefactNotReadyAlert,
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsDownload,
  ArtefactSheetActionsHeader,
  ArtefactSheetContent,
  ArtefactSheetHeader,
  ArtefactSheetImmutabilityWarning,
  ArtefactSheetMain,
  ArtefactSheetPreview,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { Download } from "lucide-react";
import { useDownloadPdf } from "@/shared/lib/utilities/download-pdf";
import { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  OfferDocument,
  useVbsOfferDownloadPDFMutation,
  useVbsOfferPreviewPDFQuery,
} from "@/services/backend/vbs/offers/service";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import {
  ContractDocument,
  useVbsContractDownloadPDFMutation,
  useVbsContractPreviewPDFQuery,
} from "@/services/backend/vbs/contracts/service";
import { Offer } from "@/services/backend/vbs/offers/offer";
import { isZeroUUID } from "@/shared/lib/utilities/uuid";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function OfferPdf({ offer }: { offer: Offer }) {
  const { data, isLoading, error } = useVbsOfferPreviewPDFQuery({
    id: offer.id,
    document: OfferDocument.ALL,
  });

  const {
    data: contractData,
    isLoading: contractIsLoading,
    error: contractError,
  } = useVbsContractPreviewPDFQuery({
    id: offer.contractId,
    document: ContractDocument.CONTRACT,
  });

  const { notReadyError } = useErrArtefactNotReady();

  return (
    <ArtefactSheet>
      <ArtefactSheetTrigger asChild>
        <Button variant="outline">PDF</Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="max-h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>{t("Download PDF")}</ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          <Tabs className="md:col-span-3" defaultValue="offer">
            <TabsList>
              <TabsTrigger value="offer">{t("Angebot")}</TabsTrigger>
              <TabsTrigger value="contract">{t("Vertrag")}</TabsTrigger>
            </TabsList>
            <TabsContent value="offer">
              <ArtefactSheetPreview
                objectURL={data?.objectURL}
                isLoading={isLoading}
                error={error}
              />
            </TabsContent>
            <TabsContent value="contract">
              <RTKQueryErrorAlert error={contractError} />
              {isZeroUUID(offer.contractId) && (
                <div className="text-muted-foreground text-center">
                  {t("Kein Vertrag vorhanden")}
                </div>
              )}
              <ArtefactSheetPreview
                objectURL={contractData?.objectURL}
                isLoading={contractIsLoading}
                error={contractError}
              />
            </TabsContent>
          </Tabs>
          <ArtefactSheetActions>
            <ArtefactSheetActionsHeader
              title={t("Angebot")}
              artefactNumber={<ArtefactNumberById artefactId={offer.id} />}
            />
            <ArtefactSheetActionsContent>
              <ArtefactNotReadyAlert
                error={notReadyError}
                artefact={t("Angebot")}
              />
              <ArtefactSheetImmutabilityWarning
                title={t("Hinweis")}
                description={t(
                  "Wenn ein Angebot heruntergeladen wird, wird es festgeschrieben. Es kann dann nicht mehr geändert werden.",
                )}
              />
              <Separator />
              <ArtefactSheetActionsDownload>
                <DownloadDocumentButton
                  offerId={offer.id}
                  document={OfferDocument.ALL}
                  text={t("Alle Dokumente")}
                />
                <DownloadDocumentButton
                  offerId={offer.id}
                  document={OfferDocument.OFFER}
                  text={t("Angebot")}
                />
                <DownloadDocumentButton
                  offerId={offer.id}
                  document={OfferDocument.TREATMENT}
                  text={t("Leistungsverzeichnis")}
                />
                <DownloadDocumentButton
                  offerId={offer.id}
                  document={OfferDocument.ORDER}
                  text={t("Auftrag")}
                />
                <Separator className="my-2" />
                <DownloadContractButton
                  contractId={offer.contractId}
                  document={ContractDocument.CONTRACT}
                  text={t("Vertrag")}
                />
                <DownloadContractButton
                  contractId={offer.contractId}
                  document={ContractDocument.TREATMENT}
                  text={t("Vertrag LV")}
                />
              </ArtefactSheetActionsDownload>
            </ArtefactSheetActionsContent>
          </ArtefactSheetActions>
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function DownloadDocumentButton({
  offerId,
  document: documentType,
  text,
}: {
  offerId: string;
  document: OfferDocument;
  text: string;
}) {
  const [open, setOpen] = useState(false);
  const [download, { isLoading, error, data, reset }] =
    useVbsOfferDownloadPDFMutation();
  const { user } = useAuth();

  useDownloadPdf(data, reset);

  useSetErrArtefactNotReadyFromRtk(error, () => {
    setOpen(false);
    reset();
  });

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: offerId,
      document: documentType,
      userId: user?.id ?? "",
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="justify-start">
          <Download className="mr-4 h-5 w-5" /> {text}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Angebot herunterladen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Wenn ein Angebot heruntergeladen wird, wird es festgeschrieben. Es kann dann nicht mehr geändert werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doDownload} disabled={isLoading}>
            {isLoading ? (
              <Download className="mr-4 h-5 w-5 animate-bounce" />
            ) : (
              <Download className="mr-4 h-5 w-5" />
            )}
            <span>{t("Herunterladen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function DownloadContractButton({
  contractId,
  document: documentType,
  text,
}: {
  contractId: string;
  document: ContractDocument;
  text: string;
}) {
  const [open, setOpen] = useState(false);
  const [download, { isLoading, error, data, reset }] =
    useVbsContractDownloadPDFMutation();
  const { user } = useAuth();

  useDownloadPdf(data, reset);

  useSetErrArtefactNotReadyFromRtk(error, () => {
    setOpen(false);
    reset();
  });

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: contractId,
      document: documentType,
      downloadBy: user?.id ?? "",
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={isZeroUUID(contractId)}>
        <Button variant="outline" className="justify-start">
          <Download className="mr-4 h-5 w-5" /> {text}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Vertrag herunterladen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Wenn ein Vertrag heruntergeladen wird, wird er festgeschrieben. Er kann dann nicht mehr geändert werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doDownload} disabled={isLoading}>
            {isLoading ? (
              <Download className="mr-4 h-5 w-5 animate-bounce" />
            ) : (
              <Download className="mr-4 h-5 w-5" />
            )}
            <span>{t("Herunterladen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
