import { User } from "@/services/backend/auth/user/user";
import { v4 } from "uuid";
import { Role } from "@/shared/lib/authorization/roles";

export interface DevToken extends User {
  token: string;
}

export const devToken: DevToken = {
  token: import.meta.env.VITE_DEV_TOKEN,
  id: v4(),
  roles: import.meta.env.VITE_DEV_TOKEN_ROLES
    ? (import.meta.env.VITE_DEV_TOKEN_ROLES.split(",") as Role[])
    : [],
  companies: import.meta.env.VITE_DEV_TOKEN_COMPANIES
    ? import.meta.env.VITE_DEV_TOKEN_COMPANIES.split(",")
    : [],
  branches: import.meta.env.VITE_DEV_TOKEN_BRANCHES
    ? import.meta.env.VITE_DEV_TOKEN_BRANCHES.split(",")
    : [],
  scopes: import.meta.env.VITE_DEV_TOKEN_SCOPES,
};
