import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Report } from "@/services/backend/htz/report/report";
import {
  AysidStatus,
  useSamplesLabApiStatusQuery,
} from "@/services/backend/samples/labapi/service";
import {
  useSamplesDeliveryListQuery,
  useSamplesDeliveryTransferResultsMutation,
} from "@/services/backend/samples/delivery/service";
import { Delivery } from "@/services/backend/samples/delivery/delivery";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { SampleNumber } from "@/shared/components/domain/samples/sample-number";
import { LabApiKey } from "@/services/backend/samples/lab/laboratory";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { H4 } from "@/shared/components/ui/typography";
import { formatDate } from "@/shared/lib/utilities/date";
import { LaboratoryName } from "@/shared/components/domain/samples/laboratory-name";
import { ScrollArea, ScrollBar } from "@/shared/components/ui/scroll-area";
import { Card, CardContent, CardHeader } from "@/shared/components/ui/card";
import { Separator } from "@/shared/components/ui/separator";
import { RefreshCw } from "lucide-react";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";

export function TransferLabResultsDialog({ report }: { report: Report }) {
  const {
    data: deliveryList,
    isLoading,
    error,
  } = useSamplesDeliveryListQuery({
    artefact: {
      active: true,
      values: [report.workOrderId ?? ""],
    },
  });

  if (isLoading) {
    return (
      <Button className="animate-pulse" disabled>
        {t("Laborergebnisse abfragen")}
      </Button>
    );
  }

  if (error) {
    return null;
  }

  return (
    <Dialog>
      <DialogTrigger asChild disabled={report.immutable}>
        <Button>{t("Laborergebnisse abfragen")}</Button>
      </DialogTrigger>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle className="text-2xl">{t("Laborergebnisse")}</DialogTitle>
          <DialogDescription>
            {t(
              "Es werden nur die Ergebnisse aus Lieferungen angezeigt, welche relevant für den Bericht sind.",
            )}
          </DialogDescription>
        </DialogHeader>
        <ScrollArea className="max-h-[70vh]">
          <div className="space-y-6">
            {deliveryList!.deliveries.map((delivery) => (
              <DeliveryResults
                key={delivery.id}
                report={report}
                delivery={delivery}
              />
            ))}
            {deliveryList!.deliveries.length === 0 && (
              <div>{t("Keine Laborergebnisse verfügbar")}</div>
            )}
          </div>
          <Separator className="my-3" />
          <div className="flex justify-end">
            <DialogClose asChild>
              <Button variant="outline">{t("Abbrechen")}</Button>
            </DialogClose>
          </div>
          <ScrollBar />
        </ScrollArea>
      </DialogContent>
    </Dialog>
  );
}

function DeliveryResults({
  report,
  delivery,
}: {
  report: Report;
  delivery: Delivery;
}) {
  const {
    data: status,
    isLoading,
    error,
  } = useSamplesLabApiStatusQuery({
    labId: delivery.labId ?? "",
    referenceId: delivery.id,
  });

  if (isLoading) {
    return (
      <div className="animate-pulse">
        {t("Laborergebnisse für Lieferung werden abgefragt")} ...
      </div>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  switch (status?.labKey) {
    case LabApiKey.Aysid:
      return (
        <AysidDeliveryResults
          report={report}
          delivery={delivery}
          status={status.payload}
        />
      );
    default:
      return null;
  }
}

function AysidDeliveryResults({
  report,
  delivery,
  status,
}: {
  report: Report;
  delivery: Delivery;
  status: AysidStatus;
}) {
  const [transferResults, { isLoading, error, isSuccess, reset }] =
    useSamplesDeliveryTransferResultsMutation();
  const { toast } = useToast();

  const doTransfer = () => {
    if (isLoading) return;

    transferResults({ id: delivery.id! });
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Laborergebnisse kopiert"),
        variant: "success",
      });
      reset();
    }
  });

  return (
    <Card className="space-y-2">
      <CardHeader className="px-3 pt-2 pb-0">
        <H4 className="text-lg">
          {t(`Lieferung vom ${formatDate(delivery.sentAt)} an `)}
          <LaboratoryName id={delivery.labId!} />
        </H4>
      </CardHeader>
      <CardContent className="px-3 pb-3">
        <div>
          <div className="flex justify-between text-sm">
            <div>{t("Status")}</div>
            <div>{t(status.state)}</div>
          </div>
          <div className="flex justify-between text-sm">
            <div>{t("Fertige Proben")}</div>
            <div>{status.completedSampleSets}</div>
          </div>
          <div className="flex justify-between text-sm">
            <div>{t("Gesamt Proben")}</div>
            <div>{status.totalSampleSets}</div>
          </div>
        </div>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Probennummer")}</TableHead>
              <TableHead>{t("Art")}</TableHead>
              <TableHead>{t("Wert")}</TableHead>
              <TableHead>{t("Einheit")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {report.microbialAssessments?.map((assessment) => {
              const sample = status.results?.find(
                (r) => r.sampleSetId === assessment.sampleId,
              );

              return (
                <TableRow key={assessment.id}>
                  <TableCell className="py-1">
                    <SampleNumber
                      kind={assessment.kind}
                      id={assessment.sampleId!}
                    />
                  </TableCell>
                  <TableCell className="py-1">{t(assessment.kind)}</TableCell>
                  <TableCell className="py-1">{sample?.value}</TableCell>
                  <TableCell className="py-1">{sample?.unit}</TableCell>
                </TableRow>
              );
            })}
          </TableBody>
        </Table>
        <div className="space-y-2 pt-4">
          <RTKQueryErrorAlert error={error} />
          <Button className="w-full" disabled={isLoading} onClick={doTransfer}>
            {isLoading && <RefreshCw className="mr-2 h-4 w-4" />}
            <span>{t("Laborergebnisse kopieren")}</span>
          </Button>
        </div>
      </CardContent>
    </Card>
  );
}
