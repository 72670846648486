import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Representative } from "@/services/backend/representatives/representative/representative";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";
import { TextInput } from "@/shared/components/form/text-input";

interface RepresentativeCardProps {
  representative: Representative;
  onRepresentativeChange: (representative: Representative) => void;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}

export function RepresentativeCard({
  representative,
  onRepresentativeChange,
  title = t("Betreuer"),
  description = t("Kontakt für Rückfragen des Kunden."),
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: RepresentativeCardProps) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    return fieldErrors ? (fieldErrors[field] ?? []) : [];
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle>{title}</CardTitle>
        <CardDescription>{description}</CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <TextInput
          label={t("Name")}
          field="name"
          text={representative.name}
          onTextChange={(name) => {
            if (resetFieldError) resetFieldError("name");
            onRepresentativeChange({ ...representative, name });
          }}
          errors={errorMessages("name")}
          disabled={disabled}
        />
        <TextInput
          label={t("Mobil")}
          field="mobilephone"
          text={representative.mobilephone}
          onTextChange={(mobilephone) => {
            if (resetFieldError) resetFieldError("mobilephone");
            onRepresentativeChange({ ...representative, mobilephone });
          }}
          errors={errorMessages("mobilephone")}
          disabled={disabled}
        />
        <TextInput
          label={t("Telefon")}
          field="phone"
          text={representative.phone}
          onTextChange={(phone) => {
            if (resetFieldError) resetFieldError("phone");
            onRepresentativeChange({ ...representative, phone });
          }}
          errors={errorMessages("phone")}
          disabled={disabled}
        />
        <TextInput
          label={t("E-Mail")}
          field="email"
          text={representative.email}
          onTextChange={(email) => {
            if (resetFieldError) resetFieldError("email");
            onRepresentativeChange({ ...representative, email });
          }}
          errors={errorMessages("email")}
          disabled={disabled}
        />
      </CardContent>
    </Card>
  );
}

type Mutation<Req, Res> = Readonly<
  () => [
    (entity: Req) => Promise<{ data: Res } | { error: unknown }> | void,
    {
      isLoading: boolean;
      error: unknown;
      isSuccess: boolean;
      reset: () => void;
    },
  ]
>;

export function RepresentativeCardWithMutation<
  Req extends { id: string; representative: Representative },
  Res,
>({
  id,
  representative,
  mutation,
  title = t("Betreuer"),
  description = t("Kontakt für Rückfragen des Kunden."),
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: {
  id: string;
  representative: Representative;
  mutation: Mutation<Req, Res>;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}) {
  // @ts-expect-error - I do not understand the error. But it works.
  const [request, setRequest] = useState<Req>({ id, representative });
  // @ts-expect-error - I do know how to define an always fitting type for RTK query mutations.
  const [update, { isLoading, error, isSuccess, reset }] = mutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  const joinedFieldErrors = {
    ...fieldErrors,
    ...(rtkErrIsValidationError(error) ? error.data.errors : {}),
  } as { [key: string]: string[] };

  return (
    <RepresentativeCard
      representative={request.representative}
      // @ts-expect-error - I do not understand the error. But it works.
      onRepresentativeChange={(r) => setRequest({ id, representative: r })}
      title={title}
      description={description}
      fieldErrors={joinedFieldErrors}
      resetFieldError={resetFieldError}
      disabled={disabled}
    />
  );
}

export function RepresentativeCardSkeleton({
  title = t("Betreuer"),
  description = "Kontakt für Rückfragen des Kunden.",
}: {
  title?: string;
  description?: string;
}) {
  return (
    <Card className="animate-pulse">
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
      </CardHeader>
    </Card>
  );
}
