import { Button } from "@/shared/components/ui/button";
import { Plus } from "lucide-react";
import t from "@/lang/lang";
import { useVbsConfirmationCreateMutation } from "@/services/backend/vbs/confirmations/service";
import { v4 } from "uuid";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useNavigate } from "react-router";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

interface Props {
  processId: string;
}

export function CreateConfirmationButton({ processId }: Props) {
  const [create, { isLoading, error, data, isSuccess, reset }] =
    useVbsConfirmationCreateMutation();
  const { data: process } = useProcessesProcessShowQuery({ id: processId });
  const { toast } = useToast();
  const navigate = useNavigate();

  const disabled = !process || isLoading;

  const doCreate = () => {
    if (disabled) {
      return;
    }

    const id = v4();
    create({
      id,
      processId: process.id,
      companyId: process.companyId,
      branchId: process.branchId,
      customerId: process.customerId,
    });
  };

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
    }
    if (isSuccess) {
      navigate(data.id);
      reset();
    }
  }, [data?.id, error, isSuccess, navigate, toast, reset]);

  return (
    <Button onClick={doCreate} disabled={disabled}>
      <Plus className="mr-2 h-5 w-5" />
      <span>{t("Auftragsbestätigung")}</span>
    </Button>
  );
}
