import { backendApi } from "@/services/backend/api";
import {
  CreateCertificate,
  CreateCustomerCertificate,
  DeleteCertificate,
  DeleteCustomerCertificate,
  ListCertificates,
  ListCustomerCertificatesRequest,
  QPMCertificate,
  QPMCertificateList,
  QPMCustomerCertificate,
  QPMCustomerCertificateList,
} from "@/services/backend/qpm/certificates/types";

const qpmCertificateApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["QPMCertificate", "QPMCustomerCertificate"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmCertificateCreate: build.mutation<QPMCertificate, CreateCertificate>({
        query: (request) => ({
          url: `/qpm/certificate`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QPMCertificate"],
      }),
      qpmCertificateList: build.query<QPMCertificateList, ListCertificates>({
        query: () => ({
          url: `/qpm/certificate/list`,
          method: "GET",
        }),
        providesTags: (result) =>
          result ? [{ type: "QPMCertificate", id: "LIST" }] : [],
      }),
      qpmCertificateDelete: build.mutation<null, DeleteCertificate>({
        query: (request) => ({
          url: `/qpm/certificate`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QPMCertificate"],
      }),

      qpmCustomerCertificateCreate: build.mutation<
        QPMCustomerCertificate,
        CreateCustomerCertificate
      >({
        query: (request) => ({
          url: `/qpm/customer-certificate`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QPMCustomerCertificate"],
      }),
      qpmCustomerCertificateList: build.query<
        QPMCustomerCertificateList,
        ListCustomerCertificatesRequest
      >({
        query: (request) => ({
          url: `/qpm/customer-certificate/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QPMCustomerCertificate", id: "LIST" }] : [],
      }),
      qpmCustomerCertificateDelete: build.mutation<
        null,
        DeleteCustomerCertificate
      >({
        query: (request) => ({
          url: `/qpm/customer-certificate`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QPMCustomerCertificate"],
      }),
    }),
  });

export const {
  useQpmCertificateCreateMutation,
  useQpmCertificateListQuery,
  useQpmCertificateDeleteMutation,
  useQpmCustomerCertificateCreateMutation,
  useQpmCustomerCertificateListQuery,
  useQpmCustomerCertificateDeleteMutation,
} = qpmCertificateApi;
