import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  DataTable,
  DataTableContainer,
  useDataTableController,
} from "@/shared/components/ui/data-table";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RefreshCw } from "lucide-react";
import { ColumnDef } from "@tanstack/react-table";
import { NavLink } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { StructuralAssessmentCatalog } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog";
import { DeleteStructuralAssessmentCatalogDialog } from "@/routes/base-data/processes/htz/structural-assessment-catalogs/_components/delete-structural-assessment-catalog-dialog";
import { ActiveStructuralAssessmentCatalogCheckbox } from "@/routes/base-data/processes/htz/structural-assessment-catalogs/_components/active-structural-assessment-catalog-checkbox";
import { useHtzStructuralAssessmentCatalogListQuery } from "@/services/backend/htz/inspection/sacatalog/structural-assessment-catalog-service";
import { CreateStructuralAssessmentCatalogDialog } from "@/routes/base-data/processes/htz/structural-assessment-catalogs/_components/create-structural-assessment-catalog-dialog";

export function StructuralAssessmentCatalogsTable() {
  const {
    data: list,
    isLoading,
    error,
  } = useHtzStructuralAssessmentCatalogListQuery({});

  const catalogs = list?.catalogs || [];

  const table = useDataTableController(tableColumns, catalogs);

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader className="flex-row items-start justify-between">
        <div>
          <CardTitle>{t("Kataloge für Konstruktive Beurteilungen")}</CardTitle>
          <CardDescription>
            {t(
              "Hier können Kataloge für Vorlagen für konstruktive Beurteilungen erstellt werden.",
            )}
          </CardDescription>
        </div>
        <CreateStructuralAssessmentCatalogDialog />
      </CardHeader>
      <CardContent>
        <DataTableContainer>
          <DataTable
            table={table}
            columns={tableColumns}
            className="border-none"
          />
        </DataTableContainer>
      </CardContent>
    </Card>
  );
}

const tableColumns: ColumnDef<StructuralAssessmentCatalog>[] = [
  {
    header: t("Aktiv"),
    id: "activeCatalog",
    cell: ({ row }) => (
      <div className="ml-4">
        <ActiveStructuralAssessmentCatalogCheckbox
          catalogId={row.original.id}
        />
      </div>
    ),
  },
  {
    header: t("Katalogname"),
    accessorKey: "name",
    cell: ({ row }) => {
      const path = `/base-data/processes/htz/structural-assessment-catalogs/${row.original.id}`;
      return (
        <NavLink to={path}>
          <Button variant="link">{row.original.name}</Button>
        </NavLink>
      );
    },
  },
  {
    id: "actions",
    header: t("Aktionen"),
    cell: ({ row }) => (
      <div>
        <DeleteStructuralAssessmentCatalogDialog catalog={row.original} />
      </div>
    ),
  },
];

function Skeleton() {
  return (
    <Card>
      <CardHeader className="flex-row items-start justify-between">
        <div>
          <CardTitle>{t("Kataloge für Konstruktive Beurteilungen")}</CardTitle>
          <CardDescription>
            {t(
              "Hier können Kataloge für Vorlagen für konstruktive Beurteilungen erstellt werden.",
            )}
          </CardDescription>
        </div>
      </CardHeader>
      <CardContent>
        <RefreshCw className="animate-spin" />
        <span>{t("Lade Daten...")}</span>
      </CardContent>
    </Card>
  );
}
