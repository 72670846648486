import { useState } from "react";
import { SignRequest } from "@/services/backend/vbs/work-orders/service";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { SignatureDialog } from "@/shared/components/domain/signature/signature-dialog";
import t from "@/lang/lang";
import { useHtzWorkOrderSignMutation } from "@/services/backend/htz/work-order/service";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function SignatureButton(props: { workOrderId: string }) {
  const { workOrderId } = props;
  const [request, setRequest] = useState<SignRequest>({
    id: workOrderId,
    signature: "",
    dataFormat: "image/svg+xml:base64",
    nameOfSigner: "",
  });
  const [sign, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderSignMutation();

  // it was easier to use debounce to update the persistence state context
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    sign,
    isLoading,
    error,
    isSuccess,
    reset,
    1,
    {
      toastError: true,
    },
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const hasSigErr = hasFieldError(notReadyError, "signatureId");

  return (
    <div className={hasSigErr ? "border-destructive rounded-md border" : ""}>
      <SignatureDialog
        title={t("Arbeitsschein bestätigen")}
        name=""
        onConfirm={(data) => {
          setRequest({
            ...request,
            signature: data.signature,
            nameOfSigner: data.name,
          });
          resetNotReadyErrorField("signatureId");
        }}
      />
    </div>
  );
}
