import { useState } from "react";
import { Filter, FilterMode } from "@/shared/lib/filter/filter";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { Check, ListFilter, RefreshCw, Search } from "lucide-react";
import t from "@/lang/lang";
import { useDebounce } from "@/shared/lib/debounce/debounce";
import { useCustomersCustomerListQuery } from "@/services/backend/customers/service";
import { ScrollArea } from "@/shared/components/ui/scroll-area";
import { cn } from "@/shared/lib/utils";
import { CustomerName } from "@/shared/components/domain/customer/customer-name";

export function CustomerFilter({
  filter,
  onFilterChange,
}: {
  filter: Filter<string>;
  onFilterChange: (filter: Filter<string>) => void;
}) {
  const [open, setOpen] = useState(false);
  const [search, setSearch] = useState("");
  const { customers, searching, error } = useSearchCustomer(search);

  const selectCustomer = (customerId: string) => () => {
    if (filter.values?.includes(customerId)) {
      return;
    }

    onFilterChange({
      ...filter,
      active: true,
      values: [...(filter.values ?? []), customerId],
    });

    setOpen(false);
    setSearch("");
  };

  const unselectCustomer = (customerId: string) => () => {
    const values = filter.values?.filter((v) => v !== customerId) ?? [];

    onFilterChange({
      ...filter,
      active: values.length > 0,
      values,
    });

    if (values.length === 0) {
      setOpen(false);
      setSearch("");
    }
  };

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
          size="sm"
        >
          <ListFilter className="mr-2 h-4 w-4" />
          <span>{t("Kunden")}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-[300px] p-0" align="start">
        {filter.values && filter.values.length > 0 && (
          <div className="text-foreground border-b p-1">
            {filter.values?.map((customerId) => (
              // eslint-disable-next-line jsx-a11y/control-has-associated-label
              <button
                type="button"
                key={customerId}
                onClick={unselectCustomer(customerId)}
                className="hover:bg-accent flex w-full cursor-default items-center rounded-sm px-2 py-1.5 text-sm outline-none select-none"
              >
                <Check className="mr-2 h-4 w-4" />
                <CustomerName customerId={customerId} />
              </button>
            ))}
          </div>
        )}
        <div className="bg-popover text-popover-foreground flex h-full w-full flex-col overflow-hidden rounded-md">
          <div className="flex items-center border-b px-3">
            <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
            <input
              className="placeholder:text-muted-foreground flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-none disabled:cursor-not-allowed disabled:opacity-50"
              value={search}
              onChange={(e) => setSearch(e.target.value)}
            />
          </div>
        </div>
        {searching && (
          <div className="text-muted-foreground flex items-center p-3 text-sm">
            <RefreshCw className="mr-2 h-4 w-4 animate-spin" /> {t("Suche ...")}
          </div>
        )}
        {!searching && error && (
          <div className="text-muted-foreground p-3 text-sm">{t("Fehler")}</div>
        )}
        {!searching &&
          !error &&
          search.length > 3 &&
          customers.length === 0 && (
            <div className="text-muted-foreground p-3 text-sm">
              {t("Keine Kunden gefunden")}
            </div>
          )}
        {customers.length > 0 && !error && (
          <ScrollArea className="[&>[data-radix-scroll-area-viewport]]:max-h-[200px]">
            <div className="text-foreground p-1">
              {customers.map((customer) => (
                <button
                  type="button"
                  key={customer.id}
                  onClick={selectCustomer(customer.id)}
                  className="hover:bg-accent flex w-full cursor-default items-center rounded-sm px-2 py-1.5 text-sm outline-none select-none"
                >
                  <Check
                    className={cn(
                      "mr-2 h-4 w-4",
                      filter.values?.includes(customer.id)
                        ? "opacity-100"
                        : "opacity-0",
                    )}
                  />
                  {customer.name}
                </button>
              ))}
            </div>
          </ScrollArea>
        )}
      </PopoverContent>
    </Popover>
  );
}

function useSearchCustomer(search: string) {
  const debouncedSearch = useDebounce(search, 250);

  const { data, isLoading, isFetching, error } = useCustomersCustomerListQuery(
    {
      name: {
        active: true,
        mode: FilterMode.Like,
        values: [debouncedSearch],
      },
      limit: 10,
    },
    { skip: !debouncedSearch || debouncedSearch.length < 3 },
  );

  const customers = data?.customers ?? [];

  const searching = isLoading || isFetching;

  return { customers, searching, error };
}
