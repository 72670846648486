import {
  useQpmCalculationDeleteMutation,
  useQpmCalculationShowQuery,
} from "@/services/backend/qpm/calculations/service";
import { useNavigate, useParams } from "react-router";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { CalculationNavLinkType } from "@/services/backend/qpm/calculations/enum";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";
import { useToast } from "@/shared/hooks/use-toast";
import { useEffect } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Trash2 } from "lucide-react";
import { SelectRequirementButtonComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/select-requirement-component";

export function CalculationRoute() {
  const { calculationId } = useParams();
  const {
    data: calculation,
    isLoading,
    error,
  } = useQpmCalculationShowQuery(
    { artefactMetaId: calculationId! },
    { skip: !calculationId },
  );

  const links = [
    { to: CalculationNavLinkType.MaterialCost, label: t("Materialkosten") },
    { to: CalculationNavLinkType.Installation, label: t("Einrichtung") },
    { to: CalculationNavLinkType.Maintenance, label: t("Service") },
    { to: CalculationNavLinkType.Rental, label: t("Miete") },
    { to: CalculationNavLinkType.TotalOverview, label: t("Gesamtübersicht") },
  ];

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <PersistenceContextProvider>
      <ArtefactPage>
        <ArtefactPageHeader>
          <ArtefactPageNavigation>
            {links.map((link) => (
              <ArtefactPageNavigationLink to={link.to}>
                {link.label}
              </ArtefactPageNavigationLink>
            ))}
          </ArtefactPageNavigation>
          {calculation && (
            <ArtefactPageActions>
              <div className="flex items-center justify-between gap-2">
                <span className="text-sm">{t("Basis-Bestandsaufnahme")}:</span>
                <SelectRequirementButtonComponent
                  calculationId={calculationId}
                  processId={calculation.artefactData.processId}
                  selectedRequirementId={
                    calculation.artefactData?.child?.artefact?.linkedArtefacts
                      ?.requirement?.linkedArtefact?.id
                  }
                />
              </div>
              <PersistenceStateUsingContext className="mr-2" />
              <DeleteCalculationDialog
                calculationId={calculation.artefactData.id}
              />
            </ArtefactPageActions>
          )}
        </ArtefactPageHeader>
        <ArtefactPageContent>
          <ArtefactPageNavigationOutlet />
        </ArtefactPageContent>
      </ArtefactPage>
    </PersistenceContextProvider>
  );
}

function DeleteCalculationDialog(props: { calculationId: string }) {
  const { calculationId } = props;
  const [deleteCalculation, { isLoading, error, isSuccess, reset }] =
    useQpmCalculationDeleteMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteCalculation({ id: calculationId });
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Kalkulation gelöscht"),
        variant: "success",
      });
      navigate(`..`);
      reset();
    }
  }, [error, isSuccess, navigate, reset, toast]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Kalkulation wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
