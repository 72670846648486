import { NavLink } from "react-router";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { formatDate } from "@/shared/lib/utilities/date";
import { useVbsWorkOrderListQuery } from "@/services/backend/vbs/work-orders/service";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { StateInfo } from "@/services/backend/vbs/work-orders/state";
import { BranchName } from "@/shared/components/domain/branch/branch-name";
import { Skeleton } from "@/shared/components/ui/skeleton";

export function WorkOrderTable({ processId }: { processId: string }) {
  const {
    data: list,
    isLoading,
    error,
  } = useVbsWorkOrderListQuery({
    process: { active: true, values: [processId!] },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Status")}</TableHead>
          <TableHead className="whitespace-nowrap">
            {t("Ausführende NL")}
          </TableHead>
          <TableHead>{t("Teamleiter")}</TableHead>
          <TableHead>{t("Beginn")}</TableHead>
          <TableHead className="whitespace-nowrap">{t("% erledigt")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {isLoading && (
          <TableRow>
            {[...Array(7)].map((i) => (
              <TableCell key={i}>
                <Skeleton className="h-2 w-32" />
              </TableCell>
            ))}
          </TableRow>
        )}
        {list?.workOrders.map((workOrder) => (
          <TableRow key={workOrder.id}>
            <TableCell>
              <NavLink to={`../../${workOrder.id}`}>
                <ArtefactNumberById artefactId={workOrder.id} />
              </NavLink>
            </TableCell>
            <TableCell>
              <StateInfo state={workOrder.state} />
            </TableCell>
            <TableCell>
              <BranchName branchId={workOrder.executingBranchId} />
            </TableCell>
            <TableCell className="whitespace-nowrap">
              {workOrder.teamLeaderId}
            </TableCell>
            <TableCell align="right">
              {formatDate(workOrder.beginDate)}
            </TableCell>
            <TableCell align="right">
              {workOrder.percentageCompleted} %
            </TableCell>
            <TableCell>
              <NavLink to={`${workOrder.id}`}>
                <Button size="sm" variant="outline">
                  <Search className="h-4 w-4" />
                </Button>
              </NavLink>
            </TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
}
