import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Plus, RefreshCw } from "lucide-react";
import { useNavigate } from "react-router";
import { v4 } from "uuid";
import { useEffect, useState } from "react";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { useRltOrderPutMutation } from "@/services/backend/rlt/orders/service";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

export function CreateOrderButton({ processId }: { processId: string }) {
  const navigate = useNavigate();

  const [id, setId] = useState(v4());
  const [create, { error, isLoading, isSuccess, reset }] =
    useRltOrderPutMutation();
  const { data: process } = useProcessesProcessShowQuery({ id: processId });

  useEffect(() => {
    if (isSuccess) {
      navigate(`${id}`);
      reset();
      setId(v4());
    }
  }, [id, isSuccess, navigate, reset]);

  const disabled = !process || isLoading;

  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error), variant: "destructive" });
      reset();
      setId(v4());
    }
  }, [error, reset, toast]);

  const doCreate = () => {
    if (disabled) {
      return;
    }

    create({
      id,
      processId: process.id,
      companyId: process.companyId,
      branchId: process.branchId,
      customerId: process.customerId,
      offerId: null,
      date: null,
      channel: null,
      customerReference: null,
      comment: null,
    });
  };

  return (
    <Button onClick={doCreate} disabled={disabled}>
      {isLoading ? (
        <RefreshCw className="h-4 w-4 animate-spin" />
      ) : (
        <Plus className="h-5 w-5" />
      )}
      <span>{t("Auftragseingang")}</span>
    </Button>
  );
}
