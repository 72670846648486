import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { LabData, Report } from "@/services/backend/htz/report/report";
import {
  UpdateLaboratoriesRequest,
  useHtzReportUpdateLaboratoriesMutation,
} from "@/services/backend/htz/report/service";
import { useState } from "react";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Button } from "@/shared/components/ui/button";
import { Edit, Trash2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { TextInput } from "@/shared/components/form/text-input";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { inputValidationErrorMessages } from "@/shared/components/ui/input-error-messages";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { Laboratory } from "@/services/backend/samples/lab/laboratory";
import { useSamplesLaboratoryListQuery } from "@/services/backend/samples/lab/service";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";

export function LaboratoriesCard({ report }: { report: Report }) {
  const [request, setRequest] = useState<UpdateLaboratoriesRequest>({
    id: report.id,
    laboratories: report.laboratories,
  });
  const [patch, { isLoading, error, isSuccess, reset }] =
    useHtzReportUpdateLaboratoriesMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    patch,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    { toastError: true },
  );

  const { resetNotReadyErrorField } = useErrArtefactNotReady();

  const onAdd = (lab: LabData) => {
    if (request.laboratories.some((l) => l.labId === lab.labId)) {
      return;
    }
    resetNotReadyErrorField("laboratories");
    setRequest({
      ...request,
      laboratories: [...request.laboratories, lab],
    });
  };

  const onLaboratoryChange = (lab: LabData) => {
    setRequest({
      ...request,
      laboratories: request.laboratories.map((l) =>
        l.labId === lab.labId ? lab : l,
      ),
    });
  };

  const onLaboratoryDelete = (lab: LabData) => {
    setRequest({
      ...request,
      laboratories: request.laboratories.filter((l) => l.labId !== lab.labId),
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="relative">
          <span>{t("Labore")}</span>
          <AddLaboratoryDropdown
            reportId={report.id}
            onAdd={onAdd}
            disabled={report.immutable}
          />
        </CardTitle>
        <CardDescription>
          {t("Daten der Labore, welche Auswertungen gemacht haben.")}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>{t("Labor")}</TableHead>
              <TableHead>{t("Aktionen")}</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {request.laboratories.map((lab) => (
              <TableRow>
                <TableCell className="w-full">{lab.name}</TableCell>
                <TableCell className="flex space-x-2 py-1.5 text-right">
                  <PutLaboratoryDialog
                    laboratory={lab}
                    onLaboratoryChange={onLaboratoryChange}
                    disabled={report.immutable}
                  />
                  <Button
                    variant="destructive"
                    size="sm"
                    onClick={() => onLaboratoryDelete(lab)}
                    disabled={report.immutable}
                  >
                    <Trash2 className="h-4 w-4" />
                  </Button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </CardContent>
    </Card>
  );
}

function AddLaboratoryDropdown({
  reportId,
  onAdd,
  disabled,
}: {
  reportId: string;
  onAdd: (lab: LabData) => void;
  disabled: boolean;
}) {
  const { data: labList } = useSamplesLaboratoryListQuery({});

  if (!labList) {
    return null;
  }

  const doAdd = (lab: Laboratory) => {
    if (disabled) return;

    onAdd({
      reportId,
      labId: lab.id,
      name: lab.name,
      street: lab.address.street,
      postalCode: lab.address.postalCode,
      city: lab.address.city,
      phone: "",
    });
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild disabled={disabled}>
        <Button variant="outline" className="absolute top-0 right-0">
          {t("Labor hinzufügen")}
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent>
        <DropdownMenuGroup>
          {labList.labs.map((lab) => (
            <DropdownMenuItem key={lab.id} onClick={() => doAdd(lab)}>
              {lab.name}
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}

function PutLaboratoryDialog({
  laboratory,
  onLaboratoryChange,
  disabled,
}: {
  laboratory: LabData;
  onLaboratoryChange: (labs: LabData) => void;
  disabled: boolean;
}) {
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const errorPrefix = `laboratories.${laboratory.labId}.`;

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          <Edit className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <TextInput
          label={t("Name")}
          field="name"
          text={laboratory.name}
          onTextChange={(name) => {
            resetNotReadyErrorField(`${errorPrefix}name`);
            onLaboratoryChange({ ...laboratory, name });
          }}
          errors={inputValidationErrorMessages(
            notReadyError,
            `${errorPrefix}name`,
          )}
          disabled={disabled}
        />
        <TextInput
          label={t("Straße")}
          field="street"
          text={laboratory.street}
          onTextChange={(street) => {
            resetNotReadyErrorField(`${errorPrefix}street`);
            onLaboratoryChange({ ...laboratory, street });
          }}
          errors={inputValidationErrorMessages(
            notReadyError,
            `${errorPrefix}street`,
          )}
          disabled={disabled}
        />
        <TextInput
          label={t("PLZ")}
          field="postalCode"
          text={laboratory.postalCode}
          onTextChange={(postalCode) => {
            resetNotReadyErrorField(`${errorPrefix}postalCode`);
            onLaboratoryChange({ ...laboratory, postalCode });
          }}
          errors={inputValidationErrorMessages(
            notReadyError,
            `${errorPrefix}postalCode`,
          )}
          disabled={disabled}
        />
        <TextInput
          label={t("Stadt")}
          field="city"
          text={laboratory.city}
          onTextChange={(city) => {
            resetNotReadyErrorField(`${errorPrefix}city`);
            onLaboratoryChange({ ...laboratory, city });
          }}
          errors={inputValidationErrorMessages(
            notReadyError,
            `${errorPrefix}city`,
          )}
          disabled={disabled}
        />
        <TextInput
          label={t("Telefon")}
          field="phone"
          text={laboratory.phone}
          onTextChange={(phone) => {
            resetNotReadyErrorField(`${errorPrefix}phone`);
            onLaboratoryChange({ ...laboratory, phone });
          }}
          errors={inputValidationErrorMessages(
            notReadyError,
            `${errorPrefix}phone`,
          )}
          disabled={disabled}
        />
      </DialogContent>
    </Dialog>
  );
}
