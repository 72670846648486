import { HTMLAttributes, useEffect, useState } from "react";
import { v4 } from "uuid";
import { SegmentKind } from "@/services/backend/rlt/treatments/segmentKind";
import { Kind } from "@/services/backend/rlt/treatments/component";
import { Position } from "@/services/backend/rlt/treatments/position";
import { Area } from "@/services/backend/rlt/treatments/segment";
import t from "@/lang/lang";
import {
  RenameAreaRequest,
  useRltTreatmentAddPositionMutation,
  useRltTreatmentDeleteAreaMutation,
  useRltTreatmentListAvailableComponentKindsQuery,
  useRltTreatmentRenameAreaMutation,
} from "@/services/backend/rlt/treatments/service";
import { cn } from "@/shared/lib/utils";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { useTreatmentComponentState } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state-context";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import {
  ChevronDown,
  ChevronRight,
  Edit,
  Plus,
  Save,
  Trash2,
  X,
} from "lucide-react";
import {
  ALL_COLUMNS,
  Column,
} from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state";
import { Button } from "@/shared/components/ui/button";
import { Input } from "@/shared/components/ui/input";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { fCleanTime } from "./utils";
import { PositionRow } from "./position-row";

export function SegmentAreaComponent({
  treatmentId,
  segmentKind,
  segmentArea,
  positions,
}: {
  treatmentId: string;
  segmentKind: SegmentKind;
  segmentArea: Area;
  positions: Position[];
}) {
  const { state } = useTreatmentComponentState();
  const [open, setOpen] = useState(true);
  const { columns } = state;

  return (
    <Collapsible open={open} onOpenChange={setOpen}>
      <div className="overflow-auto-x border-t">
        <div className="bg-muted flex h-14">
          <div className="flex grow justify-between">
            <div className="flex items-center">
              <AreaName
                treatmentId={treatmentId}
                segmentKind={segmentKind}
                area={segmentArea.area}
                open={open}
              />
            </div>
          </div>
          <div className="flex items-center justify-between text-sm font-bold uppercase">
            {columns.includes(Column.CleanTime) && (
              <div className="px-6 py-3">{t("Gesamt")}</div>
            )}
            {columns.includes(Column.CleanTime) && (
              <div className="px-6 py-3 text-right">
                {fCleanTime(segmentArea.cleanTime)}
              </div>
            )}
            <div className="px-1">
              <DeleteAreaDialog
                treatmentId={treatmentId}
                segmentKind={segmentKind}
                area={segmentArea.area}
              />
            </div>
            <div className="pr-3 pl-1">
              <AddPositionDropdown
                treatmentId={treatmentId}
                segmentKind={segmentKind}
                area={segmentArea.area}
              />
            </div>
          </div>
        </div>
        <CollapsibleContent>
          <div className="relative w-full overflow-auto">
            <table className="w-full table-auto text-left text-sm">
              <thead className="text-xs uppercase">
                <tr>
                  {ALL_COLUMNS.map((column) => {
                    if (!columns.includes(column)) {
                      return null;
                    }
                    switch (column) {
                      // some columns need special classes
                      case Column.ComponentKind:
                        if (!columns.includes(Column.Comment)) {
                          return (
                            <TH key={column} className="w-full">
                              {t(column)}
                            </TH>
                          );
                        }
                        return <TH key={column}>{t(column)}</TH>;
                      case Column.CleanTime:
                        return (
                          <TH key={column} className="whitespace-nowrap">
                            {t(Column.CleanTime)}
                          </TH>
                        );
                      case Column.Comment:
                        return (
                          <TH key={column} className="w-full">
                            {t(column)}
                          </TH>
                        );
                      default:
                        return <TH key={column}>{t(column)}</TH>;
                    }
                  })}
                </tr>
              </thead>
              <tbody>
                {positions
                  ?.filter(
                    (position): boolean =>
                      position.segmentKind === segmentKind &&
                      position.area === segmentArea.area,
                  )
                  .sort((a, b) => a.number.localeCompare(b.number))
                  .map((position) => (
                    <PositionRow
                      treatmentId={treatmentId}
                      position={position}
                      key={position.id}
                    />
                  ))}
              </tbody>
              {columns.includes(Column.CleanTime) && (
                <tfoot className="border-t font-bold uppercase">
                  <tr>
                    <td
                      colSpan={columns.indexOf(Column.CleanTime)}
                      className="px-6 py-3 text-right"
                    >
                      {t("Gesamt")}
                    </td>
                    <td className="px-6 py-3 text-right">
                      {fCleanTime(segmentArea.cleanTime)}
                    </td>
                  </tr>
                </tfoot>
              )}
            </table>
          </div>
        </CollapsibleContent>
      </div>
    </Collapsible>
  );
}

function AreaName({
  treatmentId,
  segmentKind,
  area,
  open,
}: {
  treatmentId: string;
  segmentKind: SegmentKind;
  area: string;
  open: boolean;
}) {
  const [edit, setEdit] = useState(false);
  const [renameRequest, setRenameRequest] = useState<RenameAreaRequest>({
    id: treatmentId,
    segment: segmentKind,
    old: area,
    new: area,
  });
  const [rename, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentRenameAreaMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [toast, error, reset]);

  if (isSuccess) {
    reset();
    setEdit(false);
  }

  const doRename = () => {
    if (isLoading) {
      return;
    }
    rename(renameRequest);
  };

  return (
    <div className="">
      {edit ? (
        <div className="flex items-center">
          <div className="py-3 pr-3 pl-6">
            {open ? (
              <ChevronDown className="h-5 w-5" />
            ) : (
              <ChevronRight className="h-5 w-5" />
            )}
          </div>
          <Input
            className="h-8 min-w-full"
            value={renameRequest.new}
            onChange={(event) =>
              setRenameRequest({ ...renameRequest, new: event.target.value })
            }
          />
          <Button size="sm" variant="ghost" onClick={() => setEdit(false)}>
            <X className="h-4 w-4" />
          </Button>
          <Button size="sm" variant="ghost" onClick={() => doRename()}>
            <Save className="h-4 w-4" />
          </Button>
        </div>
      ) : (
        <div className="flex items-center space-x-4">
          <CollapsibleTrigger>
            <div className="flex items-center text-sm font-bold uppercase">
              <div className="py-3 pr-3 pl-6">
                {open ? (
                  <ChevronDown className="h-5 w-5" />
                ) : (
                  <ChevronRight className="h-5 w-5" />
                )}
              </div>
              <div>{area}</div>
            </div>
          </CollapsibleTrigger>
          <Button size="sm" variant="ghost" onClick={() => setEdit(true)}>
            <Edit className="h-4 w-4" />
          </Button>
        </div>
      )}
    </div>
  );
}

interface THProps extends HTMLAttributes<HTMLTableCellElement> {
  hidden?: boolean;
}

function TH({ hidden = false, children, className }: THProps) {
  if (hidden) {
    return null;
  }
  return <th className={cn("px-6 py-3", className)}>{children}</th>;
}

function DeleteAreaDialog({
  treatmentId,
  segmentKind,
  area,
}: {
  treatmentId: string;
  segmentKind: SegmentKind;
  area: string;
}) {
  const [open, setOpen] = useState(false);
  const [deleteArea, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentDeleteAreaMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error, false),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  if (isSuccess) {
    setOpen(false);
    reset();
  }

  const doDelete = () => {
    if (isLoading) {
      return;
    }
    deleteArea({ id: treatmentId, segment: segmentKind, area });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm" variant="destructive">
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t(`Bereich ${area} wirklich löschen?`)}</DialogTitle>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={doDelete}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function AddPositionDropdown(props: {
  treatmentId: string;
  segmentKind: SegmentKind;
  area: string;
}) {
  const { treatmentId, segmentKind, area } = props;
  const { data: mapping } = useRltTreatmentListAvailableComponentKindsQuery({});
  const [add, { isLoading, error }] = useRltTreatmentAddPositionMutation();
  const availableKinds = mapping?.[segmentKind] ?? [];

  const onAdd = (kind: Kind) => {
    if (isLoading) {
      return;
    }
    add({
      id: treatmentId,
      positionId: v4(),
      segment: segmentKind,
      area,
      componentKind: kind,
    });
  };

  const { setPersistenceState } = usePersistenceContext();
  useEffect(() => {
    if (isLoading) {
      setPersistenceState(PersistenceState.SAVING);
    } else if (error) {
      setPersistenceState(PersistenceState.ERROR);
    } else {
      setPersistenceState(PersistenceState.SAVED);
    }
  }, [isLoading, error, setPersistenceState]);

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button size="sm">
          <Plus className="h-5 w-5" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent align="end">
        <DropdownMenuLabel>{t("Komponente hinzufügen")}</DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          {availableKinds.map((kind) => (
            <DropdownMenuItem
              key={kind}
              className="cursor-pointer"
              onClick={() => {
                onAdd(kind);
              }}
            >
              <Plus className="mr-2 h-4 w-4" />
              <span>{t(kind)}</span>
            </DropdownMenuItem>
          ))}
        </DropdownMenuGroup>
      </DropdownMenuContent>
    </DropdownMenu>
  );
}
