/**
 * FrontendUser is a representation of a user in the frontend.
 * Its data is filled with the data from the token.
 * This user is not used for communication with the backend.
 */
export interface FrontendUser {
  id: string;
  fullname: string;
  initials: string;
  roles: string[];
}

export function hasRole(user: FrontendUser, role: string): boolean {
  return user.roles.includes(role);
}
