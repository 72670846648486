import {
  ClipboardCheck,
  ClipboardCopy,
  ClipboardList,
  ClipboardPaste,
  ClipboardPenLine,
} from "lucide-react";
import t from "@/lang/lang";

export enum State {
  Preparation = "preparation",
  Scheduling = "scheduling",
  Execution = "execution",
  Review = "review",
  Completed = "completed",
}

export function StateInfo({
  state,
  withTitle = true,
  withDescription = false,
}: {
  state: State;
  withTitle?: boolean;
  withDescription?: boolean;
}) {
  // eslint-disable-next-line default-case
  switch (state) {
    case State.Preparation:
      return (
        <span className="flex items-center">
          <ClipboardPenLine className="mr-2 h-4 w-4" />
          {withTitle && <span>{t("Vorbereitung")}</span>}
          {withDescription && (
            <span className="ml-2">
              ({t("Inhalt wird erstellt und geprüft")})
            </span>
          )}
        </span>
      );
    case State.Scheduling:
      return (
        <span className="flex items-center">
          <ClipboardPaste className="mr-2 h-4 w-4" />
          {withTitle && <span>{t("Planung")}</span>}
          {withDescription && (
            <span className="ml-2">
              ({t("Termin mit Kunde wird koordiniert")})
            </span>
          )}
        </span>
      );
    case State.Execution:
      return (
        <span className="flex items-center">
          <ClipboardList className="mr-2 h-4 w-4" />
          {withTitle && <span>{t("Bearbeitung")}</span>}
          {withDescription && (
            <span className="ml-2">({t("Arbeiten werden durchgeführt")})</span>
          )}
        </span>
      );
    case State.Review:
      return (
        <span className="flex items-center">
          <ClipboardCopy className="mr-2 h-4 w-4" />
          {withTitle && <span>{t("Prüfung")}</span>}
          {withDescription && (
            <span className="ml-2">
              (
              {t(
                "Prüfung ob Unterlagen fehlen, Folgearbeiten notwendig sind, ...",
              )}
              )
            </span>
          )}
        </span>
      );
    case State.Completed:
      return (
        <span className="flex items-center">
          <ClipboardCheck className="mr-2 h-4 w-4" />
          {withTitle && <span>{t("Fertig")}</span>}
          {withDescription && (
            <span className="ml-2">({t("Bereit für Rechnungsstellung")})</span>
          )}
        </span>
      );
  }
}
