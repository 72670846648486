import { backendApi } from "@/services/backend/api";
import {
  ApplyCalculationDataRequest,
  DeleteRequest,
  ListOfferRequest,
  NewOfferRequest,
  Offer,
  OfferCoverLetterTextBlocks,
  OfferList,
  PDFRequest,
  PreviewPDFRequest,
  PutCoverLetterTextBlocksRequest,
  PutRequest,
  SetOfferDateRequest,
  ShowOfferRequest,
  UpdateHirePurchaseRequest,
  UpdatePostalAddressRequest,
  UpdateRepresentativeRequest,
  UpdateServiceAddressRequest,
} from "@/services/backend/qpm/offer/types";
import {
  downloadResponseHandler,
  previewResponseHandler,
} from "@/services/backend/pdf-helpers";

const qpmOfferApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["QpmOffer", "QpmOfferCoverLetterTextBlocks"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmOfferCreate: build.mutation<Offer, NewOfferRequest>({
        query: (request) => ({
          url: `/qpm/offer`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmOffer"],
      }),
      qpmOfferPut: build.mutation<Offer, PutRequest>({
        query: (request) => ({
          url: `/qpm/offer`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "QpmOffer", id: request.artefactData.id },
          "QpmOffer",
        ],
      }),
      qpmOfferShow: build.query<Offer, ShowOfferRequest>({
        query: (request) => ({
          url: `/qpm/offer`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmOffer", id: result.artefactData.id }] : [],
      }),
      qpmOfferList: build.query<OfferList, Partial<ListOfferRequest>>({
        query: (request) => ({
          url: `/qpm/offer/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmOffer", id: "LIST" }] : [],
      }),
      qpmOfferApplyCalculation: build.mutation<
        Offer,
        ApplyCalculationDataRequest
      >({
        query: (request) => ({
          url: `/qpm/offer/apply-calculation`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmOffer"],
      }),
      qpmOfferSetDate: build.mutation<Offer, SetOfferDateRequest>({
        query: (request) => ({
          url: `/qpm/offer/set-date`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmOffer"],
      }),
      qpmOfferUpdatePostalAddress: build.mutation<
        Offer,
        UpdatePostalAddressRequest
      >({
        query: (request) => ({
          url: `/qpm/offer/postal-address`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "QpmOffer", id: request.id },
          "QpmOffer",
        ],
      }),
      qpmOfferUpdateServiceAddress: build.mutation<
        Offer,
        UpdateServiceAddressRequest
      >({
        query: (request) => ({
          url: `/qpm/offer/service-address`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "QpmOffer", id: request.id },
          "QpmOffer",
        ],
      }),
      qpmOfferUpdateRepresentative: build.mutation<
        Offer,
        UpdateRepresentativeRequest
      >({
        query: (request) => ({
          url: `/qpm/offer/representative`,
          method: "PATCH",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "QpmOffer", id: request.id },
          "QpmOffer",
        ],
      }),
      qpmOfferUpdateHirePurchase: build.mutation<
        Offer,
        UpdateHirePurchaseRequest
      >({
        query: (request) => ({
          url: `/qpm/offer/update-hire-purchase`,
          method: "POST",
          body: request,
        }),
        invalidatesTags: ["QpmOffer"],
      }),
      qpmOfferPutCoverLetterTextBlocks: build.mutation<
        OfferCoverLetterTextBlocks,
        PutCoverLetterTextBlocksRequest
      >({
        query: (request) => ({
          url: `/qpm/offer/cover-letter-text-blocks`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["QpmOffer", "QpmOfferCoverLetterTextBlocks"],
      }),
      qpmOfferDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/qpm/offer`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmOffer"],
      }),
      qpmOfferPdfPreview: build.query<{ objectURL: string }, PreviewPDFRequest>(
        {
          query: (request) => ({
            url: `/qpm/offer/pdf`,
            method: "PATCH",
            body: request,
            // headers: {
            //   Accept: "application/pdf",
            // },
            responseHandler: previewResponseHandler,
            // IMPORTANT. Since this query will download the PDF under no circumstances
            // should the results be cached. This could end up using the users memory.
            cache: "no-cache",
          }),
          providesTags: (_r, _e, request) => [
            { type: "QpmOffer", id: request.offerId },
          ],
        },
      ),
      qpmOfferPdfDownload: build.mutation<
        { filename: string; objectURL: string },
        PDFRequest
      >({
        query: (request) => ({
          url: `/qpm/offer/pdf`,
          method: "PATCH",
          body: {
            ...request,
            download: true,
          },
          responseHandler: downloadResponseHandler,
          // IMPORTANT. Since this query will download the PDF under no circumstances
          // should the results be cached. This could end up using the users memory.
          cache: "no-cache",
        }),
        invalidatesTags: (_r, _e, request) => [
          { type: "QpmOffer", id: request.offerId },
        ],
      }),
    }),
  });

export const {
  useQpmOfferCreateMutation,
  useQpmOfferShowQuery,
  useQpmOfferPutMutation,
  useQpmOfferListQuery,
  useQpmOfferApplyCalculationMutation,
  useQpmOfferSetDateMutation,
  useQpmOfferUpdatePostalAddressMutation,
  useQpmOfferUpdateServiceAddressMutation,
  useQpmOfferUpdateRepresentativeMutation,
  useQpmOfferUpdateHirePurchaseMutation,
  useQpmOfferDeleteMutation,
  useQpmOfferPutCoverLetterTextBlocksMutation,
  useQpmOfferPdfPreviewQuery,
  useQpmOfferPdfDownloadMutation,
} = qpmOfferApi;
