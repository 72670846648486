import { useParams } from "react-router";
import { ServiceCostsComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/service-cost-component";
import { CalculationServiceType } from "@/services/backend/qpm/calculations/enum";
import t from "@/lang/lang";
import { fMoney } from "@/routes/gesec/processes/_components/ui/number-input";

export function CalculationInstallationOverviewComponent() {
  const { calculationId } = useParams();

  return (
    calculationId && (
      <div className="overflow-clip rounded-lg border shadow-sm">
        <ServiceCostsComponent
          calculationId={calculationId}
          calculationServiceType={CalculationServiceType.Installation}
        />
        <div className="flex justify-between rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">{t("Gesamt")}</span>
          <span className="font-bold">{fMoney(0)}</span>
        </div>
      </div>
    )
  );
}
