import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";

export function ResetFilters({ onClick }: { onClick: () => void }) {
  return (
    <Button
      variant="outline"
      className="justify-between"
      size="sm"
      onClick={onClick}
    >
      <span>{t("Reset")}</span>
    </Button>
  );
}
