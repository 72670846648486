import { Input } from "@/shared/components/ui/input";
import t from "@/lang/lang";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { HTMLAttributes, MouseEventHandler, useEffect, useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { RefreshCw, X } from "lucide-react";
import { ArtefactResults } from "@/routes/_components/search/artefact-results";
import { Result } from "@/services/backend/search/search";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useDebounce } from "@/shared/lib/debounce/debounce";
import { useSearchQuery } from "@/services/backend/search/service";
import { CustomerResults } from "@/routes/_components/search/customer-results";
import { ProcessResults } from "@/routes/_components/search/process-results";
import {
  SearchContextProvider,
  useSearchContext,
} from "@/routes/_components/search/search-context";
import { useLocation, useSearchParams } from "react-router";
import { cn } from "@/shared/lib/utils";

export function Search({ ...props }: HTMLAttributes<HTMLInputElement>) {
  return (
    <SearchContextProvider>
      <SearchComponent {...props} />
    </SearchContextProvider>
  );
}

function SearchComponent({
  className,
  ...props
}: HTMLAttributes<HTMLInputElement>) {
  const { search, setSearch } = useSearchState();
  const query = useSearchDebounce(search, 250, 3);
  const {
    data: results,
    isLoading,
    error,
  } = useSearchQuery({ query }, { refetchOnMountOrArgChange: true });

  const { open, setOpen } = useSearchContext();

  useLocationChangeToCloseSearch();

  const onBlur = () => {
    if (search === "") {
      setOpen(false);
    }
  };

  const resetSearch = () => {
    setSearch("");
    setOpen(false);
  };

  return (
    <div className="relative">
      <SearchOverlay open={open} onClick={resetSearch} />
      <Input
        type="search"
        value={search}
        onChange={(e) => setSearch(e.target.value)}
        onFocus={() => setOpen(true)}
        onBlur={onBlur}
        placeholder={t("Suche")}
        className={cn("relative z-50 md:w-[150px] lg:w-[400px]", className)}
        {...props}
      />
      <SearchResults
        open={open}
        search={search}
        results={results}
        isLoading={isLoading}
        error={error}
        resetSearch={resetSearch}
      />
    </div>
  );
}

/**
 * useSearchState utilizes the url query parameters
 * to safe the search state. This enables sharing
 * the state of the page.
 */
function useSearchState() {
  const [searchParams, setSearchParams] = useSearchParams();

  const setSearch = (value: string) => {
    setSearchParams({
      search: value,
    });
  };

  const search = searchParams.has("search")
    ? (searchParams.get("search") ?? "")
    : "";

  return { search, setSearch };
}

function useSearchDebounce(
  value: string,
  delay?: number,
  minLength: number = 3,
): string {
  const [search, setSearch] = useState(value);

  if (value.length >= minLength && value !== search) {
    setSearch(value);
  }

  return useDebounce(search, delay);
}

/**
 * useLocationChangeToCloseSearch listens for
 * a change to a new location, if such a change
 * occurs, the search is closed.
 *
 * This enables to define this behavior once "globally".
 * This way any components used as part of the search to
 * not need know that they are part of search (to close
 * the search on a navigating action).
 */
function useLocationChangeToCloseSearch() {
  const location = useLocation();
  const { setOpen } = useSearchContext();

  useEffect(() => {
    setOpen(false);
  }, [location.pathname, setOpen]);
}

interface SearchResultsProps {
  open: boolean;
  search: string;
  results?: Result;
  isLoading: boolean;
  error: unknown;
  resetSearch: () => void;
}

function SearchResults({
  open,
  search,
  results,
  isLoading,
  error,
  resetSearch,
}: SearchResultsProps) {
  return (
    <Card
      className={
        open
          ? "absolute top-12 right-0 z-50 max-h-[85vh] shadow-2xl md:min-w-[150px] lg:min-w-[400px]"
          : "hidden"
      }
    >
      <Button
        onClick={resetSearch}
        className="absolute top-2 right-2"
        variant="ghost"
      >
        <X className="h-4 w-4" />
      </Button>
      <CardHeader className="pb-2">
        <CardTitle>{`${t('Suche: "') + search}"`}</CardTitle>
      </CardHeader>
      <CardContent className="space-y-6">
        {(!search || !results) && <SearchExamples />}
        <Loading isLoading={isLoading} />
        <RTKQueryErrorAlert error={error} />
        {search && results && (
          <>
            <ArtefactResults artefacts={results.artefacts ?? []} />
            <ProcessResults processes={results.processes ?? []} />
            <CustomerResults customers={results.customers ?? []} />
          </>
        )}
      </CardContent>
    </Card>
  );
}

function SearchExamples() {
  return (
    <div className="min-w-[75vw]">
      <h5 className="mt-4 text-lg font-bold">{t("So können Sie suchen:")}</h5>
      <div className="text-muted-foreground">
        <div>
          {t(
            "Es kann nach Dokumenten, Vorgängen, und Kunden gesucht " +
              "werden. Wenn die Nummer eines Dokuments vorhanden ist, wird " +
              "die Suche nach dieser ein direkten Ergebnis liefern. Wenn " +
              "eine solche nicht Vorhanden ist können auch Suchbegriffe aneinander" +
              "gereiht werden. Die Suchbegriffe müssen durch ein Komma getrennt sein.",
          )}
        </div>
      </div>
      <h5 className="mt-4 text-lg font-bold whitespace-nowrap">
        {t("Beispiele für Suchbegriffe und deren Kombination:")}
      </h5>
      <div className="text-muted-foreground">
        <div className="mt-1">{t("Ein bestimmtes Dokument:")}</div>
        <ul className="ml-6 list-disc [&>li]:mt-1">
          <li>{t('"VBS/A/100001"')}</li>
          <li>{t('"VBS, Angebot, 2024, Muster GmbH"')}</li>
        </ul>
        <div className="mt-1">{t("Einen Vorgang:")}</div>
        <ul className="ml-6 list-disc [&>li]:mt-1">
          <li>{t('"VBS, Muster GmbH"')}</li>
          <li>{t('"VBS, 06.2024, Muster GmbH"')}</li>
        </ul>
        <div className="mt-1">{t("Eingrenzung nach Datum")}</div>
        <ul className="ml-6 list-disc [&>li]:mt-1">
          <li>{t('"2024"')}</li>
          <li>{t('"06.2024"')}</li>
          <li>{t('"01.06.2024"')}</li>
        </ul>
      </div>
    </div>
  );
}

function Loading({ isLoading }: { isLoading: boolean }) {
  if (!isLoading) {
    return null;
  }

  return (
    <div>
      <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
      <span>{t("Suche...")}</span>
    </div>
  );
}

function SearchOverlay({
  open,
  onClick,
}: {
  open: boolean;
  onClick: MouseEventHandler<HTMLDivElement>;
}) {
  return (
    <div
      role="none"
      className={open ? "fixed inset-0 z-40" : "hidden"}
      onClick={onClick}
    />
  );
}
