import { WorkOrder } from "@/services/backend/rlt/work-orders/work-order";
import { useParams } from "react-router";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { useRltWorkOrderShowQuery } from "@/services/backend/rlt/work-orders/service";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import t from "@/lang/lang";
import {
  ErrArtefactNotReady,
  ErrArtefactNotReadyContextProvider,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { DeleteWorkOrderDialog } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/_components/delete-work-order-dialog";
import { SignatureButton } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/_components/signature-button";
import { Card } from "@/shared/components/ui/card";
import { WorkOrderImmutablePage } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/_components/work-order-immutable-page";
import { WorkOrderPdf } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/_components/work-order-pdf";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";
import { CopyDataDialog } from "@/routes/gesec/processes/_components/copy-data-dialog/copy-data-dialog";

export function WorkOrderRoute() {
  const { workOrderId } = useParams();
  const {
    data: workOrder,
    isLoading,
    error,
  } = useRltWorkOrderShowQuery({
    id: workOrderId!,
  });

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (workOrder!.immutable) {
    return <WorkOrderImmutablePage workOrder={workOrder!} />;
  }

  return <WorkOrderPage workOrder={workOrder!} />;
}

function WorkOrderPage(props: { workOrder: WorkOrder }) {
  const { workOrder } = props;

  return (
    <PersistenceContextProvider>
      <ErrArtefactNotReadyContextProvider>
        <Page workOrder={workOrder} />
      </ErrArtefactNotReadyContextProvider>
    </PersistenceContextProvider>
  );
}

function Page({ workOrder }: { workOrder: WorkOrder }) {
  const { notReadyError } = useErrArtefactNotReady();

  const links = [
    {
      to: "data",
      label: t("Daten"),
      hasValidationError: hasDataError(notReadyError),
    },
    {
      to: `last-minute-risk-analysis/${workOrder.lastMinuteRiskAnalysisId}`,
      label: t("LMRA"),
      hasValidationError: hasLMRAError(notReadyError),
    },
    { to: "positions", label: t("Arbeiten") },
    {
      to: `documentation/${workOrder.documentationId}`,
      label: t("Dokumentation"),
      hasValidationError: hasDocumentationError(notReadyError),
    },
  ];

  return (
    <ArtefactPage>
      <ArtefactPageHeader>
        <div className="flex items-center space-x-2">
          <ArtefactPageNavigation>
            {links.map((link) => (
              <ArtefactPageNavigationLink
                key={link.to}
                to={link.to}
                hasValidationError={link.hasValidationError}
              >
                {link.label}
              </ArtefactPageNavigationLink>
            ))}
          </ArtefactPageNavigation>
          <CopyDataDialog
            destination={{
              location: "gsm.rlt",
              document: "workorder",
              id: workOrder.id,
            }}
            processId={workOrder.processId}
          />
        </div>
        <ArtefactPageActions>
          <PersistenceStateUsingContext className="mr-2" />
          <DeleteWorkOrderDialog workOrderId={workOrder.id} />
          <SignatureButton workOrderID={workOrder.id} />
          <WorkOrderPdf workOrderId={workOrder.id} />
        </ArtefactPageActions>
      </ArtefactPageHeader>
      <ArtefactPageContent>
        <ArtefactPageNavigationOutlet />
      </ArtefactPageContent>
    </ArtefactPage>
  );
}

function hasDataError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = [
    "state",
    "executingBranchId",
    "beginDate",
    "endDate",
    "teamLeaderId",
    "customerAddress.city",
    "customerAddress.company",
    "customerAddress.street",
    "customerAddress.postalCode",
    "serviceAddress.company",
    "serviceAddress.street",
    "serviceAddress.postalCode",
    "serviceAddress.city",
    "contact.name",
    "contact.mobilephone",
    "contact.email",
    "representative.email",
    "representative.mobilephone",
    "representative.name",
  ];

  return fields.some((field) => field in error.errors);
}

function hasLMRAError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = ["lastMinuteRiskAnalysis"];

  return fields.some((field) => field in error.errors);
}

function hasDocumentationError(error: ErrArtefactNotReady | null): boolean {
  if (!error) {
    return false;
  }

  const fields = ["documentation"];

  return fields.some((field) => field in error.errors);
}
