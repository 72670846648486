import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { Label } from "@/shared/components/ui/label";
import { InputErrorMessages } from "@/shared/components/ui/input-error-messages";
import { Input } from "@/shared/components/ui/input";
import { Button } from "@/shared/components/ui/button";
import { Contact } from "@/services/backend/contacts/contact/contact";
import { useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";

export function ContactCard({
  contact,
  onContactChange,
  title = t("Kontakt"),
  description = t("Kontakt beim Kunden."),
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: {
  contact: Contact;
  onContactChange: (contact: Contact) => void;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}) {
  const errorMessages = (field: string): string[] => {
    if (!field || field === "") {
      return [];
    }

    return fieldErrors ? (fieldErrors[field] ?? []) : [];
  };

  return (
    <Card>
      <CardHeader className="flex flex-row items-start justify-between">
        <div>
          <CardTitle>{title}</CardTitle>
          <CardDescription>{description}</CardDescription>
        </div>
        <div className="flex items-start space-x-4">
          <a href={`mailto:${contact.email}`} rel="noreferrer">
            <Button variant="outline">{t("E-Mail")}</Button>
          </a>
          <a href={`mailto:${contact.mobilephone}`} rel="noreferrer">
            <Button variant="outline">{t("Anrufen")}</Button>
          </a>
        </div>
      </CardHeader>
      <CardContent className="space-y-4">
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="name">{t("Name")}</Label>
          <Input
            type="text"
            id="name"
            placeholder={t("Name")}
            value={contact.name}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("name");
              }
              onContactChange({ ...contact, name: e.target.value });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("name")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="mobilephone">{t("Mobil")}</Label>
          <Input
            type="text"
            id="mobilephone"
            placeholder={t("Mobil")}
            value={contact.mobilephone}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("mobilephone");
              }
              onContactChange({ ...contact, mobilephone: e.target.value });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("mobilephone")} />
        </div>
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="email">{t("E-Mail")}</Label>
          <Input
            type="text"
            id="E-Mail"
            placeholder={t("E-Mail")}
            value={contact.email}
            onChange={(e) => {
              if (resetFieldError) {
                resetFieldError("email");
              }
              onContactChange({ ...contact, email: e.target.value });
            }}
            disabled={disabled}
          />
          <InputErrorMessages errors={errorMessages("email")} />
        </div>
      </CardContent>
    </Card>
  );
}

type Mutation<Req, Res> = Readonly<
  () => [
    (entity: Req) => Promise<{ data: Res } | { error: unknown }> | void,
    {
      isLoading: boolean;
      error: unknown;
      isSuccess: boolean;
      reset: () => void;
    },
  ]
>;

export function ContactCardWithMutation<
  Req extends { id: string; contact: Contact },
  Res,
>({
  id,
  contact,
  mutation,
  title = t("Kontakt"),
  description = t("Kontakt beim Kunden."),
  fieldErrors = undefined,
  resetFieldError = undefined,
  disabled = false,
}: {
  id: string;
  contact: Contact;
  mutation: Mutation<Req, Res>;
  title?: string;
  description?: string;
  fieldErrors?: { [key: string]: string[] };
  resetFieldError?: (field: string) => void;
  disabled?: boolean;
}) {
  // @ts-expect-error - I do not understand the error. But it works.
  const [request, setRequest] = useState<Req>({ id, contact });
  // @ts-expect-error - I do know how to define an always fitting type for RTK query mutations.
  const [update, { isLoading, error, isSuccess, reset }] = mutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  const joinedFieldErrors = {
    ...fieldErrors,
    ...(rtkErrIsValidationError(error) ? error.data.errors : {}),
  } as { [key: string]: string[] };

  return (
    <ContactCard
      contact={request.contact}
      // @ts-expect-error - I do not understand the error. But it works.
      onContactChange={(c) => setRequest({ id, contact: c })}
      title={title}
      description={description}
      fieldErrors={joinedFieldErrors}
      resetFieldError={resetFieldError}
      disabled={disabled}
    />
  );
}
