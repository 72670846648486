import { useRltCalculationListQuery } from "@/services/backend/rlt/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { NavLink } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { Calculation } from "@/services/backend/rlt/calculations/calculation";
import { FilterMode } from "@/shared/lib/filter/filter";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function CalculationListTable(props: { processId: string }) {
  const { processId } = props;

  const {
    data: list,
    isLoading,
    error,
  } = useRltCalculationListQuery({
    process: {
      active: true,
      values: [processId],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: false,
      values: [],
    },
    immutable: {
      active: false,
      values: [],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-32">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead className="w-full">{t("Erstellt am")}</TableHead>
          <TableHead className="w-48">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.calculations ?? [])]
          .sort(byCreatedAt)
          .map((calculation) => (
            <TableRow key={calculation.id}>
              <TableCell>
                <NavLink to={`${calculation.id}`}>
                  <ArtefactNumberById artefactId={calculation.id} />
                </NavLink>
              </TableCell>
              <TableCell>
                {new Date(calculation.createdAt).toLocaleDateString()}
              </TableCell>
              <TableCell className="text-right">
                <NavLink to={`${calculation.id}`}>
                  <Button variant="outline" size="sm">
                    <Search className="h-4 w-4" />
                  </Button>
                </NavLink>
              </TableCell>
            </TableRow>
          ))}
        {list?.calculations?.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              {t("Keine Kalkulationen gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function byCreatedAt(a: Calculation, b: Calculation) {
  return a.createdAt.localeCompare(b.createdAt);
}
