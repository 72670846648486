import { FilterMode } from "@/shared/lib/filter/filter";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  ArtefactMeta,
  ArtefactMetaList,
} from "@/services/backend/qpm/artefact-meta/types";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { Calculation } from "@/services/backend/qpm/calculations/types";
import {
  useQpmCalculationListQuery,
  useQpmCalculationShowQuery,
} from "@/services/backend/qpm/calculations/service";
import { useQpmOfferApplyCalculationMutation } from "@/services/backend/qpm/offer/service";
import {
  ApplyCalculationDataRequest,
  Offer,
} from "@/services/backend/qpm/offer/types";
import { Button } from "@/shared/components/ui/button";
import { Loader2 } from "lucide-react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { CreateFromCalculation } from "@/routes/gemex/processes/qpm/offers/_components/create-from-calculation";
import { ReactNode } from "react";

export type SelectCalculationComponentProps = {
  offer: Offer;
  customerId: string;
  processId: string;
};

export function SelectCalculationCardComponent({
  offer,
  customerId,
  processId,
}: SelectCalculationComponentProps) {
  return (
    <div className="mb-2">
      <Card>
        <CardHeader>
          <CardTitle>{t("Kalkulationen")}</CardTitle>
          <CardDescription>
            {t(
              "Wählen Sie hier die Kalkulation, auf Basis derer Sie ein Angebot erstellen möchten.",
            )}
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="w-full">
            <SelectCalculationComponent
              offer={offer}
              customerId={customerId}
              processId={processId}
            />
          </div>
        </CardContent>
      </Card>
    </div>
  );
}

export function SelectCalculationComponent({
  offer,
  customerId,
  processId,
}: SelectCalculationComponentProps) {
  const {
    data: list,
    isLoading,
    error,
  } = useQpmCalculationListQuery({
    process: {
      active: true,
      values: [processId],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: true,
      values: [customerId],
    },
    immutable: {
      active: false,
      values: [],
    },
    parent: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
  });

  const artefactMetaId =
    offer.artefactData?.child?.artefact?.linkedArtefacts?.calculation
      ?.linkedArtefact?.id;

  const skipQuery = !artefactMetaId;

  const {
    data: selectedCalculation,
    isLoading: isLoadingSelectedCalculation,
    error: errorSelectedCalculation,
  } = useQpmCalculationShowQuery({ artefactMetaId }, { skip: skipQuery });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (errorSelectedCalculation) {
    return <RTKQueryErrorAlert error={errorSelectedCalculation} />;
  }

  if (isLoading || isLoadingSelectedCalculation) {
    return (
      <div className="mb-2">
        <Skeleton className="h-32 w-full" />
      </div>
    );
  }

  return (
    <CalculationSelector
      offerArtefactMetaId={offer.artefactData.id}
      calculationList={list!}
      selectedCalculation={selectedCalculation}
    />
  );
}

type CalculationDropDownProps = {
  offerArtefactMetaId: string;
  calculationList: ArtefactMetaList;
  selectedCalculation?: Calculation;
};

export function CalculationSelector({
  offerArtefactMetaId,
  calculationList,
  selectedCalculation,
}: CalculationDropDownProps) {
  const [applyCalculationData, { error, isLoading }] =
    useQpmOfferApplyCalculationMutation();

  const onChange = (value: string) => {
    const calculation = calculationList.artefacts.find(
      (req) => req.id === value,
    );
    if (!calculation || isLoading || error) {
      return;
    }
    const request: ApplyCalculationDataRequest = {
      offerArtefactId: offerArtefactMetaId,
      calculationArtefactId: calculation.id,
    };

    applyCalculationData(request);
  };

  return (
    <Select
      defaultValue={selectedCalculation?.artefactData.id}
      value={selectedCalculation?.artefactData.id}
      onValueChange={(value: string) => {
        onChange(value);
      }}
    >
      <SelectTrigger className="w-full">
        <SelectValue placeholder={t("Kalkulationen")} />
      </SelectTrigger>
      <SelectContent>
        {calculationList.artefacts.map((calculation) => (
          <SelectItem key={calculation.id} value={calculation.id}>
            <CalculationDisplayRow calculation={calculation} />
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

export type SelectCalculationButtonComponentProps = {
  offerId?: string;
  processId: string;
  selectedCalculationId?: string;
};

export function SelectCalculationButtonComponent({
  offerId,
  processId,
  selectedCalculationId,
}: SelectCalculationButtonComponentProps) {
  const {
    data: selectedCalculation,
    isLoading: isLoadingSelectedCalculation,
    error: errorSelectedCalculation,
  } = useQpmCalculationShowQuery(
    { artefactMetaId: selectedCalculationId! },
    { skip: !selectedCalculationId },
  );

  if (errorSelectedCalculation) {
    return <RTKQueryErrorAlert error={errorSelectedCalculation} />;
  }

  if (isLoadingSelectedCalculation) {
    return <Loader2 className="animate-spin" />;
  }

  return (
    <SelectArtefactButton
      selectedArtefactId={selectedCalculation?.artefactData.id}
    >
      <CreateFromCalculation offerId={offerId} processId={processId} />
    </SelectArtefactButton>
  );
}

export type SelectArtefactButtonProps = {
  selectedArtefactId?: string;
  defaultButtonText?: string;
  children?: ReactNode;
};

export function SelectArtefactButton({
  selectedArtefactId,
  defaultButtonText,
  children,
}: SelectArtefactButtonProps) {
  if (selectedArtefactId && selectedArtefactId !== "") {
    return (
      <Button size="sm" variant="outline" disabled onClick={() => {}}>
        <ArtefactNumberById
          className="text-sm"
          artefactId={selectedArtefactId}
        />
      </Button>
    );
  }

  return (
    <Dialog>
      <DialogTrigger className="border-input bg-background hover:bg-accent hover:text-accent-foreground h-9 rounded-md border px-3">
        {(defaultButtonText && t(defaultButtonText)) ||
          t("-- nicht gewählt --")}
      </DialogTrigger>
      <DialogContent className="max-h-[80vh] min-w-[80vw] overflow-y-scroll">
        <DialogHeader>
          <DialogTitle>
            {t("Daten aus bestehender Kalkulation laden")}
          </DialogTitle>
          <DialogDescription>
            {t(
              "Sie können hier eine Kalkulation auswählen, auf Basis derer das Angebot aktualisiert wird..",
            )}
          </DialogDescription>
        </DialogHeader>
        {children}
      </DialogContent>
    </Dialog>
  );
}

type CalculationDisplayRowProps = {
  calculation: ArtefactMeta;
};

function CalculationDisplayRow({ calculation }: CalculationDisplayRowProps) {
  return (
    <div className="grid grid-cols-2">
      <div className="flex items-center">
        <div className="border-r px-2">
          <ArtefactNumberById className="text-sm" artefactId={calculation.id} />
        </div>
        <div className="border-r px-2">
          <span>{calculation.subject}</span>
        </div>
      </div>
      <div className="flex items-center">
        <div className="px-2">
          <span>{formatDate(calculation.createdAt)}</span>
        </div>
        <div className="px-2">
          <span>{formatTime(calculation.createdAt)}</span>
        </div>
      </div>
    </div>
  );
}
