import { useParams } from "react-router";
import {
  useQpmOfferPutCoverLetterTextBlocksMutation,
  useQpmOfferShowQuery,
} from "@/services/backend/qpm/offer/service";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { Separator } from "@/shared/components/ui/separator";
import {
  OfferCoverLetterTextBlocks,
  PutCoverLetterTextBlocksRequest,
} from "@/services/backend/qpm/offer/types";
import { useCallback, useState } from "react";
import { Input } from "@/shared/components/ui/input";
import { Label } from "@/shared/components/ui/label";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  HTMLTextAreaComponent,
  WYSIWYGHTMLTextAreaComponent,
} from "@/routes/gemex/processes/_components/ui/html-text-area";

export function CoverLetterRoute() {
  const params = useParams();
  const { offerId } = params;
  const {
    data: offer,
    isLoading,
    error,
  } = useQpmOfferShowQuery({ artefactMetaId: offerId! }, { skip: !offerId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    offer && (
      <Card className="">
        <CardHeader className="py-4 font-bold">
          <div className="flex items-center justify-between">
            <CardTitle>
              {t("Angebot")}
              {" - "}
              <ArtefactNumberById
                className="text-xl"
                artefactId={offer.artefactData.id}
              />
            </CardTitle>
          </div>
        </CardHeader>
        <div className="mx-6 mb-2">
          <Separator />
        </div>
        <CardContent>
          <CoverLetterTextBoxComponent
            offerId={offer.artefactData.id}
            coverLetterText={offer.coverLetterText}
          />
        </CardContent>
      </Card>
    )
  );
}

type CoverLetterTextBoxComponentProps = {
  offerId: string;
  coverLetterText: OfferCoverLetterTextBlocks;
};

function CoverLetterTextBoxComponent({
  offerId,
  coverLetterText,
}: CoverLetterTextBoxComponentProps) {
  const [request, setRequest] = useState<PutCoverLetterTextBlocksRequest>({
    ...coverLetterText,
    offerId,
  });

  const [putText, { error, isLoading, isSuccess, reset }] =
    useQpmOfferPutCoverLetterTextBlocksMutation();

  const handleTextChange = useCallback(
    (update: Partial<PutCoverLetterTextBlocksRequest>) => {
      setRequest((prev) => ({ ...prev, ...update }));
    },
    [],
  );

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putText,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
    { toastError: true },
  );

  return (
    <div className="flex flex-col gap-4">
      <div className="flex flex-col">
        <Label className="text-lg">{t("Anrede")}</Label>
        <Input
          value={request.coverLetterSalutation}
          onChange={(e) =>
            handleTextChange({ coverLetterSalutation: e.target.value })
          }
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label className="text-lg">{t("Anschreiben")}</Label>
        <HTMLTextAreaComponent
          value={request.coverLetterCustomText}
          onChange={(val) => handleTextChange({ coverLetterCustomText: val })}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label className="text-lg">{t("Konzept")}</Label>
        <WYSIWYGHTMLTextAreaComponent
          value={request.coverLetterConceptText}
          onChange={(val) => handleTextChange({ coverLetterConceptText: val })}
        />
      </div>
      <div className="flex flex-col gap-2">
        <Label className="text-lg">{t("Kundenanforderungen")}</Label>
        <HTMLTextAreaComponent
          value={request.coverLetterCustomerRequirements}
          onChange={(val) =>
            handleTextChange({ coverLetterCustomerRequirements: val })
          }
        />
      </div>
    </div>
  );
}
