import { Navigate, RouteObject } from "react-router";
import { htzOffersRoutes } from "@/routes/gesec/processes/[processId]/htz/offers/offers-routes";
import OverviewRoute from "@/routes/gesec/processes/[processId]/htz/overview/overview";
import ContactRoute from "@/routes/gesec/processes/[processId]/htz/contact/contact";
import OffersRoute from "@/routes/gesec/processes/[processId]/htz/offers/offers-route";
import OrdersRoute from "@/routes/gesec/processes/[processId]/htz/orders/orders-route";
import { htzOrdersRoutes } from "@/routes/gesec/processes/[processId]/htz/orders/orders-routes";
import ConfirmationsRoute from "@/routes/gesec/processes/[processId]/htz/confirmations/confirmations-route";
import { htzConfirmationsRoutes } from "@/routes/gesec/processes/[processId]/htz/confirmations/confirmations-routes";
import { htzWorkOrdersRoutes } from "@/routes/gesec/processes/[processId]/htz/work-orders/work-order-routes";
import { WorkOrdersRoute } from "@/routes/gesec/processes/[processId]/htz/work-orders/work-orders-route";
import { ReportsRoute } from "@/routes/gesec/processes/[processId]/htz/reports/reports-route";
import { htzReportsRoutes } from "@/routes/gesec/processes/[processId]/htz/reports/reports-routes";

export const htzRoutes: RouteObject[] = [
  {
    element: <Navigate to="overview" />,
    index: true,
  },
  {
    path: "overview",
    element: <OverviewRoute />,
  },
  {
    path: "contact",
    element: <ContactRoute />,
  },
  {
    path: "offers",
    element: <OffersRoute />,
    children: htzOffersRoutes,
  },
  {
    path: "orders",
    element: <OrdersRoute />,
    children: htzOrdersRoutes,
  },
  {
    path: "confirmations",
    element: <ConfirmationsRoute />,
    children: htzConfirmationsRoutes,
  },
  {
    path: "work-orders",
    element: <WorkOrdersRoute />,
    children: htzWorkOrdersRoutes,
  },
  {
    path: "reports",
    element: <ReportsRoute />,
    children: htzReportsRoutes,
  },
  {
    path: "certificates",
    element: "Zertifikate",
  },
];
