import { useParams } from "react-router";
import { MonitorCatalog } from "@/routes/base-data/processes/qpm/catalog/[catalogId]/[monitorTypeKey]/_components/monitor-catalog-table-component";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";

export function MonitorTypeRoute() {
  const { catalogId, monitorTypeKey } = useParams();

  return (
    <MonitorCatalog
      catalogId={catalogId}
      monitorTypeKey={monitorTypeKey as MonitorTypeKey}
    />
  );
}
