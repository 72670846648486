import { acquireBackendAccessToken } from "@/shared/lib/authorization/msal-access-token";
import { devToken } from "@/shared/lib/authorization/dev-token";

/**
 * provideBackendToken will provide a token for the backend depending on the
 * current env.
 */
export async function provideBackendToken(): Promise<string> {
  switch (import.meta.env.VITE_AUTH) {
    case "dev":
      return btoa(JSON.stringify(devToken));
    default:
      return acquireBackendAccessToken();
  }
}
