import { Navigate, RouteObject } from "react-router";
import { WorkOrderRoute } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/work-order-route";
import { WorkOrderDataRoute } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/data/work-order-data-route";
import { WorkOrderLastMinuteRiskAnalysisRoute } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/last-minute-risk-analysis/work-order-risk-analysis-route";
import { WorkOrderPositionsRoute } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/positions/work-order-positions-route";
import {
  WorkOrderDocumentationIndexRoute,
  WorkOrderDocumentationRoute,
} from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/documentation/[documentationId]/work-order-documentation-route";
import { WorkOrderDocumentationItemRoute } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/documentation/[documentationId]/[itemId]/work-order-documentation-item-route";
import { WorkOrderIndexRoute } from "@/routes/gesec/processes/[processId]/rlt/work-orders/_components/work-order-index-route";

export const workOrdersRoutes: RouteObject[] = [
  {
    index: true,
    element: <WorkOrderIndexRoute />,
  },
  {
    path: ":workOrderId",
    element: <WorkOrderRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" />,
      },
      {
        path: "data",
        element: <WorkOrderDataRoute />,
      },
      {
        path: "last-minute-risk-analysis/:lmraId",
        element: <WorkOrderLastMinuteRiskAnalysisRoute />,
      },
      {
        path: "positions",
        element: <WorkOrderPositionsRoute />,
      },
      {
        path: "documentation/:documentationId",
        element: <WorkOrderDocumentationRoute />,
        children: [
          {
            index: true,
            element: <WorkOrderDocumentationIndexRoute />,
          },
          {
            path: ":itemId",
            element: <WorkOrderDocumentationItemRoute />,
          },
        ],
      },
    ],
  },
];
