import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import { Card } from "@/shared/components/ui/card";
import {
  ListRequest,
  useVbsWorkOrderListQuery,
} from "@/services/backend/vbs/work-orders/service";
import { useRltWorkOrderListQuery } from "@/services/backend/rlt/work-orders/service";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { RefreshCw } from "lucide-react";
import { ReactNode, useState } from "react";
import { CustomerName } from "@/shared/components/domain/customer/customer-name";
import { BranchName } from "@/shared/components/domain/branch/branch-name";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { State, StateInfo } from "@/services/backend/vbs/work-orders/state";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { NavLink } from "react-router";
import { useArtefactPath } from "@/shared/components/domain/artefact/use-artefact-path";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { Button } from "@/shared/components/ui/button";
import { ProcessKindFilter } from "@/routes/gesec/processes/_components/filters/process-kind-filter";
import { CompanyFilter } from "@/routes/gesec/processes/_components/filters/company-filter";
import { BranchFilter } from "@/routes/gesec/processes/_components/filters/branch-filter";
import { ResetFilters } from "@/routes/gesec/processes/_components/filters/reset-filters";
import { Filter, FilterMode } from "@/shared/lib/filter/filter";
import { CustomerFilter } from "@/routes/gesec/processes/_components/filters/customer-filter";
import { DateBetweenFilter } from "@/routes/gesec/processes/_components/filters/date-between-filter";
import t from "@/lang/lang";
import { useDashboardContext } from "@/routes/dashboard/_components/dashboard-context";
import { WorkOrderStateFilter } from "@/routes/dashboard/_components/work-order-state-filter";
import { Role } from "@/shared/lib/authorization/roles";

export function WorkOrdersTable() {
  const { role } = useDashboardContext();

  const [request, setRequest] = useState<ListRequest>(defaultRequest(role));
  const [kinds, setKinds] = useState<Filter<ProcessKind>>({
    active: false,
    values: [],
  });

  // This (calling each service) is an intermediate solution. In the future,
  // there should be a global artefact.workorder service that provides work
  // orders for all process kinds. This endpoint may also provide pagination.
  const {
    data: vbs,
    isLoading: vbsIsLoading,
    error: vbsError,
  } = useVbsWorkOrderListQuery(request, {
    skip: kinds.active && !kinds.values?.includes(ProcessKind.VBS),
  });
  const {
    data: rlt,
    isLoading: rltIsLoading,
    error: rltError,
  } = useRltWorkOrderListQuery(request, {
    skip: kinds.active && !kinds.values?.includes(ProcessKind.RLT),
  });

  const isLoading = vbsIsLoading || rltIsLoading;
  const error = vbsError || rltError;

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const workOrders = [
    ...(vbs?.workOrders ?? []).map((wo) => ({
      ...wo,
      processKind: ProcessKind.VBS,
    })),
    ...(rlt?.workOrders ?? []).map((wo) => ({
      ...wo,
      processKind: ProcessKind.RLT,
    })),
  ].sort((a, b) => b.updatedAt.localeCompare(a.updatedAt));

  return (
    <div>
      <div className="mb-2 flex items-center gap-2">
        <ProcessKindFilter filter={kinds} onChange={setKinds} />
        <WorkOrderStateFilter
          filter={request.state!}
          onFilterChange={(value) =>
            setRequest({ ...request, state: value as Filter<State> })
          }
        />
        <CustomerFilter
          filter={request.customer!}
          onFilterChange={(customer) => setRequest({ ...request, customer })}
        />
        <CompanyFilter
          filter={request.company!}
          onChange={(company) => setRequest({ ...request, company })}
        />
        <BranchFilter
          filter={request.branch!}
          onChange={(branch) => setRequest({ ...request, branch })}
        />
        <DateBetweenFilter
          label={t("Geändert Am")}
          filter={request.updatedAt!}
          onFilterChange={(updatedAt) => setRequest({ ...request, updatedAt })}
        />
        <ResetFilters onClick={() => setRequest(defaultRequest(role))} />
      </div>
      <Card>
        <Table>
          <TableHeader>
            <TableRow>
              <TableHead>Nummer</TableHead>
              <TableHead>Status</TableHead>
              <TableHead>Kunde</TableHead>
              <TableHead>Niederlassung</TableHead>
              <TableHead>Geändert Am</TableHead>
            </TableRow>
          </TableHeader>
          <TableBody>
            {isLoading && (
              <TableRow>
                <TableCell colSpan={5}>
                  <div className="text-muted-foreground flex items-center justify-center">
                    <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
                    Loading...
                  </div>
                </TableCell>
              </TableRow>
            )}
            {workOrders.map((workOrder) => (
              <TableRow key={workOrder.id}>
                <TableCell className="py-0 pl-1">
                  <WorkOrderLink
                    artefactId={workOrder.id}
                    artefactKind={workOrder.artefactKind}
                    processId={workOrder.processId}
                    processKind={workOrder.processKind}
                    companyId={workOrder.companyId}
                  >
                    <Button variant="link" size="sm">
                      <ArtefactNumberById artefactId={workOrder.id} />
                    </Button>
                  </WorkOrderLink>
                </TableCell>
                <TableCell>
                  <StateInfo state={workOrder.state} />
                </TableCell>
                <TableCell>
                  <CustomerName customerId={workOrder.customerId} />
                </TableCell>
                <TableCell>
                  <BranchName branchId={workOrder.branchId} />
                </TableCell>
                <TableCell>
                  {formatDate(workOrder.updatedAt)},{" "}
                  {formatTime(workOrder.updatedAt)}
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </Card>
    </div>
  );
}

function WorkOrderLink({
  children,
  artefactId,
  artefactKind,
  processId,
  processKind,
  companyId,
}: {
  children: ReactNode;
  artefactId: string;
  artefactKind: ArtefactKind;
  processId: string;
  processKind: ProcessKind;
  companyId: string;
}) {
  const { path } = useArtefactPath({
    artefactId,
    artefactKind,
    processKind,
    processId,
    companyId,
  });

  if (!path) {
    return null;
  }

  return <NavLink to={path}>{children}</NavLink>;
}

const defaultRequest = (role: Role): ListRequest => {
  const now = new Date();
  const from = new Date(now.getTime() - 30 * 24 * 60 * 60 * 1000).toISOString();
  const to = new Date(now.getTime() + 24 * 60 * 60 * 1000).toISOString();

  return {
    customer: {
      active: false,
      values: [],
    },
    company: {
      active: false,
      values: [],
    },
    branch: {
      active: false,
      values: [],
    },
    state: {
      active: role === Role.TeamLeader,
      values: role === Role.TeamLeader ? [State.Execution] : [],
    },
    updatedAt: {
      active: true,
      mode: FilterMode.Between,
      values: [from, to],
    },
    limit: 50,
    offset: 0,
  };
};
