import { NavLink, Outlet, useParams } from "react-router";
import {
  Breadcrumb,
  BreadcrumbItem,
  BreadcrumbLink,
  BreadcrumbList,
  BreadcrumbPage,
  BreadcrumbSeparator,
} from "@/shared/components/ui/breadcrumb";
import t from "@/lang/lang";
import { MonitorTypeUtil } from "@/services/backend/qpm/shared/monitorTypeUtil";
import { MonitorTypeKey } from "@/services/backend/qpm/shared/enums";
import { useQpmCatalogGetQuery } from "@/services/backend/qpm/catalog/service";
import { Card, CardContent } from "@/shared/components/ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@/shared/components/ui/dropdown-menu";
import { useQpmMonitorTypeListQuery } from "@/services/backend/qpm/monitors/service";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { Checkbox } from "@/shared/components/ui/checkbox";

export function CatalogRoute() {
  const { catalogId, monitorTypeKey, monitorId } = useParams();
  const basePath = "/base-data/processes/qpm";

  const { data: catalog } = useQpmCatalogGetQuery(
    { id: catalogId! },
    { skip: !catalogId },
  );

  return (
    <Card>
      <CardContent>
        <Breadcrumb>
          <BreadcrumbList>
            {!catalogId && (
              <BreadcrumbItem>
                <BreadcrumbPage>{t("Katalogübersicht")}</BreadcrumbPage>
              </BreadcrumbItem>
            )}
            {catalogId && !monitorTypeKey && catalog && (
              <>
                <BreadcrumbItem>
                  <BreadcrumbLink href={`${basePath}/catalog`}>
                    {t("Katalogübersicht")}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>
                    {catalog?.name || t("Katalog")}
                  </BreadcrumbPage>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger asChild>
                    <span className="hover:text-accent-foreground cursor-pointer">
                      {t("Monitore")}
                    </span>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent
                    side="bottom"
                    align="start"
                    onCloseAutoFocus={(e) => e.preventDefault()} // Prevents unwanted refocus
                  >
                    <MonitorTypeDropdownItems catalogId={catalog.id} />
                  </DropdownMenuContent>
                </DropdownMenu>
              </>
            )}
            {monitorTypeKey && !monitorId && catalog && (
              <>
                <BreadcrumbItem>
                  <BreadcrumbLink href={`${basePath}/catalog`}>
                    {t("Katalogübersicht")}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink href={`${basePath}/catalog/${catalogId}`}>
                    {catalog?.name || t("Katalog")}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger asChild>
                    <span className="hover:text-accent-foreground cursor-pointer">
                      {MonitorTypeUtil.toLongString(
                        monitorTypeKey as MonitorTypeKey,
                      )}
                    </span>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent side="bottom" align="start">
                    <MonitorTypeDropdownItems
                      catalogId={catalog.id}
                      monitorTypeKey={monitorTypeKey as MonitorTypeKey}
                    />
                  </DropdownMenuContent>
                </DropdownMenu>
              </>
            )}
            {monitorId && catalog && (
              <>
                <BreadcrumbItem>
                  <BreadcrumbLink href={`${basePath}/catalog`}>
                    {t("Katalogübersicht")}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbLink href={`${basePath}/catalog/${catalogId}`}>
                    {catalog?.name || t("Katalog")}
                  </BreadcrumbLink>
                </BreadcrumbItem>
                <BreadcrumbSeparator />
                <DropdownMenu modal={false}>
                  <DropdownMenuTrigger asChild>
                    <span className="hover:text-accent-foreground cursor-pointer">
                      {MonitorTypeUtil.toLongString(
                        monitorTypeKey as MonitorTypeKey,
                      )}
                    </span>
                  </DropdownMenuTrigger>
                  <DropdownMenuContent side="bottom" align="start">
                    <MonitorTypeDropdownItems
                      catalogId={catalog.id}
                      monitorTypeKey={monitorTypeKey as MonitorTypeKey}
                    />
                  </DropdownMenuContent>
                </DropdownMenu>
                <BreadcrumbSeparator />
                <BreadcrumbItem>
                  <BreadcrumbPage>{t("Monitor")}</BreadcrumbPage>
                </BreadcrumbItem>
              </>
            )}
          </BreadcrumbList>
        </Breadcrumb>
      </CardContent>
      <Outlet />
    </Card>
  );
}

function MonitorTypeDropdownItems({
  catalogId,
  monitorTypeKey,
}: {
  catalogId: string;
  monitorTypeKey?: MonitorTypeKey;
}) {
  const {
    data: list,
    error,
    isLoading,
  } = useQpmMonitorTypeListQuery({
    catalog: { active: true, values: [catalogId] },
  });

  if (error) {
    return (
      <div className="text-destructive">{t("Fehler beim Laden der Daten")}</div>
    );
  }

  if (isLoading) {
    return <Skeleton className="h-10 w-full" />;
  }

  return (
    list &&
    list.types.map((type) => (
      <DropdownMenuItem key={type.id}>
        <Checkbox
          checked={monitorTypeKey === type.key}
          className="mr-1 border-none shadow-none disabled:cursor-default disabled:opacity-100 data-[state=checked]:bg-inherit"
          disabled
        />
        <NavLink className="w-full" to={`${catalogId}/${type.key}`}>
          {MonitorTypeUtil.toLongString(type.key)}
        </NavLink>
      </DropdownMenuItem>
    ))
  );
}
