import { DateInput } from "@/routes/gemex/processes/_components/ui/date-input";
import { useCallback, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { Offer, SetOfferDateRequest } from "@/services/backend/qpm/offer/types";
import { useQpmOfferSetDateMutation } from "@/services/backend/qpm/offer/service";
import t from "@/lang/lang";

type OfferDateSelectorProps = {
  offer: Offer;
};

export function OfferDateSelector({ offer }: OfferDateSelectorProps) {
  const [request, setRequest] = useState<SetOfferDateRequest>({
    offerId: offer.artefactData.id,
    date: offer.date,
  });
  const [setDate, { error, isLoading, isSuccess, reset }] =
    useQpmOfferSetDateMutation();

  const onChangeDate = useCallback(
    (date: string | null) => {
      if (date) {
        setRequest((prev) => ({ ...prev, date }));
      }
    },
    [setRequest],
  );

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setDate,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
    { toastError: true },
  );

  return (
    <DateInput
      label={t("Angebotsdatum")}
      date={request.date}
      onDateChange={onChangeDate}
      disabled={offer.artefactData.immutable}
    />
  );
}
