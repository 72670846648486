import React, { useEffect, useRef, useState } from "react";
import { Button } from "@/shared/components/ui/button";
import { Bold, Italic, List, SquareCode, Underline } from "lucide-react";
import { Textarea } from "@/shared/components/ui/textarea";
import { Label } from "@/shared/components/ui/label";
import t from "@/lang/lang";

export type HTMLTextAreaComponentProps = {
  value: string;
  onChange: (newValue: string) => void;
};

// HTMLTextAreaComponent is experimental and will be updated in a future issue/pr
export function HTMLTextAreaComponent({
  value,
  onChange,
}: HTMLTextAreaComponentProps) {
  const [text, setText] = useState(value || "<p></p>");
  const [lastKey, setLastKey] = useState("");
  const textareaRef = useRef<HTMLTextAreaElement>(null);

  useEffect(() => {
    onChange(text);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  function insertTag(tag: string) {
    const textarea = textareaRef.current;
    if (!textarea) return;

    const start = textarea.selectionStart;
    const end = textarea.selectionEnd;
    const selectedText = text.substring(start, end);

    const newText = `${text.substring(
      0,
      start,
    )}<${tag}>${selectedText}</${tag}>${text.substring(end)}`;

    setText(newText);
  }

  function insertList() {
    setText((prev) => `${prev}\n<ul>\n<li></li>\n</ul>`);
  }

  function handleKeyDown(e: React.KeyboardEvent<HTMLTextAreaElement>) {
    if (e.key === "Enter" && lastKey === "Enter") {
      e.preventDefault();
      setText((prev) => `${prev}\n<p></p>`);
    }
    setLastKey(e.key);
  }

  return (
    <div className="flex w-full gap-2">
      <div className="grid w-full grid-cols-2 gap-2">
        <div className="flex flex-col justify-between gap-2">
          <div className="flex gap-2">
            <Button
              className="h-fit p-1"
              size="sm"
              variant="outline"
              onClick={() => insertTag("b")}
            >
              <Bold className="h-4 w-4" />
            </Button>
            <Button
              className="h-fit p-1"
              size="sm"
              variant="outline"
              onClick={() => insertTag("i")}
            >
              <Italic className="h-4 w-4" />
            </Button>
            <Button
              className="h-fit p-1"
              size="sm"
              variant="outline"
              onClick={() => insertTag("u")}
            >
              <Underline className="h-4 w-4" />
            </Button>
            <Button
              className="h-fit p-1"
              size="sm"
              variant="outline"
              onClick={() => insertList()}
            >
              <List className="h-4 w-4" />
            </Button>
          </div>
          <Textarea
            ref={textareaRef}
            className="min-h-[300px] w-full rounded border p-2"
            value={text}
            onChange={(e) => setText(e.target.value)}
            onKeyDown={(key) => handleKeyDown(key)}
          />
        </div>

        <div className="flex flex-col justify-between gap-2">
          <Label htmlFor="preview">{t("Vorschau")}</Label>
          <div
            id="preview"
            className="min-h-[300px] rounded border bg-gray-50 p-2"
            /* eslint-disable-next-line react/no-danger */
            dangerouslySetInnerHTML={{ __html: text }}
          />
        </div>
      </div>
      <style>{`
        #preview ul {
          list-style-type: disc;
          margin-left: 1.5rem;
        }
      `}</style>
    </div>
  );
}

// WYSIWYGHTMLTextAreaComponent is experimental and will be updated in a future issue/pr
export function WYSIWYGHTMLTextAreaComponent({
  value,
  onChange,
}: HTMLTextAreaComponentProps) {
  const [text, setText] = useState(value || "<p></p>");
  const [showRawHtml, setShowRawHtml] = useState(false); // Toggle state
  const editorRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (editorRef.current && editorRef.current.innerHTML !== value) {
      editorRef.current.innerHTML = value;
    }
  }, [value]);

  function formatText(tag: string) {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const selectedText = range.toString();

    if (!selectedText) return; // No text selected

    const wrapper = document.createElement(tag);
    wrapper.appendChild(document.createTextNode(selectedText));

    range.deleteContents();
    range.insertNode(wrapper);

    handleInput();
  }

  function insertList() {
    const selection = window.getSelection();
    if (!selection || !selection.rangeCount) return;

    const range = selection.getRangeAt(0);
    const ul = document.createElement("ul");
    const li = document.createElement("li");
    li.textContent = "List item";
    ul.appendChild(li);

    range.deleteContents();
    range.insertNode(ul);

    handleInput();
  }

  function handleInput() {
    if (editorRef.current) {
      const newHtml = editorRef.current.innerHTML;
      setText(newHtml); // Do NOT cause a re-render
      onChange(newHtml);
    }
  }

  function toggleRawHtml() {
    setShowRawHtml((prev) => !prev);
  }

  return (
    <div className="flex flex-col gap-2">
      {/* Toolbar */}
      <div className="flex w-full items-center justify-between gap-2">
        <div className="flex items-center gap-2">
          <Button
            className="h-fit p-1"
            size="sm"
            variant="outline"
            onClick={() => formatText("b")}
          >
            <Bold className="h-4 w-4" />
          </Button>
          <Button
            className="h-fit p-1"
            size="sm"
            variant="outline"
            onClick={() => formatText("i")}
          >
            <Italic className="h-4 w-4" />
          </Button>
          <Button
            className="h-fit p-1"
            size="sm"
            variant="outline"
            onClick={() => formatText("u")}
          >
            <Underline className="h-4 w-4" />
          </Button>
          <Button
            className="h-fit p-1"
            size="sm"
            variant="outline"
            onClick={() => insertList()}
          >
            <List className="h-4 w-4" />
          </Button>
        </div>

        {/* Toggle Raw HTML Button */}
        <Button
          className="h-fit justify-self-end p-1"
          size="sm"
          variant="outline"
          onClick={() => toggleRawHtml()}
        >
          <SquareCode className="h-4 w-4" />
        </Button>
      </div>

      {/* Content Editing Area */}
      {showRawHtml ? (
        // Raw HTML View
        <textarea
          className="min-h-[300px] w-full rounded border p-2 font-mono text-sm"
          value={text}
          onChange={(e) => {
            setText(e.target.value);
            onChange(e.target.value);
          }}
        />
      ) : (
        // WYSIWYG Mode
        <div
          ref={editorRef}
          contentEditable
          suppressContentEditableWarning
          className="preview min-h-[300px] w-full rounded border bg-white p-2 focus:outline-none"
          onInput={handleInput}
        />
      )}
      <style>{`
        .preview ul {
          list-style-type: disc;
          margin-left: 1.5rem;
        }
      `}</style>
    </div>
  );
}
