import { backendApi } from "@/services/backend/api";
import {
  Contract,
  Lifetime,
  List,
  State,
} from "@/services/backend/vbs/contracts/contract";
import { Filter } from "@/shared/lib/filter/filter";
import { Address } from "@/services/backend/addresses/address/address";
import t from "@/lang/lang";

const contractApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    vbsContractCreate: build.mutation<Contract, CreateRequest>({
      query: (request) => ({
        url: `/vbs/contract`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "VbsContract", id: "LIST" }],
    }),
    vbsContractCreateForOffer: build.mutation<Contract, CreateForOfferRequest>({
      query: (request) => ({
        url: `/vbs/contract/for-offer`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "VbsContract", id: "LIST" }, "VbsOffer"],
    }),
    vbsContractShow: build.query<Contract, ShowRequest>({
      query: (request) => ({
        url: "/vbs/contract",
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsContract", id: result.id }] : [],
    }),
    vbsContractList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: "/vbs/contract/list",
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.contracts.map((contract) => ({
                type: "VbsContract" as const,
                id: contract.id,
              })),
              { type: "VbsContract", id: "LIST" },
            ]
          : [{ type: "VbsContract", id: "LIST" }],
    }),
    vbsContractCopyFromOffer: build.mutation<Contract, CopyFromOfferRequest>({
      query: (request) => ({
        url: `/vbs/contract/copy-from-offer`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
        "VbsCalculation",
        "VbsTreatment",
      ],
    }),
    vbsContractUpdateLifetime: build.mutation<Contract, UpdateLifetimeRequest>({
      query: (request) => ({
        url: `/vbs/contract/lifetime`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractRegisterScan: build.mutation<
      Contract,
      RegisterSignedContractRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/register-signed-contract`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractTerminate: build.mutation<Contract, TerminateRequest>({
      query: (request) => ({
        url: `/vbs/contract/terminate`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractUpdateCustomerAddress: build.mutation<
      Contract,
      UpdateCustomerAddressRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/customer-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractUpdateServiceAddress: build.mutation<
      Contract,
      UpdateServiceAddressRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/service-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractUpdateSalesDiscount: build.mutation<
      Contract,
      UpdateSalesDiscountRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/sales-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractUpdateAbsoluteDiscount: build.mutation<
      Contract,
      UpdateAbsoluteDiscountRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/absolute-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractUpdatePaymentTerms: build.mutation<
      Contract,
      UpdatePaymentTermsRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/payment-terms`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractUpdateSpecialAgreements: build.mutation<
      Contract,
      UpdateSpecialAgreementsRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/special-agreements`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractPreviewPDF: build.query<
      { objectURL: string },
      PreviewPDFRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/preview-pdf`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      providesTags: (_r, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
    vbsContractDownloadPDF: build.mutation<
      { filename: string; objectURL: string },
      DownloadPDFRequest
    >({
      query: (request) => ({
        url: `/vbs/contract/download-pdf`,
        method: "PATCH",
        body: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          // attempt to retrieve the filename from content-disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          // fallback filename
          let filename = t("Vertrag.pdf");
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return {
            filename,
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this mutation will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsContract", id: request.id },
      ],
    }),
  }),
});

export const {
  useVbsContractCreateMutation,
  useVbsContractCreateForOfferMutation,
  useVbsContractShowQuery,
  useVbsContractListQuery,
  useVbsContractCopyFromOfferMutation,
  useVbsContractUpdateLifetimeMutation,
  useVbsContractRegisterScanMutation,
  useVbsContractTerminateMutation,
  useVbsContractUpdateCustomerAddressMutation,
  useVbsContractUpdateServiceAddressMutation,
  useVbsContractUpdateSalesDiscountMutation,
  useVbsContractUpdateAbsoluteDiscountMutation,
  useVbsContractUpdatePaymentTermsMutation,
  useVbsContractUpdateSpecialAgreementsMutation,
  useVbsContractPreviewPDFQuery,
  useVbsContractDownloadPDFMutation,
} = contractApi;

export interface CreateRequest {
  id: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface CreateForOfferRequest {
  id: string;
  offerId: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  branch?: Filter<string>;
  state?: Filter<State>;
  lifetimeStartDate?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface CopyFromOfferRequest {
  id: string;
  offerId: string;
  copyCalculation: boolean;
  copyDiscounts: boolean;
  copyAddresses: boolean;
}

export interface UpdateLifetimeRequest {
  id: string;
  lifetime: Lifetime;
}

export interface RegisterSignedContractRequest {
  id: string;
  file: File;
  dataFormat: string;
  scannedBy: string;
}

export interface TerminateRequest {
  id: string;
  terminationReason: string;
}

export interface UpdateCustomerAddressRequest {
  id: string;
  customerAddress: Address;
}

export interface UpdateServiceAddressRequest {
  id: string;
  serviceAddress: Address;
}

export interface UpdateSalesDiscountRequest {
  id: string;
  active: boolean;
  percentage: number;
}

export interface UpdateAbsoluteDiscountRequest {
  id: string;
  active: boolean;
  amount: number;
}

export interface UpdatePaymentTermsRequest {
  id: string;
  terms: string;
}

export interface UpdateSpecialAgreementsRequest {
  id: string;
  agreements: string;
}

export interface PreviewPDFRequest {
  id: string;
  document: ContractDocument;
}

export interface DownloadPDFRequest {
  id: string;
  document: ContractDocument;
  downloadBy: string;
}

export enum ContractDocument {
  CONTRACT = "contract",
  TREATMENT = "treatment",
}
