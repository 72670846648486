import {
  CreateConsumable,
  Monitor,
  MonitorConsumable,
  MonitorType,
  PutMonitorConsumableRequest,
  PutMonitorPestCategoryRequest,
  PutMonitorPestTypeRequest,
  PutMonitorRequest,
} from "@/services/backend/qpm/monitors/types";
import {
  useQpmMonitorDeleteMutation,
  useQpmMonitorGetQuery,
  useQpmMonitorPutMutation,
} from "@/services/backend/qpm/monitors/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import React, { useCallback, useEffect, useState } from "react";
import { v4 } from "uuid";
import { usePersistenceStateUpdateOnTrigger } from "@/shared/lib/persistence-state/persistence-state-info";
import { PestCategory, PestType } from "@/services/backend/qpm/pests/types";
import {
  ConsumableTypeKey,
  ConsumableUnitKey,
  MonitorTypeKey,
} from "@/services/backend/qpm/shared/enums";
import { Label } from "@/shared/components/ui/label";
import t from "@/lang/lang";
import { Input } from "@/shared/components/ui/input";
import { MonitorTypeUtil } from "@/services/backend/qpm/shared/monitorTypeUtil";
import {
  FloatNumberInput,
  IntNumberInput,
} from "@/routes/gemex/processes/_components/ui/number-input";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { Button } from "@/shared/components/ui/button";
import {
  AlertTriangleIcon,
  Pencil,
  Plus,
  Trash2,
  Undo2,
  X,
} from "lucide-react";
import {
  useQpmPestCategoryListQuery,
  useQpmPestTypeListQuery,
} from "@/services/backend/qpm/pests/service";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/shared/components/ui/hover-card";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { ConsumableTypeUtil } from "@/services/backend/qpm/shared/consumableTypeUtil";
import { ConsumableUnitTypeUtil } from "@/services/backend/qpm/shared/consumableUnitUtil";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { CardContent, CardTitle } from "@/shared/components/ui/card";
import { is404 } from "@/shared/components/domain/errors/parse-r-t-k-query-error";

export type MonitorComponentProps = {
  catalogId: string;
  type: MonitorType;
  monitorId: string;
};

export function MonitorComponent({
  catalogId,
  type,
  monitorId,
}: MonitorComponentProps) {
  const {
    data: monitor,
    error,
    isLoading,
  } = useQpmMonitorGetQuery({ id: monitorId });

  if (error && !is404(error)) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Skeleton className="h-48 w-full" />;
  }

  return (
    <MonitorEditComponent
      catalogId={catalogId}
      type={type}
      monitor={monitor}
      monitorId={monitorId}
    />
  );
}

type MonitorEditComponentProps = {
  catalogId: string;
  type: MonitorType;
  monitor?: Monitor;
  monitorId?: string;
  onSaveSuccess?: () => void;
};

function MonitorEditComponent({
  catalogId,
  type,
  monitor,
  monitorId,
  onSaveSuccess,
}: MonitorEditComponentProps) {
  const [request, setRequest] = useState<PutMonitorRequest>({
    id: monitorId || v4(),
    catalogId,
    name: "",
    supplier: "",
    hasLethalAgent: false,
    recommendedAmountOfMeasuresPerYear: 0,
    basePrice: 0,
    type,
    pestCategoryList: [],
    pestTypeList: [],
    riskTypeList: [],
    consumableList: [],
    rentingFees: { fees: {} },
  });

  const [put, { error, isLoading, isSuccess, reset }] =
    useQpmMonitorPutMutation();

  const { onTrigger: triggerSave } = usePersistenceStateUpdateOnTrigger(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  const handleSubmit = () => {
    const createdAt = monitor ? monitor.createdAt : new Date().toISOString();
    setRequest((prev) => ({
      ...prev,
      createdAt,
    }));
    triggerSave();
  };

  const onSelectPestCategoryId = (pestCategory: PestCategory) => {
    setRequest((prev) => {
      if (prev.pestCategoryList.some((p) => p.id === pestCategory.id)) {
        return prev;
      }
      return {
        ...prev,
        pestCategoryList: [
          ...prev.pestCategoryList,
          { ...pestCategory, markedForDelete: false },
        ],
      };
    });
  };

  const onDeleteSelectedPestCategoryId = (
    id: string,
    markedForDelete: boolean,
  ) => {
    setRequest((prev) => ({
      ...prev,
      pestCategoryList: (prev.pestCategoryList || []).map((cat) =>
        cat.id === id ? { ...cat, markedForDelete } : cat,
      ),
    }));
  };

  const onSelectPestTypeId = (pestType: PestType) => {
    setRequest((prev) => {
      if (prev.pestTypeList.some((p) => p.id === pestType.id)) {
        return prev;
      }
      return {
        ...prev,
        pestTypeList: [
          ...prev.pestTypeList,
          { ...pestType, markedForDelete: false },
        ],
      };
    });
  };

  const onDeleteSelectedPestTypeId = (id: string, markedForDelete: boolean) => {
    setRequest((prev) => ({
      ...prev,
      pestTypeList: (prev.pestTypeList || []).map((cat) =>
        cat.id === id ? { ...cat, markedForDelete } : cat,
      ),
    }));
  };

  const addConsumable = useCallback(() => {
    setRequest((prev) => {
      const hasEmptyEntry =
        prev.consumableList &&
        prev.consumableList.some((item) => item.consumable.name.trim() === "");

      if (hasEmptyEntry) {
        return prev;
      }

      const newConsumable = {
        monitorId: prev.id,
        catalogId,
        consumable: {
          id: v4(),
          catalogId,
          name: "",
          type: ConsumableTypeKey.Bait,
          unit: ConsumableUnitKey.Item,
          property: "",
          basePrice: 0,
          effectiveDate: new Date().toISOString(),
          createdAt: new Date().toISOString(),
        },
        price: 0,
        recommendedAmountOfMeasuresPerYear: 0,
        requiredAmount: 0,
        effectiveDate: new Date().toISOString(),
        updatedAt: new Date().toISOString(),
      };

      return {
        ...prev,
        consumableList: Array.isArray(prev.consumableList)
          ? [...prev.consumableList, newConsumable]
          : [newConsumable],
      };
    });
  }, [catalogId]);

  const updateConsumable = useCallback(
    (updatedConsumable: PutMonitorConsumableRequest) => {
      setRequest((prev) => ({
        ...prev,
        consumableList: prev.consumableList.map((item) =>
          item.consumable.id === updatedConsumable.consumable.id
            ? updatedConsumable
            : item,
        ),
      }));
    },
    [],
  );

  useEffect(() => {
    if (monitor) {
      setRequest((prev) => ({
        ...monitor,
        catalogId,
        type,
        basePrice: monitor.price,
        pestCategoryList: (monitor.pestCategoryList || []).map((cat) => {
          const existingCat = prev.pestCategoryList.find(
            (reqCat) => reqCat.id === cat.id,
          );

          return {
            ...cat,
            markedForDelete: existingCat ? existingCat.markedForDelete : false,
          };
        }),
        pestTypeList: (monitor.pestTypeList || []).map((pestType) => {
          const existingCat = prev.pestTypeList.find(
            (reqPestType) => reqPestType.id === pestType.id,
          );

          return {
            ...pestType,
            markedForDelete: existingCat ? existingCat.markedForDelete : false,
          };
        }),
      }));
    }
  }, [catalogId, monitor, type]);

  useEffect(() => {
    if (isSuccess && onSaveSuccess) {
      setTimeout(() => {
        onSaveSuccess();
      }, 350);
    }
  }, [isSuccess, onSaveSuccess]);

  return (
    <CardContent className="flex flex-col gap-4">
      <div className="flex flex-col gap-4">
        <div className="grid grid-cols-3 items-center gap-4">
          <Label htmlFor="name" className="">
            {t("Name")}
          </Label>
          <Input
            id="name"
            autoComplete="nope"
            type="text"
            value={request.name}
            className="aria- col-span-2"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setRequest({ ...request, name: e.target.value })
            }
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 items-center gap-4">
        <Label htmlFor="type" className="">
          {t("Art")}
        </Label>
        <span
          id="type"
          className="col-span-2 h-10 w-full rounded-md border px-3 py-2 text-base md:text-sm"
        >
          {MonitorTypeUtil.toLongString(type.key)}
        </span>
      </div>
      <div className="flex flex-col gap-4 py-4">
        <div className="grid grid-cols-3 items-center gap-4">
          <Label htmlFor="supplier" className="">
            {t("Lieferant")}
          </Label>
          <Input
            id="supplier"
            autoComplete="nope"
            type="text"
            value={request.supplier}
            className="aria- col-span-2"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
              setRequest({ ...request, supplier: e.target.value })
            }
            disabled={isLoading}
          />
        </div>
      </div>
      <div className="grid grid-cols-3 items-center gap-4">
        <Label htmlFor="recommendedMeasures" className="">
          {t("Empfohlene Anzahl an Maßnahmen im Jahr")}
        </Label>
        <IntNumberInput
          id="recommendedMeasures"
          value={monitor?.recommendedAmountOfMeasuresPerYear || 0}
          className="col-span-2 w-full"
          onChange={(val) =>
            setRequest({ ...request, recommendedAmountOfMeasuresPerYear: val })
          }
          disabled={isLoading}
        />
      </div>
      <div className="grid grid-cols-3 items-center gap-4">
        <Label htmlFor="price" className="">
          {t("Preis")}
        </Label>
        <FloatNumberInput
          id="price"
          value={monitor?.price || 0}
          className="col-span-2 w-full"
          onChange={(val) => setRequest({ ...request, basePrice: val })}
          disabled={isLoading}
        />
      </div>
      {type.key === MonitorTypeKey.CheckPoint && (
        <div className="grid grid-cols-3 items-center gap-4">
          <Label htmlFor="hasLethalAgent" className="">
            {t("Enthält toxischen Wirkstoff")}
          </Label>
          <Checkbox
            id="hasLethalAgent"
            checked={request?.hasLethalAgent || false}
            className=""
            onCheckedChange={(val) => {
              if (val !== "indeterminate") {
                setRequest({ ...request, hasLethalAgent: val });
              }
            }}
            disabled={isLoading}
          />
        </div>
      )}
      <div className="flex flex-col gap-2">
        <div className="flex items-end justify-between gap-2">
          <CardTitle className="">{t("Schädlings-Kategorie(n)")}</CardTitle>
          <PestCategorySelectorComponent
            selectedItems={request.pestCategoryList}
            onSelect={onSelectPestCategoryId}
            disabled={isLoading}
          />
        </div>
        <SelectedPestCategoryComponent
          selectedItems={request.pestCategoryList}
          onDeleteSelected={onDeleteSelectedPestCategoryId}
          disabled={isLoading}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-end justify-between gap-2">
          <CardTitle className="">{t("Schädlings-Art(en)")}</CardTitle>
          <PestTypeSelectorComponent
            selectedItems={request.pestTypeList}
            onSelect={onSelectPestTypeId}
            disabled={isLoading}
          />
        </div>
        <SelectedPestTypeComponent
          selectedItems={request.pestTypeList}
          onDeleteSelected={onDeleteSelectedPestTypeId}
          disabled={isLoading}
        />
      </div>
      <div className="flex flex-col gap-2">
        <div className="flex items-end justify-between gap-2">
          <CardTitle className="">{t("Verbrauchsmaterialien")}</CardTitle>
          <Button onClick={addConsumable}>
            <Plus />
            {t("Material hinzufügen")}
          </Button>
        </div>
        <ConsumableMaterialTable
          consumableList={request.consumableList}
          onUpdateConsumable={updateConsumable}
        />
      </div>
      <div>
        {monitor && (
          <DeleteMonitorDialog
            monitor={monitor}
            onDeleteSuccess={onSaveSuccess}
          />
        )}
        <Button type="submit" onClick={handleSubmit}>
          {t("Speichern")}
        </Button>
      </div>
    </CardContent>
  );
}

type PestCategorySelectorComponentProps = {
  selectedItems: PutMonitorPestCategoryRequest[];
  onSelect: (selectedItem: PestCategory) => void;
  disabled?: boolean;
};

function PestCategorySelectorComponent({
  selectedItems,
  onSelect,
  disabled,
}: PestCategorySelectorComponentProps) {
  const {
    data: list,
    error,
    isLoading,
  } = useQpmPestCategoryListQuery({ useDefaultValuesIfNotExist: false });

  const [filteredList, setFilteredList] = useState<PestCategory[]>([]);

  useEffect(() => {
    if (list) {
      const filteredAndSorted = list.categories
        .filter(
          (category) =>
            !selectedItems.some(
              (selectedItem) => selectedItem.id === category.id,
            ),
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      setFilteredList(filteredAndSorted);
    }
  }, [selectedItems, list]);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-20 w-full" />
      </div>
    );
  }

  if (!list || (list.categories.length === 0 && selectedItems.length === 0)) {
    return (
      <HoverCard>
        <HoverCardTrigger>
          <Button
            variant="outline"
            className="text-muted-foreground border-yellow-300"
          >
            <AlertTriangleIcon className="h-4 w-4 text-yellow-300" />
            {t("Hinweis")}
          </Button>
        </HoverCardTrigger>
        <HoverCardContent>
          {t(
            "Es wurden noch keine Stammdaten für die Schädlingskategorien festgelegt!",
          )}
          <br />
          {t(
            "Bitte erstellen Sie zunächst die Schädlingskategorien in den Stammdaten.",
          )}
        </HoverCardContent>
      </HoverCard>
    );
  }

  return (
    <Select
      onValueChange={(id: string) => {
        const item = filteredList.find((v) => v.id === id);
        if (item) {
          onSelect(item);
        }
      }}
      disabled={disabled}
    >
      <SelectTrigger className="w-[300px]">
        {t("Schädlings-Kategorie hinzufügen")}
      </SelectTrigger>
      <SelectContent align="end">
        {filteredList.map((item) => (
          <SelectItem key={item.id} value={item.id}>
            {item.name}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

type SelectedPestCategoryComponentProps = {
  selectedItems: PutMonitorPestCategoryRequest[];
  onDeleteSelected: (selectedId: string, markedForDelete: boolean) => void;
  disabled?: boolean;
};

function SelectedPestCategoryComponent({
  selectedItems,
  onDeleteSelected,
  disabled,
}: SelectedPestCategoryComponentProps) {
  const {
    data: list,
    error,
    isLoading,
  } = useQpmPestCategoryListQuery({ useDefaultValuesIfNotExist: false });

  const [sortedList, setSortedList] = useState<PutMonitorPestCategoryRequest[]>(
    [],
  );

  useEffect(() => {
    if (list) {
      const sorted = selectedItems.sort((a, b) => a.name.localeCompare(b.name));

      setSortedList(sorted);
    }
  }, [selectedItems, list]);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-20 w-full" />
      </div>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">{t("Kategorie")}</TableHead>
          <TableHead>{t("Beschreibung")}</TableHead>
          <TableHead className="text-center">
            {t("Empfohlene Anzahl an Maßnahmen")}
          </TableHead>
          <TableHead className="text-right">{t("Aktion")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sortedList.map((item) => (
          <TableRow
            key={item.id}
            className={
              item.markedForDelete
                ? "bg-muted text-muted-foreground line-through"
                : ""
            }
          >
            <TableCell className="py-1 font-medium">{item.name}</TableCell>
            <TableCell className="py-1">{item.description}</TableCell>
            <TableCell className="flex items-center justify-center py-1.5 text-center">
              {item.requiredQpmCyclePerYear}
            </TableCell>
            <TableCell className="py-1 text-end">
              <div className="flex items-center justify-end">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        className="h-fit p-1"
                        size="sm"
                        variant="outline"
                        onClick={() =>
                          onDeleteSelected(item.id, !item.markedForDelete)
                        }
                        disabled={disabled}
                      >
                        {!item.markedForDelete && (
                          <Trash2 className="h-3 w-3" />
                        )}
                        {item.markedForDelete && <Undo2 className="h-3 w-3" />}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      {!item.markedForDelete && <p>{t("Entfernen")}</p>}
                      {item.markedForDelete && (
                        <p>{t("Löschen rückgängig machen")}</p>
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {sortedList.length === 0 && (
          <TableRow>
            <TableCell colSpan={4} className="text-center">
              -- {t("Es wurde noch keine Schädlings-Kategorie hinzugefügt")} --
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

type PestTypeSelectorComponentProps = {
  selectedItems: PestType[];
  onSelect: (selectedItem: PestType) => void;
  disabled?: boolean;
};

function PestTypeSelectorComponent({
  selectedItems,
  onSelect,
  disabled,
}: PestTypeSelectorComponentProps) {
  const {
    data: list,
    error,
    isLoading,
  } = useQpmPestTypeListQuery({ useDefaultValuesIfNotExist: false });

  const [filteredList, setFilteredList] = useState<PestType[]>([]);

  useEffect(() => {
    if (list) {
      const filteredAndSorted = list.types
        .filter(
          (type) =>
            !selectedItems.some((selectedItem) => selectedItem.id === type.id),
        )
        .sort((a, b) => a.name.localeCompare(b.name));

      setFilteredList(filteredAndSorted);
    }
  }, [selectedItems, list]);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-20 w-full" />
      </div>
    );
  }

  if (!list || (list.types.length === 0 && selectedItems.length === 0)) {
    return (
      <HoverCard>
        <HoverCardTrigger>
          <Button
            variant="outline"
            className="text-muted-foreground border-yellow-300"
          >
            <AlertTriangleIcon className="h-4 w-4 text-yellow-300" />
            {t("Hinweis")}
          </Button>
        </HoverCardTrigger>
        <HoverCardContent>
          {t(
            "Es wurden noch keine Stammdaten für die Schädlingsarten festgelegt!",
          )}
          <br />
          {t(
            "Bitte erstellen Sie zunächst die Schädlingsarten in den Stammdaten.",
          )}
        </HoverCardContent>
      </HoverCard>
    );
  }

  return (
    <Select
      onValueChange={(id: string) => {
        const item = filteredList.find((v) => v.id === id);
        if (item) {
          onSelect(item);
        }
      }}
      disabled={disabled}
    >
      <SelectTrigger className="w-[300px]">
        {t("Schädlings-Art hinzufügen")}
      </SelectTrigger>
      <SelectContent align="end">
        {filteredList.map((item) => (
          <SelectItem key={item.id} value={item.id}>
            {item.name} - {item.description}
          </SelectItem>
        ))}
      </SelectContent>
    </Select>
  );
}

type SelectedPestTypeComponentProps = {
  selectedItems: PutMonitorPestTypeRequest[];
  onDeleteSelected: (selectedId: string, markedForDelete: boolean) => void;
  disabled?: boolean;
};

function SelectedPestTypeComponent({
  selectedItems,
  onDeleteSelected,
  disabled,
}: SelectedPestTypeComponentProps) {
  const {
    data: list,
    error,
    isLoading,
  } = useQpmPestTypeListQuery({ useDefaultValuesIfNotExist: false });

  const [sortedList, setSortedList] = useState<PutMonitorPestTypeRequest[]>([]);

  useEffect(() => {
    if (list) {
      const sorted = selectedItems.sort((a, b) => a.name.localeCompare(b.name));

      setSortedList(sorted);
    }
  }, [selectedItems, list]);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <div>
        <Skeleton className="h-20 w-full" />
      </div>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="w-[100px]">{t("Bezeichner")}</TableHead>
          <TableHead>{t("Beschreibung")}</TableHead>
          <TableHead className="text-center">
            {t("Empfohlene Anzahl an Maßnahmen")}
          </TableHead>
          <TableHead className="text-right">{t("Aktion")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {sortedList.map((item) => (
          <TableRow
            key={item.id}
            className={
              item.markedForDelete
                ? "bg-muted text-muted-foreground line-through"
                : ""
            }
          >
            <TableCell className="py-1 font-medium">{item.name}</TableCell>
            <TableCell className="py-1">{item.description}</TableCell>
            <TableCell className="flex items-center justify-center py-1.5 text-center">
              {item.requiredQpmCyclePerYear}
            </TableCell>
            <TableCell className="py-1 text-end">
              <div className="flex items-center justify-end">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button
                        className="h-fit p-1"
                        size="sm"
                        variant="outline"
                        onClick={() =>
                          onDeleteSelected(item.id, !item.markedForDelete)
                        }
                        disabled={disabled}
                      >
                        {!item.markedForDelete && (
                          <Trash2 className="h-3 w-3" />
                        )}
                        {item.markedForDelete && <Undo2 className="h-3 w-3" />}
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      {!item.markedForDelete && <p>{t("Entfernen")}</p>}
                      {item.markedForDelete && (
                        <p>{t("Löschen rückgängig machen")}</p>
                      )}
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </div>
            </TableCell>
          </TableRow>
        ))}
        {sortedList.length === 0 && (
          <TableRow>
            <TableCell colSpan={4} className="text-center">
              -- {t("Es wurde noch keine Schädlings-Art hinzugefügt")} --
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

type ConsumableMaterialTableProps = {
  consumableList: MonitorConsumable[];
  onUpdateConsumable: (updatedConsumable: MonitorConsumable) => void;
};

function ConsumableMaterialTable({
  consumableList,
  onUpdateConsumable,
}: ConsumableMaterialTableProps) {
  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead className="">{t("Material")}</TableHead>
          <TableHead>{t("Art")}</TableHead>
          <TableHead>{t("Eigenschaften")}</TableHead>
          <TableHead className="text-end">{t("Benötigte Menge")}</TableHead>
          <TableHead className="text-end">{t("Einheit")}</TableHead>
          <TableHead className="text-end">{t("Maßnahmen")}</TableHead>
          <TableHead className="text-end">{t("Preis")}</TableHead>
          <TableHead className="text-end">{t("Aktion")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        <ConsumableListTableBody
          consumableList={consumableList}
          onUpdateConsumable={onUpdateConsumable}
        />
      </TableBody>
    </Table>
  );
}

type ConsumableListTableBodyProps = {
  consumableList: PutMonitorConsumableRequest[];
  onUpdateConsumable: (updatedConsumable: MonitorConsumable) => void;
};

function ConsumableListTableBody({
  consumableList,
  onUpdateConsumable,
}: ConsumableListTableBodyProps) {
  if (!consumableList || consumableList.length === 0) {
    return (
      <TableRow>
        <TableCell colSpan={8} className="text-center">
          -- {t("Es wurden noch keine Verbrauchsmaterialien zugewiesen")} --
        </TableCell>
      </TableRow>
    );
  }

  return consumableList.map((item) => (
    <ConsumableRow consumable={item} onUpdateConsumable={onUpdateConsumable} />
  ));
}

type ConsumableRowProps = {
  consumable: PutMonitorConsumableRequest;
  onUpdateConsumable: (updatedConsumable: MonitorConsumable) => void;
};

function ConsumableRow({ consumable, onUpdateConsumable }: ConsumableRowProps) {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);

  const handleChange = (
    updatedValues: Partial<PutMonitorConsumableRequest>,
    updatedConsumableValues?: Partial<CreateConsumable>,
  ) => {
    const updatedConsumable: PutMonitorConsumableRequest = {
      ...consumable,
      ...updatedValues,
      consumable: { ...consumable.consumable, ...updatedConsumableValues },
    };
    onUpdateConsumable(updatedConsumable);
  };

  return (
    <TableRow
      key={consumable.consumable.id}
      className={
        consumable.markedForDelete
          ? "bg-muted text-muted-foreground line-through"
          : ""
      }
    >
      {isEditMode && (
        <>
          <TableCell className="py-1">
            <Input
              value={consumable.consumable.name}
              onChange={(e) => handleChange({}, { name: e.target.value })}
            />
          </TableCell>
          <TableCell className="py-1">
            <SelectConsumableType
              selectedType={consumable.consumable.type}
              onChange={(val) => handleChange({}, { type: val })}
            />
          </TableCell>
          <TableCell className="py-1">
            <Input
              value={consumable.consumable.property}
              onChange={(e) => handleChange({}, { property: e.target.value })}
            />
          </TableCell>
          <TableCell className="py-1 text-end">
            <IntNumberInput
              value={consumable.requiredAmount}
              onChange={(val) => handleChange({ requiredAmount: val })}
            />
          </TableCell>
          <TableCell className="py-1">
            <SelectConsumableUnit
              selectedUnit={consumable.consumable.unit}
              onChange={(val) => handleChange({}, { unit: val })}
            />
          </TableCell>
          <TableCell className="py-1 text-end">
            <IntNumberInput
              value={consumable.recommendedAmountOfMeasuresPerYear}
              onChange={(val) =>
                handleChange({ recommendedAmountOfMeasuresPerYear: val })
              }
            />
          </TableCell>
          <TableCell className="py-1 text-end">
            <FloatNumberInput
              value={consumable.consumable.basePrice}
              onChange={(val) => handleChange({}, { basePrice: val })}
            />
          </TableCell>
          <TableCell className="py-1 text-end">
            <TooltipProvider>
              <div className="flex gap-1">
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      size="sm"
                      variant="outline"
                      className="h-fit p-1"
                      onClick={() => setIsEditMode(false)}
                    >
                      <X className="h-3 w-3" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>{t("Abbrechen")}</TooltipContent>
                </Tooltip>
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      size="sm"
                      variant="outline"
                      className="h-fit p-1"
                      onClick={() =>
                        handleChange({
                          markedForDelete: !consumable.markedForDelete,
                        })
                      }
                    >
                      {!consumable.markedForDelete && (
                        <Trash2 className="h-3 w-3" />
                      )}
                      {consumable.markedForDelete && (
                        <Undo2 className="h-3 w-3" />
                      )}
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    {!consumable.markedForDelete && <p>{t("Entfernen")}</p>}
                    {consumable.markedForDelete && (
                      <p>{t("Löschen rückgängig machen")}</p>
                    )}
                  </TooltipContent>
                </Tooltip>
              </div>
            </TooltipProvider>
          </TableCell>
        </>
      )}
      {!isEditMode && (
        <>
          <TableCell className="py-1">{consumable.consumable.name}</TableCell>
          <TableCell className="py-1">
            {ConsumableTypeUtil.toLongString(consumable.consumable.type)}
          </TableCell>
          <TableCell className="py-1">
            {consumable.consumable.property}
          </TableCell>
          <TableCell className="py-1 text-end">
            {consumable.requiredAmount}
          </TableCell>
          <TableCell className="py-1 text-end">
            {ConsumableUnitTypeUtil.toLongString(consumable.consumable.unit)}
          </TableCell>
          <TableCell className="py-1 text-end">
            {consumable.recommendedAmountOfMeasuresPerYear}
          </TableCell>
          <TableCell className="py-1 text-end">
            {consumable.consumable.basePrice}
          </TableCell>
          <TableCell className="py-1 text-end">
            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    className="h-fit p-1"
                    size="sm"
                    variant="outline"
                    onClick={() => setIsEditMode(true)}
                  >
                    <Pencil className="h-3 w-3" />
                  </Button>
                </TooltipTrigger>
                <TooltipContent>{t("Bearbeiten")}</TooltipContent>
              </Tooltip>
            </TooltipProvider>
          </TableCell>
        </>
      )}
    </TableRow>
  );
}

type SelectConsumableTypeProps = {
  selectedType: ConsumableTypeKey;
  onChange: (value: ConsumableTypeKey) => void;
  disabled?: boolean;
};

function SelectConsumableType({
  selectedType,
  onChange,
  disabled,
}: SelectConsumableTypeProps) {
  return (
    <Select value={selectedType} onValueChange={onChange} disabled={disabled}>
      <SelectTrigger className="">
        <SelectValue placeholder={t("Art wählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={ConsumableTypeKey.Bait}>
          {ConsumableTypeUtil.toString(ConsumableTypeKey.Bait)}
        </SelectItem>
        {/* <SelectItem value={ConsumableTypeKey.BaitTox}>{t("Köder")}</SelectItem> */}
        {/* <SelectItem value={ConsumableTypeKey.BaitNonTox}>{t("Köder")}</SelectItem> */}
        <SelectItem value={ConsumableTypeKey.Adhesive}>
          {ConsumableTypeUtil.toString(ConsumableTypeKey.Adhesive)}
        </SelectItem>
        <SelectItem value={ConsumableTypeKey.Tube}>
          {ConsumableTypeUtil.toString(ConsumableTypeKey.Tube)}
        </SelectItem>
      </SelectContent>
    </Select>
  );
}

type SelectConsumableUnitProps = {
  selectedUnit: string;
  onChange: (unit: ConsumableUnitKey) => void;
  disabled?: boolean;
};

export function SelectConsumableUnit({
  selectedUnit,
  onChange,
  disabled,
}: SelectConsumableUnitProps) {
  return (
    <Select value={selectedUnit} onValueChange={onChange} disabled={disabled}>
      <SelectTrigger className="">
        <SelectValue placeholder={t("Einheit wählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectItem value={ConsumableUnitKey.Item}>
          {ConsumableUnitTypeUtil.toString(ConsumableUnitKey.Item)}
        </SelectItem>
        <SelectItem value={ConsumableUnitKey.Gram}>
          {ConsumableUnitTypeUtil.toString(ConsumableUnitKey.Gram)}
        </SelectItem>
        <SelectItem value={ConsumableUnitKey.Milliliters}>
          {ConsumableUnitTypeUtil.toString(ConsumableUnitKey.Milliliters)}
        </SelectItem>
      </SelectContent>
    </Select>
  );
}

type DeleteMonitorDialogProps = {
  monitor: Monitor;
  onDeleteSuccess?: () => void;
};

function DeleteMonitorDialog({
  monitor,
  onDeleteSuccess,
}: DeleteMonitorDialogProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteMonitor, { error, isLoading, isSuccess, reset }] =
    useQpmMonitorDeleteMutation();
  const { onTrigger: triggerDelete } = usePersistenceStateUpdateOnTrigger(
    { id: monitor.id },
    deleteMonitor,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    }
  }, [isSuccess, onDeleteSuccess]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>
        <Button variant="destructive">{t("Löschen")}</Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("Wollen Sie den Monitor")} {monitor.name}{" "}
            {t("unwiderruflich löschen?")}
          </DialogTitle>
          <DialogDescription>
            {t(
              "Dieser Vorgang kann nicht rückgängig gemacht werden. Dadurch wird der Monitor dauerhaft gelöscht und von den Servern entfernt.",
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            disabled={isLoading}
          >
            {t("Abbrechen")}
          </Button>
          <Button
            variant="destructive"
            onClick={triggerDelete}
            disabled={isLoading}
          >
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
