import { useOfferContext } from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-context";
import {
  hasFieldError,
  resetField,
} from "@/shared/app-lib/errors/validation-error";
import { Label } from "@/shared/components/ui/label";
import { DatePicker } from "@/shared/components/ui/date-picker";

type DateInputProps = {
  label?: string;
  date: string | null;
  onDateChange: (date: string | null) => void;
  disabled: boolean;
};

export function DateInput({
  label,
  date,
  onDateChange,
  disabled,
}: DateInputProps) {
  const { validationError, setValidationError } = useOfferContext();

  const formattedDate = date ? new Date(date) : undefined;
  const onFormattedDateChange = (d: Date | undefined) => {
    if (!d) {
      onDateChange(null);
      return;
    }

    // We want to add the current time to the date
    const dateTime = new Date();
    dateTime.setUTCFullYear(d.getUTCFullYear(), d.getMonth(), d.getDate());
    onDateChange(dateTime.toISOString());
    setValidationError(resetField(validationError, "date"));
  };

  const markError = hasFieldError(validationError, "date");

  return (
    <div className="grid content-start gap-1.5">
      {label && <Label>{label}</Label>}
      <DatePicker
        date={formattedDate}
        onDateChange={onFormattedDateChange}
        markError={markError}
        disabled={disabled}
      />
    </div>
  );
}
