import {
  OfflineState,
  useOfflineContext,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_components/offline-context";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import {
  Mutation,
  useArtefactDBDelete,
  useArtefactDBGet,
  useArtefactDBGetAll,
  useArtefactDBPut,
} from "@/shared/indexdb/artefact-db-hooks";
import { FilterMode } from "@/shared/lib/filter/filter";
import { DustDensitySample } from "@/services/backend/samples/sample/dust-density-sample";
import {
  PutRequest,
  useSamplesDustDensitySampleDeleteMutation,
  useSamplesDustDensitySampleListQuery,
  useSamplesDustDensitySamplePutMutation,
  useSamplesDustDensitySampleShowQuery,
} from "@/services/backend/samples/sample/dust-density-sample-service";
import { DeleteRequest } from "@/services/backend/samples/sample/air-microbial-service";
import { useParams } from "react-router";

export function usePutDustDensitySample(): Mutation<
  DustDensitySample,
  DustDensitySample
> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [putDustDensitySampleMutation, { isLoading, error, isSuccess, reset }] =
    useSamplesDustDensitySamplePutMutation();

  const [
    putDustDensitySampleIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBPut<PutRequest, DustDensitySample>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "dustDensitySample",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [
        putDustDensitySampleMutation,
        { isLoading, error, isSuccess, reset },
      ];
    case OfflineState.OFFLINE:
      return [
        putDustDensitySampleIndexedDB,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Put of sample set not possible in state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}

export function useGetDustDensitySample(id: string) {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading,
    error,
  } = useSamplesDustDensitySampleShowQuery({
    id,
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGet<DustDensitySample>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "dustDensitySample",
    id,
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData,
        isLoading,
        error,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useListDustDensitySamples() {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const {
    data: serverData,
    isLoading,
    error,
  } = useSamplesDustDensitySampleListQuery({
    artefact: {
      active: true,
      mode: FilterMode.Or,
      values: [workOrderId!],
    },
  });

  const {
    data: idbData,
    isLoading: idbIsLoading,
    error: idbError,
  } = useArtefactDBGetAll<DustDensitySample>(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "dustDensitySample",
  );

  switch (offlineState) {
    case OfflineState.ONLINE:
      return {
        data: serverData?.samples,
        isLoading,
        error,
      };
    case OfflineState.OFFLINE:
      return {
        data: idbData,
        isLoading: idbIsLoading,
        error: idbError,
      };
    default:
      return {
        data: undefined,
        isLoading: false,
        error: undefined,
      };
  }
}

export function useDeleteDustDensitySample(): Mutation<DeleteRequest, null> {
  const { workOrderId } = useParams();
  const { offlineState } = useOfflineContext();

  const [
    deleteDustDensitySampleMutation,
    { isLoading, error, isSuccess, reset },
  ] = useSamplesDustDensitySampleDeleteMutation();

  const [
    deleteDustDensitySampleIndexedDB,
    {
      isLoading: idbIsLoading,
      error: idbError,
      isSuccess: idbIsSuccess,
      reset: idbReset,
    },
  ] = useArtefactDBDelete(
    ArtefactKind.WorkOrder,
    workOrderId!,
    "dustDensitySample",
  );
  const deleteIdb = async (request: DeleteRequest) => {
    const result = await deleteDustDensitySampleIndexedDB(request.id);
    if (result) {
      return result;
    }
    // This should never happen.
    throw new Error("Delete of sample set failed.");
  };

  switch (offlineState) {
    case OfflineState.ONLINE:
      return [
        deleteDustDensitySampleMutation,
        { isLoading, error, isSuccess, reset },
      ];
    case OfflineState.OFFLINE:
      return [
        deleteIdb,
        {
          isLoading: idbIsLoading,
          error: idbError,
          isSuccess: idbIsSuccess,
          reset: idbReset,
        },
      ];
    default:
      return [
        () => {
          throw new Error(
            `Delete of sample not possible in state ${offlineState}.`,
          );
        },
        {
          isLoading: false,
          error: undefined,
          isSuccess: false,
          reset: () => null,
        },
      ];
  }
}
