import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import {
  Company,
  useListCompanyQuery,
} from "@/services/backend/company/company";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PutCompanyDialog } from "@/routes/base-data/companies/list/components/put-company-dialog";
import { DeleteCompanyDialog } from "@/routes/base-data/companies/list/components/delete-company-dialog";
import { Card } from "@/shared/components/ui/card";
import { TableCard } from "@/shared/components/ui/table-card";

export function CompanyListRoute() {
  const { data: list, isLoading, error } = useListCompanyQuery({});

  if (isLoading) {
    return <Skeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <TableCard>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="w-full">{t("Unternehmen")}</TableHead>
            <TableHead>{t("Kurzform")}</TableHead>
            <TableHead className="whitespace-nowrap">
              {t("Portal Route")}
            </TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {list?.companies.map((company) => (
            <CompanyTableRow key={company.id} company={company} />
          ))}
        </TableBody>
      </Table>
    </TableCard>
  );
}

function CompanyTableRow({ company }: { company: Company }) {
  return (
    <TableRow>
      <TableCell>{company.name}</TableCell>
      <TableCell>{company.shortName}</TableCell>
      <TableCell>{company.portalRoute}</TableCell>
      <TableCell className="flex space-x-2">
        <PutCompanyDialog company={company} />
        <DeleteCompanyDialog company={company} />
      </TableCell>
    </TableRow>
  );
}

function Skeleton() {
  return (
    <Card>
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead className="w-full">{t("Unternehmen")}</TableHead>
            <TableHead>{t("Kurzform")}</TableHead>
            <TableHead className="whitespace-nowrap">
              {t("Portal Route")}
            </TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    </Card>
  );
}
