import { useParams } from "react-router";
import { useRltOfferShowQuery } from "@/services/backend/rlt/offers/service";
import { TreatmentComponent } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Column } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state";

export function OfferTreatmentRoute() {
  const { offerId } = useParams();
  const {
    data: offer,
    isLoading,
    error,
  } = useRltOfferShowQuery({ id: offerId! });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return null;
  }

  return (
    <TreatmentComponent
      treatmentId={offer!.treatmentId}
      availableColumns={[
        Column.Position,
        Column.ComponentKind,
        Column.ComponentData,
        Column.Cycle,
        Column.Formula,
        Column.CleanTime,
        Column.BasePrice,
        Column.TipCommission,
        Column.OfferPrice,
        Column.Comment,
        Column.Actions,
      ]}
      defaultColumns={[
        Column.Position,
        Column.ComponentKind,
        Column.BasePrice,
        Column.TipCommission,
        Column.OfferPrice,
        Column.Comment,
        Column.Actions,
      ]}
    />
  );
}
