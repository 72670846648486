import { useMemo } from "react";
import t from "@/lang/lang";
import { cn } from "@/shared/lib/utils";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export enum Result {
  ZERO = "",
  NONE = "None",
  OK = "OK",
  WARNING = "Warning",
  CRITICAL = "Critical",
}

export function ResultDot({
  result,
  showLabel = true,
  hasError,
}: {
  result: Result;
  showLabel?: boolean;
  hasError?: boolean;
}) {
  const { className, label } = useMemo(() => {
    switch (result) {
      case Result.OK:
        return {
          className: "",
          label: t("OK"),
        };
      case Result.WARNING:
        return {
          className: "bg-yellow-300 border-yellow-300",
          label: t("Grenzwertig"),
        };
      case Result.CRITICAL:
        return {
          className: "bg-red-500 border-red-500",
          label: t("Kritisch"),
        };
      case Result.NONE:
        return {
          className: "bg-background border-background",
          label: t("Keine Bewertung"),
        };
      case Result.ZERO:
        return {
          className: "bg-background border-background",
          label: "-",
        };
      default:
        return {
          className: "",
          label: "",
        };
    }
  }, [result]);

  if (label === "") {
    return null;
  }

  return (
    <span
      className={cn(
        "flex items-center whitespace-nowrap",
        hasError && "rounded border border-red-500 shadow-md",
      )}
    >
      <span
        className={cn(
          "bg-background inline-block h-4 w-4 rounded-full border",
          showLabel && "mr-2",
          className,
        )}
      />
      {showLabel && label}
    </span>
  );
}

export function ResultSelect({
  result,
  onResultChange,
  className,
  disabled,
  hasError,
}: {
  result: Result;
  onResultChange: (result: Result) => void;
  className?: string;
  disabled?: boolean;
  hasError?: boolean;
}) {
  return (
    <Select value={result} onValueChange={onResultChange} disabled={disabled}>
      <SelectTrigger
        className={cn(hasError && "border-red-500 shadow-md", className)}
      >
        <SelectValue placeholder={t("Bewerten")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectItem value={Result.NONE}>
            <ResultDot result={Result.NONE} />
          </SelectItem>
          <SelectItem value={Result.OK}>
            <ResultDot result={Result.OK} />
          </SelectItem>
          <SelectItem value={Result.WARNING}>
            <ResultDot result={Result.WARNING} />
          </SelectItem>
          <SelectItem value={Result.CRITICAL}>
            <ResultDot result={Result.CRITICAL} />
          </SelectItem>
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
