import { useParams } from "react-router";
import { useHtzReportShowQuery } from "@/services/backend/htz/report/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { ItemsTable } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/items/_components/items-table";
import { ReportAhuSchema } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/items/_components/report-ahu-schema";
import { CardSkeleton } from "@/shared/components/ui/card-skeleton";
import t from "@/lang/lang";

export function ItemsRoute() {
  const { reportId } = useParams();

  const {
    data: report,
    isLoading,
    error,
  } = useHtzReportShowQuery({ id: reportId! });

  if (isLoading) {
    return <CardSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Komponenten")}</CardTitle>
        <CardDescription>
          {t(
            "Hier können Sie eine Zusammenfassung der einzelnen Bewertungen für alle Komponenten sehen.",
          )}
        </CardDescription>
      </CardHeader>
      <CardContent className="space-y-4">
        <ReportAhuSchema report={report!} />
        <ItemsTable items={report!.items} disabled={report!.immutable} />
      </CardContent>
    </Card>
  );
}
