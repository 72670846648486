import { useNavigate, useParams } from "react-router";
import t from "@/lang/lang";
import {
  useQpmRequirementDeleteMutation,
  useQpmRequirementGetQuery,
} from "@/services/backend/qpm/requirements/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { useEffect, useState } from "react";
import { useQpmBuildingAddressListQuery } from "@/services/backend/qpm/buildings/service";
import { Loader, Loader2, Trash2 } from "lucide-react";
import { BuildingAddress } from "@/services/backend/qpm/buildings/types";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";
import { useToast } from "@/shared/hooks/use-toast";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

export type URLParams = {
  tenantId: string;
  processId: string;
  requirementId: string;
};

export default function CreateRequirement() {
  const { requirementId, processId } = useParams<URLParams>();
  const [currentAddress, setCurrentAddress] = useState<BuildingAddress>();

  const links = [
    { to: "info", label: t("Details") },
    { to: "inventory", label: t("Bestandsaufnahme") },
    { to: "additional-info", label: t("Zusätzliche Informationen") },
    { to: "total", label: t("Gesamtübersicht") },
  ];

  const {
    data: process,
    error: processDataError,
    isLoading: processDataIsLoading,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  const {
    data: requirement,
    error: errorRequirement,
    isLoading: isLoadingRequirement,
  } = useQpmRequirementGetQuery({
    artefactMetaId: requirementId ?? "",
  });

  const {
    data: addressList,
    error,
    isLoading: addressIsLoading,
  } = useQpmBuildingAddressListQuery(
    {
      customerId: process!.customerId,
    },
    { skip: !process },
  );

  useEffect(() => {
    if (addressList && addressList.addresses.length > 0) {
      setCurrentAddress(addressList.addresses[0]);
    }
  }, [addressList, setCurrentAddress]);

  if (processDataIsLoading) {
    return <Loader2 className="animate-spin" />;
  }

  if (!processId || !process) {
    return <div>Fehler beim Laden des Vorgangs!</div>;
  }

  if (processDataError) {
    return <RTKQueryErrorAlert error={processDataError} />;
  }

  if (addressIsLoading || isLoadingRequirement) {
    return <Loader />;
  }

  if (error || !currentAddress) {
    return <div>Fehler beim Laden der Addresse</div>;
  }

  if (errorRequirement) {
    return <RTKQueryErrorAlert error={errorRequirement} />;
  }

  if (!requirement || !processId) {
    return <div>Error loading Requirement!</div>;
  }

  return (
    <PersistenceContextProvider>
      <ArtefactPage>
        <ArtefactPageHeader>
          <ArtefactPageNavigation>
            {links.map((link) => (
              <ArtefactPageNavigationLink to={link.to}>
                {link.label}
              </ArtefactPageNavigationLink>
            ))}
          </ArtefactPageNavigation>
          <ArtefactPageActions>
            <PersistenceStateUsingContext className="mr-2" />
            {requirement && (
              <DeleteRequirementDialog
                requirementId={requirement.artefactData.id}
              />
            )}
          </ArtefactPageActions>
        </ArtefactPageHeader>
        <ArtefactPageContent>
          <ArtefactPageNavigationOutlet />
        </ArtefactPageContent>
      </ArtefactPage>
    </PersistenceContextProvider>
  );
}

function DeleteRequirementDialog(props: { requirementId: string }) {
  const { requirementId } = props;
  const [deleteCalculation, { isLoading, error, isSuccess, reset }] =
    useQpmRequirementDeleteMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteCalculation({ id: requirementId });
  };

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Bestandsaufnahme gelöscht"),
        variant: "success",
      });
      navigate(`..`);
      reset();
    }
  }, [error, isSuccess, navigate, reset, toast]);

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Bestandsaufnahme wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
