import { Offer } from "@/services/backend/vbs/offers/offer";
import t from "@/lang/lang";
import { useEffect, useState } from "react";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useGuard } from "@/shared/lib/authorization/rbac-guard";
import {
  FloatNumberInput,
  Value,
} from "@/routes/gesec/processes/_components/ui/number-input";
import { useNavigate, useParams } from "react-router";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  UpdateDateRequest,
  UpdateHourlyRateRequest,
  UpdateIncidentalCostRequest,
  UpdateRatePerAccessPanelRequest,
  UpdateTreatmentAbsoluteDiscountRequest,
  UpdateTreatmentBasePriceRequest,
  UpdateTreatmentSalesDiscountRequest,
  useVbsOfferShowQuery,
  useVbsOfferUpdateDateMutation,
  useVbsOfferUpdateHourlyRateMutation,
  useVbsOfferUpdateIncidentalCostMutation,
  useVbsOfferUpdateIncidentalCostUseCalculationMutation,
  useVbsOfferUpdateRatePerAccessPanelMutation,
  useVbsOfferUpdateTreatmentAbsoluteDiscountMutation,
  useVbsOfferUpdateTreatmentBasePriceMutation,
  useVbsOfferUpdateTreatmentSalesDiscountMutation,
  useVbsOfferUpdateTreatmentUseCalculationMutation,
} from "@/services/backend/vbs/offers/service";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { Label } from "@/shared/components/ui/label";
import { Switch } from "@/shared/components/ui/switch";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import {
  ErrArtefactNotReady,
  errorsFor,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { AlertCircle } from "lucide-react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { Separator } from "@/shared/components/ui/separator";
import { Button } from "@/shared/components/ui/button";
import { DateInput } from "@/shared/components/form/date-input";
import { inputValidationErrorMessages } from "@/shared/components/ui/input-error-messages";

export function OfferDataRoute() {
  const { offerId } = useParams();

  const {
    data: offer,
    isLoading,
    error,
  } = useVbsOfferShowQuery({
    id: offerId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return <OfferView offer={offer!} />;
}

export function OfferView(props: { offer: Offer }) {
  const { offer } = props;
  return (
    <div className="grid gap-2">
      <BaseData offer={offer} />
      <TreatmentPrices offer={offer} />
      <div className="grid gap-2 lg:grid-cols-2">
        <IncidentalCost offer={offer} />
        <Extras offer={offer} />
      </div>
    </div>
  );
}

function BaseData({ offer }: { offer: Offer }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Angebot")}</CardTitle>
      </CardHeader>
      <CardContent>
        <div className="grid lg:grid-cols-2">
          <DateView offer={offer} />
        </div>
      </CardContent>
    </Card>
  );
}

function DateView(props: { offer: Offer }) {
  const { offer } = props;
  const [request, setRequest] = useState<UpdateDateRequest>({
    id: offer.id,
    date: offer.offerDate,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useVbsOfferUpdateDateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    1000,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <DateInput
      label={t("Angebotsdatum")}
      date={request.date}
      onDateChange={(date) => {
        resetNotReadyErrorField("date");
        setRequest({ ...request, date: date ?? "" });
      }}
      errors={inputValidationErrorMessages(error || notReadyError, "date")}
      disabled={isLoading}
    />
  );
}

function OfferNotReadyErrorWarning({
  field,
  error,
}: {
  field: string;
  error: ErrArtefactNotReady | null;
}) {
  if (!error) {
    return null;
  }

  const fieldErrors = errorsFor(error, field);

  if (fieldErrors) {
    return (
      <TooltipProvider>
        <Tooltip>
          <TooltipTrigger>
            <AlertCircle className="inline-flex h-4 w-4 text-red-500" />
          </TooltipTrigger>
          <TooltipContent align="end">
            <div className="px-2 py-1">
              <div>{t("Angebot nicht fertig:")}</div>
              <Separator className="mb-2" />
              {fieldErrors.map((msg) => (
                <div key={msg} className="text-sm text-red-700">
                  {msg}
                </div>
              ))}
            </div>
          </TooltipContent>
        </Tooltip>
      </TooltipProvider>
    );
  }

  return null;
}

function TreatmentPrices(props: { offer: Offer }) {
  const { offer } = props;

  const { notReadyError } = useErrArtefactNotReady();

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <span>{t("Grundbehandlung")}</span>
          <TreatmentUseCalculationToggle offer={offer} />
        </CardTitle>
      </CardHeader>
      <CardContent>
        <TreatmentBasePrice offer={offer} />
        <TipProvision offer={offer} />
        <div className="flex items-center justify-between border-t">
          <span>{t("Grundbehandlung inkl. Tippprovision")}</span>
          <div className="space-x-2">
            <Value value={offer.treatment.priceWithCharges} fractions={2} />
            <span>€</span>
          </div>
        </div>
        <div className="pt-2">
          <SaleDiscountView offer={offer} />
          <AbsolutDiscountView offer={offer} />
          <div className="border-b" />
        </div>
        <div className="flex items-center justify-between font-bold">
          <span>{t("Angebotspreis Grundbehandlung")}</span>
          <div className="space-x-2">
            <Value value={offer.treatment.offerPrice} fractions={2} />
            <span>€</span>
            <OfferNotReadyErrorWarning
              field="treatment.offerPrice"
              error={notReadyError}
            />
          </div>
        </div>
      </CardContent>
    </Card>
  );
}

function TreatmentBasePrice(props: { offer: Offer }) {
  const { offer } = props;
  const [request, setRequest] = useState<UpdateTreatmentBasePriceRequest>({
    id: offer.id,
    price: offer.treatment.basePrice,
  });

  useEffect(() => {
    if (!offer.treatmentUseCalculation) {
      setRequest((prev) => {
        if (prev.price !== offer.treatment.basePrice) {
          return {
            ...prev,
            price: offer.treatment.basePrice,
          };
        }
        return prev;
      });
    }
    // This useEffect is supposed to update the request with updated
    // price after treatmentUseCalculations was toggled.
    // Otherwise, the input fields would be out of date in the UI.
    // However, we do not want to update anything just because the
    // offer.treatment.basePrice has changed (which may happen due
    // to invalidation), therefore it is left out of the dependencies
    // on purpose.
    // React is aware that useEffect has a shortcoming here and
    // in a future version there will be a useEffectEvent hook
    // which can be used to achieve the current behaviour without
    // breaking the reactivity rules:
    // https://react.dev/learn/removing-effect-dependencies#do-you-want-to-read-a-value-without-reacting-to-its-changes
    // https://react.dev/learn/separating-events-from-effects#is-it-okay-to-suppress-the-dependency-linter-instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer.treatmentUseCalculation]);

  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsOfferUpdateTreatmentBasePriceMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  if (!offer.treatmentUseCalculation) {
    return (
      <div className="flex items-center justify-between">
        <span>
          <span>{t("Betrag")}</span>
        </span>
        <div className="space-x-2">
          <FloatNumberInput
            value={request.price}
            onChange={(price) => setRequest({ ...request, price })}
            width={24}
          />
          <span className="font-mono">€</span>
        </div>
      </div>
    );
  }

  return (
    <div className="flex items-center justify-between">
      <span className="flex space-x-4">
        <span>{t("Betrag aus Kalkulation")}</span>
      </span>
      <div className="space-x-2">
        <Value value={offer.treatment.basePrice} fractions={2} />
        <span>€</span>
      </div>
    </div>
  );
}

function TreatmentUseCalculationToggle(props: { offer: Offer }) {
  const { offer } = props;
  const [update, { isLoading, error, reset }] =
    useVbsOfferUpdateTreatmentUseCalculationMutation();
  const { toast } = useToast();
  const { canExecute } = useGuard(
    "VBS.Offer.UpdateTreatmentUseCalculationRequest",
  );

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  const onCheckedChange = (checked: boolean) => {
    if (isLoading) {
      return;
    }
    update({ id: offer.id, value: checked });
  };

  if (!canExecute) {
    return <div />;
  }

  return (
    <div className="flex items-center space-x-2">
      <Label htmlFor="treatment-use-calculation">
        {t("Kalkulation nutzen")}
      </Label>
      <Switch
        id="treatment-use-calculation"
        checked={offer.treatmentUseCalculation}
        onCheckedChange={onCheckedChange}
      />
    </div>
  );
}

function SaleDiscountView(props: { offer: Offer }) {
  const { offer } = props;
  const [request, setRequest] = useState<UpdateTreatmentSalesDiscountRequest>({
    id: offer.id,
    active: offer.treatment.salesDiscount.active,
    percentage: offer.treatment.salesDiscount.percentage,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsOfferUpdateTreatmentSalesDiscountMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );
  const { canExecute } = useGuard("VBS.Offer.UpdateSalesDiscountRequest");

  return (
    <div>
      <div className="flex items-center justify-between">
        <span className="flex space-x-4">
          <span>{t("Verkaufsrabatt %")}</span>
        </span>
        <div className="flex space-x-4">
          <div className="flex items-center">
            <input
              type="checkbox"
              checked={request.active}
              onChange={(e) => {
                const active = e.target.checked;
                setRequest({ ...request, active });
              }}
              className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
            />
            <label
              htmlFor="checkbox-3"
              className="ml-2 text-sm font-medium text-gray-900"
            >
              {t("Aktiv")}
            </label>
          </div>
          <div className="space-x-2">
            {canExecute ? (
              <FloatNumberInput
                value={request.percentage}
                onChange={(percentage) =>
                  setRequest({ ...request, percentage })
                }
              />
            ) : (
              <Value
                value={offer.treatment.salesDiscount.percentage}
                fractions={2}
              />
            )}
            <span className="font-mono">%</span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between">
        <span className="flex space-x-4">
          <span>{t("Verkaufsrabatt €")}</span>
        </span>
        <div className="space-x-2">
          <Value
            value={
              (offer.treatment.priceWithCharges *
                offer.treatment.salesDiscount.percentage) /
              100
            }
            fractions={2}
          />
          <span>€</span>
        </div>
      </div>
    </div>
  );
}

function TipProvision({ offer }: { offer: Offer }) {
  const navigate = useNavigate();

  return (
    <div>
      <div className="flex items-center justify-between">
        <Button
          variant="link"
          className="text-md h-6 px-0"
          onClick={() => {
            navigate("../treatment");
          }}
        >
          {t("Tippprovision")}
        </Button>
        <div className="space-x-2">
          <Value value={offer.treatment.tipCommission} fractions={2} />
          <span>€</span>
        </div>
      </div>
    </div>
  );
}

function AbsolutDiscountView(props: { offer: Offer }) {
  const { offer } = props;
  const [request, setRequest] =
    useState<UpdateTreatmentAbsoluteDiscountRequest>({
      id: offer.id,
      active: offer.treatment.absoluteDiscount.active,
      amount: offer.treatment.absoluteDiscount.amount,
    });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsOfferUpdateTreatmentAbsoluteDiscountMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  return (
    <div className="flex items-center justify-between">
      <span className="flex space-x-4">
        <span>{t("Absolutrabatt")}</span>
      </span>
      <div className="flex items-center space-x-4">
        <div className="flex items-center">
          <input
            type="checkbox"
            checked={request.active}
            onChange={(e) => {
              const active = e.target.checked;
              setRequest({ ...request, active });
            }}
            className="h-4 w-4 rounded border-gray-300 bg-gray-100 text-blue-600 focus:ring-2 focus:ring-blue-500"
          />
          <label
            htmlFor="checkbox-3"
            className="ml-2 text-sm font-medium text-gray-900"
          >
            {t("Aktiv")}
          </label>
        </div>
        <div className="space-x-2">
          <FloatNumberInput
            value={request.amount}
            onChange={(amount) => setRequest({ ...request, amount })}
            width={24}
          />
          <span className="font-mono">€</span>
        </div>
      </div>
    </div>
  );
}

function IncidentalCost(props: { offer: Offer }) {
  const { offer } = props;
  const [request, setRequest] = useState<UpdateIncidentalCostRequest>({
    id: offer.id,
    incidentalCost: offer.incidentalCost,
  });

  useEffect(() => {
    if (!offer.incidentalCostUseCalculation) {
      setRequest((prev) => {
        if (prev.incidentalCost !== offer.incidentalCost) {
          return {
            ...prev,
            incidentalCost: offer.incidentalCost,
          };
        }
        return prev;
      });
    }
    // This useEffect is supposed to update the request with updated
    // incidentalCost after incidentalCostUseCalculation was toggled.
    // Otherwise, the input fields would be out of date in the UI.
    // However, we do not want to update anything just because the
    // offer.incidentalCost has changed (which may happen due
    // to invalidation), therefore it is left out of the dependencies
    // on purpose.
    // React is aware that useEffect has a shortcoming here and
    // in a future version there will be a useEffectEvent hook
    // which can be used to achieve the current behaviour without
    // breaking the reactivity rules:
    // https://react.dev/learn/removing-effect-dependencies#do-you-want-to-read-a-value-without-reacting-to-its-changes
    // https://react.dev/learn/separating-events-from-effects#is-it-okay-to-suppress-the-dependency-linter-instead
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [offer.incidentalCostUseCalculation]);

  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsOfferUpdateIncidentalCostMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { toast } = useToast();

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, toast, reset]);

  const onChangeMaterialFlatRate = (materialFlatRate: number) => {
    const totalAmount =
      materialFlatRate +
      request.incidentalCost.travelTime +
      request.incidentalCost.expenses;
    setRequest({
      ...request,
      incidentalCost: {
        ...request.incidentalCost,
        materialFlatRate,
        totalAmount,
      },
    });
  };

  const onChangeTravelTime = (travelTime: number) => {
    const totalAmount =
      request.incidentalCost.materialFlatRate +
      travelTime +
      request.incidentalCost.expenses;
    setRequest({
      ...request,
      incidentalCost: {
        ...request.incidentalCost,
        travelTime,
        totalAmount,
      },
    });
  };

  const onChangeExpenses = (expenses: number) => {
    const totalAmount =
      request.incidentalCost.materialFlatRate +
      request.incidentalCost.travelTime +
      expenses;
    setRequest({
      ...request,
      incidentalCost: {
        ...request.incidentalCost,
        expenses,
        totalAmount,
      },
    });
  };

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <span>{t("Nebenkosten (Material, Spesen, Fahrtkosten)")}</span>
          <IncidentalCostUseCalculationToggle offer={offer} />
        </CardTitle>
      </CardHeader>
      <CardContent>
        {offer.incidentalCostUseCalculation ? (
          <div>
            <div className="flex items-center justify-between">
              <span>{t("Materialkosten")}</span>
              <div className="space-x-2">
                <Value
                  value={offer.incidentalCost.materialFlatRate}
                  fractions={2}
                />
                <span className="font-mono">€</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span>
                <span>{t("Fahrtkosten")}</span>
              </span>
              <div className="space-x-2">
                <Value value={offer.incidentalCost.travelTime} fractions={2} />
                <span className="font-mono">€</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span>{t("Spesen")}</span>
              <div className="space-x-2">
                <Value value={offer.incidentalCost.expenses} fractions={2} />
                <span className="font-mono">€</span>
              </div>
            </div>
            <div className="flex items-center justify-between border-t font-semibold">
              <span>{t("Gesamt")}</span>
              <div className="space-x-2">
                <Value value={offer.incidentalCost.totalAmount} fractions={2} />
                <span className="font-mono">€</span>
              </div>
            </div>
          </div>
        ) : (
          <div>
            <div className="flex items-center justify-between">
              <span>{t("Materialkosten")}</span>
              <div className="space-x-2">
                <FloatNumberInput
                  value={request.incidentalCost.materialFlatRate}
                  onChange={onChangeMaterialFlatRate}
                  width={24}
                />
                <span className="font-mono">€</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span>{t("Fahrtkosten")}</span>
              <div className="space-x-2">
                <FloatNumberInput
                  value={request.incidentalCost.travelTime}
                  onChange={onChangeTravelTime}
                  width={24}
                />
                <span className="font-mono">€</span>
              </div>
            </div>
            <div className="flex items-center justify-between">
              <span>{t("Spesen")}</span>
              <div className="space-x-2">
                <FloatNumberInput
                  value={request.incidentalCost.expenses}
                  onChange={onChangeExpenses}
                  width={24}
                />
                <span className="font-mono">€</span>
              </div>
            </div>
            <div className="flex items-center justify-between border-t font-semibold">
              <span>{t("Gesamt")}</span>
              <div className="space-x-2">
                <Value value={request.incidentalCost.totalAmount} />
                <span className="font-mono">€</span>
              </div>
            </div>
          </div>
        )}
      </CardContent>
    </Card>
  );
}

function IncidentalCostUseCalculationToggle(props: { offer: Offer }) {
  const { offer } = props;
  const [update, { isLoading, error, reset }] =
    useVbsOfferUpdateIncidentalCostUseCalculationMutation();
  const { toast } = useToast();
  const { canExecute } = useGuard(
    "VBS.Offer.UpdateIncidentalCostUseCalculationRequest",
  );

  if (error) {
    toast({
      ...parseRTKQueryError(error),
      variant: "destructive",
    });
    reset();
  }

  const onCheckedChange = (checked: boolean) => {
    if (isLoading) {
      return;
    }
    update({ id: offer.id, value: checked });
  };

  if (!canExecute) {
    return <div />;
  }

  return (
    <div className="flex items-center space-x-2">
      <Label htmlFor="incidental-cost-use-calculation">
        {t("Kalkulation nutzen")}
      </Label>
      <Switch
        id="incidental-cost-use-calculation"
        checked={offer.incidentalCostUseCalculation}
        onCheckedChange={onCheckedChange}
      />
    </div>
  );
}

function Extras({ offer }: { offer: Offer }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Extras")}</CardTitle>
      </CardHeader>
      <CardContent>
        <AccessPanel offer={offer} />
        <HourlyRate offer={offer} />
      </CardContent>
    </Card>
  );
}

function AccessPanel(props: { offer: Offer }) {
  const { offer } = props;
  const [request, setRequest] = useState<UpdateRatePerAccessPanelRequest>({
    id: offer.id,
    rate: offer.pricesForExtras.ratePerAccessPanel,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsOfferUpdateRatePerAccessPanelMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { canExecute } = useGuard("VBS.Offer.UpdateRatePerAccessPanelRequest");

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="flex items-center justify-between">
      <span>{t("Revisionsöffnungen")}</span>
      <div className="space-x-2">
        {canExecute ? (
          <FloatNumberInput
            value={request.rate}
            onChange={(rate) => {
              resetNotReadyErrorField("pricesForExtras.ratePerAccessPanel");
              setRequest({ ...request, rate });
            }}
            width={24}
          />
        ) : (
          <Value
            value={offer.pricesForExtras.ratePerAccessPanel}
            fractions={2}
          />
        )}
        <span className="font-mono">€</span>
        <OfferNotReadyErrorWarning
          field="pricesForExtras.ratePerAccessPanel"
          error={notReadyError}
        />
      </div>
    </div>
  );
}

function HourlyRate(props: { offer: Offer }) {
  const { offer } = props;
  const [request, setRequest] = useState<UpdateHourlyRateRequest>({
    id: offer.id,
    rate: offer.pricesForExtras.hourlyRate,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsOfferUpdateHourlyRateMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { canExecute } = useGuard("VBS.Offer.UpdateHourlyRateRequest");

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="flex items-center justify-between">
      <span>{t("Stundensatz Zusatzarbeiten")}</span>
      <div className="space-x-2">
        {canExecute ? (
          <FloatNumberInput
            value={request.rate}
            onChange={(rate) => {
              resetNotReadyErrorField("pricesForExtras.hourlyRate");
              setRequest({ ...request, rate });
            }}
            width={24}
          />
        ) : (
          <Value value={offer.pricesForExtras.hourlyRate} fractions={2} />
        )}
        <span className="font-mono">€</span>
        <OfferNotReadyErrorWarning
          field="pricesForExtras.hourlyRate"
          error={notReadyError}
        />
      </div>
    </div>
  );
}
