import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import t from "@/lang/lang";
import { ContractList } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-list";
import { useParams } from "react-router";
import { ContractForOffer } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-for-offer";

export function OfferContractRoute() {
  const { processId, offerId } = useParams();

  return (
    <Tabs defaultValue="new">
      <TabsList className="grid w-full grid-cols-2">
        <TabsTrigger value="new">{t("Vertrag zum Angebot")}</TabsTrigger>
        <TabsTrigger value="existing">{t("Bestehende Verträge")}</TabsTrigger>
      </TabsList>
      <TabsContent value="new">
        <ContractForOffer processId={processId!} offerId={offerId!} />
      </TabsContent>
      <TabsContent value="existing">
        <ContractList processId={processId!} offerId={offerId!} />
      </TabsContent>
    </Tabs>
  );
}
