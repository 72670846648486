import { HTMLAttributes, useCallback, useEffect, useState } from "react";
import { twMerge } from "tailwind-merge";
import { usePersistenceContext } from "@/shared/lib/persistence-state/context";
import { PersistenceState } from "@/shared/lib/persistence-state/persistence-state";
import { AlertCircle, CircleCheck, RefreshCw } from "lucide-react";
import { Options } from "@/shared/lib/debounce/debounce";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import t from "../../../lang/lang";

export interface PersistenceStateInfoProps
  extends HTMLAttributes<HTMLDivElement> {
  state: PersistenceState;
}

export function PersistenceStateInfo(props: PersistenceStateInfoProps) {
  const { state, className } = props;
  switch (state) {
    case PersistenceState.SAVED:
      return <Saved className={className} />;
    case PersistenceState.SAVING:
      return <Saving className={className} />;
    case PersistenceState.ERROR:
      return <Error className={className} />;
    default:
      return null;
  }
}

export function PersistenceStateUsingContext({
  className,
}: HTMLAttributes<HTMLDivElement>) {
  const { persistenceState } = usePersistenceContext();
  return (
    <PersistenceStateInfo state={persistenceState} className={className} />
  );
}

function Error(props: HTMLAttributes<HTMLDivElement>) {
  const { className } = props;
  return (
    <div
      className={twMerge(
        "flex items-center space-x-2 rounded bg-red-500 px-3 py-1 text-sm font-bold text-gray-100",
        className,
      )}
    >
      <AlertCircle className="h-4 w-4" />
      <span className="whitespace-nowrap">{t("Fehler beim Speichern")}</span>
    </div>
  );
}

function Saved(props: HTMLAttributes<HTMLDivElement>) {
  const { className } = props;
  return (
    <div
      className={twMerge(
        "flex items-center space-x-2 text-sm font-bold text-gray-500",
        className,
      )}
    >
      <CircleCheck className="h-4 w-4" />
      <span>{t("Gespeichert")}</span>
    </div>
  );
}

function Saving(props: HTMLAttributes<HTMLDivElement>) {
  const { className } = props;
  return (
    <div
      className={twMerge(
        "flex animate-pulse items-center space-x-2 text-sm font-bold text-gray-500",
        className,
      )}
    >
      <RefreshCw className="h-4 w-4" />
      <span>{t("Speichere")}</span>
    </div>
  );
}

export function usePersistenceStatusUpdate(
  isLoading: boolean,
  error: never,
  state: PersistenceState,
  setPersistenceStatus: (state: PersistenceState) => void,
) {
  return useEffect(() => {
    if (isLoading && state !== PersistenceState.SAVING) {
      setPersistenceStatus(PersistenceState.SAVING);
      return;
    }
    if (error && state !== PersistenceState.ERROR) {
      setPersistenceStatus(PersistenceState.ERROR);
    }
  }, [isLoading, error, state, setPersistenceStatus]);
}

export function usePersistenceStateUpdateOnTrigger<T>(
  request: T,
  mutation: (request: T) => void,
  mutationIsLoading: boolean,
  mutationError: unknown,
  mutationIsSuccess: boolean,
  mutationReset: () => void,
  options: Options = { toastError: false, toastSuccess: false },
): { state: PersistenceState; onTrigger: () => void } {
  const { toast } = useToast();
  const { setPersistenceState } = usePersistenceContext();
  const [state, setState] = useState<PersistenceState>(PersistenceState.SAVED);

  const onTrigger = useCallback(() => {
    setState(PersistenceState.SAVING);
    mutation(request);
  }, [mutation, request]);

  useEffect(() => {
    if (mutationIsLoading) {
      setState(PersistenceState.SAVING);
    }
  }, [mutationIsLoading]);

  useEffect(() => {
    if (mutationIsSuccess) {
      setState(PersistenceState.SAVED);
      if (options?.toastSuccess) {
        toast({
          title: t("Speichern erfolgreich!"),
          description: t("Die Daten wurden vom System übernommen."),
          variant: "success",
        });
      }
    }
  }, [mutationIsSuccess, toast, options?.toastSuccess]);

  useEffect(() => {
    if (mutationError) {
      setState(PersistenceState.ERROR);
      if (options?.toastError) {
        toast({
          ...parseRTKQueryError(mutationError, false),
          variant: "destructive",
        });
      }
      mutationReset(); // Reset mutation to avoid multiple error toasts
    }
  }, [mutationError, options?.toastError, toast, mutationReset]);

  useEffect(() => {
    setPersistenceState(state);
  }, [state, setPersistenceState]);

  return { state, onTrigger };
}
