import { useParams } from "react-router";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import { WorkOrder } from "@/services/backend/rlt/work-orders/work-order";
import { useRltWorkOrderShowQuery } from "@/services/backend/rlt/work-orders/service";
import t from "@/lang/lang";
import { State } from "@/services/backend/rlt/work-orders/state";
import { TreatmentComponent } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component";
import { Column } from "@/routes/gesec/processes/[processId]/rlt/_shared/treatment/treatment-component-state";
import { PositionsByArea } from "@/routes/gesec/processes/[processId]/rlt/work-orders/[workOrderId]/positions/_components/positions-by-area";

export function WorkOrderPositionsRoute() {
  const { workOrderId } = useParams();
  const {
    data: workOrder,
    isLoading,
    error,
  } = useRltWorkOrderShowQuery({
    id: workOrderId!,
  });

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <PositionsForState workOrder={workOrder!} />;
}

export function PositionsForState({ workOrder }: { workOrder: WorkOrder }) {
  switch (workOrder!.state) {
    case State.Preparation:
    case State.Scheduling:
      return (
        <TreatmentComponent
          treatmentId={workOrder!.treatmentId}
          availableColumns={[
            Column.Position,
            Column.ComponentKind,
            Column.ComponentData,
            Column.Cycle,
            Column.Comment,
            Column.Actions,
          ]}
          defaultColumns={[
            Column.Position,
            Column.ComponentKind,
            Column.ComponentData,
            Column.Comment,
            Column.Actions,
          ]}
        />
      );
    case State.Execution:
      return <PositionsByArea workOrder={workOrder!} />;
    case State.Review:
    case State.Completed:
      return (
        <TreatmentComponent
          treatmentId={workOrder!.treatmentId}
          availableColumns={[
            Column.Position,
            Column.ComponentKind,
            Column.ComponentData,
            Column.Cycle,
            Column.ActuallyTreated,
            Column.Comment,
            Column.Actions,
          ]}
          defaultColumns={[
            Column.Position,
            Column.ComponentKind,
            Column.ComponentData,
            Column.ActuallyTreated,
            Column.Comment,
            Column.Actions,
          ]}
        />
      );
    default:
      return <div />;
  }
}
