import { useParams } from "react-router";
import {
  useQpmCalcRentingCostDurationPutMutation,
  useQpmCalcRentingCostGetQuery,
} from "@/services/backend/qpm/calculations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import { fMoney } from "@/routes/gesec/processes/_components/ui/number-input";
import {
  RentingCost,
  UpdateRentingCostDurationRequest,
} from "@/services/backend/qpm/calculations/types";
import { useState } from "react";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import { ChevronDown, ChevronRight } from "lucide-react";
import { Label } from "@/shared/components/ui/label";
import { RentingCostComponent } from "@/routes/gemex/processes/qpm/calculations/calculation/_components/renting-cost-component";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { IntNumberInput } from "@/routes/gemex/processes/_components/ui/number-input";

export function CalculationRentingOverviewComponent() {
  const { calculationId } = useParams();
  const { data: rentingCost, error: isErrorMaterials } =
    useQpmCalcRentingCostGetQuery(
      {
        calculationId: calculationId!,
      },
      { skip: !calculationId },
    );

  if (isErrorMaterials) {
    return <RTKQueryErrorAlert error={isErrorMaterials} />;
  }

  return (
    rentingCost && (
      <div className="overflow-clip rounded-lg border shadow-sm">
        <RentingCostCollapsible rentingCost={rentingCost} />
        <div className="flex justify-between rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Kosten Funktionsprüfung pro Jahr")}
          </span>
          <span className="font-bold">
            {fMoney(rentingCost.maintenanceCost)}
          </span>
        </div>
        <div className="flex justify-between rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Monatsrate inklusive Wartung")}
          </span>
          <span className="font-bold">
            {fMoney(rentingCost.ratePerMonthWithMaintenance)}
          </span>
        </div>
        <div className="flex justify-between rounded-b-lg border-t bg-gray-50 px-6 py-3">
          <span className="font-bold uppercase">
            {t("Gesamtpreis inklusive Wartung")}
          </span>
          <span className="font-bold">
            {fMoney(rentingCost.totalCostWithMaintenance)}
          </span>
        </div>
      </div>
    )
  );
}

export type RentingCostComponentProps = {
  rentingCost: RentingCost;
};

function RentingCostCollapsible({ rentingCost }: RentingCostComponentProps) {
  const [open, setOpen] = useState(true);
  return (
    rentingCost && (
      <Collapsible open={open} onOpenChange={setOpen}>
        <div className="border-t bg-gray-50 px-6 py-3">
          <div className="flex justify-between font-bold">
            <CollapsibleTrigger>
              <div className="flex items-center space-x-2">
                {open ? (
                  <ChevronDown className="h-5 w-5" />
                ) : (
                  <ChevronRight className="h-5 w-5" />
                )}
                <span>{t("Mietkosten")}</span>
              </div>
            </CollapsibleTrigger>
            <span className="flex items-center justify-end gap-2">
              <RentingCostDurationComponent rentingCost={rentingCost} />
            </span>
            <span className="flex items-center justify-end gap-2">
              <Label
                htmlFor={`${rentingCost?.calculationValuesId}-renting-rate-per-month-input`}
              >
                {t("Monatsrate")}
              </Label>
              <span
                id={`${rentingCost?.calculationValuesId}-renting-rate-per-month-input`}
              >
                {fMoney(rentingCost.ratePerMonth)}
              </span>
            </span>
            <span className="flex items-center justify-end gap-2">
              <Label
                htmlFor={`${rentingCost?.calculationValuesId}-renting-total-cost-input`}
              >
                {t("Gesamt")}
              </Label>
              <span
                id={`${rentingCost?.calculationValuesId}-renting-total-cost-input`}
              >
                {fMoney(rentingCost.totalCost)}
              </span>
            </span>
          </div>
        </div>
        <CollapsibleContent>
          <div className="border-t">
            <RentingCostComponent rentingCost={rentingCost} />
          </div>
          {/* <RentingGeneralSettingsComponent */}
          {/*   rentingCost={rentingCost} */}
          {/*   calculationId={rentingCost.calculationValuesId} */}
          {/* /> */}
        </CollapsibleContent>
      </Collapsible>
    )
  );
}

export type RentingCostDurationComponentProps = {
  rentingCost: RentingCost;
};

function RentingCostDurationComponent({
  rentingCost,
}: RentingCostDurationComponentProps) {
  const [request, setRequest] = useState<UpdateRentingCostDurationRequest>({
    calculationId: rentingCost.calculationId,
    rentingDuration: rentingCost.rentingDuration,
  });

  const [setDuration, { isLoading, error, reset, isSuccess }] =
    useQpmCalcRentingCostDurationPutMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    setDuration,
    isLoading,
    error,
    isSuccess,
    reset,
    500,
  );

  return (
    <>
      <Label
        htmlFor={`${rentingCost.calculationValuesId}-renting-duration-input`}
      >
        {t("Vertragslaufzeit (Monate)")}
      </Label>
      <IntNumberInput
        id={`${rentingCost.calculationValuesId}-renting-duration-input`}
        value={request.rentingDuration || 0}
        onChange={(val) => {
          const duration = val < 0 ? 0 : val;
          setRequest({ ...request, rentingDuration: duration });
        }}
      />
    </>
  );
}
