import { fieldErrorsForPrefix } from "@/shared/app-lib/errors/validation-error";
import { RepresentativeCardWithMutation } from "@/shared/components/domain/representatives/representative-card";
import {
  useQpmOfferShowQuery,
  useQpmOfferUpdateRepresentativeMutation,
} from "@/services/backend/qpm/offer/service";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import { useParams } from "react-router";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function RepresentativeRoute() {
  const params = useParams();
  const { offerId } = params;
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const {
    data: offer,
    isLoading,
    error,
  } = useQpmOfferShowQuery({ artefactMetaId: offerId! }, { skip: !offerId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    offer && (
      <RepresentativeCardWithMutation
        id={offer.artefactData.id}
        representative={offer.representative}
        mutation={useQpmOfferUpdateRepresentativeMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "representative.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`representative.${field}`)
        }
      />
    )
  );
}
