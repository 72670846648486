import { WorkOrder } from "@/services/backend/vbs/work-orders/work-order";
import { useVbsTreatmentShowQuery } from "@/services/backend/vbs/treatments/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  Position,
  positionTitle,
} from "@/services/backend/vbs/treatments/position";
import { ChangeEvent, ReactNode, useEffect, useState } from "react";
import {
  Check,
  ChevronDown,
  ChevronRight,
  CircleAlert,
  RefreshCw,
  Trash,
} from "lucide-react";
import { PositionCompleted } from "@/routes/gesec/processes/[processId]/vbs/work-orders/[workOrderId]/positions/_components/position-completed";
import { Button } from "@/shared/components/ui/button";
import {
  useVbsDocumentationDeleteImageMutation,
  useVbsDocumentationShowImageQuery,
  useVbsDocumentationShowQuery,
  useVbsDocumentationUploadImageForPositionMutation,
} from "@/services/backend/vbs/work-orders/documentation-service";
import { useToast } from "@/shared/hooks/use-toast";
import t from "@/lang/lang";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { Label } from "@/shared/components/ui/label";
import { cn } from "@/shared/lib/utils";
import { Input } from "@/shared/components/ui/input";
import imageCompression from "browser-image-compression";
import { v4 } from "uuid";
import { Image, Item } from "@/services/backend/vbs/work-orders/documentation";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/shared/components/ui/carousel";
import { uniqueAreas } from "@/services/backend/vbs/treatments/treatment";

export function PositionsByArea({ workOrder }: { workOrder: WorkOrder }) {
  const {
    data: treatment,
    isLoading,
    error,
  } = useVbsTreatmentShowQuery({ id: workOrder.treatmentId });

  if (isLoading) {
    return <div />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const areas = uniqueAreas(treatment!);

  return (
    <div className="grid grid-cols-1 gap-2">
      {areas.map((area) => (
        <AreaCard
          area={area}
          positions={treatment!.positions.filter((p) => p.area === area)}
          workOrder={workOrder}
          key={area}
        />
      ))}
    </div>
  );
}

function AreaCard({
  area,
  positions,
  workOrder,
}: {
  area: string;
  positions: Position[];
  workOrder: WorkOrder;
}) {
  const [open, setOpen] = useState(true);

  return (
    <Card>
      <Collapsible open={open} onOpenChange={setOpen}>
        <CollapsibleTrigger>
          <CardHeader>
            <CardTitle>
              <span className="flex items-center space-x-2">
                {open ? <ChevronDown /> : <ChevronRight />}
                <AllPositionsCompletedIndicator positions={positions} />
                <span>{area}</span>
              </span>
            </CardTitle>
          </CardHeader>
        </CollapsibleTrigger>
        <CollapsibleContent>
          <CardContent>
            <div className="divide-y border-t border-b">
              {positions.map((position) => (
                <PositionLine
                  workOrder={workOrder}
                  position={position}
                  key={position.id}
                />
              ))}
            </div>
          </CardContent>
        </CollapsibleContent>
      </Collapsible>
    </Card>
  );
}

function AllPositionsCompletedIndicator({
  positions,
}: {
  positions: Position[];
}) {
  const allPositionsCompleted = positions.every((p) => p.completed);

  if (!allPositionsCompleted) {
    return null;
  }

  return <Check className="text-green-700" />;
}

function PositionLine({
  workOrder,
  position,
}: {
  workOrder: WorkOrder;
  position: Position;
}) {
  return (
    <div className="hover:bg-accent">
      <div className="flex items-center justify-between px-2 py-2">
        <div className="flex items-center space-x-4">
          <PositionCompleted
            treatmentId={workOrder.treatmentId}
            position={position}
          />
          <PositionLineTitle position={position} />
        </div>
        <PhotoDocumentationButtons workOrder={workOrder} position={position} />
      </div>
      {position.comment !== "" && (
        <div className="px-2 pb-2">
          <span className="pl-24">{position.comment}</span>
        </div>
      )}
    </div>
  );
}

function PositionLineTitle({ position }: { position: Position }) {
  const title = positionTitle(position);

  return (
    <div className="flex text-xl font-semibold tracking-tight">
      <span className="w-12">{position.number}</span>
      <span>{title}</span>
    </div>
  );
}

function PhotoDocumentationButtons({
  workOrder,
  position,
}: {
  workOrder: WorkOrder;
  position: Position;
}) {
  return (
    <div className="flex items-center space-x-2">
      <PhotoDocumentation
        workOrder={workOrder}
        position={position}
        beforeOrAfter="before"
      />
      <PhotoDocumentation
        workOrder={workOrder}
        position={position}
        beforeOrAfter="after"
      />
    </div>
  );
}

function PhotoDocumentation({
  workOrder,
  position,
  beforeOrAfter,
}: {
  workOrder: WorkOrder;
  position: Position;
  beforeOrAfter: "before" | "after";
}) {
  const { data: documentation } = useVbsDocumentationShowQuery({
    id: workOrder.documentationId,
  });

  const documentationItem = documentation?.items.find(
    (item) => item.positionId === position.id,
  );

  if (positionHasNoFoto(documentationItem, beforeOrAfter)) {
    return (
      <UploadImageButton
        workOrder={workOrder}
        position={position}
        beforeOrAfter={beforeOrAfter}
      >
        <CircleAlert className="text-destructive mr-2 h-4 w-4" />
        <span className="mr-1">
          {t("Foto")} {t(beforeOrAfter)}
        </span>
      </UploadImageButton>
    );
  }

  return (
    <ImageEditDialog
      documentationItem={documentationItem!}
      workOrder={workOrder}
      position={position}
      beforeOrAfter={beforeOrAfter}
    />
  );
}

function positionHasNoFoto(
  documentationItem: Item | undefined,
  beforeOrAfter: "before" | "after",
) {
  return (
    !documentationItem ||
    (beforeOrAfter === "before" &&
      documentationItem.beforeImages.length === 0) ||
    (beforeOrAfter === "after" && documentationItem.afterImages.length === 0)
  );
}

function UploadImageButton({
  beforeOrAfter,
  workOrder,
  position,
  children,
}: {
  workOrder: WorkOrder;
  position: Position;
  beforeOrAfter: "before" | "after";
  children: ReactNode;
}) {
  const [upload, { isLoading, error, isSuccess, reset }] =
    useVbsDocumentationUploadImageForPositionMutation();
  const { toast } = useToast();

  useEffect(() => {
    if (isSuccess) {
      toast({
        title: t("Bild hochgeladen"),
        description: t("Das Bild wurde erfolgreich hochgeladen."),
        variant: "success",
      });
      reset();
    }
  }, [isSuccess, reset, toast]);

  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const onInputChange = async (e: ChangeEvent<HTMLInputElement>) => {
    if (e.target.files && e.target.files[0]) {
      // compress image
      const compressedFile = await imageCompression(e.target.files[0], {
        maxSizeMB: 0.3,
        maxWidthOrHeight: 1920,
        useWebWorker: true,
      });

      // upload compressed image
      upload({
        id: workOrder.documentationId,
        positionId: position.id,
        positionNumber: position.number,
        componentKind: position.component.kind,
        beforeOrAfter,
        imageId: v4(),
        image: compressedFile,
      });

      // reset input field
      e.target.value = "";
      e.target.files = null;
    }
  };

  return (
    <Label
      className={cn(
        "ring-offset-background focus-visible:ring-ring inline-flex items-center justify-center rounded-md text-sm font-medium whitespace-nowrap transition-colors focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-none disabled:pointer-events-none disabled:opacity-50",
        "border-input bg-background hover:bg-accent hover:text-accent-foreground border",
        "h-9 rounded-md px-3",
      )}
    >
      <Input
        type="file"
        accept="image/jpeg,image/png"
        className="hidden"
        onChange={onInputChange}
        disabled={isLoading}
      />
      {isLoading && (
        <span className="flex items-center">
          <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
          <span>{t("Hochladen...")}</span>
        </span>
      )}
      {!isLoading && children}
    </Label>
  );
}

function ImageEditDialog({
  documentationItem,
  workOrder,
  position,
  beforeOrAfter,
}: {
  documentationItem: Item;
  workOrder: WorkOrder;
  position: Position;
  beforeOrAfter: "before" | "after";
}) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          <Check className="text-green-700" />
          <span className="mr-1">
            {t("Foto")} {t(beforeOrAfter)}
          </span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("Fotos")} {t(beforeOrAfter)}
          </DialogTitle>
          <DialogDescription>{t("Fotos für diese Position")}</DialogDescription>
        </DialogHeader>
        <PhotoCarousel
          documentationId={workOrder.documentationId}
          documentationItem={documentationItem}
          beforeOrAfter={beforeOrAfter}
        />
        <UploadImageButton
          workOrder={workOrder}
          position={position}
          beforeOrAfter={beforeOrAfter}
        >
          {t("Zusätzliches Foto hochladen")}
        </UploadImageButton>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function PhotoCarousel({
  documentationId,
  documentationItem,
  beforeOrAfter,
}: {
  documentationId: string;
  documentationItem: Item;
  beforeOrAfter: "before" | "after";
}) {
  const images =
    beforeOrAfter === "before"
      ? documentationItem.beforeImages
      : documentationItem.afterImages;

  return (
    <Carousel>
      <CarouselContent>
        {images.map((image) => (
          <PhotoCarouselItem
            key={image.id}
            documentationId={documentationId}
            itemId={documentationItem.id}
            image={image}
          />
        ))}
      </CarouselContent>
      <CarouselPrevious className="left-6" />
      <CarouselNext className="right-6" />
    </Carousel>
  );
}

function PhotoCarouselItem({
  documentationId,
  itemId,
  image,
}: {
  documentationId: string;
  itemId: string;
  image: Image;
}) {
  const {
    data: imageData,
    isLoading,
    error,
  } = useVbsDocumentationShowImageQuery({
    id: documentationId,
    fileStoragePrefix: image.fileStoragePrefix,
    imageId: image.id,
  });

  return (
    <CarouselItem>
      <div className="p-1">
        <Card>
          <CardContent className="relative flex min-h-96 items-center justify-center p-0">
            {isLoading && <RefreshCw className="animate-spin" />}
            {error && <RTKQueryErrorAlert error={error} />}
            {image && (
              <img className="max-h-96" src={imageData!.objectURL} alt="" />
            )}
            <DeleteImageButton
              documentationId={documentationId}
              itemId={itemId}
              imageId={image.id}
            />
          </CardContent>
        </Card>
      </div>
    </CarouselItem>
  );
}

function DeleteImageButton(props: {
  documentationId: string;
  itemId: string;
  imageId: string;
}) {
  const { documentationId, itemId, imageId } = props;
  const [open, setOpen] = useState(false);
  const [deleteImage, { isLoading, error, isSuccess, reset }] =
    useVbsDocumentationDeleteImageMutation();

  const onClick = () => {
    if (isLoading) {
      return;
    }
    deleteImage({
      id: documentationId,
      itemId,
      imageId,
    });
  };

  useEffect(() => {
    if (isSuccess) {
      reset();
      setOpen(false);
    }
  }, [isSuccess, setOpen, reset]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button className="absolute top-4 right-4" variant="destructive">
          <Trash className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t("Bild wirklich löschen?")}</DialogTitle>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <Button onClick={onClick} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
