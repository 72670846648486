import { NavLink, Outlet } from "react-router";
import {
  sortCompanies,
  useListCompanyQuery,
} from "@/services/backend/company/company";
import {
  Sidebar,
  SidebarContent,
  SidebarGroup,
  SidebarGroupLabel,
  SidebarInset,
  SidebarMenu,
  SidebarMenuButton,
  SidebarMenuItem,
  SidebarMenuSub,
  SidebarMenuSubButton,
  SidebarMenuSubItem,
  SidebarProvider,
  SidebarTrigger,
} from "@/shared/components/ui/sidebar";
import t from "@/lang/lang";
import {
  Collapsible,
  CollapsibleContent,
  CollapsibleTrigger,
} from "@/shared/components/ui/collapsible";
import {
  Building2,
  ChevronRight,
  CircleDollarSign,
  Contact,
  FileDigit,
  Hammer,
  IdCard,
  LucideIcon,
  Warehouse,
} from "lucide-react";
import { ReactNode } from "react";

export default function BaseDataRoute() {
  return (
    <SidebarProvider>
      <BaseDataSidebar />
      <BaseDataContent>
        <div className="border-b pb-4 md:hidden">
          <SidebarTrigger />
        </div>
        <Outlet />
      </BaseDataContent>
    </SidebarProvider>
  );
}

function BaseDataSidebar() {
  return (
    <Sidebar className="pt-16">
      <SidebarContent>
        <GSMBaseData />
        <Settings />
      </SidebarContent>
    </Sidebar>
  );
}

function BaseDataContent({ children }: { children: ReactNode }) {
  return (
    <SidebarInset className="pt-16">
      <div className="flex flex-1 flex-col gap-4 p-4 pl-5">{children}</div>
    </SidebarInset>
  );
}

function GSMBaseData() {
  const { data } = useListCompanyQuery({});
  const companies = sortCompanies(data?.companies ?? [], "name");

  const items = [
    {
      label: t("Dienstleistungen"),
      icon: Hammer,
      children: [
        { label: t("HTZ"), to: "processes/htz" },
        { label: t("QPM"), to: "processes/qpm" },
        { label: t("RLT"), to: "processes/rlt" },
        { label: t("VBS"), to: "processes/vbs" },
      ],
    },
    {
      label: t("Nummern"),
      icon: FileDigit,
      children: [
        { label: t("Dokumente"), to: "numbers/artefact" },
        { label: t("Vorgänge"), to: "numbers/process" },
      ],
    },
    {
      label: t("Unternehmen"),
      icon: Building2,
      children: [
        { label: t("Übersicht"), to: "companies" },
        ...companies.map((company) => ({
          label: company.shortName,
          to: `companies/${company.id}`,
        })),
      ],
    },
    {
      label: t("Niederlassungen"),
      icon: Warehouse,
      children: [{ label: t("Übersicht"), to: "branches" }],
    },
    {
      label: t("Kontakte"),
      icon: Contact,
      children: [{ label: t("Kontaktarten"), to: "contacts/contact-kind" }],
    },
  ];

  return (
    <BaseDataSidebarGroup groupLabel={t("GSM Stammdaten")} items={items} />
  );
}

function Settings() {
  const items = [
    {
      label: t("Dynamics Sales"),
      icon: CircleDollarSign,
      children: [{ label: t("Verknüpfungen"), to: "dynamics/matches" }],
    },
    {
      label: t("Entra"),
      icon: IdCard,
      children: [{ label: t("Entra Gruppen"), to: "entra/entra-groups" }],
    },
  ];

  return <BaseDataSidebarGroup groupLabel={t("Einstellungen")} items={items} />;
}

function BaseDataSidebarGroup({
  groupLabel,
  items,
}: {
  groupLabel: string;
  items: {
    label: string;
    icon: LucideIcon;
    children: { label: string; to: string }[];
  }[];
}) {
  return (
    <SidebarGroup>
      <SidebarGroupLabel>{groupLabel}</SidebarGroupLabel>
      <SidebarMenu>
        {items.map((item) => (
          <Collapsible
            key={item.label}
            asChild
            defaultOpen={false}
            className="group/collapsible"
          >
            <SidebarMenuItem>
              <CollapsibleTrigger asChild>
                <SidebarMenuButton tooltip={item.label}>
                  {item.icon && <item.icon />}
                  <span>{item.label}</span>
                  <ChevronRight className="ml-auto transition-transform duration-200 group-data-[state=open]/collapsible:rotate-90" />
                </SidebarMenuButton>
              </CollapsibleTrigger>
              <CollapsibleContent>
                <SidebarMenuSub>
                  {item.children?.map((child) => (
                    <SidebarMenuSubItem key={child.to}>
                      <SidebarMenuSubButton asChild>
                        <NavLink to={child.to}>
                          <span>{child.label}</span>
                        </NavLink>
                      </SidebarMenuSubButton>
                    </SidebarMenuSubItem>
                  ))}
                </SidebarMenuSub>
              </CollapsibleContent>
            </SidebarMenuItem>
          </Collapsible>
        ))}
      </SidebarMenu>
    </SidebarGroup>
  );
}
