import { useAuthUserMeQuery } from "@/services/backend/auth/user/service";

export function useUserCompanies() {
  const {
    data: me,
    isLoading,
    error,
  } = useAuthUserMeQuery(
    {},
    {
      // needed to make sure any changes with the dev-user are reflected.
      refetchOnMountOrArgChange: true,
      refetchOnReconnect: true,
      refetchOnFocus: true,
    },
  );

  return { companyIds: me?.companies ?? [], isLoading, error };
}
