import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Plus, RefreshCw } from "lucide-react";
import t from "@/lang/lang";
import { inputValidationErrorMessages } from "@/shared/components/ui/input-error-messages";
import { useEffect, useState } from "react";
import {
  PutRequest,
  useHtzAirHandlingUnitPutMutation,
} from "@/services/backend/htz/ahu/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { v4 } from "uuid";
import { useNavigate } from "react-router";
import { newAirHandlingUnit } from "@/services/backend/htz/ahu/air-handling-unit";
import { TextInput } from "@/shared/components/form/text-input";

export function NewAhuDialog({ customerId }: { customerId: string }) {
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<PutRequest>(
    newAirHandlingUnit(v4(), customerId),
  );
  const [put, { isLoading, error, data, reset }] =
    useHtzAirHandlingUnitPutMutation();
  const navigate = useNavigate();

  useEffect(() => {
    if (data) {
      navigate(`${data.id}`);
      setOpen(false);
      setRequest(newAirHandlingUnit(v4(), customerId));
      reset();
    }
  }, [data, setOpen, setRequest, reset, customerId, navigate]);

  const doCreate = () => {
    if (isLoading) {
      return;
    }

    put({
      ...request,
      id: v4(),
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm">
          <Plus className="mr-2 h-4 w-4" />
          <span>{t("Neue RLT-Anlage")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("RLT-Anlage erstellen")}</DialogTitle>
          <DialogDescription>
            {t("Erstellt eine neue RLT-Anlage für den Kunden.")}
          </DialogDescription>
        </DialogHeader>
        <TextInput
          label={t("Name")}
          field="name"
          text={request.name}
          onTextChange={(name) => setRequest({ ...request, name })}
          errors={inputValidationErrorMessages(error, "name")}
        />
        <TextInput
          label={t("Standort")}
          field="location"
          text={request.location}
          onTextChange={(location) => setRequest({ ...request, location })}
          errors={inputValidationErrorMessages(error, "location")}
        />
        <TextInput
          label={t("Baujahr")}
          field="constructionYear"
          text={request.constructionYear}
          onTextChange={(constructionYear) =>
            setRequest({ ...request, constructionYear })
          }
          errors={inputValidationErrorMessages(error, "constructionYear")}
        />
        <TextInput
          label={t("Luftstrom m³/h")}
          field="volumeFlowRate"
          text={request.volumeFlowRate}
          onTextChange={(volumeFlowRate) =>
            setRequest({ ...request, volumeFlowRate })
          }
          errors={inputValidationErrorMessages(error, "volumeFlowRate")}
        />
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doCreate} disabled={isLoading}>
            {isLoading && <RefreshCw className="mr-2 h-4 w-4 animate-spin" />}
            {t("Erstellen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
