import { fieldErrorsForPrefix } from "@/shared/app-lib/errors/validation-error";
import t from "@/lang/lang";
import { ServiceAddressCardWithMutation } from "@/shared/components/domain/addresses/service-address-card";
import { useParams } from "react-router";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  useQpmOfferShowQuery,
  useQpmOfferUpdatePostalAddressMutation,
  useQpmOfferUpdateServiceAddressMutation,
} from "@/services/backend/qpm/offer/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import { PostalAddressCardWithMutation } from "@/shared/components/domain/addresses/postal-address-card";

export function AddressesRoute() {
  const params = useParams();
  const { offerId } = params;
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const {
    data: offer,
    isLoading,
    error,
  } = useQpmOfferShowQuery({ artefactMetaId: offerId! }, { skip: !offerId });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    offer && (
      <div className="space-y-4">
        <PostalAddressCardWithMutation
          id={offer.artefactData.id}
          address={offer.postalAddress}
          mutation={useQpmOfferUpdatePostalAddressMutation}
          fieldErrors={fieldErrorsForPrefix(notReadyError, "postalAddress.")}
          resetFieldError={(field) =>
            resetNotReadyErrorField(`postalAddress.${field}`)
          }
        />
        <ServiceAddressCardWithMutation
          id={offer.artefactData.id}
          address={offer.serviceAddress}
          mutation={useQpmOfferUpdateServiceAddressMutation}
          fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
          resetFieldError={(field) =>
            resetNotReadyErrorField(`serviceAddress.${field}`)
          }
        />
      </div>
    )
  );
}
