import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { NavLink } from "react-router";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { useHtzReportListQuery } from "@/services/backend/htz/report/service";

export function ReportsTable({ processId }: { processId: string }) {
  const {
    data: list,
    isLoading,
    error,
  } = useHtzReportListQuery({
    process: {
      active: true,
      values: [processId],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Table className="animate-pulse">
        <TableHeader>
          <TableRow>
            <TableHead>{t("Nummer")}</TableHead>
            <TableHead>{t("Erstellt am")}</TableHead>
            <TableHead className="w-full">{t("Festgeschrieben")}</TableHead>
            <TableHead className="w-32">{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    );
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead>{t("Erstellt am")}</TableHead>
          <TableHead className="w-full">{t("Festgeschrieben")}</TableHead>
          <TableHead className="w-32">{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {[...(list?.reports ?? [])]
          .sort(
            (a, b) => (a.date && b.date && a.date.localeCompare(b.date)) || 0,
          )
          .map((report) => (
            <TableRow key={report.id}>
              <TableCell>
                <NavLink to={`${report.id}`}>
                  <ArtefactNumberById artefactId={report.id} />
                </NavLink>
              </TableCell>
              <TableCell className="whitespace-nowrap">
                {formatDate(report.date ?? "")}, {formatTime(report.date ?? "")}
              </TableCell>
              <TableCell>{report.immutable ? "ja" : "nein"}</TableCell>
              <TableCell>
                <div className="flex space-x-2">
                  <NavLink to={`${report.id}`}>
                    <Button variant="ghost">
                      <Search className="h-4 w-4" />
                    </Button>
                  </NavLink>
                </div>
              </TableCell>
            </TableRow>
          ))}
        {list?.reports?.length === 0 && (
          <TableRow>
            <TableCell colSpan={4} className="text-center">
              {t("Keine Berichte gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}
