import { backendApi } from "@/services/backend/api";
import {
  ConsumableList,
  GetMonitorRequest,
  GetMonitorTypeListRequest,
  GetMonitorTypeRequest,
  ListConsumableRequest,
  Monitor,
  MonitorDeleteRequest,
  MonitorList,
  MonitorListRequest,
  MonitorRecordList,
  MonitorRecordListRequest,
  MonitorType,
  MonitorTypeList,
  PutMonitorRequest,
  PutMonitorTypeRequest,
} from "@/services/backend/qpm/monitors/types";

const qpmMonitorServiceApi = backendApi
  .enhanceEndpoints({
    addTagTypes: [
      "QpmMonitor",
      "QpmMonitorRecord",
      "QpmMonitorType",
      "QpmConsumable",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmMonitorPut: build.mutation<Monitor, PutMonitorRequest>({
        query: (request) => ({
          url: `/qpm/monitor`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["QpmMonitor", { type: "QpmConsumable", id: "LIST" }],
      }),
      qpmMonitorGet: build.query<Monitor, GetMonitorRequest>({
        query: (request) => ({
          url: `/qpm/monitor`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmMonitor", id: result.id }] : [],
      }),
      qpmMonitorList: build.query<MonitorList, MonitorListRequest>({
        query: (request) => ({
          url: `/qpm/monitor/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmMonitor", id: "LIST" }] : [],
      }),
      qpmMonitorDelete: build.mutation<null, MonitorDeleteRequest>({
        query: (request) => ({
          url: `/qpm/monitor`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["QpmMonitor"],
      }),
      qpmMonitorRecordList: build.query<
        MonitorRecordList,
        Partial<MonitorRecordListRequest>
      >({
        query: (request) => ({
          url: `/qpm/monitor-record/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmMonitorRecord", id: "LIST" }] : [],
      }),
      qpmMonitorTypePut: build.mutation<MonitorType, PutMonitorTypeRequest>({
        query: (request) => ({
          url: `/qpm/monitor/type`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (result) =>
          result ? [{ type: "QpmMonitorType", id: result.key }] : [],
      }),
      qpmMonitorTypeGet: build.query<MonitorType, GetMonitorTypeRequest>({
        query: (request) => ({
          url: `/qpm/monitor/type`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmMonitorType", id: result.key }] : [],
      }),
      qpmMonitorTypeList: build.query<
        MonitorTypeList,
        GetMonitorTypeListRequest
      >({
        query: (request) => ({
          url: `/qpm/monitor/type/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmMonitorType", id: "LIST" }] : [],
      }),
      qpmConsumableList: build.query<ConsumableList, ListConsumableRequest>({
        query: (request) => ({
          url: `/qpm/consumable/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmConsumable", id: "LIST" }] : [],
      }),
    }),
  });

export const {
  useQpmMonitorPutMutation,
  useQpmMonitorGetQuery,
  useQpmMonitorListQuery,
  useQpmMonitorRecordListQuery,
  useQpmMonitorDeleteMutation,
  useQpmMonitorTypePutMutation,
  useQpmMonitorTypeGetQuery,
  useQpmMonitorTypeListQuery,
  useQpmConsumableListQuery,
} = qpmMonitorServiceApi;
