import { ConfirmationListTable } from "@/routes/gesec/processes/[processId]/vbs/confirmations/_components/confirmation-list-table";
import { useParams } from "react-router";
import { CreateConfirmationButton } from "@/routes/gesec/processes/[processId]/vbs/confirmations/_components/create-confirmation-button";
import t from "@/lang/lang";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";
import { TableCard } from "@/shared/components/ui/table-card";

export function ConfirmationIndexRoute() {
  const { processId } = useParams();

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Auftragsbestätigungen")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateConfirmationButton processId={processId!} />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <TableCard>
          <ConfirmationListTable processId={processId!} />
        </TableCard>
      </MilestonePageContent>
    </MilestonePage>
  );
}
