import {
  ConfirmationDocument,
  useVbsConfirmationDownloadPDFMutation,
  useVbsConfirmationPreviewPDFQuery,
} from "@/services/backend/vbs/confirmations/service";
import {
  useErrArtefactNotReady,
  useSetErrArtefactNotReadyFromRtk,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { Separator } from "@/shared/components/ui/separator";
import {
  ArtefactNotReadyAlert,
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsDownload,
  ArtefactSheetActionsHeader,
  ArtefactSheetContent,
  ArtefactSheetHeader,
  ArtefactSheetImmutabilityWarning,
  ArtefactSheetMain,
  ArtefactSheetPreview,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import { Download } from "lucide-react";
import { useDownloadPdf } from "@/shared/lib/utilities/download-pdf";
import { useState } from "react";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";

export function ConfirmationPdf({
  confirmationId,
}: {
  confirmationId: string;
}) {
  const [open, setOpen] = useState(false);
  const { data, isLoading, error } = useVbsConfirmationPreviewPDFQuery(
    {
      id: confirmationId,
      document: ConfirmationDocument.All,
    },
    {
      skip: !open,
    },
  );

  const { notReadyError } = useErrArtefactNotReady();

  return (
    <ArtefactSheet open={open} onOpenChange={setOpen}>
      <ArtefactSheetTrigger asChild>
        <Button variant="outline">PDF</Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="max-h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>{t("Download PDF")}</ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          <ArtefactSheetPreview
            objectURL={data?.objectURL}
            isLoading={isLoading}
            error={error}
          />
          <ArtefactSheetActions>
            <ArtefactSheetActionsHeader
              title={t("Auftragsbestätigung")}
              artefactNumber={
                <ArtefactNumberById artefactId={confirmationId} />
              }
            />
            <ArtefactSheetActionsContent>
              <ArtefactNotReadyAlert
                error={notReadyError}
                artefact={t("Auftragsbestätigung")}
              />
              <ArtefactSheetImmutabilityWarning
                title={t("Hinweis")}
                description={t(
                  "Wenn eine Auftragsbestätigung heruntergeladen wird, wird sie festgeschrieben. Sie kann dann nicht mehr geändert werden.",
                )}
              />
              <Separator />
              <ArtefactSheetActionsDownload>
                <DownloadDocumentButton
                  confirmationId={confirmationId}
                  document={ConfirmationDocument.All}
                  text={t("Alle Dokumente")}
                />
                <DownloadDocumentButton
                  confirmationId={confirmationId}
                  document={ConfirmationDocument.Letter}
                  text={t("Bestätigung")}
                />
                <DownloadDocumentButton
                  confirmationId={confirmationId}
                  document={ConfirmationDocument.Treatment}
                  text={t("Leistungsverzeichnis")}
                />
              </ArtefactSheetActionsDownload>
            </ArtefactSheetActionsContent>
          </ArtefactSheetActions>
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function DownloadDocumentButton({
  confirmationId,
  document: documentType,
  text,
}: {
  confirmationId: string;
  document: ConfirmationDocument;
  text: string;
}) {
  const [open, setOpen] = useState(false);
  const [download, { isLoading, error, data, reset }] =
    useVbsConfirmationDownloadPDFMutation();
  const { user } = useAuth();

  useDownloadPdf(data, reset);

  useSetErrArtefactNotReadyFromRtk(error, () => {
    setOpen(false);
    reset();
  });

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      id: confirmationId,
      document: documentType,
      userId: user?.id ?? "",
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="justify-start">
          <Download className="mr-4 h-5 w-5" /> {text}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Auftragsbestätigung herunterladen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Wenn eine Auftragsbestätigung heruntergeladen wird, wird sie festgeschrieben. Sie kann dann nicht mehr geändert werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doDownload} disabled={isLoading}>
            {isLoading ? (
              <Download className="mr-4 h-5 w-5 animate-bounce" />
            ) : (
              <Download className="mr-4 h-5 w-5" />
            )}
            <span>{t("Herunterladen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
