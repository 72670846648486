import { Filter, FilterMode } from "@/shared/lib/filter/filter";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import { Button } from "@/shared/components/ui/button";
import { ListFilter } from "lucide-react";
import { useEffect, useState } from "react";
import { Calendar } from "@/shared/components/ui/calendar";
import { DateRange } from "react-day-picker";

export function DateBetweenFilter({
  label,
  filter,
  onFilterChange,
}: {
  label: string;
  filter: Filter<string>;
  onFilterChange: (filter: Filter<string>) => void;
}) {
  const [open, setOpen] = useState(false);
  const [date, setDate] = useState<DateRange | undefined>(() => {
    if (filter.values) {
      // we do expect, that there are always two values
      // otherwise, just fail fast
      return {
        from: new Date(filter.values[0]),
        to: new Date(filter.values[1]),
      };
    }

    return undefined;
  });

  useEffect(() => {
    // deactivate filter if no date is selected
    if (!date && filter.active) {
      onFilterChange({
        ...filter,
        active: false,
        values: [],
      });
    }

    // update filter if date is selected
    // we only update the filter if the full range is selected
    if (date?.from && date?.to && filter.values) {
      if (
        date.from.toISOString() !== filter.values[0] ||
        date.to.toISOString() !== filter.values[1]
      ) {
        onFilterChange({
          active: true,
          mode: FilterMode.Between,
          values: [date.from.toISOString(), date.to.toISOString()],
        });
      }
    }
  });

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className="justify-between"
          size="sm"
        >
          <ListFilter className="mr-2 h-4 w-4" />
          <span>{label}</span>
        </Button>
      </PopoverTrigger>
      <PopoverContent className="w-auto p-0" align="start">
        <Calendar
          initialFocus
          mode="range"
          defaultMonth={date?.from}
          selected={date}
          onSelect={setDate}
          numberOfMonths={2}
        />
      </PopoverContent>
    </Popover>
  );
}
