import { useParams } from "react-router";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  RepresentativeCardSkeleton,
  RepresentativeCardWithMutation,
} from "@/shared/components/domain/representatives/representative-card";
import {
  useRltOfferShowQuery,
  useRltOfferUpdateRepresentativeMutation,
} from "@/services/backend/rlt/offers/service";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";

export function OfferRepresentativeRoute() {
  const { offerId } = useParams();

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const {
    data: offer,
    isLoading,
    error,
  } = useRltOfferShowQuery({
    id: offerId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <RepresentativeCardSkeleton />;
  }

  return (
    <RepresentativeCardWithMutation
      id={offer!.id}
      representative={offer!.representative}
      mutation={useRltOfferUpdateRepresentativeMutation}
      fieldErrors={fieldErrorsForPrefix(notReadyError, "representative.")}
      resetFieldError={(field) =>
        resetNotReadyErrorField(`representative.${field}`)
      }
    />
  );
}
