import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useParams } from "react-router";
import {
  useVbsOfferShowQuery,
  useVbsOfferUpdatePostalAddressMutation,
  useVbsOfferUpdateServiceAddressMutation,
} from "@/services/backend/vbs/offers/service";
import {
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { PostalAddressCardWithMutation } from "@/shared/components/domain/addresses/postal-address-card";
import { ServiceAddressCardWithMutation } from "@/shared/components/domain/addresses/service-address-card";

export function OfferCustomerAddressRoute() {
  const { offerId } = useParams();
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const {
    data: offer,
    isLoading,
    error,
  } = useVbsOfferShowQuery({
    id: offerId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <div className="space-y-2">
      <PostalAddressCardWithMutation
        id={offer!.id}
        address={offer!.postalAddress}
        mutation={useVbsOfferUpdatePostalAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "postalAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`postalAddress.${field}`)
        }
      />
      <ServiceAddressCardWithMutation
        id={offer!.id}
        address={offer!.serviceAddress}
        mutation={useVbsOfferUpdateServiceAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
    </div>
  );
}
