import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import {
  useQpmCatalogGetActiveQuery,
  useQpmCatalogListQuery,
} from "@/services/backend/qpm/catalog/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Skeleton } from "@/shared/components/ui/skeleton";
import t from "@/lang/lang";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import {
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useNavigate } from "react-router";
import { ExternalLink } from "lucide-react";
import { Button } from "@/shared/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { CreateNewCatalogDialog } from "@/routes/base-data/processes/qpm/catalog/_components/create-new-catalog";
import {
  HoverCard,
  HoverCardContent,
  HoverCardTrigger,
} from "@/shared/components/ui/hover-card";

export function CatalogListTablePage() {
  return (
    <div className="flex flex-col gap-6">
      <div>
        <CardHeader>
          <CardTitle>{t("Aktuell aktiver Katalog")}</CardTitle>
        </CardHeader>
        <CardContent className="mb-2">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("Name")}</TableHead>
                <TableHead>{t("Beschreibung")}</TableHead>
                <TableHead>{t("Erstellt am")}</TableHead>
                <TableHead className="text-end">{t("aktiv seit")}</TableHead>
                <TableHead className="text-end">{t("Aktionen")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <ActiveTableRow />
            </TableBody>
          </Table>
        </CardContent>
      </div>
      <div>
        <CardHeader>
          <div className="flex items-center justify-between">
            <CardTitle>{t("Kataloge")}</CardTitle>
            <CreateNewCatalogDialog />
          </div>
        </CardHeader>
        <CardContent>
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>{t("Name")}</TableHead>
                <TableHead>{t("Beschreibung")}</TableHead>
                <TableHead className="text-start">{t("Erstellt am")}</TableHead>
                <TableHead className="text-end">{t("Aktionen")}</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              <CatalogListTableRows />
            </TableBody>
          </Table>
        </CardContent>
      </div>
    </div>
  );
}

function ActiveTableRow() {
  const navigate = useNavigate();
  const { data: catalog, error, isLoading } = useQpmCatalogGetActiveQuery({});

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <TableRow>
        <TableCell>
          <Skeleton className="h-10 w-full" />
        </TableCell>
        <TableCell>
          <Skeleton className="h-10 w-full" />
        </TableCell>
        <TableCell>
          <Skeleton className="h-10 w-full" />
        </TableCell>
        <TableCell>
          <Skeleton className="h-10 w-full" />
        </TableCell>
      </TableRow>
    );
  }

  if (!error && !isLoading && (!catalog || !catalog.id)) {
    return (
      <TableRow>
        <TableCell className="text-center" colSpan={5}>
          -- {t("Kein aktiver Katalog vorhanden!")} --
        </TableCell>
      </TableRow>
    );
  }

  return (
    <TableRow>
      <TableCell
        className="text-primary text-base font-bold hover:cursor-pointer hover:underline"
        onClick={() => navigate(`${catalog.id}`)}
      >
        {catalog.name}
      </TableCell>
      <TableCell className="max-w-[350px] truncate">
        <HoverCard>
          <HoverCardTrigger className="overflow-hidden text-ellipsis">
            {catalog.description}
          </HoverCardTrigger>
          <HoverCardContent
            align="start"
            className="max-h-[230px] w-fit max-w-screen-sm overflow-hidden text-wrap text-ellipsis"
          >
            {catalog.description}
          </HoverCardContent>
        </HoverCard>
      </TableCell>
      <TableCell>
        {formatDate(catalog.createdAt)} - {formatTime(catalog.createdAt)}
      </TableCell>
      <TableCell className="text-end">
        {formatDate(catalog.activeSince)}
      </TableCell>
      <TableCell className="text-end">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="mr-1"
                size="icon"
                variant="outline"
                onClick={() => navigate(`${catalog.id}`)}
              >
                <ExternalLink className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("Öffnen")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
    </TableRow>
  );
}

function CatalogListTableRows() {
  const navigate = useNavigate();
  const { data: list, error, isLoading } = useQpmCatalogListQuery({});
  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <TableRow>
        <TableCell>
          <Skeleton className="h-10 w-full" />
        </TableCell>
        <TableCell>
          <Skeleton className="h-10 w-full" />
        </TableCell>
        <TableCell>
          <Skeleton className="h-10 w-full" />
        </TableCell>
        <TableCell>
          <Skeleton className="h-10 w-full" />
        </TableCell>
      </TableRow>
    );
  }

  if (!error && !isLoading && (!list || list.entries.length <= 0)) {
    return (
      <TableRow>
        <TableCell className="text-center" colSpan={4}>
          -- {t("Es sind keine Kataloge vorhanden!")} --
        </TableCell>
      </TableRow>
    );
  }

  return list.entries.map((entry) => (
    <TableRow key={entry.id}>
      <TableCell
        className="text-primary text-base hover:cursor-pointer hover:underline"
        onClick={() => navigate(`${entry.id}`)}
      >
        {entry.name}
      </TableCell>
      <TableCell className="max-w-[350px] truncate">
        <HoverCard>
          <HoverCardTrigger className="overflow-hidden text-ellipsis">
            {entry.description}
          </HoverCardTrigger>
          <HoverCardContent
            align="start"
            className="max-h-[230px] w-fit max-w-screen-sm overflow-hidden text-wrap text-ellipsis"
          >
            {entry.description}
          </HoverCardContent>
        </HoverCard>
      </TableCell>
      <TableCell className="text-start">
        {formatDate(entry.createdAt)} - {formatTime(entry.createdAt)}
      </TableCell>
      <TableCell className="text-end">
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger asChild>
              <Button
                className="mr-1"
                size="icon"
                variant="outline"
                onClick={() => navigate(`${entry.id}`)}
              >
                <ExternalLink className="h-4 w-4" />
              </Button>
            </TooltipTrigger>
            <TooltipContent>
              <p>{t("Öffnen")}</p>
            </TooltipContent>
          </Tooltip>
        </TooltipProvider>
      </TableCell>
    </TableRow>
  ));
}
