import { useState } from "react";
import {
  AccessPanel,
  AirHandlingUnit,
  AirHandlingUnitData,
  AirHandlingUnitSchwerpunkt,
  AirHandlingUnitSchwerpunktData,
  AirHandlingUnitSystem,
  AwayAirDuctAngular,
  AwayAirDuctAngularData,
  AwayAirDuctRound,
  AwayAirDuctRoundData,
  CleaningKind,
  Component,
  CrossFlowHeatExchanger,
  CrossFlowHeatExchangerData,
  ExhaustDuctAngular,
  ExhaustDuctAngularData,
  ExhaustDuctRound,
  ExhaustDuctRoundData,
  ExtraHours,
  ExtraHoursData,
  FreeText,
  FreeTextData,
  Kind,
  OutdoorAirDuctAngular,
  OutdoorAirDuctAngularData,
  OutdoorAirDuctRound,
  OutdoorAirDuctRoundData,
  RecirculatingSprayHumidifier,
  RecirculatingSprayHumidifierData,
  RotaryHeatExchanger,
  RotaryHeatExchangerData,
  SplitUnit,
  SplitUnitData,
  SupplyDuctAngular,
  SupplyDuctAngularData,
  SupplyDuctRound,
  SupplyDuctRoundData,
} from "@/services/backend/rlt/treatments/component";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  UpdateComponentRequest,
  useRltTreatmentUpdateActuallyTreatedMutation,
  useRltTreatmentUpdateComponentMutation,
} from "@/services/backend/rlt/treatments/service";
import {
  FloatNumberInput,
  IntNumberInput,
} from "@/routes/gesec/processes/_components/ui/number-input";
import t from "@/lang/lang";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export function ComponentCell({
  treatmentId,
  positionId,
  component: propComponent,
  actuallyTreated = false,
}: {
  treatmentId: string;
  positionId: string;
  component: Component;
  actuallyTreated?: boolean;
}) {
  const [request, setRequest] = useState<UpdateComponentRequest>({
    id: treatmentId,
    positionId,
    component: propComponent,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useRltTreatmentUpdateComponentMutation();
  const [
    updateActuallyTreated,
    {
      isLoading: isLoadingActually,
      error: errorActually,
      isSuccess: isSuccessActually,
      reset: resetActually,
    },
  ] = useRltTreatmentUpdateActuallyTreatedMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    actuallyTreated ? updateActuallyTreated : update,
    actuallyTreated ? isLoadingActually : isLoading,
    actuallyTreated ? errorActually : error,
    actuallyTreated ? isSuccessActually : isSuccess,
    actuallyTreated ? resetActually : reset,
    250,
  );

  switch (propComponent.kind) {
    case Kind.AccessPanel:
      return (
        <ComponentAccessPanel
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AirHandlingUnit:
      return (
        <ComponentAirHandlingUnit
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AirHandlingUnitSchwerpunkt:
      return (
        <ComponentAirHandlingUnitSchwerpunkt
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AirHandlingUnitSystem:
      return (
        <ComponentAirHandlingUnitSystem
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AwayAirDuctAngular:
      return (
        <ComponentAwayAirDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AwayAirDuctRound:
      return (
        <ComponentAwayAirDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.CrossFlowHeatExchanger:
      return (
        <ComponentCrossFlowHeatExchanger
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExhaustDuctAngular:
      return (
        <ComponentExhaustDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExhaustDuctRound:
      return (
        <ComponentExhaustDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExtraHours:
      return (
        <ComponentExtraHours
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.FreeText:
      return (
        <ComponentFreeText
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.OutdoorAirDuctAngular:
      return (
        <ComponentOutdoorAirDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.OutdoorAirDuctRound:
      return (
        <ComponentOutdoorAirDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.RecirculatingSprayHumidifier:
      return (
        <ComponentRecirculatingSprayHumidifier
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.RotaryHeatExchanger:
      return (
        <ComponentRotaryHeatExchanger
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.SplitUnit:
      return (
        <ComponentSplitUnit
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.SupplyDuctAngular:
      return (
        <ComponentSupplyDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.SupplyDuctRound:
      return (
        <ComponentSupplyDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    default:
      return null;
  }
}

function ComponentAccessPanel(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as AccessPanel;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Anzahl:")}
      </span>
      <IntNumberInput
        value={data.count}
        onChange={(count) =>
          onChange({
            ...component,
            data: { count },
          })
        }
      />
    </div>
  );
}

function ComponentAirHandlingUnit(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as AirHandlingUnit;
  return (
    <div className="flex w-64 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("LxBxH (m):")}
      </span>
      <FloatNumberInput
        value={data.length}
        onChange={(length) =>
          onChange({
            ...component,
            data: { ...data, length } as AirHandlingUnitData,
          })
        }
      />
      <FloatNumberInput
        value={data.width}
        onChange={(width) =>
          onChange({
            ...component,
            data: { ...data, width } as AirHandlingUnitData,
          })
        }
      />
      <FloatNumberInput
        value={data.height}
        onChange={(height) =>
          onChange({
            ...component,
            data: { ...data, height } as AirHandlingUnitData,
          })
        }
      />
    </div>
  );
}

function ComponentAirHandlingUnitSchwerpunkt(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as AirHandlingUnitSchwerpunkt;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Zeit (h):")}
      </span>
      <FloatNumberInput
        value={data.cleanTime}
        onChange={(cleanTime) =>
          onChange({
            ...component,
            data: { ...data, cleanTime } as AirHandlingUnitSchwerpunktData,
          })
        }
      />
    </div>
  );
}

function ComponentAirHandlingUnitSystem(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const comp = component as AirHandlingUnitSystem;

  return (
    <div>
      <input
        className="border-input mb-1 w-full min-w-[200px] rounded border px-1 py-0.5"
        value={comp.designation}
        onChange={(e) =>
          onChange({
            ...comp,
            designation: e.target.value,
          } as AirHandlingUnitSystem)
        }
        placeholder={t("Anlagenbezeichnung")}
      />
      <div className="flex w-64 justify-between">
        <span className="mr-4 flex h-6 items-center justify-end whitespace-nowrap">
          {t("Reinigung:")}
        </span>
        <CleaningKindSelect
          value={comp.cleaningKind}
          onChange={(cleaningKind) =>
            onChange({ ...comp, cleaningKind } as AirHandlingUnitSystem)
          }
        />
      </div>
    </div>
  );
}

function CleaningKindSelect({
  value,
  onChange,
}: {
  value: CleaningKind;
  onChange: (e: CleaningKind) => void;
}) {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="h-6">
        <SelectValue>{t(value)}</SelectValue>
      </SelectTrigger>
      <SelectContent align="end">
        <SelectGroup>
          <SelectLabel>{t("Reinigung")}</SelectLabel>
          {[CleaningKind.Service, CleaningKind.Sanierung].map((buildUp) => (
            <SelectItem value={buildUp} key={buildUp}>
              {t(buildUp)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function ComponentAwayAirDuctAngular(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as AwayAirDuctAngular;
  return (
    <div className="flex w-64 justify-between">
      <span className="flex h-6 items-center whitespace-nowrap">
        {t("LxBxH (m):")}
      </span>
      <FloatNumberInput
        value={data.length}
        onChange={(length) =>
          onChange({
            ...component,
            data: { ...data, length } as AwayAirDuctAngularData,
          })
        }
      />
      <FloatNumberInput
        value={data.width}
        onChange={(width) =>
          onChange({
            ...component,
            data: { ...data, width } as AwayAirDuctAngularData,
          })
        }
      />
      <FloatNumberInput
        value={data.height}
        onChange={(height) =>
          onChange({
            ...component,
            data: { ...data, height } as AwayAirDuctAngularData,
          })
        }
      />
    </div>
  );
}

function ComponentAwayAirDuctRound(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as AwayAirDuctRound;

  return (
    <div className="flex w-64 items-center space-x-4">
      <div className="flex w-40 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Länge (m):")}
        </span>
        <FloatNumberInput
          value={data.length}
          onChange={(length) =>
            onChange({
              ...component,
              data: { ...data, length } as AwayAirDuctRoundData,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("ø (m):")}
        </span>
        <FloatNumberInput
          value={data.diameter}
          onChange={(diameter) =>
            onChange({
              ...component,
              data: { ...data, diameter } as AwayAirDuctRoundData,
            })
          }
          fractions={3}
        />
      </div>
    </div>
  );
}

function ComponentCrossFlowHeatExchanger(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as CrossFlowHeatExchanger;
  return (
    <div className="flex w-64 justify-between">
      <span className="flex h-6 items-center whitespace-nowrap">
        {t("LxB (m):")}
      </span>
      <div className="flex w-32 items-center justify-end space-x-3">
        <FloatNumberInput
          value={data.length}
          onChange={(length) =>
            onChange({
              ...component,
              data: { ...data, length } as CrossFlowHeatExchangerData,
            })
          }
        />
        <FloatNumberInput
          value={data.width}
          onChange={(width) =>
            onChange({
              ...component,
              data: { ...data, width } as CrossFlowHeatExchangerData,
            })
          }
        />
      </div>
    </div>
  );
}

function ComponentExhaustDuctAngular(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as ExhaustDuctAngular;
  return (
    <div className="flex w-64 justify-between">
      <span className="flex h-6 items-center whitespace-nowrap">
        {t("LxBxH (m):")}
      </span>
      <FloatNumberInput
        value={data.length}
        onChange={(length) =>
          onChange({
            ...component,
            data: { ...data, length } as ExhaustDuctAngularData,
          })
        }
      />
      <FloatNumberInput
        value={data.width}
        onChange={(width) =>
          onChange({
            ...component,
            data: { ...data, width } as ExhaustDuctAngularData,
          })
        }
      />
      <FloatNumberInput
        value={data.height}
        onChange={(height) =>
          onChange({
            ...component,
            data: { ...data, height } as ExhaustDuctAngularData,
          })
        }
      />
    </div>
  );
}

function ComponentExhaustDuctRound(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as ExhaustDuctRound;
  return (
    <div className="flex w-64 items-center space-x-4">
      <div className="flex w-40 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Länge (m):")}
        </span>
        <FloatNumberInput
          value={data.length}
          onChange={(length) =>
            onChange({
              ...component,
              data: { ...data, length } as ExhaustDuctRoundData,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("ø (m):")}
        </span>
        <FloatNumberInput
          value={data.diameter}
          onChange={(diameter) =>
            onChange({
              ...component,
              data: { ...data, diameter } as ExhaustDuctRoundData,
            })
          }
          fractions={3}
        />
      </div>
    </div>
  );
}

function ComponentExtraHours(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as ExtraHours;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center whitespace-nowrap">
        {t("Stunden:")}
      </span>
      <FloatNumberInput
        value={data.hours}
        onChange={(hours) =>
          onChange({
            ...component,
            data: { hours } as ExtraHoursData,
          })
        }
      />
    </div>
  );
}

function ComponentFreeText(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as FreeText;
  return (
    <div>
      <div className="mb-2">
        <input
          className="min-w-[200px] rounded border border-gray-300 px-1 py-0.5"
          value={data.text}
          onChange={(e) =>
            onChange({
              ...component,
              data: { ...data, text: e.target.value } as FreeTextData,
            })
          }
        />
      </div>
      <div className="flex w-full justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Euro:")}
        </span>
        <FloatNumberInput
          value={data.price}
          onChange={(price) =>
            onChange({
              ...component,
              data: { ...data, price } as FreeTextData,
            })
          }
          width={32}
          fractions={2}
        />
      </div>
    </div>
  );
}

function ComponentOutdoorAirDuctAngular(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as OutdoorAirDuctAngular;
  return (
    <div className="flex w-64 justify-between">
      <span className="flex h-6 items-center whitespace-nowrap">
        {t("LxBxH (m):")}
      </span>
      <FloatNumberInput
        value={data.length}
        onChange={(length) =>
          onChange({
            ...component,
            data: { ...data, length } as OutdoorAirDuctAngularData,
          })
        }
      />
      <FloatNumberInput
        value={data.width}
        onChange={(width) =>
          onChange({
            ...component,
            data: { ...data, width } as OutdoorAirDuctAngularData,
          })
        }
      />
      <FloatNumberInput
        value={data.height}
        onChange={(height) =>
          onChange({
            ...component,
            data: { ...data, height } as OutdoorAirDuctAngularData,
          })
        }
      />
    </div>
  );
}

function ComponentOutdoorAirDuctRound(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as OutdoorAirDuctRound;
  return (
    <div className="flex w-64 items-center space-x-4">
      <div className="flex w-40 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Länge (m):")}
        </span>
        <FloatNumberInput
          value={data.length}
          onChange={(length) =>
            onChange({
              ...component,
              data: { ...data, length } as OutdoorAirDuctRoundData,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("ø (m):")}
        </span>
        <FloatNumberInput
          value={data.diameter}
          onChange={(diameter) =>
            onChange({
              ...component,
              data: { ...data, diameter } as OutdoorAirDuctRoundData,
            })
          }
          fractions={3}
        />
      </div>
    </div>
  );
}

function ComponentRecirculatingSprayHumidifier(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as RecirculatingSprayHumidifier;
  return (
    <div className="w-64">
      <div className="flex w-64 justify-between">
        <span className="flex h-6 w-32 items-center whitespace-nowrap">
          {t("LxB (m):")}
        </span>
        <div className="space-x-3">
          <FloatNumberInput
            value={data.floorLength}
            onChange={(floorLength) =>
              onChange({
                ...component,
                data: {
                  ...data,
                  floorLength,
                } as RecirculatingSprayHumidifierData,
              })
            }
          />
          <FloatNumberInput
            value={data.floorWidth}
            onChange={(floorWidth) =>
              onChange({
                ...component,
                data: {
                  ...data,
                  floorWidth,
                } as RecirculatingSprayHumidifierData,
              })
            }
          />
        </div>
      </div>
      <div className="flex w-64 justify-between">
        <span className="flex h-6 items-center justify-end whitespace-nowrap">
          {t("Düsen:")}
        </span>
        <IntNumberInput
          value={data.nozzleCount}
          onChange={(nozzleCount) =>
            onChange({
              ...component,
              data: {
                ...data,
                nozzleCount,
              } as RecirculatingSprayHumidifierData,
            })
          }
        />
      </div>
    </div>
  );
}

function ComponentRotaryHeatExchanger(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as RotaryHeatExchanger;
  return (
    <div className="flex w-64 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Durchmesser (m):")}
      </span>
      <FloatNumberInput
        value={data.diameter}
        onChange={(diameter) =>
          onChange({
            ...component,
            data: { ...data, diameter } as RotaryHeatExchangerData,
          })
        }
      />
    </div>
  );
}

function ComponentSplitUnit(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as SplitUnit;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Anzahl:")}
      </span>
      <IntNumberInput
        value={data.count}
        onChange={(count) =>
          onChange({
            ...component,
            data: { ...data, count } as SplitUnitData,
          })
        }
      />
    </div>
  );
}

function ComponentSupplyDuctAngular(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as SupplyDuctAngular;
  return (
    <div className="flex w-64 justify-between">
      <span className="flex h-6 items-center whitespace-nowrap">
        {t("LxBxH (m):")}
      </span>
      <FloatNumberInput
        value={data.length}
        onChange={(length) =>
          onChange({
            ...component,
            data: { ...data, length } as SupplyDuctAngularData,
          })
        }
      />
      <FloatNumberInput
        value={data.width}
        onChange={(width) =>
          onChange({
            ...component,
            data: { ...data, width } as SupplyDuctAngularData,
          })
        }
      />
      <FloatNumberInput
        value={data.height}
        onChange={(height) =>
          onChange({
            ...component,
            data: { ...data, height } as SupplyDuctAngularData,
          })
        }
      />
    </div>
  );
}

function ComponentSupplyDuctRound(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const { data } = component as SupplyDuctRound;
  return (
    <div className="flex w-64 items-center space-x-4">
      <div className="flex w-40 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Länge (m):")}
        </span>
        <FloatNumberInput
          value={data.length}
          onChange={(length) =>
            onChange({
              ...component,
              data: { ...data, length } as SupplyDuctRoundData,
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("ø (m):")}
        </span>
        <FloatNumberInput
          value={data.diameter}
          onChange={(diameter) =>
            onChange({
              ...component,
              data: { ...data, diameter } as SupplyDuctRoundData,
            })
          }
          fractions={3}
        />
      </div>
    </div>
  );
}
