import { Navigate, RouteObject } from "react-router";
import { ReportsIndexRoute } from "@/routes/gesec/processes/[processId]/htz/reports/_components/reports-index-route";
import { ReportRoute } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/report-route";
import { DataRoute } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/data/data-route";
import { PhotosRoute } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/photos/photos-route";
import { VisualRoute } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/visual/visual-route";
import { StructuralRoute } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/structural/structural-route";
import { MicrobialRoute } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/microbial/microbial-route";
import { ItemsRoute } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/items/items-route";
import { AttachmentsRoute } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/attachments/attachments-route";

export const htzReportsRoutes: RouteObject[] = [
  {
    index: true,
    element: <ReportsIndexRoute />,
  },
  {
    path: ":reportId",
    element: <ReportRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" replace />,
      },
      {
        path: "data",
        element: <DataRoute />,
      },
      {
        path: "items",
        element: <ItemsRoute />,
      },
      {
        path: "microbial",
        element: <MicrobialRoute />,
      },
      {
        path: "structural",
        element: <StructuralRoute />,
      },
      {
        path: "visual",
        element: <VisualRoute />,
      },
      {
        path: "photos",
        element: <PhotosRoute />,
      },
      {
        path: "attachments",
        element: <AttachmentsRoute />,
      },
    ],
  },
];
