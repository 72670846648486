import { backendApi } from "@/services/backend/api";
import { LastMinuteRiskAnalysis } from "@/services/backend/vbs/work-orders/last-minute-risk-analysis";
import { Filter } from "@/shared/lib/filter/filter";

const lmraApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    vbsLMRACreate: build.mutation<LastMinuteRiskAnalysis, CreateLMRARequest>({
      query: (request) => ({
        url: `/vbs/lmra`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: ["VbsLMRA"],
    }),
    vbsLMRAShow: build.query<LastMinuteRiskAnalysis, ShowLMRARequest>({
      query: (request) => ({
        url: `/vbs/lmra`,
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsLMRA", id: result.id }] : [],
    }),
    vbsLMRAList: build.query<List, ListRequest>({
      query: (request) => ({
        url: `/vbs/lmra/list`,
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result ? [{ type: "VbsLMRA", id: "LIST" }] : [],
    }),
    vbsLMRASetCheckResult: build.mutation<
      LastMinuteRiskAnalysis,
      SetCheckResult
    >({
      query: (request) => ({
        url: `/vbs/lmra/check/result`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsLMRA", id: request.id },
        "VbsLMRA",
      ],
    }),
    vbsLMRACommentCheck: build.mutation<
      LastMinuteRiskAnalysis,
      CommentCheckRequest
    >({
      query: (request) => ({
        url: `/vbs/lmra/check/comment`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsLMRA", id: request.id },
        "VbsLMRA",
      ],
    }),
    vbsLMRAConfirm: build.mutation<LastMinuteRiskAnalysis, ConfirmLMRARequest>({
      query: (request) => ({
        url: `/vbs/lmra/confirm`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsLMRA", id: request.id },
        "VbsLMRA",
      ],
    }),
    vbsLMRAUnconfirm: build.mutation<
      LastMinuteRiskAnalysis,
      UnconfirmLMRARequest
    >({
      query: (request) => ({
        url: `/vbs/lmra/unconfirm`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "VbsLMRA", id: request.id },
        "VbsLMRA",
      ],
    }),
    vbsLMRADelete: build.mutation<LastMinuteRiskAnalysis, DeleteLMRARequest>({
      query: (request) => ({
        url: `/vbs/lmra`,
        method: "DELETE",
        params: request,
      }),
      invalidatesTags: ["VbsLMRA"],
    }),
  }),
});

export const {
  useVbsLMRACreateMutation,
  useVbsLMRAShowQuery,
  useVbsLMRAListQuery,
  useVbsLMRASetCheckResultMutation,
  useVbsLMRACommentCheckMutation,
  useVbsLMRAConfirmMutation,
  useVbsLMRAUnconfirmMutation,
  useVbsLMRADeleteMutation,
} = lmraApi;

export interface CreateLMRARequest {
  id: string;
  processId: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface ShowLMRARequest {
  id: string;
}

export interface List {
  lmras: LastMinuteRiskAnalysis[];
  count: number;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  process?: Filter<string>;
  immutable?: Filter<boolean>;
  limit?: number;
  offset?: number;
}

export interface SetCheckResult {
  id: string;
  checkId: string;
  result: null | number;
}

export interface CommentCheckRequest {
  id: string;
  checkId: string;
  comment: string;
}

export interface ConfirmLMRARequest {
  id: string;
  conductedBy: string;
  confirmedBy: string;
}

export interface UnconfirmLMRARequest {
  id: string;
}

export interface DeleteLMRARequest {
  id: string;
}
