import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Button } from "@/shared/components/ui/button";
import { Search } from "lucide-react";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { useRltContractListQuery } from "@/services/backend/rlt/contracts/service";
import { Card } from "@/shared/components/ui/card";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { oneLine } from "@/services/backend/addresses/address/address";
import { useRltOfferShowQuery } from "@/services/backend/rlt/offers/service";
import { Badge } from "@/shared/components/ui/badge";
import { Contract } from "@/services/backend/rlt/contracts/contract";
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import {
  Tabs,
  TabsContent,
  TabsList,
  TabsTrigger,
} from "@/shared/components/ui/tabs";
import { ContractBaseData } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-base-data";
import { ContractPositions } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-positions";
import { ContractAddresses } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-addresses";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import { ContractComments } from "@/routes/gesec/processes/[processId]/rlt/offers/[offerId]/contract/_components/contract-comments";

export function ContractList({
  processId,
  offerId,
}: {
  processId: string;
  offerId: string;
}) {
  const { data: process } = useProcessesProcessShowQuery({
    id: processId,
  });
  const { data: offer } = useRltOfferShowQuery({
    id: offerId,
  });
  const {
    data: list,
    isLoading,
    error,
  } = useRltContractListQuery({
    company: {
      active: true,
      values: [process?.companyId ?? ""],
    },
    customer: {
      active: true,
      values: [process?.customerId ?? ""],
    },
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Skeleton />;
  }

  return (
    <Card>
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Laufzeit")}</TableHead>
            <TableHead className="w-full">{t("Objekt")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {list?.contracts.map((contract) => (
            <TableRow key={contract.id}>
              <TableCell className="whitespace-nowrap">
                <span>
                  {new Date(contract.lifetime.startDate).toLocaleDateString()}
                </span>
                <span className="mx-1">-</span>
                <span>
                  {contract.lifetime.unlimited
                    ? t("unbegrenzt")
                    : new Date(contract.lifetime.endDate!).toLocaleDateString()}
                </span>
              </TableCell>
              <TableCell>
                {contract.id === offer?.id && (
                  <Badge className="mr-4">
                    {t("Vertrag zum aktuellen Angebot")}
                  </Badge>
                )}
                {oneLine(contract.serviceAddress)}
              </TableCell>
              <TableCell className="py-0">
                <div className="flex items-center space-x-2">
                  <ContractDetailsDialog contract={contract} />
                </div>
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function Skeleton() {
  return (
    <Card className="animate-pulse">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead>{t("Laufzeit")}</TableHead>
            <TableHead className="w-full">{t("Objekt")}</TableHead>
            <TableHead>{t("Aktionen")}</TableHead>
          </TableRow>
        </TableHeader>
      </Table>
    </Card>
  );
}

function ContractDetailsDialog({ contract }: { contract: Contract }) {
  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button variant="outline" size="sm">
          <Search className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent className="max-h-[90vh] min-w-[90vw] overflow-x-auto">
        <DialogHeader>
          <DialogTitle className="flex space-x-4">
            <span>{t("Vertrag")}</span>
            <PersistenceStateUsingContext />
          </DialogTitle>
        </DialogHeader>
        <Tabs defaultValue="data">
          <TabsList className="grid w-full grid-cols-4">
            <TabsTrigger value="data">{t("Vertragsdaten")}</TabsTrigger>
            <TabsTrigger value="treatment">{t("Leistungen")}</TabsTrigger>
            <TabsTrigger value="addresses">{t("Adressen")}</TabsTrigger>
            <TabsTrigger value="comments">{t("Kommentare")}</TabsTrigger>
          </TabsList>
          <TabsContent value="data">
            <ContractBaseData contract={contract} />
          </TabsContent>
          <TabsContent value="treatment">
            <ContractPositions contract={contract} />
          </TabsContent>
          <TabsContent value="addresses">
            <ContractAddresses contract={contract} />
          </TabsContent>
          <TabsContent value="comments">
            <ContractComments contract={contract} />
          </TabsContent>
        </Tabs>
      </DialogContent>
    </Dialog>
  );
}
