import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { provideMSGraphToken } from "@/shared/lib/authorization/provice-ms-graph-token";

export const msGraphApi = createApi({
  reducerPath: "msGraphApi",
  baseQuery: fetchBaseQuery({
    baseUrl: (() => {
      if (import.meta.env.VITE_AUTH === "msal") {
        return "https://graph.microsoft.com/v1.0/";
      }
      // see note in provideMSGraphToken.ts
      // this will just cause some error in rtk query
      return "";
    })(),
    prepareHeaders: async (headers) => {
      const accessToken: string = await provideMSGraphToken();
      headers.set("Authorization", `Bearer ${accessToken}`);
      return headers;
    },
  }),
  tagTypes: ["Users"],
  endpoints: (build) => ({
    showUser: build.query<MsUserData, ShowMsUserRequest>({
      query: (request) => ({
        url: `users/${request.id}?$select=id,displayName`,
      }),
      providesTags: (result) =>
        result ? [{ type: "Users", id: result.id }] : [],
    }),
  }),
});

export const { useShowUserQuery } = msGraphApi;

export interface MsUserData {
  id: string;
  displayName: string;
}

export interface ShowMsUserRequest {
  id: string;
}
