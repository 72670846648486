import { PestTypeKey } from "@/services/backend/qpm/shared/enums";

export class PestTypeUtil {
  private static descriptions: { [key in PestTypeKey]: string } = {
    [PestTypeKey.MouseNonTox]: "Fraßköder für Mäuse ohne Wirkstoff",
    [PestTypeKey.MouseTox]: "Fraßköder für Mäuse mit Wirkstoff",
    [PestTypeKey.RatNonTox]: "Fraßköder für Ratten ohne Wirkstoff",
    [PestTypeKey.RatTox]: "Fraßköder für Mäuse und Ratten mit Wirkstoff",
    [PestTypeKey.MouseTrapALoRa]: "Mäuseschlagfalle ALoRa",
    [PestTypeKey.RatTrapALoRa]: "Rattenschlagfalle ALoRa",
    [PestTypeKey.CrawlingInsects]: "Indikaor für Kriechinsekten wie Schaben",
    [PestTypeKey.BeetleAttractant]:
      "Lockstofffalle für vorratsschädigende Käfer",
    [PestTypeKey.MothTrapPheromone]: "Pheromonfalle für Motten",
    [PestTypeKey.FlyExterminator]: "UV-Fluginsektenvernichter",
    [PestTypeKey.BirdVisualCheckPoint]: "Visueller Prüfpunkt für Vögel",
  };

  private static abbreviations: { [key in PestTypeKey]: string } = {
    [PestTypeKey.MouseNonTox]: "MOW",
    [PestTypeKey.MouseTox]: "M",
    [PestTypeKey.RatNonTox]: "ROW",
    [PestTypeKey.RatTox]: "MR",
    [PestTypeKey.MouseTrapALoRa]: "MSA",
    [PestTypeKey.RatTrapALoRa]: "RSA",
    [PestTypeKey.CrawlingInsects]: "S",
    [PestTypeKey.BeetleAttractant]: "RMK/K",
    [PestTypeKey.MothTrapPheromone]: "MO",
    [PestTypeKey.FlyExterminator]: "FIV",
    [PestTypeKey.BirdVisualCheckPoint]: "VV",
  };

  static toString(type: PestTypeKey): string {
    return this.abbreviations[type];
  }

  static toLongString(type: PestTypeKey): string {
    return this.descriptions[type] || "Unknown";
  }
}
