import { backendApi } from "@/services/backend/api";
import {
  Contract,
  Lifetime,
  List,
  State,
} from "@/services/backend/vbs/contracts/contract";
import { Filter } from "@/shared/lib/filter/filter";
import { Address } from "@/services/backend/addresses/address/address";
import t from "@/lang/lang";

const contractApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    rltContractCreate: build.mutation<Contract, CreateRequest>({
      query: (request) => ({
        url: `/rlt/contract`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "RltContract", id: "LIST" }],
    }),
    rltContractCreateForOffer: build.mutation<Contract, CreateForOfferRequest>({
      query: (request) => ({
        url: `/rlt/contract/for-offer`,
        method: "POST",
        body: request,
      }),
      invalidatesTags: [{ type: "RltContract", id: "LIST" }, "RltOffer"],
    }),
    rltContractShow: build.query<Contract, ShowRequest>({
      query: (request) => ({
        url: "/rlt/contract",
        method: "GET",
        params: request,
      }),
      providesTags: (result) =>
        result ? [{ type: "RltContract", id: result.id }] : [],
    }),
    rltContractList: build.query<List, Partial<ListRequest>>({
      query: (request) => ({
        url: "/rlt/contract/list",
        method: "GET",
        params: {
          request: btoa(JSON.stringify(request)),
        },
      }),
      providesTags: (result) =>
        result
          ? [
              ...result.contracts.map((contract) => ({
                type: "RltContract" as const,
                id: contract.id,
              })),
              { type: "RltContract", id: "LIST" },
            ]
          : [{ type: "RltContract", id: "LIST" }],
    }),
    rltContractCopyFromOffer: build.mutation<Contract, CopyFromOfferRequest>({
      query: (request) => ({
        url: `/rlt/contract/copy-from-offer`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
        "RltCalculation",
        "RltTreatment",
      ],
    }),
    rltContractUpdateLifetime: build.mutation<Contract, UpdateLifetimeRequest>({
      query: (request) => ({
        url: `/rlt/contract/lifetime`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractRegisterScan: build.mutation<
      Contract,
      RegisterSignedContractRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/register-signed-contract`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractTerminate: build.mutation<Contract, TerminateRequest>({
      query: (request) => ({
        url: `/rlt/contract/terminate`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractUpdateCustomerAddress: build.mutation<
      Contract,
      UpdateCustomerAddressRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/customer-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractUpdateServiceAddress: build.mutation<
      Contract,
      UpdateServiceAddressRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/service-address`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractUpdateSalesDiscount: build.mutation<
      Contract,
      UpdateSalesDiscountRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/sales-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractUpdateAbsoluteDiscount: build.mutation<
      Contract,
      UpdateAbsoluteDiscountRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/absolute-discount`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractUpdatePaymentTerms: build.mutation<
      Contract,
      UpdatePaymentTermsRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/payment-terms`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractUpdateSpecialAgreements: build.mutation<
      Contract,
      UpdateSpecialAgreementsRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/special-agreements`,
        method: "PATCH",
        body: request,
      }),
      invalidatesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractPreviewPDF: build.query<
      { objectURL: string },
      PreviewPDFRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/preview-pdf`,
        method: "GET",
        params: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          return {
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this query will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      providesTags: (_r, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
    rltContractDownloadPDF: build.mutation<
      { filename: string; objectURL: string },
      DownloadPDFRequest
    >({
      query: (request) => ({
        url: `/rlt/contract/download-pdf`,
        method: "PATCH",
        body: request,
        responseHandler: async (response) => {
          if (response.status !== 200) {
            return response.json();
          }
          // attempt to retrieve the filename from content-disposition header
          const contentDisposition = response.headers.get(
            "Content-Disposition",
          );
          // fallback filename
          let filename = t("Vertrag.pdf");
          if (
            contentDisposition &&
            contentDisposition.indexOf("attachment") !== -1
          ) {
            const filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            const matches = filenameRegex.exec(contentDisposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          return {
            filename,
            objectURL: window.URL.createObjectURL(await response.blob()),
          };
        },
        // IMPORTANT. Since this mutation will download the PDF under no circumstances
        // should the results be cached. This could end up using the users memory.
        cache: "no-cache",
      }),
      invalidatesTags: (_result, _e, request) => [
        { type: "RltContract", id: request.contractId },
      ],
    }),
  }),
});

export const {
  useRltContractCreateMutation,
  useRltContractCreateForOfferMutation,
  useRltContractShowQuery,
  useRltContractListQuery,
  useRltContractCopyFromOfferMutation,
  useRltContractUpdateLifetimeMutation,
  useRltContractRegisterScanMutation,
  useRltContractTerminateMutation,
  useRltContractUpdateCustomerAddressMutation,
  useRltContractUpdateServiceAddressMutation,
  useRltContractUpdateSalesDiscountMutation,
  useRltContractUpdateAbsoluteDiscountMutation,
  useRltContractUpdatePaymentTermsMutation,
  useRltContractUpdateSpecialAgreementsMutation,
  useRltContractPreviewPDFQuery,
  useRltContractDownloadPDFMutation,
} = contractApi;

export interface CreateRequest {
  id: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface CreateForOfferRequest {
  id: string;
  offerId: string;
  companyId: string;
  branchId: string;
  customerId: string;
}

export interface ShowRequest {
  contractId: string;
}

export interface ListRequest {
  customer?: Filter<string>;
  company?: Filter<string>;
  branch?: Filter<string>;
  state?: Filter<State>;
  lifetimeStartDate?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface CopyFromOfferRequest {
  contractId: string;
  offerId: string;
  copyCalculation: boolean;
  copyDiscounts: boolean;
  copyAddresses: boolean;
}

export interface UpdateLifetimeRequest {
  contractId: string;
  lifetime: Lifetime;
}

export interface RegisterSignedContractRequest {
  contractId: string;
  file: File;
  dataFormat: string;
  scannedBy: string;
}

export interface TerminateRequest {
  contractId: string;
  terminationReason: string;
}

export interface UpdateCustomerAddressRequest {
  contractId: string;
  customerAddress: Address;
}

export interface UpdateServiceAddressRequest {
  contractId: string;
  serviceAddress: Address;
}

export interface UpdateSalesDiscountRequest {
  contractId: string;
  active: boolean;
  percentage: number;
}

export interface UpdateAbsoluteDiscountRequest {
  contractId: string;
  active: boolean;
  amount: number;
}

export interface UpdatePaymentTermsRequest {
  contractId: string;
  terms: string;
}

export interface UpdateSpecialAgreementsRequest {
  contractId: string;
  agreements: string;
}

export interface PreviewPDFRequest {
  contractId: string;
  document: ContractDocument;
}

export interface DownloadPDFRequest {
  contractId: string;
  document: ContractDocument;
  downloadBy: string;
}

export enum ContractDocument {
  CONTRACT = "contract",
  TREATMENT = "treatment",
}
