import { useState } from "react";
import { useParams } from "react-router";
import { ServiceAddressCardWithMutation } from "@/shared/components/domain/addresses/service-address-card";
import { WorkOrder } from "@/services/backend/vbs/work-orders/work-order";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  UpdateBeginDateRequest,
  UpdateCommentRequest,
  UpdateEndDateRequest,
  UpdateExecutingBranchRequest,
  UpdateStateRequest,
  UpdateTeamLeaderRequest,
  useVbsWorkOrderShowQuery,
  useVbsWorkOrderUpdateBeginDateMutation,
  useVbsWorkOrderUpdateCommentMutation,
  useVbsWorkOrderUpdateEndDateMutation,
  useVbsWorkOrderUpdateExecutingBranchMutation,
  useVbsWorkOrderUpdateRepresentativeMutation,
  useVbsWorkOrderUpdateServiceAddressMutation,
  useVbsWorkOrderUpdateServiceContactMutation,
  useVbsWorkOrderUpdateStateMutation,
  useVbsWorkOrderUpdateTeamLeaderMutation,
} from "@/services/backend/vbs/work-orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { ContactCardWithMutation } from "@/shared/components/domain/contacts/contact-card";
import { RepresentativeCardWithMutation } from "@/shared/components/domain/representatives/representative-card";
import t from "@/lang/lang";
import {
  errorsFor,
  fieldErrorsForPrefix,
  useErrArtefactNotReady,
} from "@/shared/service-manager/artefact/err-artefact-not-ready";
import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { State, StateInfo } from "@/services/backend/vbs/work-orders/state";
import { Label } from "@/shared/components/ui/label";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { useListBranchQuery } from "@/services/backend/branch/branch";
import { DateInput } from "@/shared/components/form/date-input";
import { fieldErrors } from "@/shared/app-lib/errors/validation-error";
import { TextInput } from "@/shared/components/form/text-input";
import { TextareaInput } from "@/shared/components/form/textarea-input";

export function WorkOrderDataRoute() {
  const { workOrderId } = useParams();
  const {
    data: workOrder,
    isLoading,
    error,
  } = useVbsWorkOrderShowQuery({
    id: workOrderId!,
  });

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (!workOrder) {
    return <div />;
  }

  return <WorkOrderDataCard workOrder={workOrder} />;
}

export function WorkOrderDataCard({ workOrder }: { workOrder: WorkOrder }) {
  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="grid gap-2">
      <WorkOrderBaseDataCard workOrder={workOrder} />
      <ServiceAddressCardWithMutation
        id={workOrder!.id}
        address={workOrder!.serviceAddress}
        mutation={useVbsWorkOrderUpdateServiceAddressMutation}
        fieldErrors={fieldErrorsForPrefix(notReadyError, "serviceAddress.")}
        resetFieldError={(field) =>
          resetNotReadyErrorField(`serviceAddress.${field}`)
        }
      />
      <div className="md:grid md:grid-cols-2 md:gap-2">
        <ContactCardWithMutation
          id={workOrder!.id}
          contact={workOrder!.serviceContact}
          mutation={useVbsWorkOrderUpdateServiceContactMutation}
          fieldErrors={fieldErrorsForPrefix(notReadyError, "contact.")}
          resetFieldError={(field) =>
            resetNotReadyErrorField(`contact.${field}`)
          }
        />
        <RepresentativeCardWithMutation
          id={workOrder!.id}
          representative={workOrder!.representative}
          mutation={useVbsWorkOrderUpdateRepresentativeMutation}
          fieldErrors={fieldErrorsForPrefix(notReadyError, "representative.")}
          resetFieldError={(field) =>
            resetNotReadyErrorField(`representative.${field}`)
          }
        />
      </div>
    </div>
  );
}

function WorkOrderBaseDataCard({ workOrder }: { workOrder: WorkOrder }) {
  return (
    <Card>
      <CardHeader>
        <CardTitle>{t("Arbeitsschein")}</CardTitle>
      </CardHeader>
      <CardContent className="grid gap-4">
        <div className="grid gap-4 xl:grid-cols-2">
          <StateSelect workOrder={workOrder} />
          <ExecutingBranchSelect workOrder={workOrder} />
          <BeginDateInput workOrder={workOrder} />
          <EndDateInput workOrder={workOrder} />
        </div>
        <TeamLeaderInput workOrder={workOrder} />
        <CommentInput workOrder={workOrder} />
      </CardContent>
    </Card>
  );
}

function StateSelect({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<UpdateStateRequest>({
    id: workOrder.id,
    state: workOrder.state,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsWorkOrderUpdateStateMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  return (
    <div className="grid gap-1.5">
      <Label>{t("Status")}</Label>
      <Select
        value={request.state}
        onValueChange={(state) => {
          setRequest({ ...request, state: state as State });
          resetNotReadyErrorField("state");
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t("Auswählen...")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{t("Status")}</SelectLabel>
            {Object.values(State).map((state) => (
              <SelectItem key={state} value={state}>
                <StateInfo state={state} withDescription />
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputValidationErrors error={error || notReadyError} field="state" />
    </div>
  );
}

function ExecutingBranchSelect({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<UpdateExecutingBranchRequest>({
    id: workOrder.id,
    executingBranchId: workOrder.executingBranchId,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsWorkOrderUpdateExecutingBranchMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    update,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const { data: list } = useListBranchQuery({ companyId: workOrder.companyId });
  const branches = list?.branches ?? [];

  return (
    <div className="grid gap-1.5">
      <Label>{t("Ausführende Niederlassung")}</Label>
      <Select
        value={request.executingBranchId}
        onValueChange={(executingBranchId) => {
          setRequest({ ...request, executingBranchId });
          resetNotReadyErrorField("executingBranchId");
        }}
      >
        <SelectTrigger className="w-full">
          <SelectValue placeholder={t("Auswählen...")} />
        </SelectTrigger>
        <SelectContent>
          <SelectGroup>
            <SelectLabel>{t("Niederlassung")}</SelectLabel>
            {branches.map((branch) => (
              <SelectItem key={branch.id} value={branch.id}>
                {branch.name}
              </SelectItem>
            ))}
          </SelectGroup>
        </SelectContent>
      </Select>
      <InputValidationErrors
        error={error || notReadyError}
        field="executingBranchId"
      />
    </div>
  );
}

function BeginDateInput({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<UpdateBeginDateRequest>({
    id: workOrder.id,
    beginDate: workOrder.beginDate,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useVbsWorkOrderUpdateBeginDateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const errors = [
    ...(errorsFor(notReadyError, "beginDate") ?? []),
    ...(fieldErrors(error, "beginDate") ?? []),
  ];

  return (
    <DateInput
      label={t("Beginn der Arbeiten")}
      date={request.beginDate}
      onDateChange={(beginDate) => {
        setRequest({ ...request, beginDate: beginDate ?? "" });
        resetNotReadyErrorField("beginDate");
      }}
      errors={errors}
    />
  );
}

function EndDateInput({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<UpdateEndDateRequest>({
    id: workOrder.id,
    endDate: workOrder.endDate,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useVbsWorkOrderUpdateEndDateMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const errors = [
    ...(errorsFor(notReadyError, "endDate") ?? []),
    ...(fieldErrors(error, "endDate") ?? []),
  ];

  return (
    <DateInput
      label={t("Ende der Arbeiten")}
      date={request.endDate}
      onDateChange={(endDate) => {
        setRequest({ ...request, endDate: endDate ?? "" });
        resetNotReadyErrorField("endDate");
      }}
      errors={errors}
    />
  );
}

function TeamLeaderInput({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<UpdateTeamLeaderRequest>({
    id: workOrder.id,
    teamLeaderId: workOrder.teamLeaderId,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useVbsWorkOrderUpdateTeamLeaderMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const errors = [
    ...(errorsFor(notReadyError, "teamLeaderId") ?? []),
    ...(fieldErrors(error, "teamLeaderId") ?? []),
  ];

  return (
    <TextInput
      label={t("Teamleiter")}
      field="teamLeaderId"
      text={request.teamLeaderId}
      onTextChange={(teamLeaderId) => {
        setRequest({ ...request, teamLeaderId });
        resetNotReadyErrorField("teamLeaderId");
      }}
      errors={errors}
    />
  );
}

export function CommentInput({ workOrder }: { workOrder: WorkOrder }) {
  const [request, setRequest] = useState<UpdateCommentRequest>({
    id: workOrder.id,
    comment: workOrder.comment,
  });
  const [updateDate, { isLoading, error, isSuccess, reset }] =
    useVbsWorkOrderUpdateCommentMutation();

  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    updateDate,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
  );

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();

  const errors = [
    ...(errorsFor(notReadyError, "comment") ?? []),
    ...(fieldErrors(error, "comment") ?? []),
  ];

  return (
    <TextareaInput
      label={t("Kommentar")}
      field="comment"
      text={request.comment}
      onTextChange={(comment) => {
        setRequest({ ...request, comment });
        resetNotReadyErrorField("comment");
      }}
      errors={errors}
    />
  );
}
