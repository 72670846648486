import { Outlet } from "react-router";
import {
  BaseDataPage,
  BaseDataPageContent,
  BaseDataPageHeader,
  BaseDataPageTitle,
} from "@/routes/base-data/_components/layout/base-data-page";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import {
  TabsNav,
  TabsNavLink,
  TabsNavList,
} from "@/shared/components/layout/tabs-nav";
import t from "@/lang/lang";

export function QpmBaseDataRoute() {
  return (
    <PersistenceContextProvider>
      <BaseDataPage>
        <BaseDataPageHeader>
          <BaseDataPageTitle className="flex justify-start gap-3">
            <span>
              <span className="text-primary">Q</span>uality
            </span>
            <span>
              <span className="text-primary">P</span>est{" "}
            </span>
            <span>
              <span className="text-primary">M</span>anagement
            </span>
            <span>
              <span className="text-primary">QPM&reg;</span>
            </span>
          </BaseDataPageTitle>
        </BaseDataPageHeader>
        <BaseDataPageContent>
          <TabsNav>
            <TabsNavList>
              <TabsNavLink to="base-data">{t("Basisdaten")}</TabsNavLink>
              <TabsNavLink to="catalog">{t("Monitorkataloge")}</TabsNavLink>
              <TabsNavLink to="calculation-parameters">
                {t("Kalkulation Parameter")}
              </TabsNavLink>
            </TabsNavList>
          </TabsNav>
          <Outlet />
        </BaseDataPageContent>
      </BaseDataPage>
    </PersistenceContextProvider>
  );
}
