import { Process } from "@/services/backend/processes/process/process";
import {
  ColumnDef,
  flexRender,
  getCoreRowModel,
  getPaginationRowModel,
  getSortedRowModel,
  SortingState,
  Table as ReactTable,
  useReactTable,
  VisibilityState,
} from "@tanstack/react-table";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import {
  ListRequest,
  useProcessesProcessListQuery,
} from "@/services/backend/processes/process/service";
import { HTMLAttributes, useState } from "react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { NavLink } from "react-router";
import {
  Pagination,
  PaginationContent,
  PaginationItem,
} from "@/shared/components/ui/pagination";
import { Button } from "@/shared/components/ui/button";
import { ArrowUpDown, ChevronLeft, ChevronRight, Search } from "lucide-react";
import { BranchFilter } from "@/routes/gemex/processes/components/branch-filter";
import { ProcessKindFilter } from "@/routes/gemex/processes/components/process-kind-filter";
import { cn } from "@/shared/lib/utils";
import { ColumnFilter } from "@/routes/gemex/processes/components/column-filter";
import { NewProcessDialog } from "@/routes/gemex/processes/components/new-process-dialog";
import { CustomerName } from "@/shared/components/domain/customer/customer-name";
import { ProcessNumber } from "@/shared/components/domain/numbers/process-number";
import { BranchName } from "@/shared/components/domain/branch/branch-name";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";
import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";

const tableColumns: ColumnDef<Process>[] = [
  {
    id: t("Dienstleistung"),
    accessorKey: "kind",
    header: ({ column }) => (
      <Button
        variant="ghost"
        onClick={() => column.toggleSorting(column.getIsSorted() === "asc")}
      >
        <span>{t("DL")}</span>
        <ArrowUpDown className="ml-2 h-4 w-4" />
      </Button>
    ),
    cell: ({ row }) => {
      const processData = row.original;
      return <div className="ml-4">{t(processData.kind)}</div>;
    },
  },
  {
    id: t("Vorgangs-Nr."),
    header: t("Vorgangs-Nr."),
    cell: ({ row }) => {
      const processData = row.original;
      return (
        <span className="font-mono">
          <ProcessNumber processId={processData.id} />
        </span>
      );
    },
  },
  {
    id: t("Thema"),
    accessorKey: "topic",
    header: t("Thema"),
  },
  {
    id: t("Kunde"),
    accessorKey: "customerId",
    header: t("Kunde"),
    cell: ({ row }) => {
      const processData = row.original;
      return <CustomerName customerId={processData.customerId} />;
    },
  },
  {
    id: t("Niederlassung"),
    accessorKey: "branchId",
    header: t("Niederlassung"),
    cell: ({ row }) => {
      const processData = row.original;
      return <BranchName branchId={processData.branchId} />;
    },
  },
  {
    id: t("Aktionen"),
    header: t("Aktionen"),
    cell: ({ row }) => {
      const processData = row.original;

      return (
        <NavLink to={`${processData.kind.toLowerCase()}/${processData.id}`}>
          <Button variant="ghost" size="sm" className="-mt-1.5 -mb-2.5">
            <Search className="h-4 w-4" />
          </Button>
        </NavLink>
      );
    },
  },
];

export function ProcessListTable() {
  const { companyId } = useActiveCompany();
  const [request, setRequest] = useState<ListRequest>({
    kind: {
      active: false,
      values: [],
    },
    topic: {
      active: false,
      values: [],
    },
    customer: {
      active: false,
      values: [],
    },
    company: {
      active: true,
      values: [companyId],
    },
    branch: {
      active: false,
      values: [],
    },
    state: {
      active: false,
      values: [],
    },
    limit: 50,
    offset: 0,
  });
  const {
    data: list,
    isLoading,
    isFetching,
    error,
    refetch,
  } = useProcessesProcessListQuery(request, {
    refetchOnMountOrArgChange: true,
  });

  const table = useDataTableController(tableColumns, list?.processes || []);

  if (isLoading || isFetching) {
    return <DataTableSkeleton table={table} columns={tableColumns} />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const update = (newRequest: ListRequest) => {
    setRequest(newRequest);
    refetch();
  };

  return (
    <DataTableContainer>
      <DataTableFilters className="flex justify-between space-x-2">
        <div className="space-x-2">
          <ProcessKindFilter
            filter={request.kind!}
            onChange={(value) => update({ ...request, kind: value })}
          />
          <BranchFilter
            filter={request.branch!}
            onChange={(value) => update({ ...request, branch: value })}
          />
        </div>
        <div className="flex items-center space-x-2">
          <ColumnFilter table={table} />
          <NewProcessDialog />
        </div>
      </DataTableFilters>
      <DataTable table={table} columns={tableColumns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  );
}

function DataTableSkeleton<TData>(props: {
  table: ReactTable<TData>;
  columns: ColumnDef<TData>[];
}) {
  const { table, columns } = props;
  return (
    <DataTableContainer className="animate-pulse">
      <DataTableFilters className="flex justify-between space-x-2">
        <div className="space-x-2">
          <ProcessKindFilter
            filter={{ active: false, values: [] }}
            onChange={() => null}
          />
          <BranchFilter
            filter={{ active: false, values: [] }}
            onChange={() => null}
          />
        </div>
        <ColumnFilter table={table} />
      </DataTableFilters>
      <DataTable table={table} columns={columns} />
      <DataTableBrowserPagination table={table} />
    </DataTableContainer>
  );
}

export interface DataTableContainer extends HTMLAttributes<HTMLDivElement> {}

export function DataTableContainer({
  children,
  className,
  ...props
}: DataTableContainer) {
  return (
    <div className={cn("space-y-4", className)} {...props}>
      {children}
    </div>
  );
}

export interface FiltersProps extends HTMLAttributes<HTMLDivElement> {}

export function DataTableFilters({
  children,
  className,
  ...props
}: FiltersProps) {
  return (
    <div className={cn(className)} {...props}>
      {children}
    </div>
  );
}

export function useDataTableController<TData, TValue>(
  columns: ColumnDef<TData, TValue>[],
  data: TData[],
): ReactTable<TData> {
  const [columnVisibility, setColumVisibility] = useState<VisibilityState>({});
  const [sorting, setSorting] = useState<SortingState>([]);

  return useReactTable({
    data,
    columns,
    getCoreRowModel: getCoreRowModel(),
    getPaginationRowModel: getPaginationRowModel(),
    onColumnVisibilityChange: setColumVisibility,
    onSortingChange: setSorting,
    getSortedRowModel: getSortedRowModel(),
    state: {
      columnVisibility,
      sorting,
    },
  });
}

export interface DataTableProps<TData, TValue> {
  columns: ColumnDef<TData, TValue>[];
  table: ReactTable<TData>;
}

export function DataTable<TData, TValue>({
  table,
  columns,
}: DataTableProps<TData, TValue>) {
  return (
    <div>
      <div className="rounded-md border">
        <Table>
          <TableHeader>
            {table.getHeaderGroups().map((headerGroup) => (
              <TableRow key={headerGroup.id}>
                {headerGroup.headers.map((header) => (
                  <TableHead key={header.id}>
                    {header.isPlaceholder
                      ? null
                      : flexRender(
                          header.column.columnDef.header,
                          header.getContext(),
                        )}
                  </TableHead>
                ))}
              </TableRow>
            ))}
          </TableHeader>
          <TableBody>
            {table.getRowModel().rows?.length ? (
              table.getRowModel().rows.map((row) => (
                <TableRow
                  key={row.id}
                  data-state={row.getIsSelected() && "selected"}
                >
                  {row.getVisibleCells().map((cell) => (
                    <TableCell key={cell.id}>
                      {flexRender(
                        cell.column.columnDef.cell,
                        cell.getContext(),
                      )}
                    </TableCell>
                  ))}
                </TableRow>
              ))
            ) : (
              <TableRow>
                <TableCell
                  colSpan={columns.length}
                  className="h-24 text-center"
                >
                  {t("Keine Ergebnisse")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </Table>
      </div>
    </div>
  );
}

export interface DataTableBrowserPaginationProps<TData> {
  table: ReactTable<TData>;
}

export function DataTableBrowserPagination<TData>({
  table,
}: DataTableBrowserPaginationProps<TData>) {
  return (
    <div className="grid grid-cols-3">
      <div className="flex w-[100px] items-center justify-center text-sm font-medium">
        {t("Seite")} {table.getState().pagination.pageIndex + 1} {t("von")}{" "}
        {table.getPageCount()}
      </div>
      <Pagination>
        <PaginationContent>
          <PaginationItem>
            <Button
              variant="ghost"
              onClick={() => table.previousPage()}
              disabled={!table.getCanPreviousPage()}
            >
              <ChevronLeft className="mr-2 h-4 w-4" />
              <span>{t("Vorherige")}</span>
            </Button>
          </PaginationItem>
          {/* FIRST PAGE */}
          <PaginationItem>
            <Button
              variant={
                table.getState().pagination.pageIndex === 0
                  ? "outline"
                  : "ghost"
              }
              onClick={() => table.setPageIndex(0)}
            >
              {1}
            </Button>
          </PaginationItem>
          {/* MIDDLE PAGES */}
          {[
            ...Array(
              table.getPageCount() - 2 > 0 ? table.getPageCount() - 2 : 0,
            ).keys(),
          ].map((i) => (
            <PaginationItem key={i + 1}>
              <Button
                variant={
                  table.getState().pagination.pageIndex === i + 1
                    ? "outline"
                    : "ghost"
                }
                onClick={() => table.setPageIndex(i + 1)}
              >
                {i + 2}
              </Button>
            </PaginationItem>
          ))}
          {/* LAST PAGE */}
          {table.getPageCount() > 1 && (
            <PaginationItem>
              <Button
                variant={
                  table.getState().pagination.pageIndex ===
                  table.getPageCount() - 1
                    ? "outline"
                    : "ghost"
                }
                onClick={() => table.setPageIndex(table.getPageCount() - 1)}
              >
                {table.getPageCount()}
              </Button>
            </PaginationItem>
          )}
          <PaginationItem>
            <Button
              variant="ghost"
              onClick={() => table.nextPage()}
              disabled={!table.getCanNextPage()}
            >
              <span>{t("Nächste")}</span>
              <ChevronRight className="ml-2 h-4 w-4" />
            </Button>
          </PaginationItem>
        </PaginationContent>
      </Pagination>
      <div className="inline-flex items-center justify-end space-x-2">
        <p className="text-sm font-medium">{t("Zeilen pro Seite")}</p>
        <Select
          value={`${table.getState().pagination.pageSize}`}
          onValueChange={(value) => {
            table.setPageSize(Number(value));
          }}
        >
          <SelectTrigger className="h-8 w-[70px]">
            <SelectValue placeholder={table.getState().pagination.pageSize} />
          </SelectTrigger>
          <SelectContent side="top">
            {[10, 20, 30, 40, 50].map((pageSize) => (
              <SelectItem key={pageSize} value={`${pageSize}`}>
                {pageSize}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>
    </div>
  );
}
