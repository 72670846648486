import { useQpmConsumableListQuery } from "@/services/backend/qpm/monitors/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { Button } from "@/shared/components/ui/button";
import { Pencil } from "lucide-react";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { ConsumableTypeUtil } from "@/services/backend/qpm/shared/consumableTypeUtil";

export function ConsumableCatalog({ catalogId }: { catalogId: string }) {
  const {
    data: consumableList,
    isLoading,
    error,
  } = useQpmConsumableListQuery({
    catalogId,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <ConsumableLoadingPage />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Name")}</TableHead>
          <TableHead>{t("Typ")}</TableHead>
          <TableHead className="text-center">{t("Eigenschaften")}</TableHead>
          <TableHead className="text-center">{t("Preis")}</TableHead>
          <TableHead className="text-end">{t("Aktion")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {consumableList &&
          consumableList.consumables.map((cons) => (
            <TableRow>
              <TableCell>{cons.name}</TableCell>
              <TableCell>{ConsumableTypeUtil.toString(cons.type)}</TableCell>
              <TableCell className="text-center">{cons.property}</TableCell>
              <TableCell className="text-center">{cons.basePrice}</TableCell>
              <TableCell className="text-end">
                <TooltipProvider>
                  <Tooltip>
                    <TooltipTrigger asChild>
                      <Button size="sm" variant="outline">
                        <Pencil className="h-4 w-4" />
                      </Button>
                    </TooltipTrigger>
                    <TooltipContent>
                      <p>{t("Bearbeiten")}</p>
                    </TooltipContent>
                  </Tooltip>
                </TooltipProvider>
              </TableCell>
            </TableRow>
          ))}
      </TableBody>
    </Table>
  );
}

function ConsumableLoadingPage() {
  return (
    <div className="flex w-full flex-col gap-2">
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
      <Skeleton className="h-10 w-full" />
    </div>
  );
}
