import { MonitorDetailSummaryComponent } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/components/monitoring/monitor-summary-component";
import { useParams } from "react-router";
import { URLParams } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/create-requirement";
import t from "@/lang/lang";

export function CompleteOverviewComponentRoute() {
  const { requirementId } = useParams<URLParams>();
  if (!requirementId) {
    return <div>{t("Fehler beim Laden der Bestandsaufnahme")}</div>;
  }
  return (
    <div>
      <MonitorDetailSummaryComponent requirementId={requirementId} />
    </div>
  );
}
