import {
  addPhoto,
  deletePhoto,
  Position,
  updatePhoto,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { useEffect, useState } from "react";
import { ScrollArea, ScrollBar } from "@/shared/components/ui/scroll-area";
import { Button } from "@/shared/components/ui/button";
import {
  AlertCircle,
  PlusCircle,
  RefreshCw,
  Trash,
  Trash2,
} from "lucide-react";
import { v4 } from "uuid";
import { useInspectionContext } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-context";
import { Card } from "@/shared/components/ui/card";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { cn } from "@/shared/lib/utils";
import { Photo } from "@/services/backend/htz/inspection/photo";
import { H4 } from "@/shared/components/ui/typography";
import { Separator } from "@/shared/components/ui/separator";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { InputValidationErrors } from "@/shared/components/ui/input-error-messages";
import { Textarea } from "@/shared/components/ui/textarea";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  useHtzWorkOrderPhotoDocumentationDeleteImageMutation,
  useHtzWorkOrderPhotoDocumentationShowImageQuery,
  useHtzWorkOrderPhotoDocumentationUploadImageMutation,
} from "@/services/backend/htz/work-order/service";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { FetchBaseQueryError } from "@reduxjs/toolkit/query";
import imageCompression from "browser-image-compression";
import { useErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";

export function PhotoDocumentation({ workOrder }: { workOrder: WorkOrder }) {
  const [photoId, setPhotoId] = useState<string | null>(null);
  const { position, activeComponent } = useInspectionContext();

  return (
    <ScrollArea className="h-[65vh] space-y-2">
      <PhotoTable photoId={photoId} onPhotoSelect={(p) => setPhotoId(p)} />
      <div className="p-2 py-4">
        {position.photos
          .filter((photo) => photo.componentId === activeComponent?.id)
          .map((photo) => {
            if (photo.id !== photoId) return null;
            return (
              <PhotoForm key={photo.id} workOrder={workOrder} photo={photo} />
            );
          })}
      </div>
      <ScrollBar orientation="vertical" />
    </ScrollArea>
  );
}

export function AddPhotoButton({ workOrder }: { workOrder: WorkOrder }) {
  const {
    position,
    onPositionChange: [putPosition, { error, isLoading, reset }],
  } = useInspectionContext();

  const { activeComponent } = useInspectionContext();
  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const { resetNotReadyErrorField } = useErrArtefactNotReady();
  const errorKey = `position.${position.id}.photos`;

  const doAdd = () => {
    if (isLoading) return;

    putPosition(addPhoto(position, activeComponent!.id, v4()));
    resetNotReadyErrorField(errorKey);
  };

  const disabled = workOrder.immutable || activeComponent === null || isLoading;

  return (
    <Button onClick={doAdd} disabled={disabled}>
      <PlusCircle />
    </Button>
  );
}

function PhotoTable({
  photoId,
  onPhotoSelect,
}: {
  photoId: string | null;
  onPhotoSelect: (id: string | null) => void;
}) {
  const { position, activeComponent } = useInspectionContext();

  const toggle = (photo: Photo) => {
    if (photo.id === photoId) {
      onPhotoSelect(null);
    } else {
      onPhotoSelect(photo.id);
    }
  };

  const { notReadyError } = useErrArtefactNotReady();
  const errorPrefix = `position.${position.id}.photos.`;

  return (
    <Card className="shadow-none">
      <Table>
        <TableHeader>
          <TableRow>
            <TableHead className="h-8">{t("Kommentar")}</TableHead>
            <TableHead className="h-8">{t("Foto ?")}</TableHead>
            <TableHead className="h-8">{t("")}</TableHead>
          </TableRow>
        </TableHeader>
        <TableBody>
          {position.photos
            ?.filter((p) => p.componentId === activeComponent?.id)
            .map((photo) => (
              <TableRow
                key={photo.id}
                className={cn(
                  "cursor-pointer",
                  photo.id === photoId ? "bg-accent" : "",
                )}
              >
                <TableCell className="py-1">
                  <span className="flex items-center space-x-2">
                    {hasFieldError(
                      notReadyError,
                      `${errorPrefix}${photo.id}`,
                    ) && <AlertCircle className="h-6 w-6 text-red-500" />}
                  </span>
                  {photo.comment}
                </TableCell>
                <TableCell
                  className="w-full py-1"
                  onClick={() => toggle(photo)}
                >
                  {photo.fileId === null ? t("fehlt") : t("ja")}
                </TableCell>
                <TableCell className="py-1 text-right">
                  <DeletePhotoButton
                    photoId={photo.id}
                    onDeleted={() => onPhotoSelect(null)}
                  />
                </TableCell>
              </TableRow>
            ))}
        </TableBody>
      </Table>
    </Card>
  );
}

function DeletePhotoButton({
  photoId,
  onDeleted,
}: {
  photoId: string;
  onDeleted: () => void;
}) {
  const {
    position,
    onPositionChange: [putPosition, { error, isLoading, reset }],
  } = useInspectionContext();

  const { toast } = useToast();
  useEffect(() => {
    if (error) {
      toast({
        ...parseRTKQueryError(error),
        variant: "destructive",
      });
      reset();
    }
  }, [error, reset, toast]);

  const doDelete = () => {
    if (isLoading) return;

    onDeleted();
    putPosition(deletePhoto(position, photoId));
  };

  return (
    <Dialog>
      <DialogTrigger asChild>
        <Button
          size="sm"
          variant="destructive"
          className="h-8 w-8 p-0"
          disabled={isLoading}
        >
          <Trash2 className="h-4 w-4" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Foto wirklich löschen?")}</DialogTitle>
          <DialogDescription>
            {t("Das Foto wird endgültig gelöscht")}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doDelete} variant="destructive" disabled={isLoading}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

function PhotoForm({
  workOrder,
  photo: propPhoto,
}: {
  workOrder: WorkOrder;
  photo: Photo;
}) {
  const [photo, setPhoto] = useState(propPhoto);
  const {
    position,
    onPositionChange: [putPosition],
  } = useInspectionContext();
  const onChange = (p: Photo) => {
    setPhoto(p);
    putPosition(updatePhoto(position, p));
  };

  const { notReadyError, resetNotReadyErrorField } = useErrArtefactNotReady();
  const errorPrefix = `position.${position.id}.photos.${photo.id}.`;

  return (
    <div className="h-full">
      <H4>{t("Foto")}</H4>
      <Separator className="mt-1 mb-4" />
      <div className="grid gap-4">
        <div className="grid content-start gap-1.5">
          <Label>{t("Bemerkung")}</Label>
          <Textarea
            value={photo.comment}
            onChange={(e) => {
              onChange({ ...photo, comment: e.target.value });
              resetNotReadyErrorField(`${errorPrefix}comment`);
            }}
            className={cn(
              hasFieldError(notReadyError, `${errorPrefix}commment`) &&
                "border-red-500 shadow-md",
            )}
            disabled={workOrder.immutable}
          />
          <InputValidationErrors
            error={notReadyError}
            field={`${errorPrefix}comment`}
          />
        </div>
        <PhotoUpload
          workOrder={workOrder}
          position={position}
          photo={photo}
          onPhotoChange={onChange}
        />
        <PhotoDisplay
          workOrder={workOrder}
          position={position}
          photo={photo}
          onPhotoChange={onChange}
        />
      </div>
    </div>
  );
}

// TODO, the photo upload logic is not working.
//  * The upload corretly updated the backend
//  * However, the react state must be updated as well.
//  * When online, optimally, this should be via the
//    rtk query cache invalidation. -> how to update react state?
//  * Offline as well
//  * --> Updating the photo with the fileId and filePrefix used
//    to work, but caused issues now.
//  * --> remember that for offline the frontend must know the prefix
//        as well in any case!

// TODO: offline support for photo handling
function PhotoUpload({
  workOrder,
  position,
  photo,
  onPhotoChange,
}: {
  workOrder: WorkOrder;
  position: Position;
  photo: Photo;
  onPhotoChange: (photo: Photo) => void;
}) {
  const { toast } = useToast();
  const [
    upload,
    {
      isLoading: photoUploadIsLoading,
      error: photoUploadError,
      isSuccess: photoUploadIsSuccess,
      reset: photoUploadReset,
    },
  ] = useHtzWorkOrderPhotoDocumentationUploadImageMutation();
  useEffect(() => {
    if (photoUploadError) {
      toast({
        ...parseRTKQueryError(photoUploadError),
        variant: "destructive",
      });
      photoUploadReset();
    }
  }, [photoUploadError, photoUploadReset, toast]);

  const [imageId, setImageId] = useState<string | null>(null);

  useEffect(() => {
    if (photoUploadIsSuccess) {
      toast({
        title: t("Bild hochgeladen"),
        description: t("Das Bild wurde erfolgreich hochgeladen."),
        variant: "success",
      });
      // const p = { ...photo, imageId };
      // onPhotoChange(p);
      photoUploadReset();
      setImageId(null);
    }
  }, [
    imageId,
    photoUploadIsSuccess,
    photo,
    position,
    position.id,
    photoUploadReset,
    toast,
    workOrder,
    onPhotoChange,
  ]);

  const disabled =
    photo.fileId !== null ||
    workOrder.immutable ||
    // posIsLoading ||
    photoUploadIsLoading;

  return (
    <Label
      className={cn(
        "ring-offset-background focus-visible:ring-ring inline-flex w-full items-center justify-center rounded-md text-sm font-medium whitespace-nowrap transition-colors focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-hidden disabled:pointer-events-none disabled:opacity-50",
        "border-input bg-background hover:bg-accent hover:text-accent-foreground border",
        "h-9 rounded-md px-3",
      )}
    >
      <Input
        type="file"
        className="hidden"
        accept="image/jpeg,image/png"
        onChange={async (e) => {
          if (e.target.files && e.target.files[0]) {
            // compress image
            const options = {
              maxSizeMB: 0.3,
              maxWidthOrHeight: 1920,
              useWebWorker: true,
            };
            try {
              const compressedFile = await imageCompression(
                e.target.files[0],
                options,
              );
              const id = v4();
              // upload compressed image
              setImageId(id);
              upload({
                positionId: position.id,
                photoId: photo.id,
                fileId: id,
                file: compressedFile,
              });
              e.target.value = "";
              e.target.files = null;
            } catch (err) {
              const parse = parseRTKQueryError(err as FetchBaseQueryError);
              toast({
                title: parse?.title,
                description: parse?.description,
                variant: "destructive",
              });
            }
          }
        }}
        disabled={disabled}
      />
      {photoUploadIsLoading && (
        <RefreshCw className="mr-2 h-4 w-4 animate-spin" />
      )}
      <span>{t("Foto hinzufügen")}</span>
    </Label>
  );
}

function PhotoDisplay({
  workOrder,
  position,
  photo,
  onPhotoChange,
}: {
  workOrder: WorkOrder;
  position: Position;
  photo: Photo;
  onPhotoChange: (photo: Photo) => void;
}) {
  const {
    data: image,
    isLoading,
    error,
  } = useHtzWorkOrderPhotoDocumentationShowImageQuery(
    {
      fileId: photo.fileId!,
      filePrefix: photo.filePrefix,
    },
    {
      skip: photo.fileId === null,
    },
  );

  if (photo.fileId === null) {
    return null;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="relative">
      <img
        className="w-full rounded-lg"
        src={image!.objectURL}
        alt={photo.comment}
      />
      <DeleteImageDialog
        workOrder={workOrder}
        position={position}
        photo={photo}
        onPhotoChange={onPhotoChange}
      />
    </div>
  );
}

function DeleteImageDialog({
  workOrder,
  position,
  photo,
  onPhotoChange,
}: {
  workOrder: WorkOrder;
  position: Position;
  photo: Photo;
  onPhotoChange: (photo: Photo) => void;
}) {
  const [open, setOpen] = useState(false);
  const [deleteImage, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderPhotoDocumentationDeleteImageMutation();

  useEffect(() => {
    if (isSuccess) {
      reset();
      // const p = { ...photo, imageId: null };
      // onPhotoChange(p);
      setOpen(false);
    }
  }, [
    isSuccess,
    setOpen,
    reset,
    photo,
    workOrder,
    position.id,
    position,
    onPhotoChange,
  ]);

  const onClick = () => {
    if (isLoading || photo.fileId === null) {
      return;
    }

    deleteImage({
      positionId: position.id,
      photoId: photo.id,
      fileId: photo.fileId,
      filePrefix: photo.filePrefix,
    });
  };

  const disabled = photo.fileId === null || workOrder.immutable || isLoading;

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild disabled={disabled}>
        <Button className="absolute top-4 right-4" variant="destructive">
          <Trash className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogTitle>{t("Bild wirklich löschen?")}</DialogTitle>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <Button onClick={onClick} variant="destructive">
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
