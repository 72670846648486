import { NavLink, Outlet, useParams } from "react-router";
import { HTMLAttributes, ReactNode } from "react";
import { cn } from "@/shared/lib/utils";
import { Separator } from "@/shared/components/ui/separator";
import t from "@/lang/lang";
import { AirVent, BookUser, Factory, RefreshCw } from "lucide-react";
import { useCustomersCustomerShowQuery } from "@/services/backend/customers/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { H2 } from "@/shared/components/ui/typography";

export function CustomerRoute() {
  const { customerId } = useParams();

  const {
    data: customer,
    isLoading,
    error,
  } = useCustomersCustomerShowQuery({
    id: customerId!,
  });

  if (error) {
    return (
      <Page>
        <RTKQueryErrorAlert error={error} />
      </Page>
    );
  }

  if (isLoading) {
    return (
      <Page>
        <PageSkeleton />
      </Page>
    );
  }

  return (
    <Page>
      <Navigation />
      <PageMain>
        <span className="h-6 font-bold">Kundennummer</span>
        <H2>{customer?.name}</H2>
        <div className="pt-2">
          <Outlet />
        </div>
      </PageMain>
    </Page>
  );
}

function Navigation() {
  return (
    <PageNavigation>
      <PageNavLinkGroup>
        <PageNavLinkGroupTitle>{t("Allgemein")}</PageNavLinkGroupTitle>
        <PageNavLinkList>
          <PageNavLink to="" end>
            <BookUser className="mr-2 h-5 w-5" />
            <span>{t("Informationen")}</span>
          </PageNavLink>
        </PageNavLinkList>
      </PageNavLinkGroup>
      <PageNavLinkGroup>
        <PageNavLinkGroupTitle>{t("Dienstleistungen")}</PageNavLinkGroupTitle>
        <PageNavLinkList>
          <PageNavLink to="objects" end>
            <Factory className="mr-2 h-5 w-5" />
            <span>{t("Objekte")}</span>
          </PageNavLink>
          <PageNavLink to="ahu-units">
            <AirVent className="mr-2 h-5 w-5" />
            <span>{t("RLT-Anlagen")}</span>
          </PageNavLink>
        </PageNavLinkList>
      </PageNavLinkGroup>
    </PageNavigation>
  );
}

interface PageProps extends HTMLAttributes<HTMLDivElement> {}

export function Page({ children, className, ...props }: PageProps) {
  return (
    <div className={cn("flex flex-col pt-2 pr-2", className)} {...props}>
      {children}
    </div>
  );
}

interface PageNavigationProps extends HTMLAttributes<HTMLDivElement> {}

export function PageNavigation({
  children,
  className,
  ...props
}: PageNavigationProps) {
  return (
    <aside className={cn("fixed sm:w-56", className)} {...props}>
      <nav className="pb-12">
        <div className="space-y-4">{children}</div>
      </nav>
    </aside>
  );
}

interface PageNavLinkGroupProps extends HTMLAttributes<HTMLDivElement> {}

export function PageNavLinkGroup({
  children,
  className,
  ...props
}: PageNavLinkGroupProps) {
  return (
    <div className={cn("pb-2", className)} {...props}>
      {children}
    </div>
  );
}

interface PageNavLinkGroupTitleProps extends HTMLAttributes<HTMLDivElement> {}

export function PageNavLinkGroupTitle({
  children,
  className,
  ...props
}: PageNavLinkGroupTitleProps) {
  return (
    <h2
      className={cn(
        "mb-2 px-4 text-lg font-semibold tracking-tight",
        className,
      )}
      {...props}
    >
      {children}
    </h2>
  );
}

interface PageNavLinkListProps extends HTMLAttributes<HTMLDivElement> {}

export function PageNavLinkList({
  children,
  className,
  ...props
}: PageNavLinkListProps) {
  return (
    <div className={cn("space-y-1", className)} {...props}>
      {children}
    </div>
  );
}

interface PageNavLinkSeparatorProps extends HTMLAttributes<HTMLDivElement> {}

export function PageNavLinkSeparator({
  className,
  ...props
}: PageNavLinkSeparatorProps) {
  return (
    <div className={cn("px-4", className)} {...props}>
      <Separator />
    </div>
  );
}

interface PageNavLinkProps {
  to: string;
  children: ReactNode;
  end?: boolean;
}

export function PageNavLink({ to, children, end = false }: PageNavLinkProps) {
  return (
    <NavLink
      to={to}
      end={end ?? false}
      className={({ isActive }) =>
        isActive
          ? "bg-secondary text-secondary-foreground ring-offset-background hover:bg-secondary/80 focus-visible:ring-ring inline-flex h-10 w-full items-center justify-start rounded-md px-4 py-2 text-sm font-medium whitespace-nowrap transition-colors focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-hidden disabled:pointer-events-none disabled:opacity-50"
          : "ring-offset-background hover:bg-accent hover:text-accent-foreground focus-visible:ring-ring inline-flex h-10 w-full items-center justify-start rounded-md px-4 py-2 text-sm font-medium whitespace-nowrap transition-colors focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:outline-hidden disabled:pointer-events-none disabled:opacity-50"
      }
    >
      {children}
    </NavLink>
  );
}

interface PageMainProps extends HTMLAttributes<HTMLDivElement> {}

export function PageMain({ children, className, ...props }: PageMainProps) {
  return (
    <main
      className={cn("space-y-2 overflow-x-hidden sm:ml-60", className)}
      {...props}
    >
      {children}
    </main>
  );
}

export function PageSkeleton() {
  return (
    <div className="flex">
      <RefreshCw className="mr-2 h-5 w-5 animate-spin" />
      <span>{t("Lade ...")}</span>
    </div>
  );
}
