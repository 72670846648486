import { useParams } from "react-router";
import { useHtzReportShowQuery } from "@/services/backend/htz/report/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { PhotosComponent } from "@/routes/gesec/processes/[processId]/htz/reports/[reportId]/photos/_components/photos-component";

export function PhotosRoute() {
  const { reportId } = useParams();
  const {
    data: report,
    isLoading,
    error,
  } = useHtzReportShowQuery({ id: reportId! });

  if (isLoading) {
    return null;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return <PhotosComponent report={report!} />;
}
