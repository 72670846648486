import { useParams } from "react-router";
import { useRltOrderShowQuery } from "@/services/backend/rlt/orders/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import {
  OrderCard,
  OrderCardSkeleton,
} from "@/routes/gesec/processes/[processId]/rlt/orders/[orderId]/data/_components/order-card";

export function OrderDataRoute() {
  const { orderId } = useParams();

  const {
    data: order,
    isLoading,
    error,
  } = useRltOrderShowQuery({
    id: orderId!,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <OrderCardSkeleton />;
  }

  // The key rotation is needed to force the re-render of the component.
  // This is necessary because the update from an offer through a put mutation
  // changes the whole order object. The request in the useState of the
  // OrderCard will then hold stale data.
  // To generally avoid this, the update from an offer should not be done
  // through a put operation but an explicit update mutation. Since order is
  // so small, this trade-off was accepted.
  return <OrderCard order={order!} key={JSON.stringify(order)} />;
}
