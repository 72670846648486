import { Card, CardHeader, CardTitle } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import {
  Position,
  WorkOrder,
} from "@/services/backend/htz/work-order/work-order";
import { Checkbox } from "@/shared/components/ui/checkbox";
import { AhuSelectCell } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/ahu-select-cell";
import { AddPositionDropdown } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/add-position-dropdown";
import { Fragment, useMemo, useState } from "react";
import { DeletePositionDialog } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/delete-position-dialog";
import { RefreshCw } from "lucide-react";
import { InspectionSheet } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/inspection-sheet";
import {
  useListPosition,
  usePutPosition,
} from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/use-put-position";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import { useGetAirHandlingUnit } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/_hooks/air-handling-unit-hooks";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { CompletedCheckboxCell } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/completed-checkbox-cell";
import { TitleCell } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/title-cell";
import { PositionDetailsRow } from "@/routes/gesec/processes/[processId]/htz/work-orders/[workOrderId]/positions/_components/position-details-row";

export function PositionsTable({ workOrder }: { workOrder: WorkOrder }) {
  const { data: positions, isLoading, error } = useListPosition();

  if (isLoading) {
    return (
      <Card>
        <CardHeader>
          <CardTitle>{t("Leistungen")}</CardTitle>
        </CardHeader>
        <Table>
          <TableBody>
            <TableRow>
              <TableCell colSpan={6} className="text-center">
                <RefreshCw className="h-6 w-6 animate-spin" />
              </TableCell>
            </TableRow>
          </TableBody>
        </Table>
      </Card>
    );
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="flex justify-between">
          <span>{t("Leistungen")}</span>
          <AddPositionDropdown workOrder={workOrder} />
        </CardTitle>
      </CardHeader>
      <Table>
        <PositionTableHeader />
        <PositionsTableBody workOrder={workOrder} positions={positions!} />
      </Table>
    </Card>
  );
}

function PositionTableHeader() {
  return (
    <TableHeader>
      <TableRow>
        <TableHead>{t("Pos")}</TableHead>
        <TableHead>{t("Erledigt")}</TableHead>
        <TableHead>{t("RLT-Anlage")}</TableHead>
        <TableHead>{t("Titel")}</TableHead>
        <TableHead>{t("Optional?")}</TableHead>
        <TableHead>{t("Aktionen")}</TableHead>
      </TableRow>
    </TableHeader>
  );
}

function PositionsTableBody({
  workOrder,
  positions,
}: {
  workOrder: WorkOrder;
  positions: Position[];
}) {
  const ahuIdsInPosition = useMemo(
    () =>
      (positions ?? []).reduce((prev, curr) => {
        if (
          !curr.airHandlingUnitId ||
          prev.some((p) => p === curr.airHandlingUnitId)
        ) {
          return prev;
        }

        return [...prev, curr.airHandlingUnitId];
      }, [] as string[]),
    [positions],
  );

  const positionsWithoutAhu = useMemo(
    () => (positions ?? []).filter((pos) => !pos.airHandlingUnitId),
    [positions],
  );

  return (
    <TableBody>
      {ahuIdsInPosition.map((ahuId) => (
        <Fragment key={ahuId}>
          <TableRow>
            <TableCell colSpan={6} className="bg-muted h-8 py-0 font-medium">
              <AhuName ahuId={ahuId} />
            </TableCell>
          </TableRow>
          {positions
            ?.filter(byAhuId(ahuId))
            .map((position) => (
              <PositionTableRow
                key={position.id}
                workOrder={workOrder}
                position={position}
              />
            ))}
        </Fragment>
      ))}
      {positionsWithoutAhu.length > 0 && (
        <TableRow>
          <TableCell colSpan={6} className="bg-muted h-8 py-0 font-medium">
            {t("Ohne RLT-Anlage")}
          </TableCell>
        </TableRow>
      )}
      {positionsWithoutAhu.map((position) => (
        <PositionTableRow
          key={position.id}
          workOrder={workOrder}
          position={position}
        />
      ))}
      {positions?.length === 0 && (
        <TableRow>
          <TableCell colSpan={6} className="text-center">
            {t("Keine Positionen")}
          </TableCell>
        </TableRow>
      )}
    </TableBody>
  );
}

function AhuName({ ahuId }: { ahuId: string }) {
  const { data: airHandlingUnits } = useGetAirHandlingUnit(ahuId);

  return <span>{airHandlingUnits?.name}</span>;
}

function byAhuId(ahuId: string) {
  return (a: Position) => a.airHandlingUnitId === ahuId;
}

function PositionTableRow({
  workOrder,
  position,
}: {
  workOrder: WorkOrder;
  position: Position;
}) {
  const [request, setRequest] = useState(position);
  const [putPosition, { isLoading, isSuccess, error, reset }] =
    usePutPosition();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    putPosition,
    isLoading,
    error,
    isSuccess,
    reset,
    250,
    {
      toastError: true,
    },
  );

  const disabled = workOrder.immutable;

  return (
    <>
      <TableRow className="border-b-0">
        <TableCell className="py-2">
          <span>{position.number}</span>
        </TableCell>
        <CompletedCheckboxCell
          position={request}
          onPositionChange={setRequest}
          disabled={disabled}
        />
        <AhuSelectCell
          workOrder={workOrder}
          position={request}
          onPositionChange={setRequest}
          disabled={disabled}
        />
        <TitleCell
          position={request}
          onPositionChange={setRequest}
          disabled={disabled}
        />
        <TableCell className="py-2">
          <Checkbox checked={position.optionalOffer} disabled />
        </TableCell>
        <TableCell className="py-0">
          <div className="flex gap-2">
            <InspectionSheet
              workOrder={workOrder}
              position={request}
              onPositionChange={[
                setRequest,
                { isLoading, error, reset, isSuccess },
              ]}
              disabled={disabled}
            />
            <DeletePositionDialog workOrder={workOrder} position={position} />
          </div>
        </TableCell>
      </TableRow>
      <PositionDetailsRow
        position={position}
        onPositionChange={setRequest}
        disabled={disabled}
      />
    </>
  );
}
