import { backendApi } from "@/services/backend/api";
import {
  ListPestCategoryRequest,
  ListPestTypeRequest,
  PestCategory,
  PestCategoryList,
  PestType,
  PestTypeList,
  PutPestCategoryRequest,
  PutPestTypeRequest,
} from "@/services/backend/qpm/pests/types";

const qpmPestServiceApi = backendApi
  .enhanceEndpoints({
    addTagTypes: [
      "QpmPestCategory",
      "QpmPestType",
      "QpmPestCategoryRecord",
      "QpmPestTypeRecord",
    ],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      qpmPestCategoryPut: build.mutation<PestCategory, PutPestCategoryRequest>({
        query: (request) => ({
          url: `/qpm/pest-category`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["QpmPestCategory"],
      }),
      qpmPestCategoryList: build.query<
        PestCategoryList,
        ListPestCategoryRequest
      >({
        query: (request) => ({
          url: `/qpm/pest-category/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmPestCategory", id: "LIST" }] : [],
      }),
      qpmPestTypePut: build.mutation<PestType, PutPestTypeRequest>({
        query: (request) => ({
          url: `/qpm/pest-type`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["QpmPestType"],
      }),
      qpmPestTypeList: build.query<PestTypeList, ListPestTypeRequest>({
        query: (request) => ({
          url: `/qpm/pest-type/list`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result ? [{ type: "QpmPestType", id: "LIST" }] : [],
      }),
    }),
  });

export const {
  useQpmPestCategoryPutMutation,
  useQpmPestCategoryListQuery,
  useQpmPestTypePutMutation,
  useQpmPestTypeListQuery,
} = qpmPestServiceApi;
