import { useState } from "react";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Button } from "@/shared/components/ui/button";
import { Check, ChevronDown, PlusCircle, Search } from "lucide-react";
import t from "@/lang/lang";
import {
  PutRequest,
  useProcessesProcessPutMutation,
} from "@/services/backend/processes/process/service";
import {
  ProcessKind,
  ProcessKindList,
} from "@/services/backend/processes/process/kinds";
import { Label } from "@/shared/components/ui/label";
import { Input } from "@/shared/components/ui/input";
import { useCustomersCustomerListQuery } from "@/services/backend/customers/service";
import {
  Popover,
  PopoverContent,
  PopoverTrigger,
} from "@/shared/components/ui/popover";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
  CommandList,
} from "@/shared/components/ui/command";
import { cn } from "@/shared/lib/utils";
import { useListBranchQuery } from "@/services/backend/branch/branch";
import { v4 } from "uuid";
import { useActiveCompany } from "@/shared/lib/active-company/active-company-provider";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useToast } from "@/shared/hooks/use-toast";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { ScrollArea } from "@/shared/components/ui/scroll-area";
import { useNavigate } from "react-router";
import { FilterMode } from "@/shared/lib/filter/filter";
import { useDebounce } from "@/shared/lib/debounce/debounce";
import { useShowCompanyQuery } from "@/services/backend/company/company";

export function NewProcessDialog() {
  const { companyId } = useActiveCompany();
  const [open, setOpen] = useState(false);
  const [request, setRequest] = useState<PutRequest>({
    id: v4(),
    kind: ProcessKindList[0] as ProcessKind,
    topic: "",
    customerId: "",
    companyId,
    branchId: "",
    createdAt: "",
    updatedAt: "",
  });
  const [put, { isLoading, error, isSuccess, reset }] =
    useProcessesProcessPutMutation();
  const { toast } = useToast();
  const navigate = useNavigate();

  const processLink = useProcessLink(companyId, request.kind, request.id);

  if (isSuccess) {
    // Technically, processLink may be a zero string.
    // This would only happen if the company query within useProcessLink
    // is not yet resolved. This is practically impossible. If it should
    // be the case, navigate("") would just stay on the current page.
    navigate(processLink);
    toast({
      title: t("Prozess erstellt"),
      variant: "success",
    });
    setRequest({
      id: v4(),
      kind: ProcessKindList[0] as ProcessKind,
      topic: "",
      customerId: "",
      companyId,
      branchId: "",
      createdAt: "",
      updatedAt: "",
    });
    setOpen(false);
    reset();
  }

  if (error) {
    const err = parseRTKQueryError(error);
    toast({
      title: err?.title,
      description: err?.description,
      variant: "destructive",
    });
    setOpen(false);
    reset();
  }

  const onSubmit = () => {
    if (isLoading) {
      return;
    }

    const now = new Date().toISOString();

    put({
      ...request,
      createdAt: now,
      updatedAt: now,
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button size="sm">
          <PlusCircle className="h-4 w-4" />
          <span>{t("Neuer Vorgang")}</span>
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Neuen Vorgang anlegen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Neue Vorgänge sollten hier ausschließlich angelegt werden, wenn es in MS Dynamics keine Verkaufschance gibt welche dem Vorgang entspricht. Ansonsten wird es unweigerlich zu Unklarheiten und Konflikten kommen.",
            )}
          </DialogDescription>
        </DialogHeader>
        <ProcessKindSelect
          processKind={request.kind}
          onProcessKindChange={(kind) => setRequest({ ...request, kind })}
        />
        <div className="grid w-full items-center gap-1.5">
          <Label htmlFor="topic">{t("Thema")}</Label>
          <Input
            type="topic"
            id="topic"
            placeholder={t("Thema")}
            value={request.topic}
            onChange={(e) => setRequest({ ...request, topic: e.target.value })}
          />
        </div>
        <CustomerSelect
          customerId={request.customerId}
          onCustomerIdChange={(customerId) =>
            setRequest({ ...request, customerId })
          }
        />
        <BranchSelect
          branchId={request.branchId}
          onBranchIdChange={(branchId) => setRequest({ ...request, branchId })}
        />
        <DialogFooter>
          <Button variant="outline" onClick={() => setOpen(false)}>
            {t("Abbrechen")}
          </Button>
          <Button onClick={onSubmit}>{t("Erstellen")}</Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

export function useProcessLink(
  companyId: string,
  kind: ProcessKind,
  id: string,
): string {
  const { data: company } = useShowCompanyQuery({ id: companyId });
  if (!company) {
    return "";
  }

  const tenant = company.portalRoute;
  const basePath = `/tenant/${tenant}/processes`;

  return `${basePath}/${kind.toLowerCase()}/${id}`;
}

function ProcessKindSelect(props: {
  processKind: ProcessKind;
  onProcessKindChange: (processKind: ProcessKind) => void;
}) {
  const { processKind, onProcessKindChange } = props;
  const [open, setOpen] = useState(false);

  return (
    <div className="grid w-full items-center gap-1.5">
      <Label>{t("Dienstleistung")}</Label>
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            <span className="font-normal">
              {processKind || t("Dienstleistung auswählen")}
            </span>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[450px] max-w-xs p-0 sm:max-w-full">
          <Command>
            <CommandInput placeholder={t("Dienstleistung suchen")} />
            <CommandEmpty>{t("Dienstleistung nicht gefunden")}</CommandEmpty>
            <ScrollArea className="max-h-[25vh]">
              <CommandGroup>
                {ProcessKindList.map((pk) => (
                  <CommandItem
                    key={pk}
                    value={pk}
                    onSelect={(currentValue) => {
                      onProcessKindChange(
                        (currentValue === processKind ? "" : pk) as ProcessKind,
                      );
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        processKind === pk ? "opacity-100" : "opacity-0",
                      )}
                    />
                    {pk}
                  </CommandItem>
                ))}
              </CommandGroup>
            </ScrollArea>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function CustomerSelect({
  customerId,
  onCustomerIdChange,
}: {
  customerId: string;
  onCustomerIdChange: (customerId: string) => void;
}) {
  const [open, setOpen] = useState(false);

  const [name, setName] = useState("");

  const debouncedName = useDebounce(name, 250);

  const {
    data: customerList,
    isLoading,
    error,
  } = useCustomersCustomerListQuery(
    {
      name: {
        active: true,
        mode: FilterMode.Like,
        values: [debouncedName],
      },
      limit: 10,
    },
    {
      skip: !debouncedName || debouncedName.length < 3,
    },
  );

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const list = customerList?.customers || [];

  const onSelect = (cId: string) => () => {
    onCustomerIdChange(cId);
    setOpen(false);
  };

  return (
    <div
      className={cn(
        "grid w-full items-center gap-1.5",
        isLoading ? "animate-pulse" : "",
      )}
    >
      <Label>{t("Kunde")}</Label>
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            <span className="font-normal">
              {customerName(customerId, list)}
            </span>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[450px] max-w-xs p-0 sm:max-w-full">
          <div className="bg-popover text-popover-foreground flex h-full w-full flex-col overflow-hidden rounded-md">
            <div className="flex items-center border-b px-3">
              <Search className="mr-2 h-4 w-4 shrink-0 opacity-50" />
              <input
                className="placeholder:text-muted-foreground flex h-11 w-full rounded-md bg-transparent py-3 text-sm outline-hidden disabled:cursor-not-allowed disabled:opacity-50"
                value={name}
                onChange={(e) => setName(e.target.value)}
              />
            </div>
          </div>
          {name.length > 0 && list.length === 0 && (
            <div className="py-6 text-center text-sm">
              {t("Kunde nicht gefunden")}
            </div>
          )}
          {list.length > 0 && (
            <ScrollArea className="*:data-radix-scroll-area-viewport:max-h-[200px]">
              <div className="text-foreground p-1">
                {list.map((customer) => (
                  <button
                    type="button"
                    key={customer.id}
                    onClick={onSelect(customer.id)}
                    className="hover:bg-accent flex w-full cursor-default items-center rounded-sm px-2 py-1.5 text-sm outline-hidden select-none"
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        customerId === customer.id
                          ? "opacity-100"
                          : "opacity-0",
                      )}
                    />
                    {customer.name}
                  </button>
                ))}
              </div>
            </ScrollArea>
          )}
        </PopoverContent>
      </Popover>
    </div>
  );
}

function customerName(
  customerId: string,
  list: { id: string; name: string }[],
) {
  return list.find((c) => c.id === customerId)?.name || "Kunde auswählen";
}

function BranchSelect(props: {
  branchId: string;
  onBranchIdChange: (branchId: string) => void;
}) {
  const { companyId } = useActiveCompany();
  const { branchId, onBranchIdChange } = props;
  const [open, setOpen] = useState(false);
  const {
    data: branchList,
    isLoading,
    error,
  } = useListBranchQuery({
    companyId,
  });

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  const list = branchList?.branches || [];

  return (
    <div
      className={cn(
        "grid w-full items-center gap-1.5",
        isLoading ? "animate-pulse" : "",
      )}
    >
      <Label>{t("Niederlassung")}</Label>
      <Popover open={open} onOpenChange={setOpen} modal>
        <PopoverTrigger asChild>
          <Button
            variant="outline"
            role="combobox"
            aria-expanded={open}
            className="w-full justify-between"
          >
            <span className="font-normal">{branchName(branchId, list)}</span>
            <ChevronDown className="ml-2 h-4 w-4 shrink-0 opacity-50" />
          </Button>
        </PopoverTrigger>
        <PopoverContent className="w-[450px] max-w-xs p-0 sm:max-w-full">
          <Command>
            <CommandInput placeholder={t("Niederlassung suchen")} />
            <CommandList>
              <CommandEmpty>{t("Niederlassung nicht gefunden")}</CommandEmpty>
              <CommandGroup>
                {list.map((branch) => (
                  <CommandItem
                    key={branch.id}
                    value={branch.name}
                    onSelect={(currentValue) => {
                      onBranchIdChange(
                        currentValue === branchName(branchId, list)
                          ? ""
                          : branch.id,
                      );
                      setOpen(false);
                    }}
                  >
                    <Check
                      className={cn(
                        "mr-2 h-4 w-4",
                        branchId === branch.id ? "opacity-100" : "opacity-0",
                      )}
                    />
                    {branch.name}
                  </CommandItem>
                ))}
              </CommandGroup>
            </CommandList>
          </Command>
        </PopoverContent>
      </Popover>
    </div>
  );
}

function branchName(branchId: string, list: { id: string; name: string }[]) {
  return list.find((b) => b.id === branchId)?.name || "Niederlassung auswählen";
}
