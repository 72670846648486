import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "@/shared/components/ui/table";
import t from "@/lang/lang";
import { Card } from "@/shared/components/ui/card";
import { Button } from "@/shared/components/ui/button";
import { v4 } from "uuid";
import { useToast } from "@/shared/hooks/use-toast";
import { NavLink, useNavigate, useParams } from "react-router";
import { FilterMode } from "@/shared/lib/filter/filter";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Loader2, Search } from "lucide-react";
import {
  useQpmRequirementCreateMutation,
  useQpmRequirementListQuery,
} from "@/services/backend/qpm/requirements/service";
import { useEffect } from "react";
import { Requirement as QpmRequirement } from "@/services/backend/qpm/requirements/types";
import { parseRTKQueryError } from "@/shared/components/domain/errors/parse-r-t-k-query-error";
import { formatDate, formatTime } from "@/shared/lib/utilities/date";
import { useCustomersCustomerShowQuery } from "@/services/backend/customers/service";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import {
  MilestonePage,
  MilestonePageActions,
  MilestonePageContent,
  MilestonePageHeader,
  MilestonePageTitle,
} from "@/shared/service-manager/default-ui/milestone-page/milestone-page";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";

export function RequirementsOverview() {
  const { processId } = useParams();

  const {
    data: process,
    error: processDataError,
    isLoading: processDataIsLoading,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  if (processDataIsLoading) {
    return <Loader2 className="animate-spin" />;
  }

  if (!processId || !process) {
    return <div>Fehler beim Laden des Vorgangs!</div>;
  }

  if (processDataError) {
    return <RTKQueryErrorAlert error={processDataError} />;
  }

  return (
    <MilestonePage>
      <MilestonePageHeader>
        <MilestonePageTitle>{t("Bestandsaufnahmmen")}</MilestonePageTitle>
        <MilestonePageActions>
          <CreateNewRequirement
            processId={processId!}
            customerId={process.customerId}
          />
        </MilestonePageActions>
      </MilestonePageHeader>
      <MilestonePageContent>
        <Card>
          <RequirementHistoryTable
            processId={processId}
            customerId={process.customerId}
          />
        </Card>
      </MilestonePageContent>
    </MilestonePage>
  );
}

type CreateNewRequirementProps = {
  processId: string;
  customerId: string;
};

/**
 * TODO-LT: Refactor - Build Create RequirementDialog where the user can choose to create a new requirement, or copy data from old requirements
 * @param processId
 * @param customerId
 * @constructor
 */
export function CreateNewRequirement({
  processId,
  customerId,
}: CreateNewRequirementProps) {
  const { toast } = useToast();
  const navigate = useNavigate();
  const [createRequirement, { isLoading, data, error, reset }] =
    useQpmRequirementCreateMutation();

  const {
    data: customer,
    isLoading: isLoadingCustomer,
    error: errorCustomer,
  } = useCustomersCustomerShowQuery({ id: customerId });

  useEffect(() => {
    if (error) {
      toast({ ...parseRTKQueryError(error) });
      reset();
    }
  }, [error, toast, reset]);

  useEffect(() => {
    if (data) {
      const requirement: QpmRequirement = data;
      navigate(requirement.artefactData.id);
    }
  }, [data, navigate]);

  const onClick = () => {
    if (isLoading || !customer) return;
    const id = v4();

    createRequirement({
      artefactMetaId: id,
      processId,
    });
  };

  if (errorCustomer) {
    return <RTKQueryErrorAlert error={errorCustomer} />;
  }

  if (isLoadingCustomer || isLoading) {
    return <Loader2 className="animate-spin" />;
  }
  return (
    <Button onClick={onClick} disabled={isLoading || isLoadingCustomer}>
      Erstellen
    </Button>
  );
}

function RequirementHistoryTable({
  processId,
  customerId,
}: {
  processId: string;
  customerId: string;
}) {
  const {
    data: list,
    isLoading,
    error,
  } = useQpmRequirementListQuery({
    process: {
      active: true,
      values: [processId],
    },
    owner: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
    customer: {
      active: true,
      values: [customerId],
    },
    parent: {
      active: true,
      mode: FilterMode.IsNull,
      values: [],
    },
  });

  if (isLoading) {
    return <RequirementHistoryTableSkeleton />;
  }

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <Table>
      <TableHeader>
        <TableRow>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Uhrzeit")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead className="w-full">{t("Thema")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
      <TableBody>
        {list &&
          list.artefacts.map((requirement) => (
            <TableRow key={requirement.id}>
              <TableCell>{formatDate(requirement.createdAt)}</TableCell>
              <TableCell>{formatTime(requirement.createdAt)}</TableCell>
              <TableCell>
                <NavLink to={`${requirement.id}`}>
                  <ArtefactNumberById artefactId={requirement.id} />
                </NavLink>
              </TableCell>
              <TableCell>{requirement.subject}</TableCell>
              <TableCell>
                <div className="flex space-x-2">
                  <NavLink to={`${requirement.id}`}>
                    <Button variant="ghost">
                      <Search className="h-4 w-4" />
                    </Button>
                  </NavLink>
                </div>
              </TableCell>
            </TableRow>
          ))}
        {list && list.artefacts.length === 0 && (
          <TableRow>
            <TableCell colSpan={5} className="text-center">
              {t("Keine Bestandsaufnahmen gefunden")}
            </TableCell>
          </TableRow>
        )}
      </TableBody>
    </Table>
  );
}

function RequirementHistoryTableSkeleton() {
  return (
    <Table className="animate-pulse">
      <TableHeader>
        <TableRow>
          <TableHead>{t("Datum")}</TableHead>
          <TableHead>{t("Nummer")}</TableHead>
          <TableHead className="w-full">{t("Vorgang Thema")}</TableHead>
          <TableHead>{t("Aktionen")}</TableHead>
        </TableRow>
      </TableHeader>
    </Table>
  );
}
