import { useShowDynamicsProcessMatchQuery } from "@/services/backend/dynamics/process-match";
import { Skeleton } from "@/shared/components/ui/skeleton";
import { cn } from "@/shared/lib/utils";
import { HTMLAttributes } from "react";
import { formatDate } from "@/shared/lib/utilities/date";

export function OpportunityCreatedOn({
  processId,
  className,
  ...props
}: { processId: string } & HTMLAttributes<HTMLSpanElement>) {
  const {
    data: matchData,
    isLoading,
    error,
  } = useShowDynamicsProcessMatchQuery({
    processId,
  });

  if (error) {
    return <span>N/A</span>;
  }

  if (isLoading) {
    return <Skeleton className={cn("h-4 w-20", className)} />;
  }

  return <span {...props}>{formatDate(matchData!.createdOn)}</span>;
}
