import { backendApi } from "@/services/backend/api";
import { User } from "@/services/backend/auth/user/user";

const userApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    authUserMe: build.query<User, MeRequest>({
      query: (request) => ({
        url: "auth/user/me",
        method: "GET",
        params: request,
      }),
    }),
  }),
});

export const { useAuthUserMeQuery } = userApi;

export interface MeRequest {}
