import { RouteObject } from "react-router";
import {
  AhuUnitsIndexRoute,
  AhuUnitsRoute,
} from "@/routes/customers/[customerId]/ahu-units/ahu-units-route";
import { Objects } from "@/routes/customers/[customerId]/objects/objects";
import { AhuUnitRoute } from "@/routes/customers/[customerId]/ahu-units/[ahuId]/ahu-unit-route";

export const customerRoutes: RouteObject[] = [
  {
    path: "ahu-units",
    element: <AhuUnitsRoute />,
    children: [
      {
        index: true,
        element: <AhuUnitsIndexRoute />,
      },
      {
        path: ":ahuId",
        element: <AhuUnitRoute />,
      },
    ],
  },
  {
    path: "objects",
    element: <Objects />,
  },
];
