import {
  Card,
  CardContent,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import { useParams } from "react-router";
import { URLParams } from "@/routes/gemex/processes/qpm/requirements/requirement/[requirementId]/create-requirement";
import t from "@/lang/lang";
import { useEffect, useState } from "react";
import { BuildingAddress } from "@/services/backend/qpm/buildings/types";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { useQpmBuildingAddressListQuery } from "@/services/backend/qpm/buildings/service";
import { Loader } from "lucide-react";

export function AddressInfoComponent() {
  const { processId } = useParams<URLParams>();
  const [currentAddress, setCurrentAddress] = useState<BuildingAddress>();

  const {
    data: process,
    error: processDataError,
    isLoading: processDataIsLoading,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  const {
    data: addressList,
    error,
    isLoading: addressIsLoading,
  } = useQpmBuildingAddressListQuery(
    {
      customerId: process!.customerId,
    },
    { skip: !process },
  );

  useEffect(() => {
    if (addressList && addressList.addresses.length > 0) {
      setCurrentAddress(addressList.addresses[0]);
    }
  }, [addressList, setCurrentAddress]);

  if (addressIsLoading || processDataIsLoading) {
    return <Loader className="animate-pulse" />;
  }

  if (error || !currentAddress) {
    return <div>Fehler beim Laden der Addresse</div>;
  }
  if (processDataError || !process) {
    return <div>Fehler beim Laden des Vorgangs</div>;
  }

  return (
    <Card>
      <CardHeader>
        <CardTitle className="text-md">{t("Serviceanschrift")}</CardTitle>
      </CardHeader>
      <CardContent>
        <span>{currentAddress.address.street}</span>
        <div className="flex items-center gap-2">
          <span>{currentAddress.address.postalCode}</span>
          <span>{currentAddress.address.city}</span>
        </div>
      </CardContent>
    </Card>
  );
}
