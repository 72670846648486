import { useOfferContext } from "@/routes/gesec/processes/[processId]/htz/offers/_components/offer-context";
import {
  ArtefactSheet,
  ArtefactSheetActions,
  ArtefactSheetActionsContent,
  ArtefactSheetActionsDownload,
  ArtefactSheetActionsHeader,
  ArtefactSheetContent,
  ArtefactSheetHeader,
  ArtefactSheetImmutabilityWarning,
  ArtefactSheetMain,
  ArtefactSheetPreview,
  ArtefactSheetTitle,
  ArtefactSheetTrigger,
} from "@/shared/service-manager/artefact/artefact-sheet";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { ArtefactNumberById } from "@/shared/components/domain/numbers/artefact-number";
import { Separator } from "@/shared/components/ui/separator";
import { useState } from "react";
import { useDownloadPdf } from "@/shared/lib/utilities/download-pdf";
import { rtkErrIsValidationError } from "@/shared/app-lib/errors/validation-error";
import { rtkErrIsErrArtefactNotReady } from "@/shared/service-manager/artefact/err-artefact-not-ready";
import { useAuth } from "@/shared/lib/authorization/auth-context";
import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Download } from "lucide-react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Offer, OfferDocument } from "@/services/backend/qpm/offer/types";
import {
  useQpmOfferPdfDownloadMutation,
  useQpmOfferPdfPreviewQuery,
} from "@/services/backend/qpm/offer/service";

export function OfferPdf({ offer }: { offer: Offer }) {
  const { data, isLoading, error } = useQpmOfferPdfPreviewQuery({
    offerId: offer.artefactData.id,
    document: OfferDocument.ALL,
  });

  return (
    <ArtefactSheet>
      <ArtefactSheetTrigger asChild>
        <Button variant="outline">PDF</Button>
      </ArtefactSheetTrigger>
      <ArtefactSheetContent className="max-h-[90vh]">
        <ArtefactSheetHeader>
          <ArtefactSheetTitle>{t("Download PDF")}</ArtefactSheetTitle>
        </ArtefactSheetHeader>
        <ArtefactSheetMain>
          <ArtefactSheetPreview
            objectURL={data?.objectURL}
            isLoading={isLoading}
            error={error}
          />
          <ArtefactSheetActions>
            <ArtefactSheetActionsHeader
              title={t("Angebot")}
              artefactNumber={
                <ArtefactNumberById artefactId={offer.artefactData.id} />
              }
            />
            <ArtefactSheetActionsContent>
              {/* <ArtefactNotReadyAlert error={error!} artefact={t("Angebot")} /> */}
              <ArtefactSheetImmutabilityWarning
                title={t("Hinweis")}
                description={t(
                  "Wenn ein Angebot heruntergeladen wird, wird es festgeschrieben. Es kann dann nicht mehr geändert werden.",
                )}
              />
              <Separator />
              <ArtefactSheetActionsDownload>
                <DownloadDocumentButton
                  offerId={offer.artefactData.id}
                  document={OfferDocument.ALL}
                  text={t("Alle Dokumente")}
                />
                <DownloadDocumentButton
                  offerId={offer.artefactData.id}
                  document={OfferDocument.OFFER}
                  text={t("Angebot")}
                />
                <DownloadDocumentButton
                  offerId={offer.artefactData.id}
                  document={OfferDocument.MonitorCatalog}
                  text={t("Monitor Katalog")}
                />
                <DownloadDocumentButton
                  offerId={offer.artefactData.id}
                  document={OfferDocument.QualityAssuranceRef}
                  text={t("Mehrwerte, Referenzen & Zertifikate")}
                />
                <DownloadDocumentButton
                  offerId={offer.artefactData.id}
                  document={OfferDocument.ServiceProgram}
                  text={t("Leistungsprogramm")}
                />
                <DownloadDocumentButton
                  offerId={offer.artefactData.id}
                  document={OfferDocument.Contract}
                  text={t("Vertrag")}
                />
              </ArtefactSheetActionsDownload>
            </ArtefactSheetActionsContent>
          </ArtefactSheetActions>
        </ArtefactSheetMain>
      </ArtefactSheetContent>
    </ArtefactSheet>
  );
}

function DownloadDocumentButton({
  offerId,
  document: documentType,
  text,
}: {
  offerId: string;
  document: OfferDocument;
  text: string;
}) {
  const [open, setOpen] = useState(false);
  const [download, { isLoading, error, data, reset }] =
    useQpmOfferPdfDownloadMutation();

  useDownloadPdf(data, () => {
    setOpen(false);
    reset();
  });

  const { setValidationError } = useOfferContext();
  if (rtkErrIsValidationError(error) || rtkErrIsErrArtefactNotReady(error)) {
    setValidationError(error.data);
    setOpen(false);
    reset();
  }

  const { user } = useAuth();

  const doDownload = () => {
    if (isLoading) {
      return;
    }

    download({
      offerId,
      document: documentType,
      download: true,
      downloadBy: user?.id ?? "",
    });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline" className="justify-start">
          <Download className="mr-4 h-5 w-5" /> {text}
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Angebot herunterladen")}</DialogTitle>
          <DialogDescription>
            {t(
              "Wenn ein Angebot heruntergeladen wird, wird es festgeschrieben. Es kann dann nicht mehr geändert werden.",
            )}
          </DialogDescription>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button onClick={doDownload} disabled={isLoading}>
            {isLoading ? (
              <Download className="mr-4 h-5 w-5 animate-bounce" />
            ) : (
              <Download className="mr-4 h-5 w-5" />
            )}
            <span>{t("Herunterladen")}</span>
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
