import { H2 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import { EntraGroupsTable } from "@/routes/base-data/entra/entra-groups/components/entra-groups-table";
import { PutEntraGroupDialog } from "@/routes/base-data/entra/entra-groups/components/put-entra-group-dialog";

export function EntraGroupsRoute() {
  return (
    <div>
      <H2 className="flex w-full justify-between">
        <span>{t("Entra Gruppen")}</span>
        <PutEntraGroupDialog />
      </H2>
      <div className="text-muted-foreground pt-4">
        <div>
          {t(
            "Entra Gruppen werden genutzt um die Zugehörigkeit eines Nutzers zu Unternehmen und Niederlassungen herzustellen. Eine Gruppe kann mehreren Unternehmen oder Niederlassungen zugeordnet sein. Es wird allerdings empfohlen, Entra so zu konfigurieren, dass:",
          )}
        </div>
        <ul className="text-muted-foreground my-2 ml-6 list-disc">
          <li>{t("eine Gruppe einem Unternehmen entspricht")}</li>
          <li>{t("eine Gruppe einer Niederlassung entspricht")}</li>
          <li>{t("Nutzer jeder dieser Gruppen explizit zugeordnet werden")}</li>
        </ul>
        <div>
          {t(
            "Das ist aufwendiger lässt aber eine explizite Steuerung der Rechte zu. In der Konfiguration unten sind dann einer Gruppe entweder keine Unternehmen oder keine Niederlassungen zugeordnet.",
          )}
        </div>
        <div className="pt-2">
          {t(
            "Grundsätzlich erlaubt es die Konfiguration auch, dass eine Gruppe mehreren Unternehmen und Niederlassungen auf einmal entspricht. Das ist aber wie beschrieben nicht der empfohlene Weg.",
          )}
        </div>
      </div>
      <div className="pt-4">
        <EntraGroupsTable />
      </div>
    </div>
  );
}
