import { backendApi } from "../api";

const branchMatchApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["DynamicsProcessMatch"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      showDynamicsProcessMatch: build.query<Match, LoadByProcessIDRequest>({
        query: (request) => ({
          url: `/dynamics/process-match`,
          method: "GET",
          params: request,
        }),
        providesTags: (result) =>
          result
            ? [{ type: "DynamicsProcessMatch", id: result.processId }]
            : [],
      }),
    }),
  });

export const { useShowDynamicsProcessMatchQuery } = branchMatchApi;

export interface Match {
  processId: string; // Assuming servicemanager.ProcessID is a string
  opportunityId: string;
  name: string;
  stateCode: number;
  statusCode: number;
  newUnternehmensgruppe: number;
  axNiederlassung: number;
  axDienstleistung: number;
  accountId: string;
  createdOn: string;
  modifiedOn: string;
}

export interface LoadByProcessIDRequest {
  processId: string;
}
