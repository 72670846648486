import { Outlet, useParams } from "react-router";
import { useProcessesProcessShowQuery } from "@/services/backend/processes/process/service";
import { Loader } from "lucide-react";

export default function RequirementsRoute() {
  const { processId } = useParams();
  const {
    data: process,
    error: processDataError,
    isLoading: processDataIsLoading,
  } = useProcessesProcessShowQuery({
    id: processId ?? "",
  });

  if (processDataIsLoading) {
    return <Loader />;
  }

  if (processDataError || !process) {
    return <div>Fehler beim Laden der Vorgangsdaten</div>;
  }
  return (
    <Outlet
      context={{
        customerId: process!.customerId,
      }}
    />
  );
}
