import {
  Artefact,
  Immutability,
  Ownership,
} from "@/shared/service-manager/artefact/artefact";
import { Position } from "@/services/backend/rlt/treatments/position";
import { Segment } from "@/services/backend/rlt/treatments/segment";
import { Common } from "@/services/backend/artefacts/common";

export interface Treatment extends Artefact, Immutability, Ownership, Common {
  positions: Position[];
  segments: Segment[];
  totalCleanTime: number;
  totalBasePrice: number;
  totalTipCommission: number;
  totalOfferPrice: number;
  totalContractPrice: number;
  totalPercentCompleted: number;
}

export function uniqueAreas(treatment: Treatment) {
  return treatment!.positions
    .map((position) => position.area)
    .filter((value, index, self) => self.indexOf(value) === index)
    .sort((a, b) => a.localeCompare(b));
}
