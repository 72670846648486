import { Button } from "@/shared/components/ui/button";
import {
  ChevronLeft,
  ChevronRight,
  ChevronsLeft,
  ChevronsRight,
} from "lucide-react";
import t from "@/lang/lang";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export function PaginationLimitOffset({
  offset,
  limit,
  totalCount,
  setLimitOffset,
}: {
  offset: number;
  limit: number;
  totalCount: number;
  setLimitOffset: (limit: number, offset: number) => void;
}) {
  return (
    <div className="flex gap-2">
      <LimitSelect limit={limit} setLimitOffset={setLimitOffset} />
      <CurrentPage offset={offset} pageSize={limit} totalCount={totalCount} />
      <FirstPage
        offset={offset}
        setOffset={(newOffset) => setLimitOffset(limit, newOffset)}
      />
      <PreviousPage
        offset={offset}
        setOffset={(newOffset) => setLimitOffset(limit, newOffset)}
        pageSize={limit}
      />
      <NextPage
        offset={offset}
        setOffset={(newOffset) => setLimitOffset(limit, newOffset)}
        pageSize={limit}
        totalCount={totalCount}
      />
      <LastPage
        offset={offset}
        setOffset={(newOffset) => setLimitOffset(limit, newOffset)}
        pageSize={limit}
        totalCount={totalCount}
      />
    </div>
  );
}

export function FirstPage({
  offset,
  setOffset,
}: {
  offset: number;
  setOffset: (offset: number) => void;
}) {
  return (
    <Button
      disabled={offset === 0}
      onClick={() => setOffset(0)}
      variant="outline"
      size="sm"
    >
      <ChevronsLeft className="h-4 w-4" />
    </Button>
  );
}

export function PreviousPage({
  offset,
  setOffset,
  pageSize,
}: {
  offset: number;
  setOffset: (offset: number) => void;
  pageSize: number;
}) {
  const previousPage = offset - pageSize;
  const isFirstPage = offset === 0;
  return (
    <Button
      disabled={isFirstPage}
      onClick={() => setOffset(previousPage)}
      variant="outline"
      size="sm"
    >
      <ChevronLeft className="h-4 w-4" />
    </Button>
  );
}

export function NextPage({
  offset,
  setOffset,
  pageSize,
  totalCount,
}: {
  offset: number;
  setOffset: (offset: number) => void;
  pageSize: number;
  totalCount: number;
}) {
  const nextPage = offset + pageSize;
  const isLastPage = offset + pageSize >= totalCount;
  return (
    <Button
      disabled={isLastPage}
      onClick={() => setOffset(nextPage)}
      variant="outline"
      size="sm"
    >
      <ChevronRight className="h-4 w-4" />
    </Button>
  );
}

export function LastPage({
  offset,
  setOffset,
  pageSize,
  totalCount,
}: {
  offset: number;
  setOffset: (offset: number) => void;
  pageSize: number;
  totalCount: number;
}) {
  const lastPageOffset = Math.floor(totalCount / pageSize) * pageSize;
  return (
    <Button
      disabled={offset === lastPageOffset}
      onClick={() => setOffset(lastPageOffset)}
      variant="outline"
      size="sm"
    >
      <ChevronsRight className="h-4 w-4" />
    </Button>
  );
}

export function CurrentPage({
  offset,
  pageSize,
  totalCount,
}: {
  offset: number;
  pageSize: number;
  totalCount: number;
}) {
  const currentPage = offset / pageSize + 1;
  const lastPage = Math.ceil(totalCount / pageSize);
  return (
    <span className="inline-flex h-9 items-center justify-center px-3 text-sm font-medium">
      {t("Seite")} {currentPage} {t("von")} {lastPage}
    </span>
  );
}

export function LimitSelect({
  limit,
  setLimitOffset,
}: {
  limit: number;
  setLimitOffset: (limit: number, offset: number) => void;
}) {
  return (
    <div className="inline-flex items-center justify-end space-x-2">
      <p className="mr-2 text-sm font-medium">{t("Zeilen pro Seite")}</p>
      <Select
        value={limit.toString()}
        onValueChange={(value) => {
          setLimitOffset(Number(value), 0);
        }}
      >
        <SelectTrigger className="h-8 w-[70px]">
          <SelectValue placeholder={limit} />
        </SelectTrigger>
        <SelectContent side="top">
          {["10", "20", "30", "40", "50"].map((pageSize) => (
            <SelectItem key={pageSize} value={pageSize}>
              {pageSize}
            </SelectItem>
          ))}
        </SelectContent>
      </Select>
    </div>
  );
}
