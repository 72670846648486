import { backendApi } from "@/services/backend/api";
import { Filter } from "@/shared/lib/filter/filter";
import { providesEntity, providesList } from "@/services/backend/tag-helpers";
import { AhuComponentCatalog } from "@/services/backend/htz/ahu/ahu-component-catalog";

const offerApi = backendApi
  .enhanceEndpoints({
    addTagTypes: ["HtzAhuComponentCatalog", "HtzActiveComponentCatalog"],
  })
  .injectEndpoints({
    endpoints: (build) => ({
      htzAhuComponentCatalogPut: build.mutation<
        AhuComponentCatalog,
        PutRequest
      >({
        query: (request) => ({
          url: `/htz/ahu-component-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: (_result, _e, request) => [
          { type: "HtzAhuComponentCatalog", id: request.id },
          "HtzAhuComponentCatalog",
        ],
      }),
      htzAhuComponentCatalogShow: build.query<AhuComponentCatalog, ShowRequest>(
        {
          query: (request) => ({
            url: `/htz/ahu-component-catalog`,
            method: "GET",
            params: request,
          }),
          providesTags: providesEntity("HtzAhuComponentCatalog"),
        },
      ),
      htzAhuComponentCatalogList: build.query<List, ListRequest>({
        query: (request) => ({
          url: `/htz/ahu-component-catalog/list`,
          method: "GET",
          params: {
            request: btoa(JSON.stringify(request)),
          },
        }),
        providesTags: (result) =>
          providesList(result?.catalogs, "HtzAhuComponentCatalog"),
      }),
      htzAhuComponentCatalogDelete: build.mutation<null, DeleteRequest>({
        query: (request) => ({
          url: `/htz/ahu-component-catalog`,
          method: "DELETE",
          body: request,
        }),
        invalidatesTags: ["HtzAhuComponentCatalog"],
      }),
      htzAhuComponentCatalogPutActiveCatalog: build.mutation<
        ActiveCatalog,
        ActiveCatalog
      >({
        query: (request) => ({
          url: `htz/ahu-component-catalog/active-catalog`,
          method: "PUT",
          body: request,
        }),
        invalidatesTags: ["HtzActiveComponentCatalog"],
      }),
      htzAhuComponentCatalogShowActiveCatalog: build.query<
        ActiveCatalog,
        ShowActiveCatalogRequest
      >({
        query: (request) => ({
          url: `htz/ahu-component-catalog/active-catalog`,
          method: "GET",
          params: request,
        }),
        providesTags: ["HtzActiveComponentCatalog"],
      }),
    }),
  });

export const {
  useHtzAhuComponentCatalogPutMutation,
  useHtzAhuComponentCatalogShowQuery,
  useHtzAhuComponentCatalogListQuery,
  useHtzAhuComponentCatalogDeleteMutation,
  useHtzAhuComponentCatalogPutActiveCatalogMutation,
  useHtzAhuComponentCatalogShowActiveCatalogQuery,
} = offerApi;

export interface PutRequest extends AhuComponentCatalog {}

export interface ShowRequest {
  id: string;
}

export interface ListRequest {
  name?: Filter<string>;
  limit?: number;
  offset?: number;
}

export interface List {
  catalogs: AhuComponentCatalog[];
  count: number;
}

export interface DeleteRequest {
  id: string;
}

export interface ActiveCatalog {
  catalogId: string | null;
}

export interface ShowActiveCatalogRequest {}
