import { backendApi } from "@/services/backend/api";
import { Filter } from "@/shared/lib/filter/filter";

const cpyApi = backendApi.injectEndpoints({
  endpoints: (build) => ({
    cpyCopy: build.mutation<CopyResponse, CopyRequest>({
      query: (request) => ({
        url: `/cpy/copy`,
        method: "PATCH",
        body: request,
      }),
      // This is not optimal, but was quick to implement.
      invalidatesTags: [
        "RltCalculation",
        "RltCertificate",
        "RltConfirmation",
        "RltContract",
        "RltDefaults",
        "RltDocumentation",
        "RltLMRA",
        "RltOffer",
        "RltOrder",
        "RltTreatment",
        "RltWorkOrder",
        "VbsCalculation",
        "VbsCertificate",
        "VbsConfirmation",
        "VbsContract",
        "VbsDefaults",
        "VbsDocumentation",
        "VbsLMRA",
        "VbsOffer",
        "VbsOrder",
        "VbsTreatment",
        "VbsWorkOrder",
      ],
    }),
    cpySources: build.query<SourcesResponse, SourcesRequest>({
      query: (request) => ({
        url: `/cpy/sources`,
        method: "GET",
        params: { request: btoa(JSON.stringify(request)) },
      }),
      // This is not optimal, but was quick to implement.
      // The sources query must be invalidated on every artefact tag,
      // otherwise, if an artefact was updated, it may not show up here.
      providesTags: [
        "RltCalculation",
        "RltCertificate",
        "RltConfirmation",
        "RltContract",
        "RltDefaults",
        "RltDocumentation",
        "RltLMRA",
        "RltOffer",
        "RltOrder",
        "RltTreatment",
        "RltWorkOrder",
        "VbsCalculation",
        "VbsCertificate",
        "VbsConfirmation",
        "VbsContract",
        "VbsDefaults",
        "VbsDocumentation",
        "VbsLMRA",
        "VbsOffer",
        "VbsOrder",
        "VbsTreatment",
        "VbsWorkOrder",
      ],
    }),
  }),
});

export const { useCpyCopyMutation, useCpySourcesQuery } = cpyApi;

export interface CopyRequest {
  destination: Destination;
  source: Source;
  meta?: unknown;
}

export interface CopyResponse {}

interface Target {
  location: string;
  document: string;
  id: string;
}

export interface Destination extends Target {}

export interface Source extends Target {}

export interface SourcesRequest {
  destination: Destination;
  artefactNumber: string;
  process?: Filter<string>;
  createdAt?: Filter<string>;
  updatedAt?: Filter<string>;
  company?: Filter<string>;
  branch?: Filter<string>;
  customer?: Filter<string>;
  serviceObject?: Filter<string>;
}

export interface SourcesResponse {
  sources: AvailableSource[];
}

export interface AvailableSource {
  location: string;
  document: string;
  id: string;
  number: string;
  createdAt: string;
  updatedAt: string;
}
