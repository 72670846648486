import { Navigate, RouteObject } from "react-router";
import { ConfirmationIndexRoute } from "@/routes/gesec/processes/[processId]/rlt/confirmations/_components/confirmation-index-route";
import { ConfirmationRoute } from "@/routes/gesec/processes/[processId]/rlt/confirmations/[confirmationId]/confirmation-route";
import { ConfirmationDataRoute } from "@/routes/gesec/processes/[processId]/rlt/confirmations/[confirmationId]/data/confirmation-data-route";
import { ConfirmationTreatmentRoute } from "@/routes/gesec/processes/[processId]/rlt/confirmations/[confirmationId]/treatment/confirmation-treatment-route";
import { ConfirmationCalculationRoute } from "@/routes/gesec/processes/[processId]/rlt/confirmations/[confirmationId]/calculation/confirmation-calculation-route";
import { ConfirmationCustomerAddressRoute } from "@/routes/gesec/processes/[processId]/rlt/confirmations/[confirmationId]/customer-address/confirmation-customer-address-route";
import { ConfirmationRepresentativeRoute } from "@/routes/gesec/processes/[processId]/rlt/confirmations/[confirmationId]/representative/confirmation-representative-route";

export const confirmationsRoutes: RouteObject[] = [
  {
    index: true,
    element: <ConfirmationIndexRoute />,
  },
  {
    path: ":confirmationId",
    element: <ConfirmationRoute />,
    children: [
      {
        index: true,
        element: <Navigate to="data" />,
      },
      {
        path: "data",
        element: <ConfirmationDataRoute />,
      },
      {
        path: "treatment",
        element: <ConfirmationTreatmentRoute />,
      },
      {
        path: "calculation",
        element: <ConfirmationCalculationRoute />,
      },
      {
        path: "customer-address",
        element: <ConfirmationCustomerAddressRoute />,
      },
      {
        path: "representative",
        element: <ConfirmationRepresentativeRoute />,
      },
    ],
  },
];
