import { H1 } from "@/shared/components/ui/typography";
import t from "@/lang/lang";
import { CustomersTable } from "@/routes/customers/_components/customers-table";
import { Outlet } from "react-router";
import { MainPageContent } from "@/routes/_components/main-page";

export function CustomersRoute() {
  return <Outlet />;
}

export function CustomersIndexRoute() {
  return (
    <MainPageContent>
      <H1 className="mb-4">{t("Kunden")}</H1>
      <CustomersTable />
    </MainPageContent>
  );
}
