import t from "@/lang/lang";
import {
  TableBody,
  TableCell,
  TableHead,
  TableRow,
} from "@/shared/components/ui/table";
import { ProcessKind } from "@/services/backend/processes/process/kinds";
import { Star } from "lucide-react";
import { ArtefactKind } from "@/services/backend/artefacts/kind";
import { formatDate } from "@/shared/lib/utilities/date";
import { ArtefactIcon } from "@/shared/components/domain/artefact/artefact-icon";
import {
  ScrollBodyTable,
  ScrollBodyTableHeader,
} from "@/shared/components/ui/table-scroll-body";
import { ScrollArea, ScrollBar } from "@/shared/components/ui/scroll-area";
import { useSearchContext } from "@/routes/_components/search/search-context";
import { NavLink } from "react-router";
import { Button } from "@/shared/components/ui/button";
import { useArtefactPath } from "@/shared/components/domain/artefact/use-artefact-path";
import { ArtefactPreview } from "@/shared/components/domain/artefact/preview/artefact-preview";

export interface ArtefactResult {
  id: string;
  artefactKind: ArtefactKind;
  topResult: boolean;
  artefactNumber: string;
  processId: string;
  processKind: ProcessKind;
  processNumber: string;
  customerId: string;
  customerName: string;
  companyId: string;
  companyName: string;
  createdAt: string;
}

export function ArtefactResults({
  artefacts,
}: {
  artefacts: ArtefactResult[];
}) {
  const enableScroll = artefacts.length > 4;

  return (
    <div>
      <div className="flex justify-between">
        <h5 className="text-lg font-bold">{t("Dokumente")}</h5>
        <span className="text-muted-foreground text-sm">
          {artefacts.length} {t("gefunden")}
        </span>
      </div>
      <ScrollArea className={enableScroll ? "h-[20vh] border-b" : ""}>
        <ScrollBodyTable>
          <ScrollBodyTableHeader>
            <TableRow>
              <TableHead>{t("Top")}</TableHead>
              <TableHead>{t("Typ")}</TableHead>
              <TableHead>{t("Nummer")}</TableHead>
              <TableHead>{t("Kunde")}</TableHead>
              <TableHead>{t("Vorgang")}</TableHead>
              <TableHead>{t("Datum")}</TableHead>
              <TableHead>{t("Aktionen")}</TableHead>
            </TableRow>
          </ScrollBodyTableHeader>
          <TableBody>
            {artefacts.map((artefact) => (
              <ArtefactResultTableRow artefact={artefact} key={artefact.id} />
            ))}
            {artefacts.length === 0 && (
              <TableRow>
                <TableCell colSpan={6} className="py-2 text-center">
                  {t("Keine Dokumente gefunden")}
                </TableCell>
              </TableRow>
            )}
          </TableBody>
        </ScrollBodyTable>
        {enableScroll && <ScrollBar forceMount />}
      </ScrollArea>
    </div>
  );
}

function ArtefactResultTableRow({ artefact }: { artefact: ArtefactResult }) {
  return (
    <TableRow>
      <TableCell className="py-0 whitespace-nowrap">
        {artefact.topResult && <Star className="h-5 w-5" />}
      </TableCell>
      <TableCell className="py-0 whitespace-nowrap">
        <div className="flex space-x-2">
          <ArtefactIcon artefactKind={artefact.artefactKind} />
          <span>{t(artefact.artefactKind)}</span>
        </div>
      </TableCell>
      <TableCell className="py-0 whitespace-nowrap">
        {artefact.artefactNumber}
      </TableCell>
      <TableCell className="py-0 whitespace-nowrap">
        {artefact.customerName}
      </TableCell>
      <TableCell className="py-0 whitespace-nowrap">
        {artefact.processNumber}
      </TableCell>
      <TableCell className="py-0 whitespace-nowrap">
        {formatDate(artefact.createdAt)}
      </TableCell>
      <TableCell className="flex py-0 whitespace-nowrap">
        <ArtefactPreview
          artefactId={artefact.id}
          artefactKind={artefact.artefactKind}
          processKind={artefact.processKind}
        />
        <NavigateToArtefact artefact={artefact} />
      </TableCell>
    </TableRow>
  );
}

function NavigateToArtefact({ artefact }: { artefact: ArtefactResult }) {
  const { id, processKind, processId, artefactKind, companyId } = artefact;
  const { setOpen } = useSearchContext();

  const { path } = useArtefactPath({
    artefactId: id,
    artefactKind,
    processKind,
    processId,
    companyId,
  });

  if (!path) {
    return null;
  }

  return (
    <NavLink to={path}>
      <Button variant="ghost" size="sm" onClick={() => setOpen(false)}>
        <span>{t("Navigieren")}</span>
      </Button>
    </NavLink>
  );
}
