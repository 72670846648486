import { useShowCompanyQuery } from "@/services/backend/company/company";
import { useRltWorkOrderShowQuery } from "@/services/backend/rlt/work-orders/service";

export function useDocumentationPath(
  workOrderId: string,
  documentationId: string,
): string {
  const { data: workOrder } = useRltWorkOrderShowQuery({ id: workOrderId });
  const { data: company } = useShowCompanyQuery({
    id: workOrder?.companyId ?? "",
  });

  if (!company) {
    return "";
  }

  return `/tenant/${company.portalRoute}/processes/rlt/${workOrder!.processId}/work-orders/${workOrderId}/documentation/${documentationId}`;
}
