import {
  Dialog,
  DialogClose,
  DialogContent,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { useState } from "react";
import { Trash2 } from "lucide-react";
import { Button } from "@/shared/components/ui/button";
import t from "@/lang/lang";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { useNavigate, useParams } from "react-router";
import { useHtzWorkOrderDeleteMutation } from "@/services/backend/htz/work-order/service";

export function DeleteWorkOrderDialog({
  workOrderId,
}: {
  workOrderId: string;
}) {
  const [open, setOpen] = useState(false);
  const [doDelete, { isLoading, error, isSuccess, reset }] =
    useHtzWorkOrderDeleteMutation();

  const navigate = useNavigate();
  const { tenant, processId } = useParams();

  if (isSuccess) {
    setOpen(false);
    reset();
    navigate(`/tenant/${tenant}/processes/htz/${processId}/work-orders`);
  }

  const onClick = () => {
    if (isLoading) {
      return;
    }

    doDelete({ id: workOrderId });
  };

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger asChild>
        <Button variant="outline">
          <Trash2 className="h-5 w-5" />
        </Button>
      </DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Arbeitsschein wirklich löschen?")}</DialogTitle>
        </DialogHeader>
        <RTKQueryErrorAlert error={error} />
        <DialogFooter>
          <DialogClose asChild>
            <Button variant="outline">{t("Abbrechen")}</Button>
          </DialogClose>
          <Button variant="destructive" onClick={onClick}>
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
