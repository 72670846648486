export default function t(string?: string) {
  if (!string) {
    return "";
  }
  return translations[string] ?? string;
}

const translations: Record<string, string> = {
  AccessPanel: "Revisionsöffnungen",
  actions: "Aktionen",
  actuallyTreated: "Tatsächlich behandelt",
  AerosolSeparator: "Aerosolabscheider",
  AirHandlingUnit: "RLT-Anlage",
  AirHandlingUnitSchwerpunkt: "RLT-Anlage Schwerpunktreinigung",
  AirHandlingUnitSystem: "RLT-Anlage System",
  AirHandlingUnits: "RLT-Anlagen",
  airMicrobial: "Luftkeimprobe",
  AluminiumMeshGreaseFilter: "Alugestrick-Fettfilter",
  after: "Nachher",
  Anemostat: "Anemostat",
  archived: "Archiviert",
  AwayAirDuctAngular: "Fortluftleitung eckig",
  AwayAirDuctRound: "Fortluftleitung rund",
  basePrice: "Grundpreis",
  before: "Vorher",
  billingContact: "Kontakt Rechnungen",
  buildUp: "Verschmutzung",
  BuildUp: "Verschmutzung",
  BranchManager: "Niederlassungsleiter",
  "certificate.Certificate": "Zertifikat",
  cleanTime: "Zeit",
  Closed: "Geschlossen",
  caso: "Caso",
  Caso: "Caso",
  calculation: "Kalkulation",
  calculations: "Kalkulationen",
  certificate: "Zertifikat",
  certificates: "Zertifikate",
  comment: "Kommentar",
  completed: "Erledigt",
  Completed: "Erledigt",
  componentKind: "Komponente",
  componentData: "Basisdaten",
  "confirmation.Confirmation": "Auftragsbestätigung",
  confirmation: "Auftragsbestätigung",
  confirmations: "Auftragsbestätigungen",
  Construction: "Bauart",
  contact: "Ansprechpartner",
  contract: "Vertrag",
  contracts: "Verträge",
  contractContact: "Kontakt Vertrag",
  contractPrice: "Vertragspreis",
  "count * value": "Anzahl * Wert",
  CrossFlowHeatExchanger: "Kreuzstromwärmetauscher",
  cycle: "Turnus",
  cycleDiscount: "Turnusrabatt",
  Director: "Geschäftsführer",
  documentation: "Dokumentation",
  download: "Heruntergeladen",
  done: "Beendet",
  draft: "Entwurf",
  Ductwork: "Abluftleitungen",
  DummyPlate: "Blindblech",
  dustDensity: "Staubdichtemessung",
  email: "E-Mail",
  ExhaustDuct: "Abluftleitung",
  ExhaustDuctAngular: "Abluftleitung eckig",
  ExhaustDuctRound: "Abluftleitung rund",
  ExhaustHood: "Dunstabzugshaube",
  ExhaustHoodSystem: "DAH System",
  ExhaustHoods: "Dunstabzugshauben",
  ExtraHours: "Zusatzarbeiten",
  failed: "Nicht bestanden",
  Fatty: "Fettig",
  fax: "Fax",
  finalPrice: "Endpreis",
  formula: "Formel",
  FreeText: "Freitext",
  "h / 30m²": "Std / 30m²",
  "h / m": "Std / m",
  "h / m²": "Std / m²",
  "h / piece": "Std / Stück",
  Heydal: "Heydal",
  HoodCanopy: "Haube",
  lastminuteriskanalysis: "Gefährdungsbeurteilung",
  "lmra: last minute risk assessment is not completed":
    "Gefährdungsbeurteilung nicht abgeschlossen",
  "length * value": "Länge * Wert",
  "length * width * value": "Länge * Breite * Wert",
  "((length * width) / 30) * value": "((Länge * Breite) / 30) * Wert",
  Lighting: "Beleuchtungskörper",
  malt: "Malz",
  Malt: "Malz",
  "min / piece": "min / Stück",
  "min / m": "min / m",
  "min / m²": "min / m²",
  "min / 30m²": "min / 30m²",
  None: "Keine",
  Oily: "Ölig",
  offer: "Angebot",
  offers: "Angebote",
  offerContact: "Kontakt Angebot",
  offerPrice: "Angebot",
  "offer.Offer": "Angebot",
  Open: "Offen",
  order: "Auftragseingang",
  orders: "Auftragseingänge",
  OutdoorAirDuctAngular: "Außenluftleitung eckig",
  OutdoorAirDuctRound: "Außenluftleitung rund",
  Overnight: "Übernacht",
  overview: "Übersicht",
  passed: "Bestanden",
  PassiveArea: "Passivfläche",
  Pending: "Ausstehend",
  PerDiem: "Tagesspesen",
  phone: "Telefon",
  position: "Pos",
  post: "Post",
  primaryContact: "Hauptansprechpartner",
  Processing: "In Bearbeitung",
  RecirculatingSprayHumidifier: "Umlaufsprühbefeuchter",
  RotaryHeatExchanger: "Rotationswärmetauscher",
  report: "Bericht",
  reports: "Berichte",
  requirements: "Bestandsaufnahmen",
  requirementsContact: "Kontakt Aufnahme",
  Resinous: "Verharzt",
  running: "In Bearbeitung",
  serviceContact: "Kontakt Service",
  SplitUnit: "Splitgerät",
  SupplyDuct: "Zuluftleitung",
  SupplyDuctAngular: "Zuluftleitung eckig",
  SupplyDuctRound: "Zuluftleitung rund",
  surfaceMicrobial: "Oberflächenkeimprobe",
  treatment: "Behandlung",
  treatments: "Behandlungen",
  tipCommission: "Tippprovision",
  VBSConfirmationContact: "Kontakt VBS Auftragsbestätigung",
  VBSOfferContact: "Kontakt VBS Angebot",
  VBSWorkOrderContact: "Kontakt VBS Arbeitsschein",
  VentilatedCeiling: "Abluftdecke",
  VentilatedCeilingSystem: "Abluftdecke System",
  VentilatedCeilings: "Abluftdecken",
  water: "Wasserprobe",
  "workorder.WorkOrder": "Arbeitsschein",
  workorder: "Arbeitsschein",
  workorders: "Arbeitsscheine",
  "work-orders": "Arbeitsscheine",
  checkpoint: "Monitore",
  "fly-exterminator": "Fluginsektenvernichter",
  alora: "ALoRa",
  adhesive: "Klebefläche",
  tube: "Röhre",
};
