import { Kind } from "@/services/backend/vbs/treatments/component";
import {
  ExhaustHoodSystem as ExhaustHoodSystemFormula,
  VentilatedCeilingSystem as VentilatedCeilingSystemFormula,
} from "@/services/backend/vbs/treatments/formula";
import { Position } from "@/services/backend/vbs/treatments/position";
import { fCleanTime } from "./utils";

export function CleanTimeCell(props: { postion: Position }) {
  const { postion } = props;

  switch (postion.formula.componentKind) {
    case Kind.ExhaustHoodSystem:
      return <ExhaustHoodSystem position={postion} />;
    case Kind.VentilatedCeilingSystem:
      return <VentilatedCeilingSystem position={postion} />;
    case Kind.AccessPanel:
    case Kind.FreeText:
    case Kind.ExtraHours:
      return <div>-</div>;
    default:
      return (
        <div className="flex h-6 items-center justify-end font-bold whitespace-nowrap">
          {fCleanTime(postion.cleanTime)}
        </div>
      );
  }
}

function ExhaustHoodSystem({ position }: { position: Position }) {
  const formula = position.formula.parameters as ExhaustHoodSystemFormula;
  return (
    <div className="whitespace-nowrap">
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.exhaustHood.cleanTime)}
      </div>
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.aerosolSeparator.cleanTime)}
      </div>
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.aluminiumMeshGreaseFilter.cleanTime)}
      </div>
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.lighting.cleanTime)}
      </div>
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.dummyPlate.cleanTime)}
      </div>
      <div className="mt-2 flex h-6 items-center justify-end font-bold">
        {fCleanTime(position.cleanTime)}
      </div>
    </div>
  );
}

function VentilatedCeilingSystem({ position }: { position: Position }) {
  const formula = position.formula.parameters as VentilatedCeilingSystemFormula;
  return (
    <div className="whitespace-nowrap">
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.ventilatedCeiling.cleanTime)}
      </div>
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.aerosolSeparator.cleanTime)}
      </div>
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.lighting.cleanTime)}
      </div>
      <div className="flex h-6 items-center justify-end">
        {fCleanTime(formula.dummyPlate.cleanTime)}
      </div>
      <div className="mt-2 flex h-6 items-center justify-end font-bold">
        {fCleanTime(position.cleanTime)}
      </div>
    </div>
  );
}
