import { format } from "date-fns";
import { de } from "date-fns/locale";

/**
 * Converts a RFC3339 date string to a date string in the format YYYY-MM-DD.
 * This can be used in input fields.
 * @param rfc3339
 */
export function rfc3339toDateString(rfc3339: string): string {
  if (rfc3339 === "" || rfc3339 === undefined) {
    return "";
  }
  // handling golang zero value
  if (rfc3339 === "0001-01-01T00:00:00Z") {
    return "";
  }
  return new Date(rfc3339).toISOString().split("T")[0];
}

/**
 * Converts a date string in the format YYYY-MM-DD to a RFC3339 date string.
 * This can be used to transform the value returned input fields.
 * @param date
 */
export function dateStringToRFC3339(date: string): string {
  if (date === "") {
    return "";
  }
  return new Date(date).toISOString();
}

export function rfc3339toDate(rfc3339: string): Date | undefined {
  if (rfc3339 === "" || rfc3339 === undefined) {
    return undefined;
  }
  // handling golang zero value
  if (rfc3339 === "0001-01-01T00:00:00Z") {
    return undefined;
  }
  return new Date(rfc3339);
}

/**
 * Formats a RFC3339 date string to a locale-specific date string in the format YYYY-MM-DD.
 * @param isoString - The ISO date string to convert.
 * @param locale - The locale to use for formatting.
 * @returns A formatted date string.
 */
export const formatDate = (isoString: string, locale = de): string => {
  if (isoString === "") {
    return "";
  }

  const date = new Date(isoString);
  return format(date, "P", { locale }); // P is the date format for the locale
};

/**
 * Formats a RFC3339 date string to a locale-specific time string.
 * @param isoString - The ISO date string to convert.
 * @param locale - The locale to use for formatting.
 * @returns A formatted time string.
 */
export const formatTime = (isoString: string, locale = de): string => {
  if (isoString === "") {
    return "";
  }

  const date = new Date(isoString);
  return format(date, "p", { locale }); // p is the time format for the locale
};

/**
 * Formats a RFC3339 date string to a locale-specific date and time string in the format YYYY-MM-DD'T'HH:mm.
 * @param dateString - The RFC3339 date string to convert.
 * @param locale - The locale to use for formatting.
 * @returns A formatted date and time string.
 */
export function formatDateTimeLocal(dateString: string, locale = de): string {
  if (dateString === "") {
    return "";
  }

  const date = rfc3339toDate(dateString);
  if (date === undefined) {
    return "";
  }

  return format(date, "yyyy-MM-dd'T'HH:mm", { locale });
}
