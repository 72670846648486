import { useState } from "react";
import {
  AccessPanel,
  AerosolSeparator,
  AirHandlingUnit,
  AluminiumMeshGreaseFilter,
  Anemostat,
  BuildUp,
  Component,
  Construction,
  DummyPlate,
  ExhaustDuctAngular,
  ExhaustDuctRound,
  ExhaustHood,
  ExhaustHoodSystem,
  ExtraHours,
  FreeText,
  Kind,
  Lighting,
  PassiveArea,
  SupplyDuctAngular,
  SupplyDuctRound,
  VentilatedCeiling,
  VentilatedCeilingSystem,
} from "@/services/backend/vbs/treatments/component";
import { useDebouncedMutationWithPersistenceStateContextUpdate } from "@/shared/lib/debounce/debounce";
import {
  UpdateComponentRequest,
  useVbsTreatmentUpdateActuallyTreatedMutation,
  useVbsTreatmentUpdateComponentMutation,
} from "@/services/backend/vbs/treatments/service";
import {
  FloatNumberInput,
  IntNumberInput,
} from "@/routes/gesec/processes/_components/ui/number-input";
import t from "@/lang/lang";
import {
  Select,
  SelectContent,
  SelectGroup,
  SelectItem,
  SelectLabel,
  SelectTrigger,
  SelectValue,
} from "@/shared/components/ui/select";

export function ComponentCell({
  treatmentId,
  positionId,
  component: propComponent,
  actuallyTreated = false,
}: {
  treatmentId: string;
  positionId: string;
  component: Component;
  actuallyTreated?: boolean;
}) {
  const [request, setRequest] = useState<UpdateComponentRequest>({
    id: treatmentId,
    positionId,
    component: propComponent,
  });
  const [update, { isLoading, error, isSuccess, reset }] =
    useVbsTreatmentUpdateComponentMutation();
  const [
    updateActuallyTreated,
    {
      isLoading: isLoadingActually,
      error: errorActually,
      isSuccess: isSuccessActually,
      reset: resetActually,
    },
  ] = useVbsTreatmentUpdateActuallyTreatedMutation();
  useDebouncedMutationWithPersistenceStateContextUpdate(
    request,
    actuallyTreated ? updateActuallyTreated : update,
    actuallyTreated ? isLoadingActually : isLoading,
    actuallyTreated ? errorActually : error,
    actuallyTreated ? isSuccessActually : isSuccess,
    actuallyTreated ? resetActually : reset,
    250,
  );

  switch (propComponent.kind) {
    case Kind.AccessPanel:
      return (
        <ComponentAccessPanel
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AerosolSeparator:
      return (
        <ComponentAerosolSeparator
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AirHandlingUnit:
      return (
        <ComponentAirHandlingUnit
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.AluminiumMeshGreaseFilter:
      return (
        <ComponentAluminiumMeshGreaseFilter
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.Anemostat:
      return (
        <ComponentAnemostat
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.DummyPlate:
      return (
        <ComponentDummyPlate
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExhaustDuctAngular:
      return (
        <ComponentExhaustDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExhaustDuctRound:
      return (
        <ComponentExhaustDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExhaustHoodSystem:
      return (
        <ComponentExhaustHoodSystem
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExhaustHood:
      return (
        <ComponentExhaustHood
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.ExtraHours:
      return (
        <ComponentExtraHours
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.FreeText:
      return (
        <ComponentFreeText
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.Lighting:
      return (
        <ComponentLighting
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.PassiveArea:
      return (
        <ComponentPassiveArea
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.SupplyDuctAngular:
      return (
        <ComponentSupplyDuctAngular
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.SupplyDuctRound:
      return (
        <ComponentSupplyDuctRound
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.VentilatedCeilingSystem:
      return (
        <ComponentVentilatedCeilingSystem
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    case Kind.VentilatedCeiling:
      return (
        <ComponentVentilatedCeiling
          component={request.component}
          onChange={(component) => setRequest({ ...request, component })}
        />
      );
    default:
      return null;
  }
}

function ComponentAccessPanel(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as AccessPanel;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Anzahl:")}
      </span>
      <IntNumberInput
        value={data.count}
        onChange={(count) =>
          onChange({
            kind: component.kind,
            data: { count },
          })
        }
      />
    </div>
  );
}

function ComponentAerosolSeparator(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as AerosolSeparator;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Anzahl:")}
      </span>
      <IntNumberInput
        value={data.count}
        onChange={(count) =>
          onChange({
            kind: component.kind,
            data: { count },
          })
        }
      />
    </div>
  );
}

function ComponentAirHandlingUnit(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as AirHandlingUnit;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Zeit (h):")}
      </span>
      <FloatNumberInput
        value={data.cleanTime}
        onChange={(cleanTime) =>
          onChange({
            ...component,
            data: { ...data, cleanTime },
          })
        }
      />
    </div>
  );
}

function ComponentAluminiumMeshGreaseFilter(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as AluminiumMeshGreaseFilter;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Anzahl:")}
      </span>
      <IntNumberInput
        value={data.count}
        onChange={(count) =>
          onChange({
            kind: component.kind,
            data: { count },
          })
        }
      />
    </div>
  );
}

function ComponentAnemostat(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as Anemostat;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Anzahl:")}
      </span>
      <IntNumberInput
        value={data.count}
        onChange={(count) =>
          onChange({
            kind: component.kind,
            data: { count },
          })
        }
      />
    </div>
  );
}

function ComponentDummyPlate(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as DummyPlate;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center justify-end whitespace-nowrap">
        {t("Anzahl:")}
      </span>
      <IntNumberInput
        value={data.count}
        onChange={(count) =>
          onChange({
            kind: component.kind,
            data: { count },
          })
        }
      />
    </div>
  );
}

function ComponentExhaustDuctAngular(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as ExhaustDuctAngular;
  return (
    <div>
      <div className="flex items-center space-x-4">
        <div className="flex w-32 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Länge (m):")}
          </span>
          <FloatNumberInput
            value={data.length}
            onChange={(length) =>
              onChange({
                kind: component.kind,
                data: { ...data, length },
              })
            }
          />
        </div>
        <div className="flex grow justify-end space-x-4">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("BuildUp")}:
          </span>
          <BuildUpSelect
            value={data.buildUp}
            onChange={(buildUp) =>
              onChange({
                kind: component.kind,
                data: { ...data, buildUp },
              })
            }
          />
        </div>
      </div>
      <div className="my-1">
        <hr />
      </div>
      <div className="flex space-x-4">
        <div className="flex w-32 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Höhe (mm):")}
          </span>
          <IntNumberInput
            value={data.height}
            onChange={(height) =>
              onChange({
                kind: component.kind,
                data: { ...data, height },
              })
            }
          />
        </div>
        <div className="flex w-24 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Breite (mm):")}
          </span>
          <IntNumberInput
            value={data.width}
            onChange={(width) =>
              onChange({
                kind: component.kind,
                data: { ...data, width },
              })
            }
          />
        </div>
      </div>
      <div className="">
        <span className="text-sx text-gray-500">
          {t("Höhe und Breite haben keinen Einfluss auf die Zeit.")}
        </span>
      </div>
    </div>
  );
}

function BuildUpSelect({
  value,
  onChange,
}: {
  value: BuildUp;
  onChange: (e: BuildUp) => void;
}) {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="h-6 w-28">
        <SelectValue>{t(value)}</SelectValue>
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{t("Verschmutzung")}</SelectLabel>
          {Object.values(BuildUp).map((buildUp) => (
            <SelectItem value={buildUp} key={buildUp}>
              {t(buildUp)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}

function ComponentExhaustDuctRound(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as ExhaustDuctRound;
  return (
    <div>
      <div className="flex space-x-4">
        <div className="flex w-32 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Länge (m):")}
          </span>
          <FloatNumberInput
            value={data.length}
            onChange={(length) =>
              onChange({
                kind: component.kind,
                data: { ...data, length },
              })
            }
          />
        </div>
        <div className="flex grow justify-end space-x-4">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("BuildUp")}:
          </span>
          <BuildUpSelect
            value={data.buildUp}
            onChange={(buildUp) =>
              onChange({
                kind: component.kind,
                data: { ...data, buildUp },
              })
            }
          />
        </div>
      </div>
      <div className="my-1">
        <hr />
      </div>
      <div className="flex space-x-4">
        <div className="flex w-48 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Durchmesser (mm):")}
          </span>
          <IntNumberInput
            value={data.diameter}
            onChange={(diameter) =>
              onChange({
                kind: component.kind,
                data: { ...data, diameter },
              })
            }
          />
        </div>
      </div>
      <div className="">
        <span className="text-sx text-gray-500">
          {t("Durchmesser hat keinen Einfluss auf die Zeit.")}
        </span>
      </div>
    </div>
  );
}

function ComponentExhaustHoodSystem(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as ExhaustHoodSystem;
  return (
    <div>
      <ComponentExhaustHood
        component={data.exhaustHood}
        onChange={(exhaustHood) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              exhaustHood,
            },
          })
        }
      />
      <ComponentAerosolSeparator
        component={data.aerosolSeparator}
        onChange={(aerosolSeparator) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              aerosolSeparator,
            },
          })
        }
      />
      <ComponentAluminiumMeshGreaseFilter
        component={data.aluminiumMeshGreaseFilter}
        onChange={(aluminiumMeshGreaseFilter) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              aluminiumMeshGreaseFilter,
            },
          })
        }
      />
      <ComponentLighting
        component={data.lighting}
        onChange={(lighting) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              lighting,
            },
          })
        }
      />
      <ComponentDummyPlate
        component={data.dummyPlate}
        onChange={(dummyPlate) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              dummyPlate,
            },
          })
        }
      />
    </div>
  );
}

function ComponentExhaustHood(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as ExhaustHood;
  return (
    <div className="flex space-x-4">
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Länge (m):")}
        </span>
        <FloatNumberInput
          value={data.length}
          onChange={(length) =>
            onChange({
              kind: component.kind,
              data: { ...data, length },
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Breite (m):")}
        </span>
        <FloatNumberInput
          value={data.width}
          onChange={(width) =>
            onChange({
              kind: component.kind,
              data: { ...data, width },
            })
          }
        />
      </div>
    </div>
  );
}

function ComponentExtraHours(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as ExtraHours;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center whitespace-nowrap">
        {t("Stunden:")}
      </span>
      <IntNumberInput
        value={data.hours}
        onChange={(hours) =>
          onChange({
            kind: component.kind,
            data: { hours },
          })
        }
      />
    </div>
  );
}

function ComponentFreeText(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as FreeText;
  return (
    <div>
      <div className="mb-2">
        <input
          className="min-w-[200px] rounded border border-gray-300 px-1 py-0.5"
          value={data.text}
          onChange={(e) =>
            onChange({
              kind: component.kind,
              data: { ...data, text: e.target.value },
            })
          }
        />
      </div>
      <div className="flex w-full justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Euro:")}
        </span>
        <FloatNumberInput
          value={data.price}
          onChange={(price) =>
            onChange({
              kind: component.kind,
              data: { ...data, price },
            })
          }
          width={32}
          fractions={2}
        />
      </div>
    </div>
  );
}

function ComponentLighting(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as Lighting;
  return (
    <div className="flex w-32 justify-between">
      <span className="flex h-6 items-center whitespace-nowrap">
        {t("Anzahl:")}
      </span>
      <IntNumberInput
        value={data.count}
        onChange={(count) =>
          onChange({
            kind: component.kind,
            data: { count },
          })
        }
      />
    </div>
  );
}

function ComponentPassiveArea(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as PassiveArea;
  return (
    <div className="flex space-x-4">
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Länge (m):")}
        </span>
        <FloatNumberInput
          value={data.length}
          onChange={(length) =>
            onChange({
              kind: component.kind,
              data: { ...data, length },
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Breite (m):")}
        </span>
        <FloatNumberInput
          value={data.width}
          onChange={(width) =>
            onChange({
              kind: component.kind,
              data: { ...data, width },
            })
          }
        />
      </div>
    </div>
  );
}

function ComponentSupplyDuctAngular(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as SupplyDuctAngular;
  return (
    <div>
      <div className="flex space-x-4">
        <div className="flex w-32 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Länge (m):")}
          </span>
          <FloatNumberInput
            value={data.length}
            onChange={(length) =>
              onChange({
                kind: component.kind,
                data: { ...data, length },
              })
            }
          />
        </div>
      </div>
      <div className="my-1">
        <hr />
      </div>
      <div className="flex space-x-4">
        <div className="flex w-32 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Höhe (mm):")}
          </span>
          <IntNumberInput
            value={data.height}
            onChange={(height) =>
              onChange({
                kind: component.kind,
                data: { ...data, height },
              })
            }
          />
        </div>
        <div className="flex w-24 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Breite (mm):")}
          </span>
          <IntNumberInput
            value={data.width}
            onChange={(width) =>
              onChange({
                kind: component.kind,
                data: { ...data, width },
              })
            }
          />
        </div>
      </div>
      <div className="">
        <span className="text-sx text-gray-500">
          {t("Höhe und Breite haben keinen Einfluss auf die Zeit.")}
        </span>
      </div>
    </div>
  );
}

function ComponentSupplyDuctRound(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as SupplyDuctRound;
  return (
    <div>
      <div className="flex space-x-4">
        <div className="flex w-32 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Länge (m):")}
          </span>
          <FloatNumberInput
            value={data.length}
            onChange={(length) =>
              onChange({
                kind: component.kind,
                data: { ...data, length },
              })
            }
          />
        </div>
      </div>
      <div className="my-1">
        <hr />
      </div>
      <div className="flex space-x-4">
        <div className="flex w-48 justify-between">
          <span className="flex h-6 items-center whitespace-nowrap">
            {t("Durchmesser (mm):")}
          </span>
          <IntNumberInput
            value={data.diameter}
            onChange={(diameter) =>
              onChange({
                kind: component.kind,
                data: { ...data, diameter },
              })
            }
          />
        </div>
      </div>
      <div className="">
        <span className="text-sx text-gray-500">
          {t("Durchmesser hat keinen Einfluss auf die Zeit.")}
        </span>
      </div>
    </div>
  );
}

function ComponentVentilatedCeilingSystem(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as VentilatedCeilingSystem;
  return (
    <div>
      <ComponentVentilatedCeiling
        component={data.ventilatedCeiling}
        onChange={(ventilatedCeiling) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              ventilatedCeiling,
            },
          })
        }
      />
      <ComponentAerosolSeparator
        component={data.aerosolSeparator}
        onChange={(aerosolSeparator) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              aerosolSeparator,
            },
          })
        }
      />
      <ComponentLighting
        component={data.lighting}
        onChange={(lighting) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              lighting,
            },
          })
        }
      />
      <ComponentDummyPlate
        component={data.dummyPlate}
        onChange={(dummyPlate) =>
          onChange({
            kind: component.kind,
            data: {
              ...data,
              dummyPlate,
            },
          })
        }
      />
    </div>
  );
}

function ComponentVentilatedCeiling(props: {
  component: Component;
  onChange: (component: Component) => void;
}) {
  const { component, onChange } = props;
  const data = component.data as VentilatedCeiling;
  return (
    <div className="flex space-x-4">
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Länge (m):")}
        </span>
        <FloatNumberInput
          value={data.length}
          onChange={(length) =>
            onChange({
              kind: component.kind,
              data: { ...data, length },
            })
          }
        />
      </div>
      <div className="flex w-32 justify-between">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Breite (m):")}
        </span>
        <FloatNumberInput
          value={data.width}
          onChange={(width) =>
            onChange({
              kind: component.kind,
              data: { ...data, width },
            })
          }
        />
      </div>
      <div className="flex grow justify-end space-x-4">
        <span className="flex h-6 items-center whitespace-nowrap">
          {t("Construction")}
        </span>
        <ConstructionSelect
          value={data.construction}
          onChange={(construction) => {
            onChange({
              kind: component.kind,
              data: { ...data, construction },
            });
          }}
        />
      </div>
    </div>
  );
}

function ConstructionSelect({
  value,
  onChange,
}: {
  value: Construction;
  onChange: (e: Construction) => void;
}) {
  return (
    <Select value={value} onValueChange={onChange}>
      <SelectTrigger className="h-6 min-w-16">
        <SelectValue placeholder={t("Auswählen")} />
      </SelectTrigger>
      <SelectContent>
        <SelectGroup>
          <SelectLabel>{t("Bauart")}</SelectLabel>
          {Object.values(Construction).map((construction) => (
            <SelectItem value={construction} key={construction}>
              {t(construction)}
            </SelectItem>
          ))}
        </SelectGroup>
      </SelectContent>
    </Select>
  );
}
