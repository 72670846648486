import {
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle,
} from "@/shared/components/ui/card";
import {
  useQpmCatalogDeleteMutation,
  useQpmCatalogGetActiveQuery,
  useQpmCatalogPutMutation,
  useQpmCatalogSetActiveMutation,
} from "@/services/backend/qpm/catalog/service";
import { Loader2, Pencil, Save, X } from "lucide-react";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import t from "@/lang/lang";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/shared/components/ui/tooltip";
import { Button } from "@/shared/components/ui/button";
import { formatDate } from "@/shared/lib/utilities/date";
import { ReactElement, useEffect, useState } from "react";
import {
  ActiveCatalog,
  Catalog,
  PutCatalogRequest,
} from "@/services/backend/qpm/catalog/types";
import { usePersistenceStateUpdateOnTrigger } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/shared/components/ui/dialog";
import { Textarea } from "@/shared/components/ui/textarea";

export function CatalogInfoCard({ catalog }: { catalog: Catalog }) {
  const [isEditActive, setIsEditActive] = useState<boolean>(false);
  const [request, setRequest] = useState<PutCatalogRequest>({
    id: catalog.id,
    name: catalog.name,
    description: catalog.description,
    createdAt: catalog.createdAt,
    updatedAt: new Date().toISOString(),
  });

  const [put, { error, isLoading, isSuccess, reset }] =
    useQpmCatalogPutMutation();

  const { onTrigger: triggerSave } = usePersistenceStateUpdateOnTrigger(
    request,
    put,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setIsEditActive(false);
    }
  }, [isSuccess]);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return <Loader2 className="animate-spin" />;
  }

  return (
    <>
      <CardHeader className="relative">
        <div className="flex items-center justify-between gap-2">
          <CardTitle>
            {catalog.name !== "" ? catalog.name : t("-- Kein Name --")}
          </CardTitle>
          <TooltipProvider>
            <div className="flex items-center justify-end gap-1">
              <Tooltip>
                <TooltipTrigger asChild>
                  <Button
                    size="icon"
                    variant="outline"
                    onClick={() => setIsEditActive((prev) => !prev)}
                  >
                    {!isEditActive && <Pencil className="h-4 w-4" />}
                    {isEditActive && <X className="h-4 w-4" />}
                  </Button>
                </TooltipTrigger>
                <TooltipContent>
                  {!isEditActive && <p>{t("Bearbeiten")}</p>}
                  {isEditActive && <p>{t("Abbrechen")}</p>}
                </TooltipContent>
              </Tooltip>
              {isEditActive && (
                <Tooltip>
                  <TooltipTrigger asChild>
                    <Button
                      className=""
                      size="icon"
                      variant="default"
                      onClick={triggerSave}
                    >
                      <Save className="h-4 w-4" />
                    </Button>
                  </TooltipTrigger>
                  <TooltipContent>
                    <p>{t("Speichern")}</p>
                  </TooltipContent>
                </Tooltip>
              )}
            </div>
          </TooltipProvider>
        </div>
        <CardDescription>
          {!isEditActive &&
            (catalog.description !== ""
              ? catalog.description
              : t("-- Keine Beschreibung vorhanden --"))}
          {isEditActive && (
            <Textarea
              value={request.description}
              onChange={(e) =>
                setRequest((prev) => ({
                  ...prev,
                  description: e.target.value,
                }))
              }
            />
          )}
        </CardDescription>
      </CardHeader>
      <CardContent>
        <div className="flex flex-col gap-2">
          <details className="hover:cursor-pointer" open={isEditActive}>
            <div className="bg-secondary mt-2 grid grid-cols-3 gap-2 rounded-lg border p-2">
              <div className="flex flex-col">
                <span>{t("Erstellt am:")}</span>
                <span>{formatDate(catalog.createdAt)}</span>
              </div>
              <div className="flex flex-col">
                <span>{t("Zuletzt geändert:")}</span>
                <span>{formatDate(catalog.updatedAt)}</span>
              </div>
              <CatalogActiveDetail
                catalogId={catalog.id}
                isEditActive={isEditActive}
              />
            </div>
          </details>
          {isEditActive && (
            <div className="flex w-full items-end justify-end">
              <DeleteCatalogDialog catalog={catalog}>
                <Button
                  variant="destructive"
                  onClick={() => {
                    // navigate(`..`);
                  }}
                >
                  {t("Katalog löschen")}
                </Button>
              </DeleteCatalogDialog>
            </div>
          )}
        </div>
      </CardContent>
    </>
  );
}

type CatalogActiveDetailProps = {
  catalogId: string;
  isEditActive: boolean;
};

function CatalogActiveDetail({
  catalogId,
  isEditActive,
}: CatalogActiveDetailProps) {
  const { data: catalog, error, isLoading } = useQpmCatalogGetActiveQuery({});

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  return (
    <div className="flex flex-col items-start">
      <span>{t("Aktiv seit:")}</span>
      {isLoading && <Loader2 className="animate-spin" />}
      {catalog && catalogId === catalog.id && (
        <span>{formatDate(catalog.activeSince)}</span>
      )}
      {((catalog && catalogId !== catalog.id) || (!error && !catalog)) && (
        <div className="flex items-center justify-start gap-4">
          <span>-- {t("nicht aktiv")} --</span>
          {isEditActive && (
            <SwitchActiveCatalogDialog
              currentActiveCatalog={catalog}
              newActiveCatalogId={catalogId}
            >
              <Button className="h-fit py-0.5" size="sm">
                {t("Aktiv setzen")}
              </Button>
            </SwitchActiveCatalogDialog>
          )}
        </div>
      )}
    </div>
  );
}

type SwitchActiveCatalogDialogProps = {
  currentActiveCatalog?: ActiveCatalog;
  newActiveCatalogId: string;
  children?: ReactElement;
};

function SwitchActiveCatalogDialog({
  currentActiveCatalog,
  newActiveCatalogId,
  children,
}: SwitchActiveCatalogDialogProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [setActiveCatalog, { error, isLoading, isSuccess, reset }] =
    useQpmCatalogSetActiveMutation();
  const { onTrigger: triggerSetActive } = usePersistenceStateUpdateOnTrigger(
    {
      id: newActiveCatalogId,
      activeSince: new Date().toISOString(),
    },
    setActiveCatalog,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
    }
  }, [isSuccess]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>{t("Katalog aktivieren?")}</DialogTitle>
          <DialogDescription>
            {t(
              "Bitte bestätigen Sie, ob Sie diesen Katalog als aktiven Katalog festlegen möchten.",
            )}
            <br />
            {t(
              "Durch die Aktivierung werden die Daten dieses Katalogs für die Erstellung weiterer Meilensteine, " +
                "wie die Bestandsaufnahme und Kalkulation, verwendet.",
            )}
            <br />
            {currentActiveCatalog && (
              <>
                <b>{t("Achtung")}</b>
                {": "} {t("Der derzeit aktive Katalog ")}{" "}
                <b>{currentActiveCatalog.name}</b>{" "}
                {t(
                  "wird dadurch ersetzt und ist anschließend nicht mehr aktiv!",
                )}
              </>
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            disabled={isLoading}
          >
            {t("Abbrechen")}
          </Button>
          <Button
            variant="default"
            onClick={triggerSetActive}
            disabled={isLoading}
          >
            {t("Bestätigen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}

type DeleteCatalogDialogProps = {
  catalog: Catalog;
  children: ReactElement;
  onDeleteSuccess?: () => void;
};

function DeleteCatalogDialog({
  catalog,
  children,
  onDeleteSuccess,
}: DeleteCatalogDialogProps) {
  const [open, setOpen] = useState<boolean>(false);
  const [deleteMonitor, { error, isLoading, isSuccess, reset }] =
    useQpmCatalogDeleteMutation();
  const { onTrigger: triggerDelete } = usePersistenceStateUpdateOnTrigger(
    { id: catalog.id },
    deleteMonitor,
    isLoading,
    error,
    isSuccess,
    reset,
    { toastError: true, toastSuccess: true },
  );

  useEffect(() => {
    if (isSuccess) {
      setOpen(false);
      if (onDeleteSuccess) {
        onDeleteSuccess();
      }
    }
  }, [isSuccess, onDeleteSuccess]);

  return (
    <Dialog open={open} onOpenChange={setOpen}>
      <DialogTrigger>{children}</DialogTrigger>
      <DialogContent>
        <DialogHeader>
          <DialogTitle>
            {t("Wollen Sie den Katalog")} {catalog.name}{" "}
            {t("unwiderruflich löschen?")}
          </DialogTitle>
          <DialogDescription>
            {t(
              "Dieser Vorgang kann nicht rückgängig gemacht werden. Dadurch wird der Katalog und dessen Inhalt dauerhaft gelöscht und von den Servern entfernt.",
            )}
          </DialogDescription>
        </DialogHeader>
        <DialogFooter>
          <Button
            variant="outline"
            onClick={() => setOpen(false)}
            disabled={isLoading}
          >
            {t("Abbrechen")}
          </Button>
          <Button
            variant="destructive"
            onClick={triggerDelete}
            disabled={isLoading}
          >
            {t("Löschen")}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
}
