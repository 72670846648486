import { useParams } from "react-router";
import { useHtzConfirmationShowQuery } from "@/services/backend/htz/confirmations/service";
import { RTKQueryErrorAlert } from "@/shared/components/domain/errors/rtk-query-error-alert";
import { Card } from "@/shared/components/ui/card";
import t from "@/lang/lang";
import { PersistenceContextProvider } from "@/shared/lib/persistence-state/provider";
import { PersistenceStateUsingContext } from "@/shared/lib/persistence-state/persistence-state-info";
import {
  ConfirmationContextProvider,
  useConfirmationContext,
} from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/_components/confirmation-context";
import {
  useHtzPositionCatalogShowActiveCatalogQuery,
  useHtzPositionCatalogShowQuery,
} from "@/services/backend/htz/position-catalog/service";
import { useHtzAirHandlingUnitListQuery } from "@/services/backend/htz/ahu/service";
import { ConfirmationPdf } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/_components/confirmation-pdf";
import { DeleteConfirmationDialog } from "@/routes/gesec/processes/[processId]/htz/confirmations/[confirmationId]/_components/delete-confirmation-dialog";
import { hasFieldError } from "@/shared/app-lib/errors/validation-error";
import { useHtzOrderListQuery } from "@/services/backend/htz/orders/service";
import { useHtzOfferListQuery } from "@/services/backend/htz/offers/service";
import {
  ArtefactPage,
  ArtefactPageActions,
  ArtefactPageContent,
  ArtefactPageHeader,
  ArtefactPageNavigation,
  ArtefactPageNavigationLink,
  ArtefactPageNavigationOutlet,
} from "@/shared/service-manager/default-ui/artefact-page/artefact-page";

export function ConfirmationRoute() {
  const { confirmationId } = useParams();

  const {
    confirmation,
    catalog,
    airHandlingUnits,
    offers,
    orders,
    isLoading,
    error,
  } = useConfirmationRouteDependencies(confirmationId!);

  if (error) {
    return <RTKQueryErrorAlert error={error} />;
  }

  if (isLoading) {
    return (
      <Card className="text-muted-foreground animate-pulse p-6">
        {t("Lade Daten...")}
      </Card>
    );
  }

  return (
    <PersistenceContextProvider>
      <ConfirmationContextProvider
        confirmation={confirmation!}
        catalog={catalog!}
        airHandlingUnits={airHandlingUnits!}
        offers={offers!}
        orders={orders!}
      >
        <ConfirmationPage />
      </ConfirmationContextProvider>
    </PersistenceContextProvider>
  );
}

function useConfirmationRouteDependencies(confirmationId: string) {
  const {
    data: confirmation,
    isLoading: confirmationIsLoading,
    error: confirmationError,
  } = useHtzConfirmationShowQuery({
    id: confirmationId!,
  });

  const {
    data: activeCatalog,
    isLoading: activeCatalogIsLoading,
    error: activeCatalogError,
  } = useHtzPositionCatalogShowActiveCatalogQuery(
    {
      companyId: confirmation?.companyId ?? "",
    },
    {
      skip: confirmation === undefined,
    },
  );

  const {
    data: positionCatalog,
    isLoading: catalogIsLoading,
    error: catalogError,
  } = useHtzPositionCatalogShowQuery(
    {
      id: activeCatalog?.catalogId ?? "",
    },
    {
      skip: activeCatalog === undefined || activeCatalog?.catalogId === null,
    },
  );

  const {
    data: ahuList,
    isLoading: ahuIsLoading,
    error: ahuError,
  } = useHtzAirHandlingUnitListQuery(
    {
      customer: {
        active: confirmation !== undefined,
        values: [confirmation?.customerId ?? ""],
      },
    },
    {
      skip: confirmation === undefined,
    },
  );
  const airHandlingUnits = ahuList?.airHandlingUnits ?? [];

  const {
    data: orderList,
    isLoading: orderIsLoading,
    error: orderError,
  } = useHtzOrderListQuery(
    {
      process: {
        active: true,
        values: [confirmation?.processId ?? ""],
      },
      immutable: {
        active: false,
        values: [true],
      },
    },
    {
      skip: confirmation === undefined,
    },
  );
  const orders = orderList?.orders ?? [];

  const {
    data: offerList,
    isLoading: offerIsLoading,
    error: offerError,
  } = useHtzOfferListQuery(
    {
      process: {
        active: true,
        values: [confirmation?.processId ?? ""],
      },
      immutable: {
        active: true,
        values: [true],
      },
    },
    {
      skip: confirmation === undefined,
    },
  );
  const offers = offerList?.offers ?? [];

  const isLoading =
    confirmationIsLoading ||
    activeCatalogIsLoading ||
    catalogIsLoading ||
    ahuIsLoading ||
    orderIsLoading ||
    offerIsLoading;

  const error = (() => {
    switch (true) {
      case confirmationError !== undefined:
        return confirmationError;
      case activeCatalogError !== undefined:
        return activeCatalogError;
      case catalogError !== undefined:
        return catalogError;
      case ahuError !== undefined:
        return ahuError;
      case orderError !== undefined:
        return orderError;
      case offerError !== undefined:
        return offerError;
      case activeCatalog?.catalogId === null:
        // fake 404 error
        return {
          status: 404,
        };
      default:
        return undefined;
    }
  })();

  return {
    confirmation,
    catalog: positionCatalog,
    airHandlingUnits,
    offers,
    orders,
    isLoading,
    error,
  };
}

function ConfirmationPage() {
  const { confirmation, validationError } = useConfirmationContext();

  const hasDataError = hasFieldError(validationError, "date");
  const hasPosError = hasFieldError(validationError, "positions");
  const hasAddrError = hasFieldError(
    validationError,
    "postalAddress",
    "serviceAddress",
  );
  const hasRepError = hasFieldError(validationError, "representative");

  const links = [
    {
      label: t("Auftragsbestätugung"),
      to: "data",
      hasValidationError: hasDataError,
    },
    {
      label: t("Leistungen"),
      to: "positions",
      hasValidationError: hasPosError,
    },
    {
      label: t("Adressen"),
      to: "addresses",
      hasValidationError: hasAddrError,
    },
    {
      label: t("Betreuer"),
      to: "representative",
      hasValidationError: hasRepError,
    },
  ];

  return (
    <ArtefactPage>
      <ArtefactPageHeader>
        <ArtefactPageNavigation>
          {links.map((link) => (
            <ArtefactPageNavigationLink
              key={link.to}
              to={link.to}
              hasValidationError={link.hasValidationError}
            >
              {link.label}
            </ArtefactPageNavigationLink>
          ))}
        </ArtefactPageNavigation>
        <ArtefactPageActions>
          <PersistenceStateUsingContext className="mr-2" />
          <DeleteConfirmationDialog confirmationId={confirmation.id} />
          <ConfirmationPdf confirmation={confirmation} />
        </ArtefactPageActions>
      </ArtefactPageHeader>
      <ArtefactPageContent>
        <ArtefactPageNavigationOutlet />
      </ArtefactPageContent>
    </ArtefactPage>
  );
}
